import YourDetails from "src/pages/public/onboarding/tabs/YourDetails";
import styles from "../../ManageStaffMember.module.scss";
import { useStyling, useFetch, useCachedQuery } from "src/shiftly-ui";
import { forwardRef, useImperativeHandle, useMemo, useRef } from "react";
import AddressDetails from "src/pages/public/onboarding/tabs/AddressDetails";
import BankDetails from "src/pages/public/onboarding/tabs/BankDetails";
import SuperMembership from "src/pages/public/onboarding/tabs/SuperMembership";
import TaxDeclaration from "src/pages/public/onboarding/tabs/tax_declaration/TaxDeclaration";

const ManageStaffMemberOnboarding = forwardRef(function ManageStaffMemberOnboarding(
  { employeeOnboard = {}, data = {} },
  ref
) {
  const styling = useStyling(styles);

  const yourDetailsRef = useRef(null);
  const addressDetailsRef = useRef(null);
  const bankDetailsRef = useRef(null);
  const taxDeclarationRef = useRef(null);
  const superMembershipsRef = useRef(null);

  const {
    EmployeeOnboard: { LoadOnboardingDetails },
  } = useCachedQuery();

  const {
    data: {
      bank_accounts,
      employment,
      internalStaff,
      address,
      onboardRequest,
      super_membership,
      tax_declaration,
      location,
      authToken,
    },
  } = useFetch({
    request: {
      id: LoadOnboardingDetails,
      entity: "EmployeeOnboard",
      method: "loadOnboardingRequest",
      data: {
        token: employeeOnboard.token,
      },
    },
    options: {
      staleTime: 0,
    },
    dependency: employeeOnboard.token,
  });

  useImperativeHandle(ref, () => ({
    async submit() {
      const yourDetails = yourDetailsRef.current.submit();
      const addressDetails = addressDetailsRef.current.submit();
      const bankDetails = bankDetailsRef.current.submit();
      const taxDeclaration = taxDeclarationRef.current.submit();
      const superMemberships = superMembershipsRef.current.submit();

      const results = await Promise.all([yourDetails, addressDetails, bankDetails, taxDeclaration, superMemberships]);
      if (results.some((result) => !result)) {
        return false;
      }

      return true;
    },
  }));

  const sharedProps = useMemo(
    () => ({
      onboardRequest,
      setFormLoading: () => {},
      minified: true,
      authToken,
      bank_accounts,
      employment,
      internalStaff,
      address,
      super_membership,
      tax_declaration: { ...tax_declaration, ...data },
      location,
    }),
    [
      onboardRequest,
      authToken,
      bank_accounts,
      employment,
      internalStaff,
      address,
      super_membership,
      tax_declaration,
      location,
      data,
    ]
  );

  return (
    <>
      <div className={styling("onboarding-heading")}>
        <p className={styling("explain")}>
          The following fields are set by the employee, however you can update them here:
        </p>
      </div>
      <div className={styling("onboarding-content")}>
        <div className={styling("onboarding-block")}>
          <div className={styling("onboarding-title")}>
            <h4>Personal Details</h4>
          </div>
          <YourDetails ref={yourDetailsRef} {...sharedProps} />
        </div>
        <div className={styling("onboarding-block")}>
          <div className={styling("onboarding-title")}>
            <h4>Address Details</h4>
          </div>
          <AddressDetails ref={addressDetailsRef} {...sharedProps} />
        </div>

        <div className={styling("onboarding-block")}>
          <div className={styling("onboarding-title")}>
            <h4>Tax Declaration</h4>
          </div>
          <TaxDeclaration ref={taxDeclarationRef} {...sharedProps} />
        </div>
        <div className={styling("onboarding-block")}>
          <div className={styling("onboarding-title")}>
            <h4>Bank Details</h4>
          </div>
          <BankDetails ref={bankDetailsRef} {...sharedProps} />
        </div>
        <div className={styling("onboarding-block")}>
          <div className={styling("onboarding-title")}>
            <h4>Super Membership</h4>
          </div>
          <SuperMembership ref={superMembershipsRef} {...sharedProps} />
        </div>
      </div>
    </>
  );
});

export default ManageStaffMemberOnboarding;
