import { Input, Each, useStyling, EmptyState, ShiftlySuspense } from "src/shiftly-ui";
import styles from "./SearchAndDisplay.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGrid2, faList, faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";
import { Fragment, useMemo, useState } from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useUncontrolled } from "uncontrollable";

const SearchAndDisplay = (props) => {
  /*************************************** State *************************************** */
  const [listMode, setListMode] = useState(props.defaultView ?? "list");
  const [searchText, setSearchText] = useState("");

  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  const {
    elements = [],
    elementsName = "",
    searchPlaceholder = "Search...",
    searchFields = [],
    isLoading = false,
    hideToggle = false,
    hideSearchBar = false,
    link = {},
    emptyStateText,
    image = "default.svg",
  } = useUncontrolled(props, {
    tab: "setTab",
  });

  /********************************** Refs & Constants ********************************* */
  const [parent] = useAutoAnimate();

  /******************************** Functions & Memos ********************************** */
  const filteredElements = useMemo(
    () =>
      searchText
        ? elements.filter((element) =>
            searchFields.some((field) =>
              element["original"]?.[field]?.toLowerCase()?.includes(searchText?.toLowerCase())
            )
          )
        : elements,
    [elements, searchFields, searchText]
  );

  return (
    <div className={styling("container")}>
      <div className={styling("w-100", "flex", "flex-row", !hideToggle && "with-toggle")}>
        {!hideSearchBar && (
          <div className={styling("w-100")}>
            <Input
              label={"Search " + elementsName}
              setValue={setSearchText}
              value={searchText}
              placeholder={searchPlaceholder}
              icon={{ icon: faMagnifyingGlass, side: "right" }}
              link={link}
            />
          </div>
        )}
      </div>
      <ShiftlySuspense loading={isLoading}>
        <EmptyState items={elements} text={emptyStateText} className={styling("my-3")} image={image}>
          <div className={styling("mx-auto", "w-100", listMode)} ref={parent}>
            <Each
              of={filteredElements}
              render={({ key, ...element }) =>
                listMode === "grid" ? (
                  <Fragment key={key}>{element.cardContent}</Fragment>
                ) : (
                  <Fragment key={key}>{element.listContent}</Fragment>
                )
              }
            />
          </div>
        </EmptyState>
      </ShiftlySuspense>
    </div>
  );
};

const SearchControlButton = ({ icon, active = false, setListMode, mode }) => {
  const styling = useStyling(styles);
  return (
    <div className={styling("control-button", active && "active-control")} onClick={() => setListMode(mode)}>
      <FontAwesomeIcon icon={icon} />
    </div>
  );
};

export default SearchAndDisplay;
