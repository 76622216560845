import { Fragment } from "react";
import styles from "./Toggle.module.scss";
import { useUncontrolled } from "uncontrollable";
import { useStyling } from "src/shiftly-ui";
import clsx from "clsx";

export const Toggle = (props) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const {
    tab = 0,
    setTab = () => {},
    tabs = [],
    className,
  } = useUncontrolled(props, {
    tab: "setTab",
  });

  return (
    <div className={clsx(styling("w-100"), className)}>
      <div className={clsx(styling("tabs-container", "py-1", "sm-py-0"))}>
        {tabs.map((tabItem, index) => {
          return (
            <Fragment key={index}>
              <div className={styling("tab-item", tab === index && "active")} onClick={() => setTab(index)}>
                {tabItem.icon}
                <p className={styling("label", "ml-2")}>{tabItem.text}</p>
              </div>
              {index !== tabs.length - 1 && <div className={styling("divider")}></div>}
            </Fragment>
          );
        })}
      </div>
      <div className={styling("tab-slider-container")}>
        <div
          className={styling("tab-slider")}
          style={{
            width: `${100 / tabs.length}%`,
            left: `${(tab / tabs.length) * 100}%`,
          }}
        ></div>
      </div>
    </div>
  );
};
