import {
  convertStringTimeToDate,
  roundNumber,
  useAsyncEffect,
  applyLateNightPenalties,
  getHourlyRateForDayAndLevel,
  calculateBreakTime,
  useFetch,
} from "src/shiftly-ui";
import { useReducer, useMemo } from "react";
import useBusiness from "./useBusiness";
import Holidays from "date-holidays";
import useShiftlyLocation from "./useShiftlyLocation";

const actionTypes = {
  SET_FIELD: "SET_FIELD",
  RESET_VALUES: "RESET_VALUES",
};

const initialState = {
  shiftDate: new Date(),
  startTime: "09:00",
  endTime: "17:00",
  wageInfo: { adjustedMin: 0, adjustedMax: 0, lateNightPenalty: 0 },
  increasedHourlyRateModifier: 0,
  position: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_FIELD:
      return { ...state, [action.field]: action.value };
    case actionTypes.RESET_VALUES:
      return initialState;
    default:
      return state;
  }
};

const useShiftTotals = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { shiftDate, startTime, endTime, wageInfo, increasedHourlyRateModifier, position } = state;
  const { activeBusiness } = useBusiness();
  const { activeLocation } = useShiftlyLocation();

  const { data: awards } = useFetch({
    request: {
      id: "Award.6dd6b610-0903-4268-8c69-d23c7fe678b2",
      entity: "Award",
      criteria: {
        award: activeBusiness?.industries?.map((industry) => industry.award_code),
      },
    },
    dependency: activeBusiness?.industries,
  });

  const voidEffect = useMemo(
    () =>
      Boolean(startTime === ":" || endTime === ":" || !shiftDate || !position || !activeBusiness || !activeLocation),
    [activeBusiness, activeLocation, position, shiftDate, startTime, endTime]
  );
  const duration = useMemo(() => {
    if (voidEffect) return 0;
    const award_code = position?.industry?.award_code;
    const { startDate, endDate } = convertStringTimeToDate(shiftDate, startTime, endTime);
    const { unpaidBreakTime } = calculateBreakTime({
      startTime: startDate,
      endTime: endDate,
      timezone: activeLocation?.timezone,
      award_code,
    });
    const dur = (endDate - startDate) / 1000 / 60 / 60 - unpaidBreakTime;
    return isNaN(dur) ? 0 : dur;
  }, [voidEffect, shiftDate, startTime, endTime, position?.industry?.award_code, activeLocation]);

  useAsyncEffect(async () => {
    if (voidEffect || isNaN(duration)) return;
    const { startDate, endDate } = convertStringTimeToDate(shiftDate, startTime, endTime);
    const baseMax = Math.max(...awards.map((item) => item.full_time_rate)) || 0;
    const baseMin = Math.min(...awards.map((item) => item.full_time_rate)) || 0;

    const adjustedMax =
      getHourlyRateForDayAndLevel(
        startDate,
        position?.classification_level,
        baseMax,
        activeLocation?.timezone,
        Holidays
      ) || 0;

    const adjustedMin =
      getHourlyRateForDayAndLevel(
        startDate,
        position?.classification_level,
        baseMin,
        activeLocation?.timezone,
        Holidays
      ) || 0;

    const lateNightPenalty = applyLateNightPenalties(startDate, endDate, activeLocation?.timezone) || 0;

    dispatch({
      type: actionTypes.SET_FIELD,
      field: "wageInfo",
      value: { adjustedMax, adjustedMin, lateNightPenalty },
    });
  }, [position, shiftDate, activeLocation, startTime, endTime, voidEffect]);

  const totalEmployerContribution = useMemo(() => {
    if (!position) return 0;
    return ((position?.position_rate_modifier || 0) + Number(increasedHourlyRateModifier || 0)) * duration;
  }, [position, duration, increasedHourlyRateModifier]);

  const lateNightPenalty = useMemo(() => wageInfo.lateNightPenalty, [wageInfo]);

  const subTotals = useMemo(() => {
    const { adjustedMin, adjustedMax } = wageInfo;
    return {
      min: adjustedMin * duration + totalEmployerContribution + lateNightPenalty,
      max: adjustedMax * duration + totalEmployerContribution + lateNightPenalty,
    };
  }, [duration, wageInfo, totalEmployerContribution, lateNightPenalty]);

  const superContribution = useMemo(() => {
    const { min, max } = subTotals;
    return {
      min: min * Number(process.env.REACT_APP_SHIFTLY_SUPER_RATE),
      max: max * Number(process.env.REACT_APP_SHIFTLY_SUPER_RATE),
    };
  }, [subTotals]);

  const shiftlyFees = useMemo(() => {
    const { min, max } = subTotals;
    if (!min || !max) return { min: 0, max: 0 };
    return {
      min: min * Number(process.env.REACT_APP_SHIFTLY_PERCENTAGE) + Number(process.env.REACT_APP_SHIFTLY_FLAT_FEE),
      max: max * Number(process.env.REACT_APP_SHIFTLY_PERCENTAGE) + Number(process.env.REACT_APP_SHIFTLY_FLAT_FEE),
    };
  }, [subTotals]);

  const totalRange = useMemo(() => {
    const { min, max } = subTotals;
    return {
      min: min + superContribution.min + shiftlyFees.min,
      max: max + superContribution.max + shiftlyFees.max,
    };
  }, [shiftlyFees, superContribution, subTotals]);

  return useMemo(
    () => ({
      shiftDispatch: dispatch,
      actionTypes,
      shiftDate,
      startTime,
      endTime,
      increasedHourlyRateModifier,
      position,
      baseWageFromAward: { min: roundNumber(wageInfo.adjustedMin), max: roundNumber(wageInfo.adjustedMax) },
      superContribution: {
        min: roundNumber(superContribution.min),
        max: roundNumber(superContribution.max),
      },
      shiftlyFees: {
        min: roundNumber(shiftlyFees.min),
        max: roundNumber(shiftlyFees.max),
      },
      totalEmployerContribution: roundNumber(totalEmployerContribution),
      lateNightPenalty: roundNumber(lateNightPenalty),
      totalRange: {
        min: roundNumber(totalRange.min),
        max: roundNumber(totalRange.max),
      },
      duration: roundNumber(duration),
    }),
    [
      shiftDate,
      startTime,
      endTime,
      increasedHourlyRateModifier,
      wageInfo,
      superContribution,
      shiftlyFees,
      totalEmployerContribution,
      lateNightPenalty,
      totalRange,
      duration,
      position,
    ]
  );
};
export default useShiftTotals;
