import { Toggle, useStyling } from "src/shiftly-ui";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRepeat, faGear, faLocationDot, faCalendar, faUserCheck } from "@fortawesome/pro-regular-svg-icons";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import { useMemo, useState } from "react";

import styles from "./MobileLocationSelector.module.scss";

const MobileLocationSelector = () => {
  /*************************************** State *************************************** */
  const [tab, setTab] = useState(0);

  /*************************************** Hooks *************************************** */
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { activeLocation } = useShiftlyLocation();
  const styling = useStyling(styles);

  /******************************** Functions & Memos ********************************** */
  const tabs = useMemo(
    () => [
      { text: "Today", icon: <FontAwesomeIcon icon={faCalendar} /> },
      { text: "Upcoming", icon: <FontAwesomeIcon icon={faUserCheck} /> },
    ],
    []
  );

  if (pathname.includes("business-account")) return null;

  return (
    <div className={styling("w-100")}>
      <div className={styling("py-1", "px-2", "w-100")}>
        <div className={styling("searchbox")} onClick={() => navigate("/business-account")}>
          <div className={styling("search-icon")}>
            <FontAwesomeIcon icon={faLocationDot} />
          </div>
          <div className={styling("text-center")}>
            <p className={styling("filter-header")}>
              <strong>{activeLocation?.name}</strong>
            </p>
            <p className={styling("filter-addnotes")}>
              Tap to switch locations{" "}
              <span>
                <FontAwesomeIcon icon={faArrowsRepeat} />
              </span>
            </p>
          </div>
          <div className={styling("filterbox")}>
            <FontAwesomeIcon icon={faGear} />
          </div>
        </div>
      </div>
      {pathname === "/mobile-shifts" && <Toggle tab={tab} setTab={setTab} tabs={tabs} />}
    </div>
  );
};

export default MobileLocationSelector;
