import { useState, useEffect } from "react";
import clsx from "clsx";
import styles from "./LoadingSpinner.module.css";

const mountedStyle = { animation: "inAnimation 500ms ease-in" };

const unmountedStyle = {
  animation: "outAnimation 500ms ease-out",
  animationFillMode: "forwards",
};

export const LoadingSpinner = ({ colour = "orange", loader, minVisibleDuration = 1000 }) => {
  /*************************************** State *************************************** */
  const [isMounted, setIsMounted] = useState(loader);
  const [lastMountedTime, setLastMountedTime] = useState(Date.now());

  /******************************** Effects & Handles ********************************** */
  useEffect(() => {
    let timeoutId;
    if (loader) {
      setIsMounted(true);
      setLastMountedTime(Date.now());
    } else {
      const timeSinceMounted = Date.now() - lastMountedTime;
      const remainingTime = Math.max(0, minVisibleDuration - timeSinceMounted);

      timeoutId = setTimeout(() => setIsMounted(false), remainingTime);
    }
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loader, minVisibleDuration]);

  return (
    <span
      style={isMounted ? mountedStyle : unmountedStyle}
      className={clsx(styles["loader"], colour && styles[colour])}
    ></span>
  );
};
