import { useState, useEffect, forwardRef, useImperativeHandle, useMemo } from "react";
import { Pagination, Navigation } from "swiper/modules";
import { Swiper as SwiperNative, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useStyling } from "src/shiftly-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-solid-svg-icons";

export const Swiper = forwardRef(function Swiper(
  {
    slides = [],
    hasPagination = true,
    setSwiper: setExposedSwiper = () => {},
    spaceBetween = 50,
    slidesPerView = 1,
    setPage = () => {},
    withNavigation = false,
    ...props
  },
  ref
) {
  /*************************************** State *************************************** */
  const [swiper, setSwiper] = useState(null);

  const styling = useStyling();
  /******************************** Effects & Handles ********************************** */
  useImperativeHandle(
    ref,
    () => ({
      slideNext: () => swiper?.slideNext(),
      slidePrev: () => swiper?.slidePrev(),
    }),
    [swiper]
  );

  useEffect(() => {
    if (swiper) {
      setExposedSwiper(swiper);
      setPage(swiper.activeIndex);
    }
  }, [swiper, setExposedSwiper, setPage]);

  const modules = useMemo(() => {
    const modules = [];
    if (hasPagination) modules.push(Pagination);
    if (withNavigation) modules.push(Navigation);
    return modules;
  }, [hasPagination, withNavigation]);

  return (
    <div className={styling("position-relative", "w-100")}>
      {/* Swiper Component */}
      <SwiperNative
        style={{
          "--swiper-pagination-color": "var(--orange)",
        }}
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        modules={modules}
        onSwiper={(swiperInstance) => {
          setSwiper(swiperInstance);
        }}
        onActiveIndexChange={(swiperInstance) => {
          setPage(swiperInstance.activeIndex);
        }}
        pagination={{ clickable: true }}
        navigation={withNavigation ? { nextEl: ".swiper-next", prevEl: ".swiper-prev" } : false}
        {...props}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>{slide}</SwiperSlide>
        ))}
      </SwiperNative>

      {/* Custom Navigation Buttons */}
      {withNavigation && (
        <>
          <button
            className="swiper-prev"
            style={{
              position: "absolute",
              left: "var(--v2)",
              top: "50%",
              transform: "translateY(-50%)",
              color: "var(--secondary-900)",
              border: "none",
              cursor: "pointer",
              zIndex: "10",
              fontSize: "var(--h5-font-size)",
            }}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <button
            className="swiper-next"
            style={{
              position: "absolute",
              right: "var(--v2)",
              top: "50%",
              transform: "translateY(-50%)",
              color: "var(--secondary-900)",
              border: "none",
              cursor: "pointer",
              zIndex: "10",
              fontSize: "var(--h5-font-size)",
            }}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </>
      )}
    </div>
  );
});
