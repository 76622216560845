import { useFetch } from "src/shiftly-ui";

const useStaffData = (internalStaff = {}) => {
  const {
    isLoading: taxIsLoading,
    data: [taxDeclaration],
  } = useFetch({
    request: {
      entity: "TaxDeclaration",
      criteria: {
        internalStaff: internalStaff?._id,
        employee_onboard: internalStaff?.employee_onboard,
      },
    },
    dependency: internalStaff?._id,
    options: {
      staleTime: 0,
    },
  });

  const {
    isLoading: employmentIsLoading,
    data: [employment],
  } = useFetch({
    request: {
      entity: "Employment",
      criteria: {
        internalStaff: internalStaff?._id,
        employee_onboard: internalStaff?.employee_onboard,
      },
    },
    dependency: internalStaff?._id,
    options: {
      staleTime: 0,
    },
  });

  const {
    data: [employeeOnboard],
    isLoading: onboardIsLoading,
  } = useFetch({
    request: {
      entity: "EmployeeOnboard",
      criteria: {
        _id: internalStaff?.employee_onboard,
      },
    },
    dependency: internalStaff?._id,
    options: {
      staleTime: 0,
    },
  });

  return {
    loading: taxIsLoading || employmentIsLoading || onboardIsLoading,
    taxDeclaration,
    employment,
    employeeOnboard,
  };
};

export default useStaffData;
