import { useFetch, useToast, useCachedQuery, useAlerts } from "src/shiftly-ui";
import { useCallback } from "react";
import mongoose from "mongoose";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import useIntegration from "src/hooks/useIntegration";
import { useNavigate } from "react-router-dom";

const useInternalStaffTable = () => {
  /*************************************** Hooks *************************************** */
  const toast = useToast();
  const { confirm } = useAlerts();
  const { activeLocation } = useShiftlyLocation();
  const { activeIntegration: integration } = useIntegration("xero");
  const navigate = useNavigate();
  const {
    InternalStaff: { GetAllStaffIncludingIncomplete },
  } = useCachedQuery();

  /************************************** Queries ************************************** */

  const {
    post: sendSync,
    isLoading: loadingAction,
    refresh,
  } = useFetch({
    options: {
      onSuccess: () => {
        toast.success("Successfully sent onboarding request to the staff member.");
        refresh(GetAllStaffIncludingIncomplete);
      },
      onError: () => {
        toast.error("We'll need a couple more details from you before we can onboard this employee.");
      },
    },
  });

  const { post: sendRemind } = useFetch({
    options: {
      onSuccess: () => toast.success("Successfully sent reminder to your staff member."),
      onError: () => toast.error("Failed to send reminder to the staff member. Please try again later."),
    },
  });

  const { post } = useFetch({
    options: {
      onSuccess: () => {
        refresh(GetAllStaffIncludingIncomplete);
      },
    },
  });

  /******************************** Functions & Memos ********************************** */

  const editStaffMember = useCallback(
    (row) => navigate("/people/internal", { state: { internalStaff: row } }),
    [navigate]
  );

  const deleteStaffMember = useCallback(
    async (row = {}) => {
      if (
        !(await confirm({
          label: "Delete Staff Member",
          text: `Are you sure you want to remove this ${row.first_name} from the system? Future shifts will be marked as 'Unpublished'. This action cannot be undone.`,
          confirmText: "Delete",
          cancelText: "Cancel",
          inverse: true,
        }))
      )
        return;

      post({
        entity: "InternalStaff",
        method: "delete",
        criteria: {
          _id: row._id,
        },
      });
    },
    [confirm, post]
  );

  const sendOnboardingRequest = useCallback(
    async (row) => {
      const employee_onboard = new mongoose.Types.ObjectId();
      const { _id } = row;

      const { status } = await sendSync([
        {
          entity: "InternalStaff",
          method: "update",
          criteria: {
            _id,
          },
          data: {
            employee_onboard,
            sync_status: "pending",
          },
        },
        {
          entity: "Employment",
          method: "update",
          criteria: {
            internalStaff: _id,
          },
          data: {
            employee_onboard,
          },
        },
        {
          entity: "TaxDeclaration",
          method: "update",
          criteria: {
            internalStaff: _id,
          },
          data: {
            employee_onboard,
          },
        },
        {
          entity: "BankAccount",
          method: "update",
          criteria: {
            internalStaff: _id,
          },
          data: {
            employee_onboard,
          },
        },
        {
          entity: "SuperMembership",
          method: "update",
          criteria: {
            internalStaff: _id,
          },
          data: {
            employee_onboard,
          },
        },
        {
          entity: "EmployeeOnboard",
          method: "create",
          data: [
            {
              _id: employee_onboard,
              status: "pending",
              internalStaff: _id,
              integration: integration._id,
              location: activeLocation?._id,
            },
          ],
        },
      ]);

      if (status === 400) editStaffMember(row);
    },
    [activeLocation?._id, sendSync, integration, editStaffMember]
  );

  const sendReminder = useCallback(
    async (row) => {
      sendRemind({
        entity: "EmployeeOnboard",
        method: "sendReminder",
        data: {
          internalStaff: row?._id,
        },
      });
    },
    [sendRemind]
  );

  const handleXeroColumn = useCallback(
    async (row) => {
      const { sync_status } = row;

      let confirmLabel;
      let confirmText;
      let buttonText;
      let callback;

      switch (sync_status) {
        case "unsynced":
          confirmLabel = "Send Sync Request";
          confirmText =
            "Are you sure you want to send a sync request to the staff member? This will charge your business account at your current onboarding rate.";
          buttonText = "Send";
          callback = () => sendOnboardingRequest(row);
          break;

        case "outofsync":
          confirmLabel = "Sync from Xero";
          confirmText =
            "This action will import all the staff member's details from Xero, are you sure you would like to continue?";
          callback = () =>
            post({
              entity: "InternalStaff",
              method: "importEmployeeDetailsFromXero",
              data: {
                internalStaff: row._id,
                integration: integration._id,
              },
            });
          buttonText = "Import";
          break;

        case "failed":
          confirmLabel = "Sync To Xero";
          confirmText = "This will attempt to sync the staff member to Xero. Are you sure you would like to continue?";
          buttonText = "Sync to Xero";
          callback = () =>
            post({
              entity: "InternalStaff",
              method: "syncShiftlyDetailsToXero",
              data: {
                internalStaff: row._id,
              },
            });
          break;
        default:
          return;
      }

      if (
        !(await confirm({
          label: confirmLabel,
          text: confirmText,
          confirmText: buttonText,
          cancelText: "Cancel",
        }))
      )
        return;

      callback?.();
    },
    [sendOnboardingRequest, confirm, post, integration]
  );

  return { sendOnboardingRequest, sendReminder, loadingAction, editStaffMember, deleteStaffMember, handleXeroColumn };
};

export default useInternalStaffTable;
