import React, { forwardRef, useEffect, useState } from "react";

import { useStyling, useFetch, FormV2, FormFlex, Dropdown, Checkbox, Input, useCachedQuery } from "src/shiftly-ui";
import AnimatedContainer from "../../components/AnimatedContainer";
import Title from "../../components/Title";
import FormContainer from "../../components/FormContainer";
import { useAutoAnimate } from "@formkit/auto-animate/react";

import {
  australianResident,
  foreignResident,
  nonEmpoloyee,
  workingHolidayMaker,
  australianTaxScaleOptions,
  standardTFNExemptionOptions,
  actorsAndEnterTainers,
  seniorAndPensioner,
  notQuoted,
  buildTaxScaleOptions,
  getInitialFormState,
  calculateCanClaimTaxFreeThreshold,
  buildTFNExemptionOptions,
  handleResidencyChange,
} from "./taxDeclarationUtility.js";

const TaxDeclaration = forwardRef(
  ({ tax_declaration, setActiveTab = () => {}, authToken, setFormLoading, minified }, ref) => {
    const [data, setData] = useState({});

    const [taxScaleOptions, setTaxScaleOptions] = useState(australianTaxScaleOptions);
    const [tfnExemptionOptions, setTfnExemptionOptions] = useState(standardTFNExemptionOptions);
    const [canClaimTaxFreeThreshold, setCanClaimTaxFreeThreshold] = useState(true);

    const styling = useStyling();

    const {
      EmployeeOnboard: { LoadOnboardingDetails },
    } = useCachedQuery();

    const { post: onSubmit, refresh } = useFetch({
      options: {
        onSuccess: () => {
          refresh(LoadOnboardingDetails);
          setFormLoading(false);
          setActiveTab((prev) => prev + 1);
        },
        onMutate: () => {
          setFormLoading(true);
        },
        onError: () => {
          setFormLoading(false);
        },
      },
    });

    useEffect(() => {
      setData(getInitialFormState(tax_declaration));
    }, [tax_declaration]);

    useEffect(() => {
      setCanClaimTaxFreeThreshold(calculateCanClaimTaxFreeThreshold(data));
      setTfnExemptionOptions(buildTFNExemptionOptions(data));
      setTaxScaleOptions(buildTaxScaleOptions(data));
    }, [data]);

    return (
      <AnimatedContainer className={styling("container")}>
        {!minified && (
          <Title
            title={"Tax Declaration"}
            subtitle="Please complete your tax details accurately. If you need assistance, follow the link on the field to find out more or contact your employer."
          />
        )}
        <FormContainer>
          <FormV2
            ref={ref}
            data={data}
            setData={setData}
            onSubmit={(data) =>
              onSubmit({
                entity: "TaxDeclaration",
                method: "update",
                criteria: {
                  _id: tax_declaration._id,
                },
                data,
                headers: { token: authToken },
              })
            }
          >
            <ResidencyFields data={data} />
            <TFNFields
              tfnExemptionOptions={tfnExemptionOptions}
              setData={setData}
              minified={minified}
              tfnExemption={data?.tfn_exemption_type}
            />
            <TaxScaleFields data={data} setData={setData} taxScaleOptions={taxScaleOptions} />

            <CheckboxFields data={data} canClaimTaxFreeThreshold={canClaimTaxFreeThreshold} />
          </FormV2>
        </FormContainer>
      </AnimatedContainer>
    );
  }
);

const ResidencyFields = ({ data }) => {
  const [residencyParent] = useAutoAnimate();

  const isNotAustralian = data?.residency_status && data?.residency_status !== australianResident;

  return (
    <FormFlex
      ref={residencyParent}
      style={{ display: isNotAustralian ? "grid" : "flex", gridTemplateColumns: "1fr 1fr" }}
    >
      <Dropdown
        name={"residency_status"}
        disabled={[nonEmpoloyee, workingHolidayMaker].includes(data?.income_type)}
        onChange={(value) => handleResidencyChange({ ...data, residency_status: value })}
        options={[
          { label: "Australian Resident", value: australianResident },
          { label: "Foreign Resident", value: foreignResident },
        ]}
      />
      {isNotAustralian && <Dropdown name={"country_of_residence"} />}
      {data?.employment_type === "CONTRACTOR" && <Input name={"abn"} required={data.income_type === nonEmpoloyee} />}
    </FormFlex>
  );
};

const TFNFields = ({ tfnExemptionOptions, setData, minified, tfnExemption }) => {
  return (
    <FormFlex>
      <Input
        name={"tax_file_number"}
        required={!minified && !tfnExemption}
        onChange={(v) => {
          setData((prev) => ({ ...prev, tax_file_number: v, tfn_exemption_type: "" }));
        }}
      />
      <Dropdown
        name={"tfn_exemption_type"}
        options={tfnExemptionOptions}
        onChange={(v) => {
          setData((prev) => ({
            ...prev,
            tfn_exemption_type: v,
            tax_file_number: "",
          }));
        }}
      />
    </FormFlex>
  );
};

const TaxScaleFields = ({ data, setData, taxScaleOptions }) => {
  const taxScaleHasMultipleFields =
    data?.tax_scale_type === actorsAndEnterTainers || data?.tax_scale_type === seniorAndPensioner;

  const [taxScaleParent] = useAutoAnimate();

  const showTaxScaleFields = data?.income_type !== nonEmpoloyee && data?.tfn_exemption_type !== notQuoted;

  if (!showTaxScaleFields) return null;

  return (
    <FormFlex
      ref={taxScaleParent}
      style={{ display: taxScaleHasMultipleFields ? "grid" : "flex", gridTemplateColumns: "1fr 1fr" }}
    >
      <Dropdown
        name={"tax_scale_type"}
        label={"Tax Scale"}
        placeholder={"Select Tax Scale"}
        disabled={data?.income_type === workingHolidayMaker || !data?.residency_status}
        options={taxScaleOptions}
        required={data?.tfn_exemption_type !== notQuoted}
        onChange={(v) => {
          setData((prev) => ({
            ...prev,
            tax_scale_type: v,
            work_condition: "",
            senior_marital_status: "",
            tax_free_threshold: v === actorsAndEnterTainers ? false : prev.tax_free_threshold,
          }));
        }}
      />
      {data?.tax_scale_type === actorsAndEnterTainers && (
        <Dropdown
          name={"work_condition"}
          onChange={(v) => {
            setData((prev) => ({ ...prev, work_condition: v, tax_free_threshold: false }));
          }}
        />
      )}
      {data?.tax_scale_type === seniorAndPensioner && <Dropdown name={"senior_marital_status"} />}
    </FormFlex>
  );
};

const CheckboxFields = ({ data, canClaimTaxFreeThreshold }) => {
  const [checkboxParent] = useAutoAnimate();
  const styling = useStyling();
  
  const canHaveStudentDebt =
    (!data?.residency_status || data?.residency_status === australianResident) && data?.income_type !== nonEmpoloyee;

  return (
    <FormFlex
      className={styling("mt-4")}
      style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
      ref={checkboxParent}
    >
      {canClaimTaxFreeThreshold && <Checkbox name={"tax_free_threshold"} disabled={!canClaimTaxFreeThreshold} />}
      {canHaveStudentDebt && (
        <Checkbox
          ml={canClaimTaxFreeThreshold ? "35px" : "0px"}
          name={"has_loan_or_student_debt"}
          disabled={data?.residency_status && data?.residency_status !== australianResident}
        />
      )}
    </FormFlex>
  );
};
export default TaxDeclaration;
