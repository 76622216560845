import { useStyling } from "src/shiftly-ui";
import styles from "./StandardLayoutLink.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const StandardLayoutLink = ({ icon, text, link }) => {
  /*************************************** Hooks *************************************** */
  const navigate = useNavigate();
  const styling = useStyling(styles);

  return (
    <div className={styling("account-link")} onClick={() => navigate(link)}>
      <div className={styling("flex", "flex-row")}>
        <div className={styling("link-icon")}>
          <FontAwesomeIcon icon={icon} />
        </div>
        <p>
          <strong>{text}</strong>
        </p>
      </div>
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );
};

export default StandardLayoutLink;
