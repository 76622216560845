import { Input, useStyling, OrangeSpan } from "src/shiftly-ui";
import styles from "./NewPosition.module.scss";

const NewPositionWage = () => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  return (
    <div className={styling("container")}>
      <div className={styling("heading")}>
        <h3>
          Would you like to increase the <OrangeSpan>pay</OrangeSpan> for your employee?
        </h3>
      </div>
      <div className={styling("content")}>
        <Input name={"position_rate_modifier"} style={{ maxWidth: "350px" }} className={styling("mx-auto")} />
        <p className={styling("label")}>
          <strong>
            *Offering competitive rates will attract <OrangeSpan>a broader range of applicants</OrangeSpan>, including
            those of candidates with <OrangeSpan>higher ratings.</OrangeSpan>
          </strong>
        </p>
      </div>
    </div>
  );
};
export default NewPositionWage;
