import styles from "./ApplicantProfileImages.module.scss";
import { useStyling } from "src/shiftly-ui";

const ApplicantProfileImages = () => {
  /*************************************** State *************************************** */

  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  /********************************** Refs & Constants ********************************* */

  /******************************** Functions & Memos ********************************** */

  /************************************** Queries ************************************** */

  /******************************** Effects & Handles ********************************** */

  return <></>;
};

export default ApplicantProfileImages;
