import styles from "./NewBusinessRadioButtonCard.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStyling } from "src/shiftly-ui";

const NewBusinessRadioButtonCard = ({ icon, label, award, active, onClick = () => {} }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  return (
    <div className={styling("container", active && "active")} onClick={onClick}>
      <FontAwesomeIcon icon={icon} />
      <p className={styling("my-2", "text-center")}>
        <strong>{label}</strong>
      </p>
      <p className={styling("label")}>{award}</p>
    </div>
  );
};

export default NewBusinessRadioButtonCard;
