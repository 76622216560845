import React, { forwardRef } from "react";

import { useStyling, FormFlex, Dropdown, useFetch, Input, FormV2, useCachedQuery, OrangeSpan } from "src/shiftly-ui";
import AnimatedContainer from "../components/AnimatedContainer";
import Title from "../components/Title";
import FormContainer from "../components/FormContainer";

const YourDetails = forwardRef(
  ({ internalStaff = {}, setActiveTab = () => {}, authToken, setFormLoading, minified }, ref) => {
    /*************************************** Hooks *************************************** */
    const styling = useStyling();
    const {
      EmployeeOnboard: { LoadOnboardingDetails },
    } = useCachedQuery();

    /************************************** Queries ************************************** */
    const { post: onSubmit, refresh } = useFetch({
      options: {
        onSuccess: () => {
          refresh(LoadOnboardingDetails);
          setFormLoading(false);
          setActiveTab((prev) => prev + 1);
        },
        onMutate: () => {
          setFormLoading(true);
        },
        onError: () => {
          setFormLoading(false);
        },
      },
    });

    return (
      <AnimatedContainer className={styling("container")}>
        {!minified && (
          <Title
            title={
              <>
                <OrangeSpan>Your</OrangeSpan> Details
              </>
            }
            subtitle={"Please confirm your details below are correct or make any changes accordingly."}
          />
        )}
        <FormContainer>
          <FormV2
            ref={ref}
            initial={internalStaff}
            onSubmit={(data) =>
              onSubmit({
                entity: "InternalStaff",
                method: "update",
                criteria: { _id: internalStaff._id },
                data,
                headers: { token: authToken },
              })
            }
          >
            {!minified && (
              <FormFlex>
                <Input name={"first_name"} />
                <Input name={"middle_names"} />
                <Input name={"last_name"} />
              </FormFlex>
            )}
            {!minified && (
              <FormFlex>
                <Input name={"email"} />
                <Input name={"phone_number"} required />
              </FormFlex>
            )}
            <FormFlex>
              {minified && <Input name={"phone_number"} required />}
              <Input name={"date_of_birth"} />
              <Dropdown name={"gender"} />
            </FormFlex>
          </FormV2>
        </FormContainer>
      </AnimatedContainer>
    );
  }
);

export default YourDetails;
