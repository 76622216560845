import clsx from "clsx";
import styles from "./NotificationWrapper.module.css";
import { NotificationWithLink } from "./NotificationWithLink";
import { NotificationInfoOnly } from "./NotificationInfoOnly";
import React from "react";
import { formatDateToPrettyTime } from "../../utility/date.js";
import { useStyling } from "src/shiftly-ui";

const notificationTypeMap = {
  link: NotificationWithLink,
  info: NotificationInfoOnly,
};

export const NotificationWrapper = ({ read = false, type, createdAt, ...props }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  return (
    <div className={clsx(styling("container"), !read && styling("unread"))}>
      {notificationTypeMap[type] ? React.createElement(notificationTypeMap[type], props) : <></>}
      <div className={styling("time")}>
        <p>{formatDateToPrettyTime(createdAt)}</p>
      </div>
    </div>
  );
};
