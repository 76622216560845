export const Divider = ({ className }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "2px",
        backgroundColor: "var(--border)",
      }}
      className={className}
    ></div>
  );
};

export default Divider;
