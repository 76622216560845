import { useMemo } from "react";
import styles from "./DesktopRating.module.scss";
import {
  Stepper,
  TextArea,
  useStyling,
  ModalLabel,
  useProfile,
  RatingSlider,
  OrangeSpan,
  normaliseID,
} from "src/shiftly-ui";
import useRating from "./useRating";

const DesktopRating = ({ open, setOpen, shifts }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  const { activeStep, ratings, comments, setRatings, setComments, setActiveStep, handleSave } = useRating({
    shifts,
    setOpen,
  });

  /******************************** Functions & Memos ********************************** */
  const steps = useMemo(
    () =>
      shifts.map((shift) => ({
        label: shift.user.name,
        key: normaliseID(shift),
        content: (
          <RateAction
            user={shift.user}
            rating={ratings[normaliseID(shift)] || 0}
            setRating={(value) => setRatings((prev) => ({ ...prev, [normaliseID(shift)]: value }))}
            comment={comments[normaliseID(shift)] || ""}
            setComment={(value) => setComments((prev) => ({ ...prev, [normaliseID(shift)]: value }))}
          />
        ),
      })),
    [shifts, ratings, comments, setRatings, setComments]
  );

  return (
    <Stepper
      label={<ModalLabel text={`Rate your shifter${shifts.length > 1 ? "s" : ""}`} />}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      steps={steps}
      className={styling("stepper")}
      showStepper={open}
      setShowStepper={setOpen}
      onComplete={handleSave}
      onNext={handleSave}
      keydown={false}
    />
  );
};

const RateAction = ({ user, rating, setRating, comment, setComment }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const { profile } = useProfile({ criteria: { user: normaliseID(user) } });

  return (
    <>
      <div className={styling("flex", "p-4")}>
        <div className={styling("rate-picture")}>
          <img src={profile?.profile_picture ?? "/defaults/shifter.png"} alt="Profile" />
        </div>
      </div>
      <div className={styling("text-center", "p-3")}>
        <h3>
          Give {user?.first_name} {user?.last_name} a <OrangeSpan>rating</OrangeSpan>
        </h3>
      </div>
      <div className={styling("rating-stars-container")}>
        <RatingSlider value={rating} setValue={setRating} />
      </div>
      <TextArea
        value={comment}
        setValue={setComment}
        label={"Leave a review"}
        placeholder={`Write something nice about ${user?.first_name}...`}
        className={styling("mb-4", "mx-auto")}
      />
    </>
  );
};

export default DesktopRating;
