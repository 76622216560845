import {
  useUI,
  Modal,
  useToast,
  useFetch,
  convertStringTimeToDate,
  useAlerts,
  useStyling,
  normaliseID,
  useCachedQuery,
} from "src/shiftly-ui";
import styles from "./NewShiftModalLabel.module.scss";
import { useCallback, useEffect, useReducer, useRef } from "react";
import useBusiness from "src/hooks/useBusiness";
import useShiftTotals from "src/hooks/useShiftTotals";
import moment from "moment-timezone";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import usePositions from "src/hooks/usePositions";
import useShiftManager from "src/hooks/useShiftManager";
import NewShiftModalLabel from "./NewShiftModalLabel";
import NewShiftModalContent from "./NewShiftModalContent";

const initialState = {
  howMany: 1,
  mode: "Create",
  originalShift: {},
  selectedGroup: "",
  selectedLevel: "",
  selectedStaffMember: "",
  notes: "",
  tab: 0,
};

function shiftReducer(state, action) {
  switch (action.type) {
    case "SET_FIELD":
      return {
        ...state,
        [action.field]: action.value,
      };
    case "RESET":
      return initialState;
    default:
      return state;
  }
}

const validateShift = (shift, tab) => {
  if (!shift.position && tab === 0) throw new Error("Please select a position for the shift");

  if (!shift.shiftDate) throw new Error("Please select a date for the shift");

  if (!shift.startTime) throw new Error("Please select a start time for the shift");

  if (!shift.endTime) throw new Error("Please select an end time for the shift");

  const { startDate, endDate } = convertStringTimeToDate(shift.shiftDate, shift.startTime, shift.endTime);

  if (startDate >= endDate) throw new Error("Start time must be before end time");

  return { startDate, endDate };
};

const NewShiftModal = () => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const toast = useToast();
  const { confirm } = useAlerts();
  const { newShiftModal, hideNewShiftModal, newShiftModalDefaults } = useUI();
  const { activeBusiness } = useBusiness();
  const { activeLocation } = useShiftlyLocation();
  const { getPositionFromLevelAndGroup, groupMap } = usePositions();
  const { deleteShifts } = useShiftManager();

  const [{ mode, originalShift, selectedGroup, selectedLevel, selectedStaffMember, notes, tab }, dispatch] = useReducer(
    shiftReducer,
    initialState
  );

  const {
    InternalShift: { GetInternalShifts },
    Shift: { ShiftsBetweenDates, GetShiftsByLocation },
  } = useCachedQuery();

  const shiftTotals = useShiftTotals();

  /********************************** Refs & Constants ********************************* */
  const calendarRef = useRef();
  const { shiftDispatch, actionTypes, position, shiftDate, startTime, endTime, increasedHourlyRateModifier } =
    shiftTotals;

  /************************************** Queries ************************************** */
  const {
    post: postShift,
    isLoading,
    refresh,
  } = useFetch({
    options: {
      onSuccess: () => {
        postShiftAction();
      },
      onError: ({ error }) => {
        const { prettyError } = error;
        prettyError && toast.error(prettyError);
      },
    },
  });

  /******************************** Functions & Memos ********************************** */
  const postShiftAction = useCallback(() => {
    hideNewShiftModal();
    setTimeout(() => {
      shiftDispatch({ type: "RESET" });
      dispatch({ type: "RESET" });
      refresh([ShiftsBetweenDates, GetShiftsByLocation, GetInternalShifts]);
      calendarRef.current.refresh();
    }, 500);
  }, [
    hideNewShiftModal,
    shiftDispatch,
    dispatch,
    refresh,
    calendarRef,
    ShiftsBetweenDates,
    GetInternalShifts,
    GetShiftsByLocation,
  ]);

  const setShiftFields = useCallback(
    (fields, dispatchFun) => {
      Object.entries(fields).forEach(([field, value]) => {
        dispatchFun({ type: actionTypes.SET_FIELD, field, value });
      });
    },
    [actionTypes]
  );

  const handleSubmit = useCallback(
    async (status) => {
      try {
        const { startDate, endDate } = validateShift({ position, shiftDate, startTime, endTime }, tab);

        const shift = {
          ...originalShift,
          award_code: selectedGroup?.industry?.award_code,
          industry: normaliseID(groupMap[normaliseID(selectedGroup)]?.industry),
          business: normaliseID(activeBusiness),
          location: normaliseID(activeLocation),
          timezone: activeLocation.timezone,
          position: normaliseID(position),
          position_group: normaliseID(selectedGroup),
          start_time: new Date(startDate),
          end_time: new Date(endDate),
          shift_rate_modifier: increasedHourlyRateModifier,
          status,
          responsibilities: position?.responsibilities,
          user: tab === 1 ? selectedStaffMember || null : originalShift?.user,
          notes,
        };

        if (originalShift?.applicants?.length > 0) {
          hideNewShiftModal();
          if (
            !(await confirm({
              label: (
                <>
                  Are you sure you want to <span>edit this shift?</span>
                </>
              ),
              text: "Updating shift details will remove all your current applicants, they will need to apply again.",
              mode: "danger",
              inverse: true,
              confirmText: "Confirm",
            }))
          )
            return;
        }

        postShift({
          entity: tab === 0 ? "Shift" : "InternalShift",
          method: mode === "Create" ? "create" : "update",
          data: shift,
          criteria: {
            _id: normaliseID(shift),
          },
        });
      } catch (error) {
        toast.error(error.message, "Missing Fields");
      }
    },
    [
      groupMap,
      activeBusiness,
      activeLocation,
      position,
      shiftDate,
      startTime,
      endTime,
      increasedHourlyRateModifier,
      postShift,
      toast,
      mode,
      originalShift,
      confirm,
      hideNewShiftModal,
      selectedGroup,
      selectedStaffMember,
      tab,
      notes,
    ]
  );

  const handleDeleteShift = useCallback(async () => {
    postShiftAction();
    deleteShifts([originalShift]);
  }, [deleteShifts, originalShift, postShiftAction]);

  /******************************** Effects & Handles ********************************** */
  useEffect(() => {
    dispatch({ type: "SET_FIELD", field: "tab", value: 0 });
  }, [newShiftModal]);

  useEffect(() => {
    setShiftFields({ position: null }, shiftDispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, shiftDispatch]);

  //Initialise the modal with the default values
  useEffect(() => {
    if (!newShiftModalDefaults) return;

    const shift = newShiftModalDefaults.shift ? JSON.parse(newShiftModalDefaults.shift || "{}") : {};
    const type = newShiftModalDefaults.type;

    type === "internal" && dispatch({ type: "SET_FIELD", field: "tab", value: 1 });

    if (Object.keys(shift).length > 0) {
      //Load values from scheduler or shift
      const startTime = shift.start_time ? moment.tz(shift.start_time, shift.timezone).format("HH:mm") : "09:00";
      const endTime = shift.end_time ? moment.tz(shift.end_time, shift.timezone).format("HH:mm") : "17:00";

      setShiftFields(
        {
          shiftDate: new Date(shift.date),
          position: shift.position,
          startTime,
          endTime,
          increasedHourlyRateModifier: shift.shift_rate_modifier || 0,
        },
        shiftDispatch
      );

      setShiftFields(
        {
          selectedLevel: shift.position?.classification_level,
          selectedGroup: normaliseID(shift.position_group),
          selectedStaffMember: type === "internal" ? normaliseID(shift.user) : "",
          mode: newShiftModalDefaults.mode === "edit" ? "Edit" : "Create",
          originalShift: shift,
          notes: shift.notes,
        },
        dispatch
      );
    } else {
      //Load defautl values
      setShiftFields(
        {
          shiftDate: new Date(),
          startTime: "09:00",
          endTime: "17:00",
          increasedHourlyRateModifier: 0,
        },
        shiftDispatch
      );
      setShiftFields(
        {
          selectedLevel: "",
          selectedGroup: "",
          mode: "Create",
          notes: "",
        },
        dispatch
      );
    }

    if (newShiftModalDefaults.position) {
      const pos = JSON.parse(newShiftModalDefaults.position || "{}");
      setShiftFields(
        {
          selectedLevel: pos.classification_level,
          selectedGroup: normaliseID(pos.group),
        },
        dispatch
      );
      setShiftFields(
        {
          position: pos,
          shiftDate: newShiftModalDefaults.shiftDate ? new Date(newShiftModalDefaults.shiftDate) : new Date(),
        },
        shiftDispatch
      );
    }
  }, [newShiftModalDefaults, setShiftFields, shiftDispatch, dispatch]);

  useEffect(() => {
    if (!selectedGroup || !selectedLevel) return;
    const pos = getPositionFromLevelAndGroup(selectedGroup, selectedLevel);
    pos && setShiftFields({ position: pos }, shiftDispatch);
  }, [selectedGroup, selectedLevel, shiftDispatch, setShiftFields, getPositionFromLevelAndGroup]);

  return (
    <Modal
      label={
        <NewShiftModalLabel
          tab={tab}
          setTab={(v) => dispatch({ type: "SET_FIELD", field: "tab", value: v })}
          mode={mode}
        />
      }
      showModal={!!newShiftModal}
      setShowModal={hideNewShiftModal}
      className={styling("p-0")}
      iconClassName={styling("modal-close-icon")}
    >
      <NewShiftModalContent
        ref={calendarRef}
        selectedGroup={selectedGroup}
        selectedLevel={selectedLevel}
        selectedStaffMember={selectedStaffMember}
        dispatch={dispatch}
        setShiftFields={setShiftFields}
        tab={tab}
        mode={mode}
        isLoading={isLoading}
        handleDeleteShift={handleDeleteShift}
        handleSubmit={handleSubmit}
        {...shiftTotals}
      />
    </Modal>
  );
};

export default NewShiftModal;
