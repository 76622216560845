import { useStyling } from "../../hooks/useStyling";
import styles from "./NotificationInfoOnly.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble } from "@fortawesome/pro-solid-svg-icons";
import placeholderImage from "../../assets/png/no_img_placeholder.jpeg";
import { truncate } from "../../utility/format";

export const NotificationInfoOnly = ({ icon, title, text }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  return (
    <div className={styling("container")}>
      <div className={styling("image-container")}>
        <img src={icon || placeholderImage} alt="" className={styling("image")} />
        <div className={styling("checks")}>
          <FontAwesomeIcon icon={faCheckDouble} />
        </div>
      </div>
      <div className={styling("content")}>
        <div className={styling("body")}>
          <p>
            <strong>{title}: </strong>
            {truncate(text, 50)}
          </p>
        </div>
      </div>
    </div>
  );
};
