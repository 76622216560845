import styles from "./Title.module.scss";
import { useStyling } from "src/shiftly-ui";

const Title = ({ title, subtitle = "" }) => {
  const styling = useStyling(styles);
  return (
    <div className={styling("title")}>
      <h2 className={styling("mb-3")}>{title}</h2>
      <p className={styling("subtitle")}>
        <strong>{subtitle}</strong>
      </p>
    </div>
  );
};

export default Title;
