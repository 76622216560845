import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "../contexts/FormV2";
import { formDefaults } from "../utility/form";
import * as leoProfanity from "leo-profanity";

leoProfanity.loadDictionary();

export const useFormProps = (props) => {
  /*************************************** State *************************************** */
  const [localErrors, setLocalErrors] = useState({});

  /*************************************** Hooks *************************************** */
  const { register, getValues, errors, submit, clearErrors, setFormValue } = useForm();

  /********************************** Refs & Constants ********************************* */
  const { name = "noname", onChange, value = "", setValue, formatter, validate, required } = props;
  const formValue = getValues(name);

  /******************************** Functions & Memos ********************************** */
  const registerFunc = useMemo(() => {
    const originalRegister = register(name, {
      validate: (v) => {
        if (typeof v === "string" && leoProfanity.check(v ?? ""))
          return "We do not allow profanity. Please revise your input.";
        const validatingFunction = validate ?? formDefaults[name]?.validate ?? (() => true);
        if (!validatingFunction) return true;

        const response = validatingFunction?.(v, getValues());
        if (response === true) return true;
        if (typeof response === "string") return response;

        return formDefaults[name]?.message || "Invalid";
      },
      required: (required ?? formDefaults[name]?.required) && "Required",
    });

    return {
      ...originalRegister,
      onChange: (e) => {
        const newValue = formatter ? formatter(e.target.value) : e.target.value;
        e.target.value = newValue;
        onChange?.(newValue);
        originalRegister?.onChange(e);
        clearErrors(name);
      },
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register, name, validate, getValues, required]);

  const controlledInput = useMemo(() => !registerFunc || name === "noname", [registerFunc, name]);

  const handleChange = useCallback(
    (e) => {
      const newValue = formatter ? formatter(e.target.value) : e.target.value;
      e.target.value = newValue;

      onChange?.(newValue);
      setValue(newValue);
    },
    [setValue, onChange, formatter]
  );

  const handleOnBlur = useCallback(
    (e) => {
      if (controlledInput) {
        if (!validate) return;
        setLocalErrors((prev) => ({ ...prev, [name]: validate?.(value) }));
      }
    },
    [setLocalErrors, name, validate, controlledInput, value]
  );

  const formProps = useMemo(() => {
    return controlledInput
      ? {
          value: value ?? "",
          onChange: handleChange,
          onBlur: handleOnBlur,
        }
      : { ...registerFunc, value: formValue ?? "" };
  }, [registerFunc, value, handleChange, controlledInput, handleOnBlur, formValue]);

  /******************************** Effects & Handles ********************************** */

  // Ensure state is updated even when inside a form
  useEffect(() => {
    if (controlledInput || formValue === undefined) return;
    clearErrors("toast");
    const formattedValue = formatter?.(formValue) ?? formValue;
    setValue(formattedValue);
  }, [formValue, setValue, clearErrors, name, onChange, formatter, controlledInput]);

  return {
    formProps,
    submit,
    value: controlledInput ? value ?? "" : formValue ?? "",
    setValue: controlledInput ? setValue : (v, specificName) => setFormValue(specificName ?? name, v),
    errors: controlledInput
      ? localErrors
      : Object.keys(errors).reduce((acc, key) => ({ ...acc, [key]: errors[key]?.message }), {}),
  };
};
