import { Divider, Each, Link, useAlerts, useAuth, useFetch, useStyling } from "src/shiftly-ui";
import styles from "./AccountSettings.module.scss";
import StandardLayout from "../../components/standard_layout/StandardLayout";
import { faHeadphones, faInstitution, faUserGroup, faUserShield } from "@fortawesome/pro-regular-svg-icons";
import StandardLayoutLink from "../../components/standard_layout/StandardLayoutLink";
import { OrangeSpan, normaliseID } from "src/shiftly-ui";
import { useCallback } from "react";

const links = [
  { icon: faInstitution, text: "Organisation Details", link: "/account-management/organisation-settings" },
  { icon: faUserShield, text: "Personal Details & Security", link: "/account-management/personal-details" },
  { icon: faUserGroup, text: "Super Users", link: "/account-management/user-access" },
];

const AccountSettings = () => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const { confirm } = useAlerts();
  const { logout, user, isAdmin } = useAuth();

  /************************************** Queries ************************************** */
  const { post: deactivateAccount } = useFetch({
    options: {
      onSuccess: () => {
        logout();
      },
    },
  });
  /******************************** Functions & Memos ********************************** */

  const handleDeactivateAccount = useCallback(async () => {
    if (
      !(await confirm({
        label: (
          <>
            Are you sure you want to <span>deactivate your account?</span>
          </>
        ),
        text: "Penalties may apply for confimed shifts. This action cannot be undone.",
        mode: "danger",
        confirmText: "Deactivate",
        cancelText: "Cancel",
        inverse: true,
      }))
    )
      return;

    deactivateAccount({
      entity: "Account",
      method: "delete",
      criteria: {
        _id: normaliseID(user?.account_id),
      },
    });
  }, [confirm, deactivateAccount, user]);

  return (
    <StandardLayout
      heading={
        <>
          Account <OrangeSpan>Management</OrangeSpan>
        </>
      }
    >
      <Each of={links} render={({ key, ...link }) => <StandardLayoutLink key={key} {...link} />} />

      <Divider className={styling("my-3")} />

      <StandardLayoutLink
        key={"support"}
        icon={faHeadphones}
        text={"Contact Specialised Support"}
        link={process.env.REACT_APP_WEBSITE_URL + "/contact"}
      />

      <Divider className={styling("my-3")} />

      {isAdmin && (
        <div className={styling("mt-4")}>
          <p>
            Is there anything we can do better? We'd love your advice. Contact{" "}
            <Link to="mailto:team@shiftly.au">team@shiftly.au</Link> to get in touch with a team member.
          </p>
          <p onClick={handleDeactivateAccount} className={styling("delete-account", "my-3")}>
            Deactivate Account
          </p>
        </div>
      )}
    </StandardLayout>
  );
};

export default AccountSettings;
