import { useMemo } from "react";
import useSubscriptions from "src/hooks/useSubscriptions";
import PricingLayout from "./components/PricingLayout";

const PricingOnDemand = () => {
  const { prices, current } = useSubscriptions();

  const cards = useMemo(
    () => [
      {
        title: "Free",
        description:
          "Access our on-demand workforce solution without any upfront cost. Perfect for businesses needing flexible, short-term staffing solutions to cover busy periods or unexpected absences.",
        prices: [{ amount: 0, unit: "month", id: prices?.ondemand?.free, subscription_item: current?.ondemand?.id }],
        highlight: "Pay per shift",
        active: current.ondemand?.price === prices?.ondemand?.free,
      },
    ],
    [prices, current]
  );

  return <PricingLayout name={"Ondemand"} cards={cards} />;
};

export default PricingOnDemand;
