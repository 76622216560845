import { useStyling, BigToggle, Button, useSearchParams, withDisplay, useUI } from "src/shiftly-ui";
import { useMemo, useRef, useState } from "react";
import styles from "./People.module.scss";
import { faPlus, faRefresh } from "@fortawesome/pro-solid-svg-icons";
import ShiftlyApplicants from "./components/applicants/ShiftlyApplicants";
import { useNavigate } from "react-router-dom";
import InternalStaffTable from "./components/internal_staff/InternalStaffTable";
import useBusiness from "src/hooks/useBusiness";
import TabHeader from "src/shared/components/tabs/TabHeader";
import { faLinkSimple } from "@fortawesome/pro-regular-svg-icons";
import ImportXeroStaffModal from "./components/internal_staff/ImportXeroStaffModal";

const defaultTab = 0;

const People = () => {
  /*************************************** State *************************************** */
  const [tab, setTab] = useState(defaultTab);
  const [resyncIsLoading, setResyncIsLoading] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const navigate = useNavigate();
  const { activeBusiness } = useBusiness();
  const {
    settings: { isAThirdPartyProvider },
  } = useUI();

  /********************************** Refs & Constants ********************************* */
  const internalRef = useRef();

  /******************************** Functions & Memos ********************************** */
  const tabs = useMemo(() => {
    const tabs = [{ text: "Employees" }];

    if (!isAThirdPartyProvider) tabs.push({ text: "Shifters" });

    return tabs;
  }, [isAThirdPartyProvider]);

  /******************************** Effects & Handles ********************************** */
  useSearchParams(
    ["tab"],
    ({ tab }) => {
      switch (tab) {
        case "shifters":
          setTab(1);
          break;
        case "internal":
          setTab(0);
          break;
        default:
          setTab(defaultTab);
          break;
      }
    },
    []
  );

  const rightActions = useMemo(() => {
    const actions = [];
    if (activeBusiness?.xero_organisation) {
      actions.push(
        {
          text: "Import from Xero",
          icon: faLinkSimple,
          theme: "secondary-outline",
          onClick: async () => {
            setShowImportModal(true);
          },
        },
        {
          text: "Refresh Status",
          icon: faRefresh,
          loading: resyncIsLoading,
          theme: "secondary-outline",
          onClick: async () => {
            setResyncIsLoading(true);
            await internalRef.current?.handleResyncXero();
            setResyncIsLoading(false);
          },
        }
      );
    } else {
      actions.push({
        text: "Connect to Xero",
        icon: faLinkSimple,
        theme: "secondary-outline",
        onClick: () => navigate("/integrations/xero/pricing"),
      });
    }

    if (tab === 0) {
      actions.push({
        text: "Add new employee",
        icon: faPlus,
        onClick: () => navigate("/people/internal"),
        theme: "secondary",
      });
    }
    return actions;
  }, [tab, activeBusiness?.xero_organisation, resyncIsLoading, navigate]);

  return (
    <div className={styling("container")}>
      <ImportXeroStaffModal show={showImportModal} setShow={setShowImportModal} />
      <TabHeader tab={tab} setTab={setTab} tabs={tabs} rightActions={tab === 0 ? rightActions : []} />

      {tab === 0 && <InternalStaffTable ref={internalRef} setShowImportModal={setShowImportModal} />}
      {tab === 1 && <ShiftlyApplicants />}
    </div>
  );
};
export default withDisplay(People);
