import styles from "./CentralParagraph.module.scss";
import { useStyling } from "src/shiftly-ui";
import clsx from "clsx";

export const CentralParagraph = ({ children, className, ...props }) => {
  const styling = useStyling(styles);
  return (
    <p className={clsx(styling("text-left"), className)} {...props}>
      {children}
    </p>
  );
};

export default CentralParagraph;
