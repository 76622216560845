import { Button, TextArea, useStyling } from "src/shiftly-ui";
import { useCallback, useRef, useState } from "react";
import styles from "./MessagesMessageBar.module.css";
const MessagesMessageBar = ({ activeConversation, sendMessage }) => {
  const [message, setMessage] = useState("");
  const styling = useStyling(styles);
  const inputRef = useRef();
  const handleSendMessage = useCallback(() => {
    if (!activeConversation || !message) return;
    const mes = {
      content: message,
      conversation: activeConversation._id,
      user: activeConversation.user?._id,
      location: activeConversation.location,
      for_id: activeConversation.user?._id,
      unread: true,
      sent_date: new Date(),
    };
    sendMessage({
      entity: "Message",
      data: { ...mes },
      method: "create",
    });
    setMessage("");
  }, [message, activeConversation, sendMessage]);
  return (
    <div className={styling("container")}>
      <div className={styling("input-container")}>
        <TextArea
          ref={inputRef}
          resize={"none"}
          placeholder={"Start typing..."}
          value={message}
          setValue={setMessage}
          rows={1}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleSendMessage();
            }
          }}
        />
      </div>
      <Button theme={"primary"} className={styling("send-message-button")} onClick={handleSendMessage}>
        Send Message
      </Button>
    </div>
  );
};
export default MessagesMessageBar;
