import { useStyling, Each, Button, useAlerts } from "src/shiftly-ui";
import useBusiness from "src/hooks/useBusiness";
import styles from "./MobileAccount.module.scss";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import noImage from "src/shared/assets/png/no_img_placeholder.jpeg";
import { useRef } from "react";

const MobileAccount = () => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const { activeBusiness } = useBusiness();
  const { allLocations } = useShiftlyLocation();
  const { confirm } = useAlerts();

  /********************************** Refs & Constants ********************************* */
  const cardRef = useRef();
  /************************************** Queries ************************************** */

  return (
    <div className={styling("container")}>
      <div
        className={styling("header")}
        style={{
          height: (cardRef.current?.clientHeight ?? 0) + 100,
        }}
      >
        <div className={styling("header-top", "h-50")}>
          <div className={styling("blob", "blob-1")}></div>
          <div className={styling("blob", "blob-2")}></div>
        </div>
        <div className={styling("header-bottom", "h-50")}></div>
        <div className={styling("header-card")} ref={cardRef}>
          <div className={styling("business-title")}>
            <h4>{activeBusiness?.name}</h4>
          </div>
          <Each of={allLocations} render={({ key, ...location }) => <Location location={location} key={key} />} />
          <Button theme={"secondary"} link={"/"} className={styling("mt-3", "w-100")}>
            Swap Business
          </Button>
        </div>
      </div>
      <div className={styling("body")}>
        <h5>Enhanced features are available on desktop and tablet devices</h5>
        <p className={styling("label", "my-3")}>
          For a better experience, certain account management features are currently only available on desktop or tablet
          devices. We're working to bring these features to mobile soon. In the meantime, please use a desktop or table
          to access the full range of features.
        </p>
      </div>
      <div className={styling("logout")}>
        <Button
          theme={"secondary-outline"}
          size={"fw"}
          onClick={async () => {
            if (
              !(await confirm({
                label: "Log Out",
                text: "Are you sure you want to log out?",
                confirmText: "Log Out",
                cancelText: "Cancel",
                inverse: true,
              }))
            )
              return;
            localStorage.removeItem("shiftly-auth-token");
            window.location.href = "/portal";
          }}
        >
          Log Out
        </Button>
      </div>
    </div>
  );
};
export default MobileAccount;
const Location = ({ location }) => {
  const styling = useStyling(styles);
  const { activeLocation, setActiveLocation } = useShiftlyLocation();
  return (
    <div className={styling("location")} onClick={() => setActiveLocation(location)}>
      <div className={styling("location-logo")}>
        <img src={location?.icon || location?.logo || noImage} alt="logo" className={styling("cover")} />
      </div>
      <p className={styling("label")}>
        <strong>{location?.name}</strong>
      </p>
      <div className={styling("radio-btn", activeLocation?._id === location?._id && "selected")}>
        <div className={styling("radio-btn-inner")}></div>
      </div>
    </div>
  );
};
