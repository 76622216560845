import React, { forwardRef } from "react";
import { useStyling, useFetch, FormV2, Address, useCachedQuery, OrangeSpan } from "src/shiftly-ui";
import AnimatedContainer from "../components/AnimatedContainer";
import Title from "../components/Title";
import FormContainer from "../components/FormContainer";

const AddressDetails = forwardRef(
  (
    { address, setActiveTab = () => {}, authToken, location, internalStaff, onboardRequest, setFormLoading, minified },
    ref
  ) => {
    /*************************************** Hooks *************************************** */
    const styling = useStyling();
    const {
      EmployeeOnboard: { LoadOnboardingDetails },
    } = useCachedQuery();

    /************************************** Queries ************************************** */
    const { post: onSubmit, refresh } = useFetch({
      options: {
        onSuccess: () => {
          refresh(LoadOnboardingDetails);
          setFormLoading(false);
          setActiveTab((prev) => prev + 1);
        },
        onMutate: () => {
          setFormLoading(true);
        },
        onError: () => {
          setFormLoading(false);
        },
      },
    });

    return (
      <AnimatedContainer className={styling("container")}>
        {!minified && (
          <Title
            title={
              <>
                <OrangeSpan>Address</OrangeSpan> Details
              </>
            }
            subtitle="Please add your home address details or the best place to relevant communications."
          />
        )}
        <FormContainer>
          <FormV2
            ref={ref}
            initial={address}
            onSubmit={(data) => {
              return onSubmit({
                entity: "Address",
                method: "update",
                criteria: {
                  _id: address?._id,
                },
                data: {
                  ...data,
                  account_id: location?.account_id,
                  internalStaff: internalStaff._id,
                  employee_onboard: onboardRequest?._id,
                },
                headers: { token: authToken },
                options: { upsert: true },
              });
            }}
          >
            <Address name={"address"} />
          </FormV2>
        </FormContainer>
      </AnimatedContainer>
    );
  }
);

export default AddressDetails;
