import { useNavigate } from "react-router-dom";
import styles from "./Link.module.scss";
import clsx from "clsx";
import { useStyling } from "src/shiftly-ui";

export const Link = ({ to = "/", children, onClick, state = {}, className }) => {
  /*************************************** Hooks *************************************** */
  const navigate = useNavigate();
  const styling = useStyling(styles);

  return (
    <span
      className={clsx(styling("container"), className)}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick
          ? onClick(e)
          : to.startsWith("http") || to.startsWith("mailto")
          ? window.open(to, "_blank").focus()
          : navigate(to, { state });
      }}
    >
      {children}
    </span>
  );
};
