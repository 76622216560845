import { useStyling } from "src/shiftly-ui";
import styles from "./BusinessNavMobileMode.module.scss";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

const BusinessNavMobileMode = ({ links = [] }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  return (
    <div className={styling("container")}>
      <div className={styling("flex", "flex-between", "flex-row", "w-100", "py-2", "px-3")}>
        {links.map((item) => {
          return <NavItem key={item.label} {...item} />;
        })}
      </div>
    </div>
  );
};
export default BusinessNavMobileMode;

const NavItem = ({ icon, path = "/", hasNotifications, label, activeKey, badge = 0 }) => {
  /*************************************** Hooks *************************************** */
  const navigate = useNavigate();
  const styling = useStyling(styles);

  /********************************** Refs & Constants ********************************* */
  const currentPath = window.location.pathname;

  return (
    <div className={styling("nav-item", currentPath === "/" + activeKey && "active")} onClick={() => navigate(path)}>
      <div className={styling("position-relative", "flex", "flex-column")}>
        {badge > 0 && <div className={styling("badge")}>{badge}</div>}
        <FontAwesomeIcon icon={icon} className={styling("icon")} />
        {hasNotifications && <div className={styling("notification")}></div>}
        <p>{label}</p>
      </div>
    </div>
  );
};
