import { useUncontrolled } from "uncontrollable";
import styles from "./SchedulerTemplates.module.scss";
import { useStyling, Overlay } from "src/shiftly-ui";
import { useState } from "react";

import SchedulerTemplatesSave from "./SchedulerTemplatesSave";
import SchedulerTemplatesLoad from "./SchedulerTemplatesLoad";

const SchedulerTemplates = (props) => {
  /*************************************** State *************************************** */
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showLoadModal, setShowLoadModal] = useState(false);

  /*************************************** Hooks *************************************** */
  const { open, setOpen } = useUncontrolled(props, { open: "setOpen" });
  const styling = useStyling(styles);

  return (
    <>
      <Overlay open={open} setOpen={setOpen} />
      <div className={styling("container", open && "open")}>
        <div
          className={styling("row")}
          onClick={(e) => {
            e.stopPropagation();
            setShowSaveModal(true);
            setShowLoadModal(false);
            setOpen(false);
          }}
        >
          <p>Save as a template</p>
        </div>
        <div
          className={styling("row")}
          onClick={(e) => {
            e.stopPropagation();
            setShowLoadModal(true);
            setShowSaveModal(false);
            setOpen(false);
          }}
        >
          <p>Load a template</p>
        </div>
      </div>
      <SchedulerTemplatesSave showSaveModal={showSaveModal} setShowSaveModal={setShowSaveModal} />
      <SchedulerTemplatesLoad
        showLoadModal={showLoadModal}
        setShowLoadModal={setShowLoadModal}
        setShowSaveModal={setShowSaveModal}
      />
    </>
  );
};

export default SchedulerTemplates;
