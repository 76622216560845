import { useUI, useFetch, withDisplay } from "src/shiftly-ui";
import {
  faAddressCard,
  faCalendarWeek,
  faClock,
  faGear,
  faMessages,
  faUserGroup,
} from "@fortawesome/pro-regular-svg-icons";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BusinessNavLaptopMode from "./components/BusinessNavLaptopMode";
import BusinessNavTabletMode from "./components/BusinessNavTabletMode";
import BusinessNavMobileMode from "./components/BusinessNavMobileMode";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import useMessages from "src/hooks/useMessages";
import useBusiness from "src/hooks/useBusiness";

const excludeNavItemsPaths = ["onboarding"];

const BusinessNavigation = ({ isLaptop, isMobile, isTablet, width }) => {
  /*************************************** State *************************************** */
  const [visible, setVisible] = useState(true);
  const [showLocationSelector, setShowLocationSelector] = useState(true);
  const [locationFilter, setlocationFilter] = useState();

  /*************************************** Hooks *************************************** */
  const { setActiveLocation, activeLocation } = useShiftlyLocation();
  const {
    excludedPaths,
    settings: { isAThirdPartyProvider },
  } = useUI();
  const location = useLocation();
  const navigate = useNavigate();
  const { unreadMessages } = useMessages();
  const { activeBusiness } = useBusiness();
  /************************************** Queries ************************************** */

  /******************************** Functions & Memos ********************************** */
  const {
    data: [updatedLocation],
  } = useFetch({
    request: { entity: "Location", method: "get", criteria: { _id: locationFilter } },
    dependency: locationFilter,
  });

  const links = useMemo(() => {
    if (!showLocationSelector && isLaptop) {
      return [];
    }
    const links = [
      {
        label: "People",
        icon: faUserGroup,
        path: "/people",
        activeKey: "people",
      },
    ];

    if (isAThirdPartyProvider) return links;

    links.push(
      {
        label: "Timesheets",
        icon: faClock,
        path: "/timesheets",
        activeKey: "timesheets",
      },
      {
        label: "Messaging",
        icon: faMessages,
        path: "/messaging",
        activeKey: "messaging",
        badge: unreadMessages.length,
      }
    );

    if (isLaptop || isTablet) {
      links.unshift({
        label: "Shifts",
        icon: faCalendarWeek,
        path: "/shifts",
        activeKey: "shifts",
      });
      links.push({
        label: "Positions",
        icon: faAddressCard,
        path: "/positions",
      });
    }

    if (isMobile) {
      links.unshift({
        label: "Shifts",
        icon: faClock,
        activeKey: "mobile-shifts",
        path: "/mobile-shifts",
      });
      links.push({
        label: "Account",
        icon: faGear,
        path: "/business-account",
        activeKey: "business-account",
      });
    }
    return links;
  }, [showLocationSelector, isLaptop, isMobile, unreadMessages, isTablet, isAThirdPartyProvider]);

  /******************************** Effects & Handles ********************************** */
  useEffect(() => {
    updatedLocation && setActiveLocation(updatedLocation);
  }, [updatedLocation, setActiveLocation]);

  useEffect(() => {
    const pathname = window.location.pathname;
    // Check if pathname includes any excluded path
    setVisible(!excludedPaths.some((path) => path.includes(pathname)) || pathname === "/");
    // Check if pathname includes any excludeNavItemsPaths path
    setShowLocationSelector(
      !excludeNavItemsPaths.some((path) => pathname.includes(path)) && activeLocation?._id && activeBusiness?._id
    );
  }, [excludedPaths, location, links, activeLocation, activeBusiness]);

  useLayoutEffect(() => {
    setlocationFilter(activeLocation?._id);
  }, [activeLocation, navigate, excludedPaths]);

  switch (true) {
    case !visible:
      return <></>;
    case width >= 1280:
      return (
        <BusinessNavLaptopMode
          links={links}
          locationFilter={locationFilter}
          setlocationFilter={setlocationFilter}
          showLocationSelector={showLocationSelector}
        />
      );

    case width < 768:
      return <BusinessNavMobileMode links={links} />;
    default:
      return (
        <BusinessNavTabletMode
          links={links}
          locationFilter={locationFilter}
          setlocationFilter={setlocationFilter}
          showLocationSelector={showLocationSelector}
        />
      );
  }
};
export default withDisplay(BusinessNavigation);
