import {
  useFetch,
  OrangeSpan,
  EmptyState,
  Table,
  normaliseID,
  useStyling,
  ShiftlySuspense,
  useAuth,
} from "src/shiftly-ui";
import StandardLayout from "src/components/standard_layout/StandardLayout";

import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import { useCallback, useMemo } from "react";
import useBusiness from "src/hooks/useBusiness";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-regular-svg-icons";

const downloadCSV = (data, filename = "invoice.csv") => {
  const csvContent = data
    .map((row) =>
      row
        .map((cell) => {
          if (cell === null || cell === undefined) return "";
          const cellStr = String(cell).replace(/"/g, '""');
          return `"${cellStr}"`;
        })
        .join(",")
    )
    .join("\n");

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

const BusinessAccountPaymentHistory = () => {
  /*************************************** Hooks *************************************** */
  const { activeLocation } = useShiftlyLocation();
  const { activeBusiness } = useBusiness();
  const { account } = useAuth();
  const styling = useStyling();

  /************************************** Queries ************************************** */
  const { data: payments = [], isLoading: paymentsIsLoading } = useFetch({
    request: {
      entity: "Payment",
      method: "get",
      criteria: {
        location: activeLocation?._id,
      },
      populate: [
        {
          path: "shift",
          populate: ["user", "position"],
        },
      ],
      options: {
        includeArchived: true,
      },
      dependency: activeLocation?._id,
    },
  });

  const { data: invoiceHistory, isLoading: invoiceHistoryIsLoading } = useFetch({
    request: {
      entity: "Payment",
      method: "getInvoiceHistory",
      data: { business: normaliseID(activeBusiness) },
      dependency: normaliseID(activeBusiness),
    },
  });

  const combinedHistory = useMemo(() => {
    const invoices = invoiceHistory.map((invoice) => ({
      id: invoice.id,
      type: "Monthly Invoice",
      createdAt: new Date(invoice.created * 1000),
      items: invoice?.lines?.data?.length,
      amount: `$${(invoice.amount_due / 100).toFixed(2)}`,
      pdf: invoice.invoice_pdf,
    }));

    return [...invoices]?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  }, [invoiceHistory]);

  const { post: buildInvoiceExport, isLoading: buildInvoiceIsLoading } = useFetch({
    options: {
      onSuccess: (csv) => {
        downloadCSV(csv, `payments-${new Date().toLocaleDateString()}.csv`);
      },
    },
  });

  /******************************** Functions & Memos ********************************** */
  const columns = useMemo(
    () => [
      { field: "type", headerName: "Payment Type", width: 200 },
      {
        field: "createdAt",
        headerName: "Payment Date",
        width: 200,
        render: (row) => {
          return new Date(row.createdAt).toLocaleDateString();
        },
      },
      {
        field: "items",
        headerName: "Line Items",
        flex: 1,
      },
      {
        field: "amount",
        headerName: "Total Amount",
        width: 200,
      },
      {
        field: "pdf",
        headerName: "Download",

        render: (row) => {
          return (
            <div className={styling("flex", "flex-center", "w-100", "px-3")}>
              <a href={row.pdf} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faDownload} />
              </a>
            </div>
          );
        },
      },
    ],
    [styling]
  );

  const handleExport = useCallback(() => {
    buildInvoiceExport({
      entity: "Payment",
      method: "buildInvoiceExport",
      data: { account_id: normaliseID(account) },
    });
  }, [account, buildInvoiceExport]);

  return (
    <StandardLayout
      heading={
        <>
          Payment <OrangeSpan>History</OrangeSpan>
        </>
      }
      breadcrumb={[
        { label: "Business Settings", link: "/business-account" },
        { label: "Billing & Payment", link: "/business-account/billing-and-payment" },
      ]}
      description={<>Review your past payments or pending payments.</>}
      buttonText={"Export Payments"}
      buttonEvent={handleExport}
      buttonProps={{
        loading: buildInvoiceIsLoading,
      }}
    >
      <ShiftlySuspense loading={paymentsIsLoading || invoiceHistoryIsLoading}>
        <EmptyState items={combinedHistory} text="No payments found. Accept a shifter to view payments here!">
          <Table columns={columns} rows={combinedHistory} />
        </EmptyState>
      </ShiftlySuspense>
    </StandardLayout>
  );
};
export default BusinessAccountPaymentHistory;
