import clsx from "clsx";
import styles from "./Illustration.module.scss";
import { useStyling } from "src/shiftly-ui";

const Illustration = ({ image = "default.svg", className }) => {
  /*************************************** State *************************************** */

  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  /********************************** Refs & Constants ********************************* */

  /******************************** Functions & Memos ********************************** */

  /************************************** Queries ************************************** */

  /******************************** Effects & Handles ********************************** */

  return (
    <div className={clsx(styling("container"), className)}>
      <img src={"/illustrations/" + image} alt={"Shiftly Illustration"} />
    </div>
  );
};

export default Illustration;
