import { useFetch, newGUID, useUI, useCachedQuery, normaliseID } from "src/shiftly-ui";
import { createContext, useState, useContext, useLayoutEffect, useCallback, useEffect } from "react";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import useBusiness from "src/hooks/useBusiness";

export const SchedulerContext = createContext({
  shifts: [],
  applications: [],
  thirdPartyShifts: [],
  internalShifts: [],
  handleSlotClick: () => {},
  handleNextPeriod: () => {},
  handlePrevPeriod: () => {},
  onComplete: () => {},
  periods: {
    prev: [],
    current: [],
    next: [],
  },
  direction: 0,
  setDirection: () => {},
  duration: 0.5,
  fromDate: new Date(),
  isLoading: false,
  setIsLoading: () => {},
  referencingShift: null,
  setReferencingShift: () => {},
  shiftTray: [],
  setShiftTray: () => {},
});

export const useScheduler = () => useContext(SchedulerContext);

const getDatesInWeek = (date, offset = 0) => {
  const days = [];
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1) + 7 * offset;
  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(date);
    currentDate.setDate(diff + i);
    if (i === 0) {
      currentDate.setHours(0, 0, 0, 0);
    } else if (i === 6) {
      currentDate.setHours(23, 59, 59, 999);
    }
    days.push(new Date(currentDate));
  }
  return days;
};

export const SchedulerContextProvider = ({ children }) => {
  /*************************************** State *************************************** */
  const [fromDate, setFromDate] = useState(new Date());
  const [direction, setDirection] = useState(0);
  const [duration, setDuration] = useState(0.5);
  const [isLoading, setIsLoading] = useState(false);
  const [referencingShift, setReferencingShift] = useState(null);
  const [shiftTray, setShiftTray] = useState([]);
  const [multiSelect, setMultiSelect] = useState(false);
  const [periods, setPeriods] = useState({
    prev: [],
    current: [],
    next: [],
  });

  /*************************************** Hooks *************************************** */
  const { activeLocation } = useShiftlyLocation();
  const { activeBusiness } = useBusiness();
  const { setNewShiftModalDefaults, showNewShiftModal, setCurrentPeriod } = useUI();
  const {
    Integration: { GetThirdPartyShifts },
    InternalShift: { GetInternalShifts },
    Shift: { ShiftsBetweenDates },
    ShiftApplications: { GetApplicationsForShifts },
  } = useCachedQuery();

  /************************************** Queries ************************************** */
  const { data: shifts, isLoading: shiftIsLoading } = useFetch({
    request: {
      entity: "Shift",
      method: "get",
      populate: ["user", "position"],
      criteria: {
        location: normaliseID(activeLocation),
        start_time: {
          $gte: periods.current[0],
          $lte: periods.current[periods.current.length - 1],
        },
      },
      id: ShiftsBetweenDates,
    },
    dependency: `${periods.current[0]}_${normaliseID(activeLocation)}`,
    options: {
      enabled: !!periods.current[0],
    },
  });

  const { data: applications } = useFetch({
    request: {
      entity: "ShiftApplication",
      criteria: {
        location: normaliseID(activeLocation),
        shift: {
          $in: shifts?.map((shift) => normaliseID(shift)),
        },
        status: {
          $ne: "unverified",
        },
      },
      id: GetApplicationsForShifts,
    },
    dependency: shifts ? shifts.map((s) => normaliseID(s)).join("_") : "",
  });

  const { data: thirdPartyShifts, isLoading: thirdPartyIsLoading } = useFetch({
    request: {
      entity: "Integration",
      method: "getShifts",
      criteria: {
        business: normaliseID(activeBusiness),
        location: normaliseID(activeLocation),
        start_date: periods.current[0],
        end_date: periods.current[periods.current.length - 1],
      },
      id: GetThirdPartyShifts,
    },
    dependency: `${periods.current[0]}_${normaliseID(activeLocation)}_${normaliseID(activeBusiness)}`,
    options: {
      enabled: !!periods.current[0],
    },
  });

  const { data: internalShifts, isLoading: internalIsLoading } = useFetch({
    request: {
      entity: "InternalShift",
      method: "get",
      populate: ["user", "location"],
      criteria: {
        location: normaliseID(activeLocation),
        start_time: {
          $gte: periods.current[0],
          $lte: periods.current[periods.current.length - 1],
        },
      },
      id: GetInternalShifts,
    },
    dependency: `${periods.current[0]}_${normaliseID(activeLocation)}`,
    options: {
      enabled: !!periods.current[0],
      select: useCallback((data) => {
        return data.map((shift) => ({
          ...shift,
          position: { _id: newGUID(), type: "internal", name: "Internal" },
          type: "internal",
        }));
      }, []),
    },
  });

  /******************************** Functions & Memos ********************************** */
  const handleNextPeriod = useCallback(() => {
    setFromDate((prevDate) => getDatesInWeek(new Date(prevDate), 1)[0]);
  }, []);

  const handlePrevPeriod = useCallback(() => {
    setFromDate((prevDate) => getDatesInWeek(new Date(prevDate), -1)[0]);
  }, []);

  const addShiftToTray = useCallback(
    (shift) => {
      setShiftTray((prevTray) => {
        if (prevTray.find((s) => normaliseID(s) === normaliseID(shift))) {
          return prevTray.filter((s) => normaliseID(s) !== normaliseID(shift));
        }
        return [...prevTray, shift];
      });
    },
    [setShiftTray]
  );

  const removeShiftFromTray = useCallback(
    (shift) => {
      setShiftTray((prevTray) => prevTray.filter((s) => normaliseID(s) !== normaliseID(shift)));
    },
    [setShiftTray]
  );

  const shiftTraySlotClick = useCallback(
    (index, position, shift) => {
      if (!normaliseID(shift) || shift?.type === "external") return;
      if (shiftTray.find((s) => normaliseID(s) === normaliseID(shift))) {
        removeShiftFromTray(shift);
      }
      addShiftToTray({ shift, index, position });
    },
    [shiftTray, addShiftToTray, removeShiftFromTray]
  );

  /******************************** Effects & Handles ********************************** */

  // Update periods when fromDate changes
  useLayoutEffect(() => {
    const currentPeriod = getDatesInWeek(fromDate, 0);
    setPeriods({
      prev: getDatesInWeek(fromDate, -1),
      current: currentPeriod,
      next: getDatesInWeek(fromDate, 1),
    });
    setCurrentPeriod(JSON.stringify(currentPeriod));
  }, [fromDate, setCurrentPeriod]);

  const handleSlotClick = useCallback(
    (dayIndex, position) => {
      setNewShiftModalDefaults({
        position: JSON.stringify(position),
        shiftDate: periods.current[dayIndex]?.toString(),
        type: position?.type === "internal" ? "internal" : "shiftly",
      });
      showNewShiftModal(true);
    },
    [setNewShiftModalDefaults, showNewShiftModal, periods]
  );

  //Shift Event Listener
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Shift") {
        setMultiSelect(true);
      }
    };
    const handleKeyUp = (e) => {
      if (e.key === "Shift") {
        setMultiSelect(false);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  useEffect(() => {
    if (!multiSelect) setShiftTray([]);
  }, [multiSelect]);

  return (
    <SchedulerContext.Provider
      value={{
        shifts,
        applications,
        thirdPartyShifts,
        internalShifts,
        handleSlotClick,
        handleNextPeriod,
        handlePrevPeriod,
        periods,
        direction,
        setDirection,
        setDuration,
        duration,
        fromDate,
        setFromDate,
        isLoading: shiftIsLoading || thirdPartyIsLoading || internalIsLoading || isLoading,
        setIsLoading,
        referencingShift,
        setReferencingShift,
        shiftTray,
        shiftTraySlotClick,
        removeShiftFromTray,
        multiSelect,
        setMultiSelect,
      }}
    >
      {children}
    </SchedulerContext.Provider>
  );
};
