import clsx from "clsx";
import styles from "./SearchAndDisplayRow.module.scss";
import { useStyling } from "src/shiftly-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faEllipsis } from "@fortawesome/pro-solid-svg-icons";
import { motion } from "framer-motion";
import { useState } from "react";

const variants = {
  visible: {
    height: "auto",
  },
  hidden: {
    height: 0,
  },
};

export const SearchAndDisplayRow = ({
  cells = [],
  actions = [],
  moreActions = [],
  onClick = () => {},
  hideChevron = false,
  className,
  active = false,
  ...props
}) => {
  /*************************************** State *************************************** */
  const [actionsVisible, setActionsVisible] = useState(false);

  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  return (
    <>
      {actionsVisible && <div className={styling("overlay")} onClick={() => setActionsVisible(false)}></div>}
      <div
        className={clsx(styling("container", hideChevron && "no-hover", active && "active"), className)}
        onClick={onClick}
        {...props}
      >
        {cells.map((cell, index) => (
          <div key={index} className={clsx(styling("cell", cell?.className))}>
            {cell?.content}
          </div>
        ))}

        <div className={styling("actions")}>
          {actions.map((action, index) => (
            <div key={index} className={styling("action")} onClick={action.onClick}>
              <FontAwesomeIcon icon={action?.icon} />
            </div>
          ))}

          {moreActions.length > 0 && (
            <div className={styling("action")}>
              <div className={styling("position-relative")}>
                <div className={styling("more-buttion")} onClick={() => setActionsVisible((prev) => !prev)}>
                  <FontAwesomeIcon icon={faEllipsis} />
                </div>
                <motion.div
                  variants={variants}
                  initial={"hidden"}
                  animate={actionsVisible ? "visible" : "hidden"}
                  transition={{ duration: 0.2 }}
                  className={styling("dropdown")}
                >
                  {moreActions.map((moreAction, index) => (
                    <div
                      key={index}
                      className={styling("more-action")}
                      onClick={() => {
                        moreAction.onClick?.();
                        setActionsVisible(false);
                      }}
                    >
                      <p className={styling("text-left", "label")}>
                        <strong>{moreAction.label}</strong>
                      </p>
                    </div>
                  ))}
                </motion.div>
              </div>
            </div>
          )}
          {!hideChevron && (
            <div className={styling("chevron")}>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SearchAndDisplayRow;
