import { DataGrid } from "@mui/x-data-grid";
import { useMemo } from "react";
import styles from "./Table.module.scss";
import clsx from "clsx";
import { Link, useStyling } from "src/shiftly-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Tooltip from "@mui/material/Tooltip";

export const Table = ({ columns = [], rows = [], actions, link, ...props }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  /******************************** Functions & Memos ********************************** */
  const tableColumns = useMemo(() => {
    const cols = columns.map((column) => {
      const col = {
        ...column,
        minWidth: column?.minWidth ?? 100,
        flex: column?.flex ?? 1,
        align: column?.align ?? "left",
        sortable: column?.sortable ?? true,
      };
      col.renderCell = ({ row }) => {
        return (
          <div className={clsx(styling("cell"), styling(column.align || "left"))}>
            {(() => column.render?.(row))() ?? <p>{row[column.field]}</p>}
          </div>
        );
      };

      return col;
    });

    if (actions?.length > 0) {
      cols.push({
        flex: 1,
        field: "actions",
        headerName: "Actions",
        headerAlign: "right",
        sortable: false,
        align: "right",
        minWidth: 50,
        renderCell: ({ row }) => {
          return (
            <div className={clsx(styling("cell"), styling("right"))}>
              <div className={styling("actions")}>
                {actions.map(
                  ({ icon, label, onClick = () => {}, disabled = () => false }, index) =>
                    !disabled(row) && (
                      <Tooltip key={index} title={label} arrow placement="top">
                        <div className={styling("action")} onClick={() => onClick(row)}>
                          <FontAwesomeIcon icon={icon} />
                        </div>
                      </Tooltip>
                    )
                )}
              </div>
            </div>
          );
        },
      });
    }

    return cols;
  }, [columns, styling, actions]);

  return (
    <DataGrid
      columns={tableColumns}
      rows={rows}
      autoPageSize
      disableSelectionOnClick
      disableRowSelectionOnClick
      disableDensitySelector
      getRowId={(row) => row._id ?? row.id}
      className={clsx(styling("table"), props.className)}
      {...props}
    />
  );
};
