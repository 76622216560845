import { businessActions, newGUID, normaliseID, useCachedQuery, useFetch } from "src/shiftly-ui";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const useBusiness = () => {
  /*************************************** Hooks *************************************** */
  const business = useSelector((state) => state.business);
  const ui = useSelector((state) => state.ui);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    Business: { AvailableBusinesses },
  } = useCachedQuery();

  /************************************** Queries ************************************** */
  const {
    data: availableBusinesses,
    refetch: refreshAvailableBusinesses,
    isLoading,
  } = useFetch({
    request: {
      entity: "Business",
      method: "get",
      populate: ["industries"],
      id: AvailableBusinesses,
    },
    options: {
      enabled: ui?.settings?.mode === "owner",
    },
  });

  const { post: getActiveBusiness } = useFetch({
    options: {
      onSuccess: ([business]) => {
        business && setActiveBusiness(business);
      },
    },
  });

  /******************************** Functions & Memos ********************************** */
  const setActiveBusiness = useCallback(
    (business = {}) => {
      dispatch(businessActions.setActiveBusiness(business));
      localStorage.setItem("activeBusiness", JSON.stringify(business));
    },
    [dispatch]
  );

  const clearActiveBusiness = useCallback(() => {
    dispatch(businessActions.setActiveBusiness(null));
    dispatch(businessActions.setActiveLocation(null));
    localStorage.removeItem("activeBusiness");
    localStorage.removeItem("activeLocation");
  }, [dispatch]);

  const initBusiness = useCallback(async () => {
    let activeBusiness;
    let activeLocation;
    let parsedBusiness;
    let parsedLocation;
    const storedBusiness = localStorage.getItem("activeBusiness");
    const storedLocation = localStorage.getItem("activeLocation");

    if (storedBusiness) {
      parsedBusiness = JSON.parse(storedBusiness);
      if (parsedBusiness.account_id === normaliseID(user?.account_id)) {
        activeBusiness = parsedBusiness;
        dispatch(businessActions.setActiveBusiness(activeBusiness));
      }
    }
    if (storedLocation) {
      parsedLocation = JSON.parse(storedLocation);
      if (parsedLocation.account_id === normaliseID(user?.account_id)) {
        activeLocation = parsedLocation;
        dispatch(businessActions.setActiveLocation(activeLocation));
      }
    }
    const pathname = window.location.pathname;

    if ((!activeBusiness?._id || !activeLocation?._id) && !pathname.includes("portal")) return navigate("/");

    if (
      parsedBusiness &&
      parsedLocation &&
      activeBusiness?.account_id === normaliseID(user?.account_id) &&
      activeLocation?.account_id === normaliseID(user?.account_id)
    ) {
      if (pathname === "/") {
        navigate("/shifts");
      }
    }
  }, [dispatch, user, navigate]);

  const refreshActiveBusiness = useCallback(async () => {
    getActiveBusiness({
      entity: "Business",
      method: "get",
      criteria: {
        _id: business?.activeBusiness?._id,
      },
      id: newGUID(),
    });
  }, [business, getActiveBusiness]);

  return useMemo(
    () => ({
      ...business,
      availableBusinesses,
      clearActiveBusiness,
      refreshAvailableBusinesses,
      initBusiness,
      setActiveBusiness,
      refreshActiveBusiness,
      isLoading,
    }),
    [
      business,
      refreshAvailableBusinesses,
      initBusiness,
      setActiveBusiness,
      isLoading,
      availableBusinesses,
      clearActiveBusiness,
      refreshActiveBusiness,
    ]
  );
};
export default useBusiness;
