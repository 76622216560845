import { Each, getDayOfWeekStartingMonday, normaliseID, truncate, useStyling } from "src/shiftly-ui";
import styles from "./SchedulerPositionRow.module.scss";
import { useMemo, useCallback } from "react";
import ShiftSlot from "./SchedulerShiftSlot";
import usePositions from "src/hooks/usePositions";
import { useScheduler } from "src/contexts/Scheduler";

const SchedulerPositionRow = ({ position, shifts = [], applicants, additive }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const { levels } = usePositions();
  const { isLoading } = useScheduler();
  /******************************** Functions & Memos ********************************** */
  const positionName = useMemo(() => truncate(position?.name, 15), [position?.name]);

  const getRowTitle = useCallback(
    (shift) => {
      if (shift?.type === "external") return <p>{shift?.position?.provider}</p>;
      if (shift?.type === "internal") return <p>Internal</p>;
      return <p>{levels[position?.classification_level - 1] || "N/A"}</p>;
    },
    [levels, position?.classification_level]
  );

  const shiftRows = useMemo(() => {
    if (isLoading) return [];

    const initialRows = [];

    shifts.forEach((shift) => {
      const dayIndex = getDayOfWeekStartingMonday(new Date(shift.start_time));
      const shiftApplicants = applicants.filter((app) => normaliseID(app.shift) === normaliseID(shift));
      const shiftStatus = shiftApplicants.length > 0 && shift.status === "published" ? "hasApplicants" : shift.status;

      let added = false;
      for (const row of initialRows) {
        if (row.days[dayIndex]?.status === "free") {
          row.days[dayIndex] = { status: shiftStatus, shift: { ...shift, applicants: shiftApplicants } };
          added = true;
          break;
        }
      }

      if (!added) {
        const newRow = {
          title: getRowTitle(shift),
          days: Array(7).fill({ status: "free" }),
        };
        newRow.days[dayIndex] = { status: shiftStatus, shift: { ...shift, applicants: shiftApplicants } };
        initialRows.push(newRow);
      }
    });

    if (additive) {
      initialRows.push({
        title: (
          <p>
            Add <br />
            {positionName} +
          </p>
        ),
        days: Array(7).fill({ status: "add" }),
      });
    }

    return initialRows;
  }, [shifts, applicants, additive, positionName, getRowTitle, isLoading]);

  const renderRow = useCallback(
    ({ title, days }, idx) => (
      <div key={idx} className={styling("flex", "flex-start")}>
        <div className={styling("row-title")}>{title}</div>
        <Each
          of={days}
          render={(day, idx) => {
            const key = normaliseID(day?.shift) || `day-${idx}`;
            return <ShiftSlot {...day} key={key} index={idx} position={position} />;
          }}
        />
      </div>
    ),
    [position, styling]
  );

  return <Each of={shiftRows} render={renderRow} />;
};

export default SchedulerPositionRow;
