import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./ApplicantProfileTestimonials.module.scss";
import { normaliseID, Swiper, useFetch, useStyling } from "src/shiftly-ui";
import { faStar } from "@fortawesome/pro-regular-svg-icons";

const ApplicantProfileTestimonials = ({ user }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  /************************************** Queries ************************************** */
  const { data: ratings } = useFetch({
    request: {
      entity: "Rating",
      method: "get",
      criteria: {
        user: normaliseID(user),
        comment: { $ne: "" },
      },
      populate: [{ path: "shift", populate: ["location"] }],
      options: {
        limit: 5,
      },
    },
    dependecy: normaliseID(user),
  });
  /******************************** Effects & Handles ********************************** */

  if (!ratings.length) return null;

  return (
    <>
      <h6 className={styling("mt-4")}>Testimonials</h6>
      <div className={styling("container")}>
        <Swiper
          withNavigation
          loop
          slides={ratings.map((rating) => (
            <Slide key={rating?._id} {...rating} />
          ))}
        ></Swiper>
      </div>
    </>
  );
};

const Slide = ({ rating, shift, comment }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  return (
    <div className={styling("slide")}>
      <h6>{shift?.location?.name ?? "Anonymous"}</h6>
      <p className={styling("my-3")}>{comment}</p>
      <div>
        {Array.from({ length: rating }).map((_, index) => (
          <FontAwesomeIcon icon={faStar} key={index} className={styling("star")} />
        ))}
      </div>
    </div>
  );
};

export default ApplicantProfileTestimonials;
