import { useMemo } from "react";
import { useFetch } from "src/shiftly-ui";
import useBusiness from "./useBusiness";

const getDateOfMonday = (date) => {
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1);
  const monday = new Date(date.setDate(diff));
  monday.setHours(0, 0, 0, 0);
  return monday;
};

const usePayCalendars = (xero_organisation) => {
  const { activeBusiness } = useBusiness();

  const { data: calendars, isLoading } = useFetch({
    request: {
      entity: "XeroOrganisation",
      method: "getPayCalendarForBusiness",
      data: {
        xero_organisation,
      },
    },
    dependency: xero_organisation,
  });

  const frequencySelect = useMemo(() => {
    const monday = getDateOfMonday(new Date()).toLocaleDateString("en-AU", {
      weekday: "short",
      day: "numeric",
      month: "short",
      year: "2-digit",
    });

    if (!calendars.length)
      return [
        {
          label: `WEEKLY - Starting on: ${monday}`,
          value: "internal",
        },
      ];

    return calendars.map((calendar) => {
      const startDate = new Date(calendar.startDate);
      const startDateString = startDate.toLocaleDateString("en-AU", {
        weekday: "short",
        day: "numeric",
        month: "short",
        year: "2-digit",
      });

      return {
        label: `${calendar?.calendarType} - Starting on: ${startDateString}`,
        value: calendar?.payrollCalendarID,
      };
    });
  }, [calendars]);

  const currentPayPeriod = useMemo(() => {
    const calendar = calendars?.find((calendar) => calendar?.payrollCalendarID === activeBusiness?.payroll_calendar_id);

    const monday = getDateOfMonday(new Date());
    const sunday = new Date(monday);
    sunday.setDate(sunday.getDate() + 6);
    monday.setHours(0, 0, 0, 0);
    sunday.setHours(23, 59, 59, 999);

    if (!calendar?.startDate)
      return {
        startDate: monday,
        endDate: sunday,
      };

    const startDate = new Date(calendar.startDate);
    const endDate = new Date(startDate);
    const startDay = startDate.getDate();

    switch (calendar.calendarType) {
      case "WEEKLY":
        endDate.setDate(endDate.getDate() + 6);
        break;
      case "FORTNIGHTLY":
        endDate.setDate(endDate.getDate() + 13);
        break;
      case "MONTHLY":
        endDate.setMonth(endDate.getMonth() + 1);
        endDate.setDate(0);
        break;
      case "QUARTERLY":
        endDate.setMonth(endDate.getMonth() + 3);
        endDate.setDate(0);
        break;
      case "TWICEMOTHLY":
        if (startDay <= 15) {
          endDate.setDate(15);
        } else {
          endDate.setMonth(endDate.getMonth() + 1);
          endDate.setDate(0);
        }
        break;
      case "FOURWEEKLY":
        endDate.setDate(endDate.getDate() + 27);
        break;
      default:
        endDate.setDate(endDate.getDate() + 6); // Default to weekly
        break;
    }

    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    return {
      startDate,
      endDate,
    };
  }, [calendars, activeBusiness]);

  return {
    calendars,
    frequencySelect,
    isLoading,
    currentPayPeriod,
  };
};

export default usePayCalendars;
