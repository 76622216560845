import { useState, useEffect, useRef, useCallback } from "react";

export const useUserActivity = (inactivityTimeout = 60000) => {
  /*************************************** State *************************************** */
  const [isUserActive, setIsUserActive] = useState(true);

  /********************************** Refs & Constants ********************************* */
  const userActiveTimeoutRef = useRef(null);

  /******************************** Functions & Memos ********************************** */
  const resetUserActiveTimeout = useCallback(() => {
    if (userActiveTimeoutRef.current) {
      clearTimeout(userActiveTimeoutRef.current);
    }
    setIsUserActive(true);
    userActiveTimeoutRef.current = setTimeout(() => {
      setIsUserActive(false);
    }, inactivityTimeout);
  }, [inactivityTimeout]);

  /******************************** Effects & Handles ********************************** */

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        resetUserActiveTimeout();
      } else {
        setIsUserActive(false);
      }
    };

    const handleFocus = () => {
      resetUserActiveTimeout();
    };

    const handleBlur = () => {
      setIsUserActive(false);
    };

    const handleUserActivity = () => {
      resetUserActiveTimeout();
    };

    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keypress", handleUserActivity);
    window.addEventListener("touchstart", handleUserActivity);
    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Initialise timeout
    resetUserActiveTimeout();

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keypress", handleUserActivity);
      window.removeEventListener("touchstart", handleUserActivity);
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      if (userActiveTimeoutRef.current) {
        clearTimeout(userActiveTimeoutRef.current);
      }
    };
  }, [inactivityTimeout, resetUserActiveTimeout]);

  return isUserActive;
};
