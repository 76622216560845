import { Button, withDisplay, useStyling, CentralParagraph, ShiftlySuspense, FadeInDiv } from "src/shiftly-ui";
import styles from "./StandardLayout.module.scss";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { Fragment } from "react";

const StandardLayout = ({
  loading,
  heading,
  breadcrumb = [],
  description,
  children,
  buttonText,
  buttonEvent = () => {},
  buttonProps = {},
  withCard = false,
  size = "small",
  align = "left",
  className,
}) => {
  /*************************************** Hooks *************************************** */
  const navigate = useNavigate();
  const styling = useStyling(styles);

  /********************************** Refs & Constants ********************************* */

  return (
    <div className={styling("container", withCard && "with-card")} id="standard-layout">
      <div className={styling("banner")}>
        {buttonText && (
          <Button
            ml={"auto"}
            icon={faPlus}
            iconSide="right"
            theme={"secondary"}
            onClick={buttonEvent}
            loading={loading}
            {...buttonProps}
          >
            {buttonText}
          </Button>
        )}
      </div>
      <ShiftlySuspense loading={loading} className={styling('loading-container')}>
        <FadeInDiv className={clsx(styling("card", size, !withCard && "full-flex"), className)}>
          {heading && (
            <div className={styling(`text-${align}`, "sm-px-6", withCard && "mt-4")}>
              {breadcrumb.length > 0 && (
                <div className={styling("mb-2", "flex", "flex-row", "flex-start")}>
                  {breadcrumb.map((item, index) => (
                    <Fragment key={index}>
                      <p onClick={() => navigate(item.link)} className={styling("cursor-pointer", "underline", "mr-2")}>
                        <strong>{item.label}</strong>
                      </p>
                      <span className={styling("mr-2")}>/</span>
                    </Fragment>
                  ))}
                </div>
              )}

              <h2 className={styling("mb-3", "mt-2")}>{heading}</h2>
              {description && (
                <CentralParagraph className={styling(`text-${align}`, "mb-3")}>{description}</CentralParagraph>
              )}
            </div>
          )}

          <div className={styling("content-container")}>{children}</div>
        </FadeInDiv>
      </ShiftlySuspense>
    </div>
  );
};
export default withDisplay(StandardLayout);
