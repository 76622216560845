import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./Alerts.module.scss";
import { ButtonGroup, Divider, Overlay, useStyling, Button } from "src/shiftly-ui";
import { faCircleExclamation, faWarning } from "@fortawesome/pro-regular-svg-icons";
import { useEffect, useState } from "react";

const AlertsModal = ({
  label,
  mode = "warning",
  text = "This action cannot be undone.",
  show,
  setShow,
  children,
  handleConfirm,
  inverse,
  cancelText = "Cancel",
  confirmText = "Confirm",
  cancelAction,
  input,
}) => {
  /*************************************** State *************************************** */
  const [view, setView] = useState(false);

  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  /******************************** Effects & Handles ********************************** */

  useEffect(() => {
    if (!show) {
      setView(false);
      return;
    } else {
      setTimeout(() => {
        setView(true);
      }, 300);
    }
  }, [show]);

  const handleClose = () => {
    setView(false);
    setTimeout(() => {
      setShow(false);
    }, 300);
  };

  return (
    <>
      <Overlay open={show} setOpen={handleClose} />
      <div className={styling("container", view && "visible")}>
        <h3 className={styling("mb-3")}>{label}</h3>

        {children ?? (
          <div className={styling("p-2", "my-3", "text-left", mode)}>
            <div className={styling("flex", "flex-row", "flex-start", "mb-2")}>
              <FontAwesomeIcon icon={mode === "warning" ? faWarning : faCircleExclamation} />
              <p className={styling("ml-3")}>
                <strong>{mode === "warning" ? "Warning" : mode === "info" ? "Info" : "Danger"}</strong>
              </p>
            </div>
            <p className={styling("label")}>{text}</p>
          </div>
        )}
        {input}

        <Divider />
        <ButtonGroup className={styling("flex-between")}>
          <Button
            onClick={() => {
              handleClose();
              cancelAction?.();
            }}
            theme={"secondary-outline"}
          >
            {cancelText}
          </Button>
          <Button onClick={() => handleConfirm(input ? null : true)} theme={inverse ? "danger" : "secondary"}>
            {confirmText}
          </Button>
        </ButtonGroup>
      </div>
    </>
  );
};

export default AlertsModal;
