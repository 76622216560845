import { Input, useUI } from "src/shiftly-ui";
import styles from "./NewBusinessSetup.module.scss";
import { useStyling, OrangeSpan } from "src/shiftly-ui";

const NewBusinessDetails = () => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const {
    settings: { isAThirdPartyProvider },
  } = useUI();

  return (
    <div className={styling("container")}>
      <div className={styling("heading")}>
        <h2>
          Tell us a bit about <OrangeSpan>your {isAThirdPartyProvider && "client's"} business</OrangeSpan>
        </h2>
      </div>
      <div className={styling("content", "medium-content")}>
        <Input
          name={"name"}
          label={`What is your ${isAThirdPartyProvider && "client's"} business name?`}
          placeholder={"Cafe on the Corner"}
          required
        />
      </div>
    </div>
  );
};

export default NewBusinessDetails;
