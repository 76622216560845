import { Link, Button, useUI, roundNumber, useStyling, Overlay, normaliseID } from "src/shiftly-ui";
import styles from "./SchedulerViewShift.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { faArrowUpRight, faCalendar, faClock, faCoffee, faEyeSlash, faLock } from "@fortawesome/pro-regular-svg-icons";
import logo from "src/shared/assets/logos/icon.svg";
import useShiftTotals from "src/hooks/useShiftTotals";
import { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment-timezone";
import CancelShift from "../../components/CancelShift";

const editableStatuses = ["unpublished", "published", "hasApplicants"];

const SchedulerViewShift = ({ view, setView, shift, status, profile }) => {
  /*************************************** State *************************************** */
  const [cancelShift, setCancelShift] = useState(false);

  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const { setNewShiftModalDefaults, showNewShiftModal } = useUI();
  const { shiftDispatch, actionTypes, totalRange, duration } = useShiftTotals();

  /********************************** Refs & Constants ********************************* */
  let statusContent = status;
  let iconContent = <img src={logo} alt="Shiftly logo" style={{ objectFit: "contain" }} />;

  /******************************** Functions & Memos ********************************** */
  const confirmedAmount = useMemo(() => {
    if (shift.status !== "confirmed" && shift.status !== "completed") return null;
    const application = shift.applicants.find((application) => application.status === "accepted");
    return application?.total;
  }, [shift]);

  const setShiftFields = useCallback(
    (fields) => {
      Object.entries(fields).forEach(([field, value]) => {
        shiftDispatch({ type: actionTypes.SET_FIELD, field, value });
      });
    },
    [actionTypes, shiftDispatch]
  );

  const handleEditShiftClick = useCallback(() => {
    setView(false);
    setNewShiftModalDefaults({
      mode: "edit",
      shift: JSON.stringify(shift),
      type: shift?.type ?? "shiftly",
    });
    showNewShiftModal(true);
  }, [shift, setNewShiftModalDefaults, showNewShiftModal, setView]);

  /******************************** Effects & Handles ********************************** */
  useEffect(() => {
    if (!shift?.status) return;

    const startTime = moment.tz(shift?.start_time, shift?.timezone);
    const endTime = moment.tz(shift?.end_time, shift?.timezone);

    setShiftFields({
      position: shift?.position,
      shiftDate: startTime.toDate(),
      startTime: startTime.format("HH:mm"),
      endTime: endTime.format("HH:mm"),
      increasedHourlyRateModifier: shift?.shift_rate_modifier,
    });
  }, [shift, setShiftFields]);

  switch (status) {
    case "unpublished":
      statusContent = <p className={styling("heading-status")}>Unpublished</p>;
      iconContent = <FontAwesomeIcon icon={faEyeSlash} />;
      break;
    case "published":
      statusContent = <p className={styling("heading-status")}>Published</p>;
      break;
    case "confirmed":
      statusContent = (
        <Link to={"/people/" + normaliseID(shift?.user)} className={styling("heading-status")}>
          {"Shifter: " + shift?.user?.first_name + " " + shift?.user?.last_name}
        </Link>
      );
      iconContent = <img src={profile?.profile_picture} alt="Profile" style={{ objectFit: "cover" }} />;
      break;
    case "expired":
      statusContent = <p className={styling("heading-status")}>Expired</p>;
      break;
    case "hasApplicants":
      statusContent = (
        <Link
          to={"/people?shift=" + shift?._id}
          state={{ shift: normaliseID(shift) }}
          className={styling("heading-status")}
        >
          {shift?.applicants?.length} applicant{shift?.applicants?.length > 1 && "s"}
          <FontAwesomeIcon icon={faArrowUpRight} className={styling("linkArrow")} />
        </Link>
      );
      break;
    default:
      break;
  }

  return (
    <>
      <CancelShift showModal={cancelShift} setShowModal={setCancelShift} shift={shift} />
      <Overlay open={view} setOpen={setView} />

      <div className={styling("container", view && "visible")}>
        <div className={styling("heading", status)}>
          <div className={styling("heading-image", status)}>{iconContent}</div>
          <div className={styling("heading-details")}>
            <h6 className={styling("heading-position")}>
              {shift?.position?.name} - Level {shift?.position?.classification_level}
            </h6>
            {statusContent}
          </div>
          <div className={styling("close-icon")} onClick={() => setView(false)}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>
        <div className={styling("body")}>
          <div className={styling("body-row")}>
            <div className={styling("body-icon")}>
              <FontAwesomeIcon icon={faCalendar} />
            </div>
            <div className={styling("body-text")}>
              {shift?.day_of_week} {shift?.prettyDate}
            </div>
          </div>
          <div className={styling("body-row")}>
            <div className={styling("body-icon")}>
              <FontAwesomeIcon icon={faClock} />
            </div>
            <div className={styling("body-text")}>
              {shift?.prettyStartTime} - {shift?.prettyEndTime} ({duration} hrs)
            </div>
          </div>
          {shift?.breakTime && (
            <div className={styling("body-row")}>
              <div className={styling("body-icon")}>
                <FontAwesomeIcon icon={faCoffee} />
              </div>
              <div className={styling("body-text")}> Breaks: {shift?.breakTime}</div>
            </div>
          )}
          {shift?.pin && (
            <div className={styling("body-row")}>
              <div className={styling("body-icon")}>
                <FontAwesomeIcon icon={faLock} />
              </div>
              <div className={styling("body-text")}>
                Sign on pin: <strong>{shift?.pin}</strong>
              </div>
            </div>
          )}
        </div>
        {status === "hasApplicants" && (
          <p className={styling("editing-published")}>
            *Please note: Editing a published shift will remove all applicants
          </p>
        )}
        <div className={styling("footer")}>
          {!shift.type && (
            <div className={styling("footer-details")}>
              <p className={styling("footer-subtext")}>Total</p>
              {
                <p className={styling("footer-total")}>
                  {confirmedAmount
                    ? `$${roundNumber(confirmedAmount)}`
                    : `$${roundNumber(totalRange.min)} - $${roundNumber(totalRange.max)}`}
                </p>
              }
            </div>
          )}
          <div className={styling("footer-actions")}>
            {editableStatuses.includes(status) && (
              <Button onClick={handleEditShiftClick} theme={"secondary-outline"} className={styling("edit-button")}>
                Edit Shift
              </Button>
            )}
            {status === "confirmed" && (
              <div className={styling("cancel-shift-button")}>
                <Button
                  onClick={() => {
                    setView(false);
                    setCancelShift(true);
                  }}
                  theme={"secondary-outline"}
                  className={styling("edit-button")}
                >
                  Cancel Shift
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default SchedulerViewShift;
