import { useCallback, useMemo, useState } from "react";
import styles from "./SchedulerPublishRoster.module.scss";
import {
  Button,
  ButtonGroup,
  Divider,
  Modal,
  ModalLabel,
  normaliseID,
  OrangeSpan,
  useFetch,
  useStyling,
} from "src/shiftly-ui";
import { useScheduler } from "src/contexts/Scheduler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlaneTop, faSparkle } from "@fortawesome/pro-regular-svg-icons";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";

const SchedulerPublishRoster = ({ roster }) => {
  /*************************************** State *************************************** */
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("all");

  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const { internalShifts, periods } = useScheduler();

  /************************************** Queries ************************************** */
  const { post: publishRoster, isLoading } = useFetch({
    options: {
      onSuccess: () => {
        setSelectedOption("all");
        setShowModal(false);
      },
    },
  });

  /******************************** Functions & Memos ********************************** */
  const shiftsLength = useMemo(() => {
    if (!roster) return 0;
    if (roster.status === "published") return 0;

    if (roster.status === "draft") return internalShifts?.length;

    return (roster?.changed_shifts?.length ?? 0) + (roster?.cancelled_shifts?.length ?? 0);
  }, [roster, internalShifts]);

  const buttonText = useMemo(() => {
    if (roster?.status !== "published" && shiftsLength) return `Publish ${shiftsLength} shift${shiftsLength > 1 ? "s" : ""}`;

    return "Shifts Published";
  }, [roster, shiftsLength]);

  const handleSendMode = (mode) => {
    setSelectedOption(mode);
  };

  const handlePublish = useCallback(() => {
    publishRoster({
      entity: "Roster",
      method: "publishRoster",
      data: {
        mode: selectedOption,
        roster: normaliseID(roster),
      },
    });
  }, [publishRoster, selectedOption, roster]);

  /******************************** Effects & Handles ********************************** */

  return (
    <>
      <Modal showModal={showModal} setShowModal={setShowModal} label={<ModalLabel />} className={styling("modal")}>
        <div className={styling("text-center")}>
          <h2 className={styling("my-3")}>
            <OrangeSpan>Publish</OrangeSpan> your roster
          </h2>
          <p>
            Send employees their roster for the period{" "}
            <strong>
              {periods?.current?.[0]?.toLocaleDateString()} - {periods?.current?.[6]?.toLocaleDateString()}
            </strong>{" "}
            via email.
          </p>
        </div>
        <div className={styling("py-3", "px-5")}>
          <SendMode
            selected={selectedOption === "all"}
            main="Send roster to all employees"
            sub="All team members will be notified of this schedule"
            mode="all"
            handleSendMode={handleSendMode}
          />
          {roster?.status === "unpublished" && (
            <SendMode
              selected={selectedOption === "updates"}
              main="Send updates only"
              sub="Only team members whose shifts have changed will be notified"
              mode="updates"
              handleSendMode={handleSendMode}
            />
          )}
          <div className={styling("new-update")}>
            <div className={styling("flex")}>
              <FontAwesomeIcon icon={faSparkle} className={styling("mr-4")} />
              <p>
                <strong className={styling("mr-1")}>Live employees updates:</strong> New employee app coming soon!
              </p>
            </div>
          </div>
        </div>
        <Divider />
        <ButtonGroup className={styling("flex-between")}>
          <Button theme={"secondary-outline"} onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button theme={"primary"} onClick={handlePublish} icon={faPaperPlaneTop} iconSide="right" loading={isLoading}>
            Publish
          </Button>
        </ButtonGroup>
      </Modal>
      <Button
        className={styling("action-btn")}
        onClick={() => setShowModal(true)}
        disabled={roster?.status === "published" || !shiftsLength}
        icon={roster?.status === "published" || !shiftsLength  ? faCheck : faPaperPlaneTop}
        iconSide="right"
      >
        {buttonText}
      </Button>
    </>
  );
};

export default SchedulerPublishRoster;

const SendMode = ({ selected, main, sub, handleSendMode, mode }) => {
  const styling = useStyling(styles);
  return (
    <div className={styling("mode-container")} onClick={() => handleSendMode(mode)}>
      <div className={styling("mode-button", selected && "selected")}>
        <div className={styling("mode-radio", selected && "selected")} />
      </div>
      <div className={styling("mode-details")}>
        <p className={styling("mb-2")}>
          <strong>{main}</strong>
        </p>
        <p>{sub}</p>
      </div>
    </div>
  );
};
