import { useMemo } from "react";
import useSubscriptions from "src/hooks/useSubscriptions";
import PricingLayout from "./components/PricingLayout";

const PricingOnboarding = () => {
  const { prices, current } = useSubscriptions();
  const cards = useMemo(
    () => [
      {
        title: "Free",
        description: "Perfect for startups or small teams. Manage one organisation and pay just $7 per application.",
        prices: [
          {
            amount: 0,
            unit: "month",
            id: prices?.onboarding?.fixed?.free,
            subscription_item: current?.onboarding?.fixed?.id,
          },
          {
            amount: 7,
            unit: "employee onboarded",
            id: prices?.onboarding?.metered?.free,
            subscription_item: current?.onboarding?.metered?.id,
          },
        ],
        highlight: "1 organisation",
        active: current.onboarding?.fixed?.price === prices?.onboarding?.fixed?.free,
      },
      {
        title: "Essential",
        description:
          "Scale your onboarding process efficiently. Manage up to 5 organisations at a reduced rate of $6.50 per application.",
        prices: [
          {
            amount: 15,
            unit: "month",
            id: prices?.onboarding?.fixed?.essential,
            subscription_item: current?.onboarding?.fixed?.id,
          },
          {
            amount: 6.5,
            unit: "employee onboarded",
            id: prices?.onboarding?.metered?.essential,
            subscription_item: current?.onboarding?.metered?.id,
          },
        ],
        highlight: "5 organisations",
        active: current.onboarding?.fixed?.price === prices?.onboarding?.fixed?.essential,
      },
      {
        title: "Premium",
        description:
          "Ideal for growing businesses. Coordinate up to 10 organisations with an even lower rate of $6 per application.",
        prices: [
          {
            amount: 25,
            unit: "month",
            id: prices?.onboarding?.fixed?.premium,
            subscription_item: current?.onboarding?.fixed?.id,
          },
          {
            amount: 6,
            unit: "employee onboarded",
            id: prices?.onboarding?.metered?.premium,
            subscription_item: current?.onboarding?.metered?.id,
          },
        ],
        highlight: "10 organisations",
        active: current.onboarding?.fixed?.price === prices?.onboarding?.fixed?.premium,
      },
      {
        title: "Enterprise",
        description:
          "Best for large businesses. Manage up to 20 organisations with the lowest rate of $5.50 per application.",
        prices: [
          {
            amount: 50,
            unit: "month",
            id: prices?.onboarding?.fixed?.enterprise,
            subscription_item: current?.onboarding?.fixed?.id,
          },
          {
            amount: 5.5,
            unit: "employee onboarded",
            id: prices?.onboarding?.metered?.enterprise,
            subscription_item: current?.onboarding?.metered?.id,
          },
        ],
        highlight: "20 organisations",
        active: current.onboarding?.fixed?.price === prices?.onboarding?.fixed?.enterprise,
      },
    ],
    [prices, current]
  );

  return <PricingLayout name={"Onboarding"} cards={cards} />;
};

export default PricingOnboarding;
