import moment from "moment-timezone";
import Holidays from "date-holidays";

const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

const roundNumber = (number = 0, digits = 2) => {
  const multiplier = Math.pow(10, digits);
  return Math.round(number * multiplier) / multiplier;
};

const timezoneToStateMap = {
  "Australia/Sydney": "NSW",
  "Australia/Melbourne": "VIC",
  "Australia/Brisbane": "QLD",
  "Australia/Adelaide": "SA",
  "Australia/Perth": "WA",
  "Australia/Hobart": "TAS",
  "Australia/Darwin": "NT",
  "Australia/Lord_Howe": "NSW",
  "Australia/Broken_Hill": "NSW",
  "Australia/Currie": "TAS",
  "Australia/Lindeman": "QLD",
  "Australia/Eucla": "WA",
  "Australia/Yancowinna": "NSW",
};

const awardRules = {
  MA000119: {
    breaks: [{ minHours: 5, maxHours: 10, breakDuration: 30 }],
  },
  MA000009: {
    breaks: [
      { minHours: 5, maxHours: 8, breakDuration: 30 },
      {
        minHours: 8,
        maxHours: 10,
        breakDuration: 30,
        additionalBreak: { breakDuration: 20, paid: true },
      },
    ],
  },
  MA000003: {
    breaks: [
      { minHours: 4, maxHours: 5, breakDuration: 10, paid: true },
      {
        minHours: 5,
        maxHours: 10,
        breakDuration: 30,
        additionalBreak: { breakDuration: 10, paid: true },
      },
    ],
  },
};

const penaltyMap = {
  rates: {
    Saturday: {
      1: 1.5,
      2: 1.5,
      3: 1.5,
      4: 1.5,
      5: 1.5,
      6: 1.5,
      7: 1.5,
    },
    Sunday: {
      1: 1.5,
      2: 1.5,
      3: 1.5,
      4: 1.75,
      5: 1.75,
      6: 1.75,
      7: 1.75,
    },
    publicHoliday: {
      1: 2.5,
      2: 2.5,
      3: 2.5,
      4: 2.5,
      5: 2.5,
      6: 2.5,
      7: 2.5,
    },
    casualLoading: {
      1: 1.25,
      2: 1.25,
      3: 1.25,
      4: 1.25,
      5: 1.25,
      6: 1.25,
      7: 1.25,
    },
  },
  flat: [
    { start: 22, end: 24, rate: 2.62 }, // 10pm - 12am
    { start: 24, end: 30, rate: 3.93 }, // 12am - 6am, adjusted for continuous scale
  ],
};

export const getStateFromTimezone = (timezone) => {
  return timezoneToStateMap[timezone];
};

export const isPublicHoliday = (date, state, holidayClass) => {
  let hd;
  if (holidayClass) {
    hd = new holidayClass();
  } else {
    hd = new Holidays();
  }
  hd.init("AU", state);
  return hd.isHoliday(date);
};

export const getHourlyRateForDayAndLevel = (date, level, hourlyRate, timezone, holidayClass, isCasual = true) => {
  const isHoliday = isPublicHoliday(date, getStateFromTimezone(timezone), holidayClass);
  const dayOfWeek = new Date(date).getDay();
  const dayName = dayNames[dayOfWeek];

  const adjustedHourlyRate = isCasual ? hourlyRate * penaltyMap.rates.casualLoading[level] : hourlyRate;

  if (isHoliday) return adjustedHourlyRate * penaltyMap.rates.publicHoliday[level];

  if (penaltyMap.rates[dayName] && penaltyMap.rates[dayName][level])
    return adjustedHourlyRate * penaltyMap.rates[dayName][level];

  return adjustedHourlyRate;
};

export const applyLateNightPenalties = (start_time, end_time, timezone) => {
  let penalty = 0;

  const startHour = moment.tz(start_time, timezone).hour();
  let endHour = moment.tz(end_time, timezone).hour();

  if (endHour <= startHour) {
    endHour += 24;
  }

  const penaltyPeriods = penaltyMap.flat;

  penaltyPeriods.forEach((period) => {
    const { start, end, rate } = period;
    if (startHour < end && endHour > start) {
      const overlapStart = Math.max(start, startHour);
      const overlapEnd = Math.min(end, endHour);
      const hoursWithinPenalty = overlapEnd - overlapStart;
      penalty += hoursWithinPenalty * rate;
    }
  });
  return penalty;
};

export const calculateBreakTime = ({ startTime, endTime, timezone, award_code }) => {
  const start_time = moment.tz(startTime, timezone);
  const end_time = moment.tz(endTime, timezone);
  const shiftDuration = moment.duration(end_time.diff(start_time)).asHours();

  const rules = awardRules[award_code];

  if (!rules) return { breakTime: null, unpaidBreakTime: 0, breakTimes: [] };

  let breakString = [];
  let breakTimes = [];
  let totalUnpaidBreakTime = 0;

  for (const rule of rules.breaks) {
    if (shiftDuration >= rule.minHours && shiftDuration < rule.maxHours) {
      // Handle the additional break first if it exists
      if (rule.additionalBreak) {
        const remainingShift = shiftDuration - rule.breakDuration / 60;

        const secondBreakStart = start_time.clone().add(remainingShift / 3, "hours"); // More even spacing
        const secondBreakEnd = secondBreakStart.clone().add(rule.additionalBreak.breakDuration, "minutes");

        // Ensure second break happens within shift times
        if (secondBreakEnd.isAfter(end_time)) {
          secondBreakEnd.subtract(secondBreakEnd.diff(end_time), "milliseconds");
          secondBreakStart.subtract(secondBreakEnd.diff(end_time), "milliseconds");
        }

        // Round second break to nearest 15-minute mark
        const roundedSecondBreakStart = roundToNearest15Minutes(secondBreakStart);
        const roundedSecondBreakEnd = roundedSecondBreakStart
          .clone()
          .add(rule.additionalBreak.breakDuration, "minutes");

        breakString.push(
          `${roundedSecondBreakStart.format("hh:mm a")} - ${roundedSecondBreakEnd.format("hh:mm a")} (${
            rule.additionalBreak.paid ? "paid" : "unpaid"
          })`
        );

        if (!rule.additionalBreak.paid) {
          breakTimes.push({
            breakStart: roundedSecondBreakStart,
            breakEnd: roundedSecondBreakEnd,
            breakDuration: rule.additionalBreak.breakDuration,
          });
          totalUnpaidBreakTime += rule.additionalBreak.breakDuration;
        }
      }

      // Now calculate the main break (happens after the additional break if it exists)
      const firstBreakMidpoint = start_time.clone().add(shiftDuration / 2, "hours");
      const firstBreakStart = firstBreakMidpoint.clone().subtract(rule.breakDuration / 2, "minutes");
      const firstBreakEnd = firstBreakStart.clone().add(rule.breakDuration, "minutes");

      // Ensure first break happens within shift times
      if (firstBreakEnd.isAfter(end_time)) {
        firstBreakEnd.subtract(firstBreakEnd.diff(end_time), "milliseconds");
        firstBreakStart.subtract(firstBreakEnd.diff(end_time), "milliseconds");
      }

      // Round to nearest 15-minute mark
      const roundedFirstBreakStart = roundToNearest15Minutes(firstBreakStart);
      const roundedFirstBreakEnd = roundedFirstBreakStart.clone().add(rule.breakDuration, "minutes");

      const isPaid = rule.paid ? " (paid)" : " (unpaid)";
      breakString.push(
        `${roundedFirstBreakStart.format("hh:mm a")} - ${roundedFirstBreakEnd.format("hh:mm a")}${isPaid}`
      );

      if (!rule.paid) {
        breakTimes.push({
          breakStart: roundedFirstBreakStart,
          breakEnd: roundedFirstBreakEnd,
          breakDuration: rule.breakDuration,
        });
        totalUnpaidBreakTime += rule.breakDuration;
      }
    }
  }

  return {
    breakTime: breakString.join(", "),
    unpaidBreakTime: totalUnpaidBreakTime / 60,
    breakTimes,
  };
};

export const roundToNearest15Minutes = (time) => {
  const minutes = time.minutes();
  const roundedMinutes = Math.round(minutes / 15) * 15;
  return time.clone().minutes(roundedMinutes).seconds(0);
};

export const calculateShiftAmount = ({
  start_time: startTime,
  end_time: endTime,
  level,
  hourlyRate,
  modifier,
  timezone,
  award_code,
}) => {
  const start_time = moment.tz(startTime, timezone);
  const end_time = moment.tz(endTime, timezone);
  const duration = moment.duration(end_time.diff(start_time)).asHours();

  let baseAmount = 0;

  const hourCount = {
    ordinary: {
      hours: 0,
      rate: 0,
    },
    Saturday: {
      hours: 0,
      rate: 0,
    },
    Sunday: {
      hours: 0,
      rate: 0,
    },
    publicHoliday: {
      hours: 0,
      rate: 0,
    },
    lateNightRates: {},
  };

  const startTimeHourlyRate = getHourlyRateForDayAndLevel(startTime, level, hourlyRate, timezone);
  let lateNightBonus = 0;

  // Calculate break time
  const { breakTime, unpaidBreakTime, breakTimes } = calculateBreakTime({ startTime, endTime, timezone, award_code });

  // Iterate through each hour of the shift and calculate the adjusted hourly rate
  for (let hour = start_time.clone(); hour.isBefore(end_time); hour.add(1, "hour")) {
    // Determine if this hour is within an unpaid break
    const inUnpaidBreak = breakTimes.some((breakTime) => {
      return hour.isBetween(breakTime.breakStart, breakTime.breakEnd, null, "[)");
    });

    // Determine the adjusted hourly rate for this hour
    let adjustedHourlyRate = hourlyRate;
    const dayOfWeek = hour.day();
    const dayName = dayNames[dayOfWeek];
    const onPublicHoliday = isPublicHoliday(hour.format(), getStateFromTimezone(timezone));

    if (onPublicHoliday) {
      adjustedHourlyRate *= penaltyMap.rates.publicHoliday[level];
      !inUnpaidBreak ? hourCount.publicHoliday.hours++ : (hourCount.publicHoliday.hours += 0.5);
      hourCount.publicHoliday.rate = adjustedHourlyRate;
    } else {
      if (penaltyMap.rates[dayName] && penaltyMap.rates[dayName][level]) {
        adjustedHourlyRate *= penaltyMap.rates[dayName][level];
        !inUnpaidBreak ? hourCount[dayName].hours++ : (hourCount[dayName].hours += 0.5);
        hourCount[dayName].rate = adjustedHourlyRate;
      } else {
        adjustedHourlyRate *= penaltyMap.rates.casualLoading[level];
        !inUnpaidBreak ? hourCount.ordinary.hours++ : (hourCount.ordinary.hours += 0.5);
        hourCount.ordinary.rate = adjustedHourlyRate;
      }
    }

    // Track late night rates
    const hourStart = hour.hour();
    let hourEnd = hour.clone().add(1, "hour").hour();
    if (hourEnd <= hourStart) {
      hourEnd += 24;
    }

    penaltyMap.flat.forEach((period) => {
      const { start, end, rate } = period;
      if (hourStart < end && hourEnd > start) {
        const overlapStart = Math.max(start, hourStart);
        const overlapEnd = Math.min(end, hourEnd);
        const hoursWithinPenalty = overlapEnd - overlapStart;
        const penaltyRate = roundNumber(rate, 2);

        if (hourCount["lateNightRates"][penaltyRate]) {
          hourCount["lateNightRates"][penaltyRate].hours += hoursWithinPenalty;
        } else {
          hourCount["lateNightRates"][penaltyRate] = {
            hours: hoursWithinPenalty,
            rate: penaltyRate,
          };
        }

        lateNightBonus += hoursWithinPenalty * rate;
      }
    });

    // Add the appropriate rate to the base amount
    if (inUnpaidBreak) {
      baseAmount += adjustedHourlyRate / 2;
    } else {
      baseAmount += adjustedHourlyRate;
    }
  }

  const employerContribution = modifier * (duration - unpaidBreakTime);

  const subtotal = baseAmount + lateNightBonus + employerContribution;

  return {
    subtotal,
    lateNightBonus,
    baseAmount,
    hourlyRate: startTimeHourlyRate,
    breakTime,
    unpaidBreakTime,
    duration: duration - unpaidBreakTime,
    employerContribution,
    hourCount,
  };
};

export const addShiftlyFeesToShiftTotal = (shiftSubTotal) => {
  const SHIFTLY_FLAT_FEE = parseFloat(process.env.SHIFTLY_FLAT_FEE) || 0;
  const SHIFTLY_PERCENTAGE = parseFloat(process.env.SHIFTLY_PERCENTAGE) || 0;
  const SHIFTLY_SUPER_RATE = parseFloat(process.env.SHIFTLY_SUPER_RATE) || 0;

  const shiftlyFlatFee = SHIFTLY_FLAT_FEE;
  const shiftlyPercentageRate = shiftSubTotal * SHIFTLY_PERCENTAGE;
  const shiftlyFee = shiftlyPercentageRate + shiftlyFlatFee;
  const superAmount = shiftSubTotal * SHIFTLY_SUPER_RATE;
  const shiftTotal = shiftSubTotal + shiftlyFee + superAmount;

  return { shiftlyFee, superAmount, shiftTotal, shiftSubTotal };
};
