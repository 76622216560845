import React, { forwardRef } from "react";
import styles from "./Welcome.module.scss";
import { useStyling, Button, OrangeSpan } from "src/shiftly-ui";
import logo from "src/shared/assets/logos/shiftly logo.svg";

import AnimatedContainer from "../components/AnimatedContainer";

const Welcome = forwardRef(({ internalStaff = {}, location = {}, setActiveTab = () => {} }, ref) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  return (
    <AnimatedContainer className={styling("flex", "flex-column")}>
      <div className={styling("text-center")}>
        <div className={styling("logo")}>
          <img src={location?.logo === "/defaults/location_default.png" ? logo : location?.logo} alt="logo" />
        </div>
        <h1>Hello {internalStaff?.first_name}!</h1>
        <h2 className={styling("mt-3")}>
          Welcome to <OrangeSpan>{location?.name}</OrangeSpan>.
        </h2>
        <div className={styling("content")}>
          <p>
            <strong>
              We're so excited to have you join {location?.name}. To get started, we’ll guide you through a quick
              onboarding process to set you up in our system. Here's what you'll need to have handy:
            </strong>
          </p>
          <ul>
            <li>
              <span>Tax File Number (TFN):</span>
              <br />
              This helps us ensure everything's set up for your payments
            </li>
            <li>
              <span>Residency Status Details</span>
              <br />
              Whether you're an Australian resident or working on a visa will help us pay you accordingly
            </li>
            <li>
              <span>Bank Account Information</span>
              <br />
              So we can deposit your earnings directly into your account
            </li>
            <li>
              <span>Superannuation Details</span>
              <br />
              Let us know where you'd like your super contributions to be sent
            </li>
          </ul>
          <p>
            If you get stuck on any of the questions, there are links available to explain the appropriate answer for
            your circumstances. Otherwise, please reach out to your employer who will guide you through the process.{" "}
            <br />
            <br />
          </p>
          <p>
            <strong>When you're ready, click the button below to get started!</strong>
          </p>
          <Button theme={"primary"} onClick={() => setActiveTab((prev) => prev + 1)} className={styling("mt-3")}>
            Get Started
          </Button>
        </div>
      </div>
    </AnimatedContainer>
  );
});

export default Welcome;
