import {
  useCachedQuery,
  useFetch,
  convertStringTimeToDate,
  calculateBreakTime,
  getHourlyRateForDayAndLevel,
  normaliseID,
  roundNumber,
  applyLateNightPenalties,
} from "src/shiftly-ui";
import useShiftlyLocation from "./useShiftlyLocation";
import { useMemo } from "react";
import useBusiness from "./useBusiness";
import Holidays from "date-holidays";

const useInternalStaff = ({ startTime, endTime, shiftDate, selectedStaffMember } = {}) => {
  /*************************************** State *************************************** */
  /*************************************** Hooks *************************************** */
  const { activeLocation } = useShiftlyLocation();
  const { activeBusiness } = useBusiness();
  const {
    InternalStaff: { GetStaffForLocation },
  } = useCachedQuery();
  /********************************** Refs & Constants ********************************* */

  /************************************** Queries ************************************** */
  const { data: internalStaff } = useFetch({
    request: {
      entity: "InternalStaff",
      method: "get",
      id: GetStaffForLocation,
      criteria: {
        location: activeLocation?._id,
        sync_status: {
          $ne: "incomplete",
        },
      },
    },
    dependency: activeLocation?._id,
  });

  const {
    data: [employment],
  } = useFetch({
    request: {
      entity: "Employment",
      criteria: {
        internalStaff: normaliseID(selectedStaffMember),
      },
    },
    dependency: selectedStaffMember,
  });

  /******************************** Functions & Memos ********************************** */
  const voidEffect = useMemo(
    () =>
      Boolean(startTime === ":" || endTime === ":" || !shiftDate || !activeBusiness || !activeLocation || !employment),
    [activeBusiness, activeLocation, shiftDate, startTime, endTime, employment]
  );

  const duration = useMemo(() => {
    if (voidEffect) return 0;

    const { startDate, endDate } = convertStringTimeToDate(shiftDate, startTime, endTime);
    const { award_code } = employment;

    const { unpaidBreakTime } = calculateBreakTime({
      startTime: startDate,
      endTime: endDate,
      timezone: activeLocation?.timezone,
      award_code,
    });

    const dur = roundNumber((endDate - startDate) / 1000 / 60 / 60 - unpaidBreakTime);
    return isNaN(dur) ? 0 : dur;
  }, [voidEffect, shiftDate, startTime, endTime, employment, activeLocation]);

  const hourlyRate = useMemo(() => {
    if (voidEffect) return 0;
    const { startDate } = convertStringTimeToDate(shiftDate, startTime, endTime);
    const fullTimeRate = employment?.hourly_rate ?? 0;

    const adjustedRate = getHourlyRateForDayAndLevel(
      startDate,
      employment?.classification_level ?? 1,
      fullTimeRate,
      activeLocation?.timezone,
      Holidays
    );

    return adjustedRate;
  }, [employment, shiftDate, startTime, endTime, activeLocation, voidEffect]);

  const subTotal = useMemo(() => duration * hourlyRate, [duration, hourlyRate]);

  const superContribution = useMemo(
    () => duration * hourlyRate * Number(process.env.REACT_APP_SHIFTLY_SUPER_RATE),
    [duration, hourlyRate]
  );

  const lateNightPenalty = useMemo(() => {
    const { startDate, endDate } = convertStringTimeToDate(shiftDate, startTime, endTime);
    return roundNumber(applyLateNightPenalties(startDate, endDate, activeLocation?.timezone), 2);
  }, [shiftDate, startTime, endTime, activeLocation]);

  const shiftTotal = useMemo(
    () => subTotal + lateNightPenalty + superContribution,
    [subTotal, lateNightPenalty, superContribution]
  );

  return {
    internalStaff,
    duration,
    hourlyRate: hourlyRate?.toFixed(2),
    subTotal: subTotal?.toFixed(2),
    superContribution: superContribution?.toFixed(2),
    lateNightPenalty,
    shiftTotal: shiftTotal?.toFixed(2),
  };
};

export default useInternalStaff;
