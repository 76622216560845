import StandardLayout from "src/components/standard_layout/StandardLayout";
import styles from "./XeroPricing.module.scss";
import { Button, Divider, Link, OrangeSpan, useStyling } from "src/shiftly-ui";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import usePaymentMethods from "src/hooks/usePaymentMethods";
import BusinessAccountAddNewPaymentMethod from "src/pages/business/business_account/payment_method/BusinessAccountAddNewPaymentMethod";

const XeroPricing = () => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const { defaultPaymentMethod } = usePaymentMethods();
  const navigate = useNavigate();
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);
  /******************************** Functions & Memos ********************************** */
  const handleButtonClick = useCallback(() => {
    if (!defaultPaymentMethod) {
      setShowPaymentMethodModal(true);
    } else {
      navigate("/integrations/xero");
    }
  }, [defaultPaymentMethod, navigate]);

  const paymentMethodAddedCallback = useCallback(() => {
    navigate("/integrations/xero");
  }, [navigate]);

  return (
    <>
      <BusinessAccountAddNewPaymentMethod
        showModal={showPaymentMethodModal}
        setShowModal={setShowPaymentMethodModal}
        callback={paymentMethodAddedCallback}
      />
      <StandardLayout
        breadcrumb={[
          { label: "Business Settings", link: "/business-account" },
          { label: "Integrations", link: "/integrations" },
        ]}
        heading={
          <>
            Xero <OrangeSpan>Integration</OrangeSpan>
          </>
        }
      >
        <div className={styling("container")}>
          <p>
            Shiftly integrates directly with Xero, allowing syncing of employee and rostering data for a fast,
            hassle-free payrun. Timesheets approved through Shiftly can be exported to Xero in seconds, meaning less
            manual entry and more payroll accuracy. With this integration, you can expect seamless workflows between
            business owners and payroll administrators, and improvement in overall efficiency and accurancy and less
            admin time and errors.
          </p>

          <p className={styling("mt-3")}>
            <strong>Key Benefits:</strong>
          </p>
          <ul>
            <li>Sync employee information automatically between Shiftly and Xero</li>
            <li>Fully managed new hire onboarding forms</li>
            <li>Live updates on onboarding status</li>
            <li>Verify employee details</li>
            <li>Keep existing employees in sync</li>
            <li>Export timesheets instantly, with consistent and standardised formatting</li>
            <li>Eliminate lost or incomplete timesheets with a digital, paperless approval system</li>
          </ul>
          <p>
            Shiftly and Xero work hand-in-hand to simplify shift management, optimise payroll, and give you more time to
            focus on growing your business.
          </p>
          <Divider className={styling("my-3")} />
          <p>
            <strong>
              <OrangeSpan>Pay as you go</OrangeSpan>
            </strong>
          </p>
          <ul>
            <li>$3/month per Xero Organisation connected</li>
            <li>$7 per employee onboarded and imported into Xero</li>
          </ul>
          <Divider className={styling("my-3")} />
          <div className={styling("flex", "flex-between", "mt-2")}>
            <Button theme={"primay"} onClick={handleButtonClick}>
              {defaultPaymentMethod ? "Get Started" : "Set up a payment method"}
            </Button>
            <Link to="https://www.shiftly.au/contact">Learn how to integrate Shiftly and Xero</Link>
          </div>
        </div>
      </StandardLayout>
    </>
  );
};

export default XeroPricing;
