import { forwardRef, useMemo, useState } from "react";
import {
  useStyling,
  FormFlex,
  responsibilities,
  FormV2,
  Dropdown,
  RadioButtons,
  Input,
  Checkbox,
} from "src/shiftly-ui";
import useBusiness from "src/hooks/useBusiness";
import usePositions from "src/hooks/usePositions";

// Variables
const contractor = "CONTRACTOR";
const employee = "EMPLOYEE";

const employeeOptions = [
  { label: "Full Time", value: "FULLTIME" },
  { label: "Part Time", value: "PARTTIME" },
  { label: "Casual", value: "CASUAL" },
];

const contractorOptions = [
  { label: "Labour Hire", value: "LABOURHIRE" },
  { label: "Non Employee", value: "NONEMPLOYEE" },
];

const incomeOptions = [
  { label: "Salary and Wages", value: "SALARYANDWAGES" },
  { label: "Working Holiday Maker", value: "WORKINGHOLIDAYMAKER" },
  { label: "Closely Held Payees", value: "CLOSELYHELDPAYEES" },
];

const ManageStaffBaseFields = forwardRef(({ onSubmit, data, setData }, ref) => {
  /*************************************** State *************************************** */
  const [employmentBasisOptions, setEmploymentBasisOptions] = useState(employeeOptions);
  const [incomeTypeOptions, setIncomeTypeOptions] = useState(incomeOptions);

  /*************************************** Hooks *************************************** */
  const styling = useStyling();
  const { activeBusiness } = useBusiness();
  const { availableLevels } = usePositions();

  /********************************** Refs & Constants ********************************* */
  const canSelectEligibleToReceiveLeaveLoading =
    data?.employment_basis !== "CASUAL" && (!data?.employment_type || data?.employment_type === employee);
  const notAContractor = data?.employment_type !== contractor;
  const award = responsibilities[data?.award_code];

  /******************************** Functions & Memos ********************************** */
  const awardCodes = useMemo(
    () => activeBusiness?.industries?.map((i) => ({ label: i.award_code + " - " + i.name, value: i.award_code })),
    [activeBusiness]
  );

  const awardStreams = useMemo(() => {
    if (!award) return [];
    return Object.keys(award).map((key) => ({ label: key, value: key }));
  }, [award]);

  /******************************** Effects & Handles ********************************** */

  return (
    <FormV2 ref={ref} onSubmit={onSubmit} data={data} setData={setData}>
      <div className={styling("flex", "flex-column", "lg-flex-row", "flex-between", "flex-align-start")}>
        <div className={styling("w-100", "lg-pr-2")}>
          <FormFlex>
            <Input name={"first_name"} required />
            <Input name={"last_name"} required />
          </FormFlex>
          <Input name={"email"} required />
          <Input name={"job_title"} label={"Job Title"} placeholder={"Job Title"} />
          <FormFlex>
            <Input type={"date"} name={"start_date"} required label={"Start Date"} />
            <Input type={"number"} name={"hourly_rate"} label={"Hourly Rate"} placeholder={"0.00"} required />
          </FormFlex>
          <RadioButtons
            name={"employment_type"}
            options={[
              { label: "Employee", value: employee },
              { label: "Contractor", value: contractor },
            ]}
            onChange={(value) => {
              if (value === employee) {
                setEmploymentBasisOptions(employeeOptions);
                setIncomeTypeOptions(incomeOptions);
              } else {
                setEmploymentBasisOptions(contractorOptions);
                setIncomeTypeOptions(contractorOptions);
              }
              setData((prev) => ({ ...prev, employment_type: value, employment_basis: null, income_type: null }));
            }}
            label={"Is this person an employee or a contractor?"}
            required
            mt={"5px"}
          />
        </div>
        <div className={styling("w-100", "lg-pl-2")}>
          <FormFlex>
            <Dropdown
              name={"employment_basis"}
              label={"Employment Basis"}
              placeholder={"Select Employment Basis"}
              required
              options={employmentBasisOptions}
              disabled={!data.employment_type}
              onChange={(value) => {
                if (data.employment_type === contractor) {
                  setData((prev) => ({
                    ...prev,
                    income_type: value,
                    employment_basis: value,
                    eligible_to_receive_leave_loading: false,
                  }));
                } else if (value === "CASUAL") {
                  setData((prev) => ({
                    ...prev,
                    eligible_to_receive_leave_loading: false,
                    employment_basis: value,
                  }));
                }
              }}
            />
            <Dropdown
              name={"income_type"}
              placeholder={"Select Income Type"}
              label={"Income Type"}
              options={incomeTypeOptions}
              required
              disabled={data.employment_type === contractor || !data.employment_type}
            />
          </FormFlex>

          <Dropdown
            name={"award_code"}
            label={"Award Code"}
            link={"https://www.com."}
            placeholder={"Select an Award Code"}
            options={awardCodes}
            required={notAContractor}
            disabled={!notAContractor}
          />
          <FormFlex>
            <Dropdown
              name={"income_stream"}
              label={"Award Stream"}
              disabled={!data?.award_code || !notAContractor || !awardStreams.length}
              options={awardStreams}
              required={notAContractor && !!awardStreams.length}
            />
            <Dropdown
              name={"classification_level"}
              label={"Award Level"}
              placeholder={"Select an Award Level"}
              disabled={!data?.award_code || !notAContractor || !availableLevels.length}
              options={availableLevels}
              required={notAContractor && !!availableLevels.length}
            />
          </FormFlex>
          <Input name={"notes"} label={"Notes"} placeholder={"Notes"} />
          <FormFlex className={styling("mt-3")}>
            <Checkbox name={"is_authorised_to_approve_leave"} label={"Authorised to approve leave"} />
            <Checkbox name={"is_authorised_to_approve_timesheets"} label={"Authorised to approve timesheets"} />
          </FormFlex>
          {canSelectEligibleToReceiveLeaveLoading && (
            <Checkbox
              name={"eligible_to_receive_leave_loading"}
              label={"Eligible to receive leave loading"}
              disabled={!canSelectEligibleToReceiveLeaveLoading}
            />
          )}
        </div>
      </div>
    </FormV2>
  );
});

export default ManageStaffBaseFields;
