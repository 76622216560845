//Constants

export const actorsAndEnterTainers = "ACTORSARTISTSENTERTAINERS";
export const horticulturistsAndShearers = "HORTICULTURISTORSHEARER";
export const seniorAndPensioner = "SENIORORPENSIONER";

export const australianResident = "AUSTRALIANRESIDENT";
export const foreignResident = "FOREIGNRESIDENT";
export const workingHolidayMaker = "WORKINGHOLIDAYMAKER";

export const nonEmpoloyee = "NONEMPLOYEE";
export const notQuoted = "NOTQUOTED";

// Tax Scale Options
export const australianTaxScaleOptions = [
  { label: "Regular", value: "REGULAR" },
  { label: "Actors, Artists & Entertainers", value: actorsAndEnterTainers },
  { label: "Horticulturists or Shearers", value: horticulturistsAndShearers },
  { label: "Seniors & Pensioners", value: seniorAndPensioner },
];

export const workingHolidayTaxScaleOptions = [{ label: "Working Holiday Makers", value: workingHolidayMaker }];

export const foreignTaxScaleOptions = [
  { label: "Foreign Resident", value: "FOREIGN" },
  { label: "Horticulturists or Shearers", value: horticulturistsAndShearers },
];

//TFN Exemption Options
export const standardTFNExemptionOptions = [
  { label: "I'm under 18", value: "UNDER18" },
  { label: "I'm a pensioner", value: "PENSIONER" },
  { label: "I'm waiting for my TFN", value: "PENDING" },
  { label: "I don't have a TFN", value: notQuoted },
];

export const workingHolidayTFNExemptionOptions = [
  { label: "I'm waiting for my TFN", value: "PENDING" },
  { label: "I don't have a TFN", value: notQuoted },
];

export const getInitialFormState = (tax_declaration = {}) => {
  const {
    income_type,
    tax_scale_type = "",
    tax_free_threshold,
    residency_status,
    country_of_residence,
    has_loan_or_student_debt,
  } = tax_declaration;

  const isWHM = income_type === workingHolidayMaker;
  const isNonEmployee = income_type === nonEmpoloyee;
  const isWHMOrNonEmployee = isWHM || isNonEmployee;

  return {
    ...tax_declaration,
    tax_scale_type: isWHM ? workingHolidayMaker : isNonEmployee ? "" : tax_scale_type,
    residency_status: isNonEmployee ? australianResident : isWHM ? foreignResident : residency_status,
    country_of_residence: residency_status === australianResident ? "" : country_of_residence,
    tax_free_threshold: isWHMOrNonEmployee ? false : tax_free_threshold,
    has_loan_or_student_debt: isWHMOrNonEmployee ? false : has_loan_or_student_debt,
  };
};

export const calculateCanClaimTaxFreeThreshold = (tax_declaration) => {
  const { income_type, tax_scale_type, residency_status, tfn_exemption_type } = tax_declaration;

  const isNonEmployee = income_type === nonEmpoloyee;
  const isActorOrEntertainer = tax_scale_type === actorsAndEnterTainers;
  const isAustralianResident = residency_status === australianResident || !residency_status;
  const isNonQuoted = tfn_exemption_type === notQuoted;

  return !isActorOrEntertainer && isAustralianResident && !isNonEmployee && !isNonQuoted;
};

export const buildTFNExemptionOptions = (tax_declaration) => {
  const { income_type } = tax_declaration;
  return income_type === workingHolidayMaker ? workingHolidayTFNExemptionOptions : standardTFNExemptionOptions;
};

export const buildTaxScaleOptions = (tax_declaration) => {
  const { income_type, residency_status } = tax_declaration;

  const taxScaleMap = {
    [workingHolidayMaker]: workingHolidayTaxScaleOptions,
    [foreignResident]: foreignTaxScaleOptions,
    [australianResident]: australianTaxScaleOptions,
    default: australianTaxScaleOptions,
  };

  return taxScaleMap[income_type] ?? taxScaleMap[residency_status] ?? taxScaleMap.default;
};

export const handleResidencyChange = (tax_declaration) => {
  const { residency_status, tax_scale_type = "", income_type } = tax_declaration;

  const isAustralianResident = residency_status === australianResident;

  const isWHM = income_type === workingHolidayMaker;

  return {
    ...tax_declaration,
    tax_scale_type: isWHM ? workingHolidayMaker : tax_scale_type,
    residency_status,
    country_of_residence: isAustralianResident ? "" : tax_declaration.country_of_residence,
  };
};
