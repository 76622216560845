import { useState } from "react";
import styles from "./Status.module.scss";
import { useStyling, useSearchParams, OrangeSpan } from "src/shiftly-ui";
import Illustration from "src/shared/components/decorative/Illustration";

const Status = () => {
  const styling = useStyling(styles);
  const [mode, setMode] = useState("expired");

  useSearchParams(
    ["mode"],
    ({ mode }) => {
      setMode(mode);
    },
    []
  );

  return (
    <div className={styling("container")}>
      <div className={styling("content-wrapper")}>
        <div className={styling("content")}>
          <Illustration image={mode === "expired" ? "expired_link.webp" : "onboarding_success.webp"} />

          {mode === "expired" ? (
            <h2 className={styling("title")}>
              Your onboarding link has <OrangeSpan>expired.</OrangeSpan>
            </h2>
          ) : (
            <h2 className={styling("title")}>
              Your <span>fully onboarded</span>, thank you!
            </h2>
          )}

          <p className={styling("mt-3")}>
            {mode === "expired"
              ? "Please contact your employer and request a new link."
              : "You can now close this window."}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Status;
