import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import styles from "./InternalStaffTable.module.scss";
import {
  useStyling,
  useFetch,
  Table,
  useCachedQuery,
  EmptyState,
  Button,
  Modal,
  useAlerts,
  useUI,
} from "src/shiftly-ui";
import { faPaperPlane } from "@fortawesome/pro-regular-svg-icons";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import ActionsDropdown from "../ActionsDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/pro-solid-svg-icons";
import useIntegration from "src/hooks/useIntegration";
import useInternalStaffTable from "../../hooks/useInternalStaffTable";
import useBusiness from "src/hooks/useBusiness";

const InternalStaffTable = forwardRef((props, ref) => {
  /*************************************** State *************************************** */

  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const { activeLocation } = useShiftlyLocation();
  const { activeIntegration: integration } = useIntegration("xero");
  const { activeBusiness } = useBusiness();
  const {
    InternalStaff: { GetAllStaffIncludingIncomplete },
  } = useCachedQuery();
  const { confirm } = useAlerts();
  const { sendOnboardingRequest, sendReminder, loadingAction, editStaffMember, deleteStaffMember, handleXeroColumn } =
    useInternalStaffTable();
  const { settings, updateShallowSetting } = useUI();
  /************************************** Queries ************************************** */
  const {
    data: internalStaff,
    isLoading,
    refetch,
  } = useFetch({
    request: {
      id: GetAllStaffIncludingIncomplete,
      entity: "InternalStaff",
      method: "get",
      criteria: {
        location: activeLocation?._id,
      },
      options: {
        GetOnboardingStatus: true,
      },
    },
    dependency: activeLocation?._id,
  });

  const { post } = useFetch({
    options: {
      onSuccess: () => {
        refetch();
      },
    },
  });

  /******************************** Functions & Memos ********************************** */

  const handleImportXeroClick = useCallback(() => {
    props.setShowImportModal(true);
  }, [props]);

  const columns = useMemo(() => {
    const columns = [
      { flex: 1, field: "shifter", headerName: "Staff Member", sortable: true, align: "left", minWidth: 200 },
      { flex: 1, field: "email", headerName: "Email", sortable: true, align: "left", minWidth: 150 },
      { flex: 1, field: "phone", headerName: "Phone", sortable: true, align: "left", minWidth: 150 },
      {
        flex: 1,
        field: "income_stream",
        headerName: "Stream",
        sortable: true,
        align: "left",
        render: (row) => <p>{row.income_stream}</p> || "-",
        minWidth: 150,
      },
      {
        flex: 1,
        field: "level",
        headerName: "Level",
        sortable: true,
        align: "center",
        render: (row) => <p>{row.level ? "Level " + row.level : "-"}</p>,
        minWidth: 150,
      },
      {
        flex: 1,
        minWidth: 150,
        field: "hourly_rate",
        headerName: "Hourly Rate",
        sortable: true,
        align: "center",
        render: (row) => (
          <p>
            {row.hourly_rate
              ? new Intl.NumberFormat("en-AU", { style: "currency", currency: "AUD" }).format(row.hourly_rate)
              : "-"}
          </p>
        ),
      },
      {
        width: 100,
        minWidth: 150,
        field: "actions",
        headerName: "",
        sortable: false,
        align: "center",
        render: (row) => {
          const actions = [
            { label: "Edit", action: () => editStaffMember(row) },
            { label: "Delete", action: () => deleteStaffMember(row) },
          ];

          if (integration && row.sync_status === "incomplete")
            actions.push({ label: "Send Onboarding Invite", action: () => sendOnboardingRequest(row) });

          if (row.sync_status === "pending") {
            actions.push({
              label: "Send Reminder",
              action: () => sendReminder(row),
            });
          }

          return (
            <div className={styling("position-relative", "flex")}>
              <ActionsDropdown actions={actions} />
            </div>
          );
        },
      },
    ];

    if (integration)
      columns.push({
        width: 120,
        field: "sync_status",
        headerName: "Xero Sync",
        sortable: false,
        align: "center",
        render: (row) => {
          const { sync_status } = row;
          let text = "";
          let icon;

          switch (sync_status) {
            case "unsynced":
              text = "Send invite";
              icon = faPaperPlane;
              break;

            case "outofsync":
              text = "Sync now";
              icon = faRefresh;
              break;

            case "synced":
              text = "Synced";
              break;

            case "pending":
              text = "Pending";
              break;

            case "incomplete":
              text = "Incomplete";
              break;

            case "failed":
              text = "Retry";
              icon = faRefresh;
              break;
            default:
              break;
          }
          return (
            <div onClick={() => handleXeroColumn(row)} className={styling("sync", sync_status)}>
              <p>{text}</p>
              {icon && <FontAwesomeIcon icon={icon} />}
            </div>
          );
        },
      });

    return columns;
  }, [sendReminder, styling, integration, editStaffMember, handleXeroColumn, deleteStaffMember, sendOnboardingRequest]);

  /******************************** Effects & Handles ********************************** */
  useImperativeHandle(
    ref,
    () => ({
      handleResyncXero: async () => {
        return await post({
          entity: "InternalStaff",
          method: "refreshAllEmployeeSyncStatus",
          data: {
            location: activeLocation?._id,
            integration: integration?._id,
          },
        });
      },
    }),
    [post, activeLocation?._id, integration?._id]
  );

  useEffect(() => {
    if (!activeBusiness?.xero_organisation || internalStaff.length || isLoading || settings.promptedToImportFromXero)
      return;

    setTimeout(async () => {
      if (
        await confirm({
          label: (
            <>
              Would you like to <span>import employees from Xero?</span>
            </>
          ),
          confirmText: "Yes, import employees",
          cancelText: "No, cancel",
          content: (
            <div className={styling("import-content")}>
              <p>
                Now that you have connceted Xero to your business, you can import employees into this location{" "}
                <span>free of charge.</span>
              </p>
              <p>
                <strong>This means you can:</strong>
              </p>
              <ul>
                <li>Manage employee data in Shiftly</li>
                <li>Keep Xero and SHiftly employee data in sync</li>
                <li>Send Shiftly rosters directly to staff</li>
                <li>Send employee timesheets to Xero</li>
              </ul>
            </div>
          ),
        })
      )
        handleImportXeroClick();

      updateShallowSetting({ setting: "promptedToImportFromXero", value: true });
    }, 1000);
  }, [
    activeBusiness?.xero_organisation,
    confirm,
    handleImportXeroClick,
    styling,
    internalStaff.length,
    isLoading,
    settings.promptedToImportFromXero,
    updateShallowSetting,
  ]);

  return (
    <EmptyState
      items={internalStaff}
      isLoading={isLoading}
      image="empty_employees.webp"
      text="Create one now!"
      component={
        <>
          <p className={styling("my-3")}>
            <strong>
              No employees setup yet. {activeBusiness?.xero_organisation ? "Import them below:" : "Create one now!"}
            </strong>
          </p>
          <Button theme={"secondary-outline"} onClick={handleImportXeroClick}>
            <span>Import from Xero</span>
            <img alt={"Xero Logo"} src={"/global/xero_logo.png"} className={styling("xero-logo")} />
          </Button>
        </>
      }
    >
      <Table
        getRowId={(r) => r._id}
        columns={columns}
        onRowDoubleClick={({ row }) => editStaffMember(row)}
        loading={isLoading || loadingAction}
        rows={internalStaff}
      />
    </EmptyState>
  );
});

export default InternalStaffTable;
