import { Input, Dropdown, Address, FormFlex, OrangeSpan } from "src/shiftly-ui";
import styles from "./NewBusinessSetup.module.scss";
import moment from "moment-timezone";
import { useEffect } from "react";
import { useStyling } from "src/shiftly-ui";

const NewBusinessLocations = ({ data, setData, multipleLocations = {} }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  /******************************** Effects & Handles ********************************** */
  useEffect(() => {
    if (!data?.timezone) {
      setData((prev) => ({
        ...prev,
        timezone: moment.tz.guess(),
      }));
    }
  }, [data, setData]);

  return (
    <div className={styling("container")}>
      <div className={styling("heading")}>
        <h2>
          Add your {multipleLocations ? "first" : ""} <OrangeSpan>location</OrangeSpan> below{" "}
        </h2>
      </div>
      <div className={styling("content", "medium-content")}>
        <FormFlex>
          {multipleLocations && (
            <Input
              label={"Name this location"}
              required
              placeholder={"Cafe on the Corner - High Street"}
              name={"locationName"}
            />
          )}
          <Dropdown name={"timezone"} />
        </FormFlex>
        <Address name={"address"} />
      </div>
    </div>
  );
};

export default NewBusinessLocations;
