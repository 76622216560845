import Illustration from "src/shared/components/decorative/Illustration";
import styles from "./Confirm.module.scss";
import { Button, OrangeSpan, useFetch, useSearchParams, useStyling } from "src/shiftly-ui";

const Confirm = () => {
  /*************************************** State *************************************** */

  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  /********************************** Refs & Constants ********************************* */

  /******************************** Functions & Memos ********************************** */
  const { post: confirmAccount } = useFetch({});
  /************************************** Queries ************************************** */

  /******************************** Effects & Handles ********************************** */
  useSearchParams(["token"], ({ token }) => {
    if (token)
      confirmAccount({
        node: "auth-node",
        method: "confirmAccount",
        data: {
          token,
        },
      });
  });
  return (
    <div className={styling("flex", "flex-column")}>
      <h2>
        Your account is now <OrangeSpan>confirmed!</OrangeSpan>
      </h2>
      <p className={styling("mt-4")}>
        You're all set. Lets make some <OrangeSpan>magic</OrangeSpan> happen.
      </p>
      <Illustration image="confirm_account.webp" />
      <Button link={"/portal?page=login"}>Back to Shiftly</Button>
    </div>
  );
};

export default Confirm;
