import { useDisplay } from "src/shiftly-ui";
import { motion, useInView } from "framer-motion";
import { useRef } from "react";

export const FadeInDiv = ({ show = false, delay = 0.5, duration = 0.8, distance = 20, margin = "-20%", ...props }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { margin, once: true });

  const { isMobile } = useDisplay();

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: distance }}
      animate={isInView || show || isMobile ? { opacity: 1, y: 0 } : {}}
      transition={{ duration, delay }}
      {...props}
    ></motion.div>
  );
};

export default FadeInDiv;
