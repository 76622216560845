import { useFetch, RadioButtons, OrangeSpan, useCachedQuery, useUI, Dropdown } from "src/shiftly-ui";
import styles from "./NewBusinessSetup.module.scss";
import NewBusinessRadioButtonCard from "./NewBusinessRadioButtonCard";
import { faBurger, faForkKnife, faHotel, faMartiniGlass } from "@fortawesome/pro-regular-svg-icons";
import { useStyling } from "src/shiftly-ui";

const iconMap = {
  MA000009: faForkKnife,
  MA000003: faBurger,
  MA000119: faHotel,
};

const supportedAwards = ["MA000009", "MA000003", "MA000119"];

const NewBusinessIndustry = ({ data }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const {
    settings: { isAThirdPartyProvider },
  } = useUI();

  /************************************** Queries ************************************** */
  const { data: industries } = useFetch({ request: { entity: "Industry" } });

  /******************************** Functions & Memos ********************************** */
  const supportedIndustries = industries?.filter((award) => supportedAwards.includes(award?.award_code));

  return (
    <div className={styling("container")}>
      <div className={styling("heading")}>
        <h2>
          Which <OrangeSpan>industry awards</OrangeSpan> cover your workforce?
        </h2>
      </div>
      <div className={styling("content")}>
        {isAThirdPartyProvider ? (
          <div className={styling("award-dropdown")}>
            <Dropdown
              name={"industries"}
              label={"Which industry awards cover your clients workforce?"}
              multiSelect
              required
              options={industries.map((industry) => ({
                label: `${industry.award_code} - ${industry?.name}`,
                value: industry?._id,
              }))}
            ></Dropdown>
          </div>
        ) : (
          <RadioButtons
            required
            align="center"
            multiselect
            options={supportedIndustries.map((industry) => ({
              label: industry?.name,
              value: industry?._id,
              content: (
                <NewBusinessRadioButtonCard
                  label={industry?.name}
                  award={industry?.award_code}
                  icon={iconMap[industry?.award_code]}
                  active={data?.category === industry?._id}
                />
              ),
            }))}
            type="cards"
            name="industries"
          />
        )}
      </div>
    </div>
  );
};

export default NewBusinessIndustry;
