import {
  Input,
  validatePassword,
  Button,
  useFetch,
  useToast,
  useAuth,
  OrangeSpan,
  useStyling,
  Divider,
  ButtonGroup,
} from "src/shiftly-ui";

import StandardLayout from "../../components/standard_layout/StandardLayout";
import { useCallback, useState } from "react";
import { faSave } from "@fortawesome/pro-regular-svg-icons";
import { useNavigate } from "react-router-dom";

const AccountSettingsPassword = () => {
  /*************************************** State *************************************** */
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  /*************************************** Hooks *************************************** */
  const navigate = useNavigate();
  const { user } = useAuth();
  const toast = useToast();
  const styling = useStyling();

  /************************************** Queries ************************************** */
  const { isLoading, post: savePasswordPost } = useFetch({
    options: {
      onSuccess: () => {
        toast.success("Your password has been updated successfully!", "Success");
        navigate("/account-management/personal-details");
      },
    },
  });

  /******************************** Functions & Memos ********************************** */
  const handleSavePassword = useCallback(() => {
    if (newPassword !== confirmPassword) {
      toast.error("Your passwords don't match. Give it another whirl?", "Error");
      return;
    }
    if (!validatePassword(newPassword)) {
      toast.error("Ah this puzzle seems too easy to crack! Please make it more secure.", "Error");
      return;
    }
    savePasswordPost({
      entity: "Authentication",
      method: "changePassword",
      criteria: {
        user: user?._id,
      },
      data: {
        currentPassword,
        newPassword,
      },
    });
  }, [toast, newPassword, confirmPassword, savePasswordPost, currentPassword, user]);

  return (
    <StandardLayout
      heading={
        <>
          Manage <OrangeSpan>Password</OrangeSpan>
        </>
      }
      breadcrumb={[
        { label: "Account Management", link: "/account-management" },
        { label: "Personal Details & Security", link: "/account-management/personal-details" },
      ]}
    >
      <h6 className={styling("my-3")}>Current Password</h6>
      <Input value={currentPassword} setValue={setCurrentPassword} name={"password"} validate={false} />
      <Divider />
      <h6 className={styling("my-3")}>
        <OrangeSpan>New Password</OrangeSpan>
      </h6>
      <Input name={"password"} value={newPassword} setValue={setNewPassword} />
      <Input name={"confirm_password"} value={confirmPassword} setValue={setConfirmPassword} />
      <ButtonGroup className={styling("")}>
        <Button onClick={handleSavePassword} disabled={isLoading} icon={faSave} theme={"secondary"}>
          Save Password
        </Button>
      </ButtonGroup>
    </StandardLayout>
  );
};

export default AccountSettingsPassword;
