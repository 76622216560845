import moment from "moment-timezone";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export const getEndOfGivenMonth = (dt) => {
  const date = new Date(dt);
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

export const getStartOfGivenMonth = (dt) => {
  const date = new Date(dt);
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const getStartOfGivenWeek = (dt) => {
  const date = new Date(dt);
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(date.setDate(diff));
};

export const getEndOfGivenWeek = (dt) => {
  const date = new Date(dt);
  const day = date.getDay();
  const diff = date.getDate() + (6 - day);
  return new Date(date.setDate(diff));
};

export const getStartOfGivenYear = (dt) => {
  const date = new Date(dt);
  return new Date(date.getFullYear(), 0, 1);
};

export const getEndOfGivenYear = (dt) => {
  const date = new Date(dt);
  return new Date(date.getFullYear(), 11, 31);
};

export const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const subtractDays = (date, days) => {
  return addDays(date, -days);
};

export const numberOfDaysThisMonth = () => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
};

export const useToday = () => {
  /*************************************** State *************************************** */
  const [today, setToday] = useState(new Date());
  const [yesterday, setYesterday] = useState(subtractDays(new Date(), 1));
  const [time, setTime] = useState(new Date());

  /********************************** Refs & Constants ********************************* */
  const intervalRef = useRef(null);
  const endOfCurrentMonth = useMemo(() => getEndOfGivenMonth(today), [today]);
  const endOfNextMonth = useMemo(
    () => getEndOfGivenMonth(new Date(today.getFullYear(), today.getMonth() + 1, 1)),
    [today]
  );
  const startOfCurrentMonth = useMemo(() => getStartOfGivenMonth(today), [today]);
  const startOfNextMonth = useMemo(
    () => getStartOfGivenMonth(new Date(today.getFullYear(), today.getMonth() + 1, 1)),
    [today]
  );
  const startOfCurrentWeek = useMemo(() => getStartOfGivenWeek(today), [today]);
  const endOfCurrentWeek = useMemo(() => getEndOfGivenWeek(today), [today]);
  const startOfCurrentYear = useMemo(() => getStartOfGivenYear(today), [today]);
  const endOfCurrentYear = useMemo(() => getEndOfGivenYear(today), [today]);

  /******************************** Functions & Memos ********************************** */
  const synchronize = useCallback(() => {
    const now = new Date();
    setToday(now);
    setYesterday(subtractDays(now, 1));

    const msUntilMidnight = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1).getTime() - now.getTime();
    intervalRef.current = setTimeout(synchronize, msUntilMidnight);
  }, []);

  /******************************** Effects & Handles ********************************** */

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(moment().tz()?.format("HH:mm"));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    synchronize();

    return () => {
      if (intervalRef.current) clearTimeout(intervalRef.current);
    };
  }, [synchronize]);

  return useMemo(
    () => ({
      today,
      yesterday,
      time,
      endOfCurrentMonth,
      endOfNextMonth,
      startOfCurrentMonth,
      startOfNextMonth,
      startOfCurrentWeek,
      endOfCurrentWeek,
      startOfCurrentYear,
      endOfCurrentYear,
    }),
    [
      today,
      yesterday,
      time,
      endOfCurrentMonth,
      endOfNextMonth,
      startOfCurrentMonth,
      startOfNextMonth,
      startOfCurrentWeek,
      endOfCurrentWeek,
      startOfCurrentYear,
      endOfCurrentYear,
    ]
  );
};
