import { useState, useCallback } from "react";
import { normaliseID, useFetch } from "src/shiftly-ui";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import mongoose from "mongoose";

const useRating = ({ shifts, setOpen } = {}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [ratings, setRatings] = useState({});
  const [comments, setComments] = useState({});
  const { activeLocation } = useShiftlyLocation();

  const { post } = useFetch({});

  const handleSave = useCallback(() => {
    const currentShift = shifts[activeStep];
    const ratingID = new mongoose.Types.ObjectId();

    const normalisedCurrentShiftID = normaliseID(currentShift);
    post([
      {
        entity: "ShiftHistory",
        method: "update",
        criteria: { _id: normalisedCurrentShiftID },
        data: { rated: true, rating: ratingID },
      },
      {
        entity: "Rating",
        method: "create",
        data: {
          _id: ratingID,
          user: normaliseID(currentShift.user),
          shift: normaliseID(currentShift?.shift),
          comment: comments[normalisedCurrentShiftID] || "",
          rating: ratings[normalisedCurrentShiftID] || 0,
          logo: activeLocation?.logo,
        },
      },
    ]);

    if (activeStep + 1 === shifts.length) {
      setOpen(false);
    }
  }, [activeStep, ratings, shifts, post, comments, activeLocation, setOpen]);

  return {
    activeStep,
    setActiveStep,
    ratings,
    setRatings,
    comments,
    setComments,
    handleSave,
  };
};

export default useRating;
