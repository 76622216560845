import { useState } from "react";
import styles from "./SchedulerHeaderDropdown.module.scss";
import { useStyling, Overlay, Each } from "src/shiftly-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";

const SchedulerHeaderDropdown = ({ options = [], buttonText, buttonIcon }) => {
  /*************************************** State *************************************** */
  const [open, setOpen] = useState(false);
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  return (
    <div className={styling("position-relative")}>
      <div className={styling("action-btn", open && "open")} onClick={() => setOpen((prev) => !prev)}>
        <FontAwesomeIcon icon={buttonIcon} className={styling("mr-2")} />
        <p>{buttonText}</p>
        <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} className={styling("ml-2", "chevron")} />
      </div>
      <Overlay open={open} setOpen={setOpen} />
      <div className={styling("container", open && "open")}>
        <Each
          of={options}
          render={({ key, ...option }) => {
            if (option.divider) return <div className={styling("divider-container")} key={key}>
                <div className={styling('divider')}></div>
            </div>;
            return (
              <div
                key={key}
                className={styling("row")}
                onClick={(e) => {
                  e.stopPropagation();
                  option.onClick?.(option);
                  setOpen(false);
                }}
              >
                <p>{option.label}</p>
                {option.icon && <FontAwesomeIcon icon={option.icon} className={styling("row-icon")} />}
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default SchedulerHeaderDropdown;
