import { useStyling, useFetch, Divider } from "src/shiftly-ui";
import { faCamera, faImage, faLink, faList, faMoneyBill } from "@fortawesome/pro-regular-svg-icons";
import styles from "./TaskList.module.scss";
import { faCheck, faChevronRight, faChevronUp, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { useLayoutEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import usePositions from "src/hooks/usePositions";
import useBusiness from "src/hooks/useBusiness";
import usePaymentMethods from "src/hooks/usePaymentMethods";
import useIntegration from "src/hooks/useIntegration";

const TaskList = () => {
  /*************************************** State *************************************** */
  const [taskBarOpen, setTaskBarOpen] = useState(false);
  const [showTaskList, setShowTaskList] = useState(false);

  /*************************************** Hooks *************************************** */
  const { activeLocation, isLoading: locationLoading } = useShiftlyLocation();
  const { availablePositions, isLoading: positionsLoading } = usePositions();
  const { activeBusiness, isLoading: businessLoading } = useBusiness();
  const { defaultPaymentMethod, loading: paymentMethodLoading } = usePaymentMethods();
  const { integrations, isFetching: intgrationsIsLoading } = useIntegration();
  const styling = useStyling(styles);

  /************************************** Queries ************************************** */
  const { data: shifts } = useFetch({
    request: {
      entity: "Shift",
      method: "get",
      criteria: {
        location: activeLocation?._id,
      },
      options: {
        limit: 1,
      },
    },
    dependency: activeLocation?._id,
  });
  /******************************** Functions & Memos ********************************** */

  const tasks = useMemo(() => {
    return [
      {
        id: "positionTemplates",
        status: (() => {
          if (availablePositions.length > 0) return "complete";
          return "incomplete";
        })(),
        title: "Create positions",
        path: "/positions?mode=new",
        icon: faCamera,
        category: "learn",
        required: true,
      },
      {
        id: "locationLogo",
        status: (() => {
          if (activeLocation?.logo && activeLocation?.logo !== "/defaults/location_default.png") return "complete";
          return "incomplete";
        })(),
        title: "Add your location logo",
        path: "/business-account/locations/edit-location",
        icon: faCamera,
        category: "learn",
        required: true,
      },
      {
        id: "locationDescription",
        status: (() => {
          if (activeLocation?.description) return "complete";
          return "incomplete";
        })(),
        title: "Add your workplace description",
        path: "/business-account/locations/edit-location",
        icon: faList,
        category: "learn",
        required: true,
      },
      {
        id: "locationPhotos",
        status: (() => {
          if (activeLocation?.images?.length > 1) return "complete";
          return "incomplete";
        })(),
        title: "Add multiple location photographs",
        path: "/business-account/locations/edit-location",
        icon: faImage,
        category: "learn",
        required: true,
      },
      {
        id: "firstShift",
        status: (() => {
          if (shifts?.length > 0) return "complete";
          return "incomplete";
        })(),
        title: "Publish your first shift",
        path: "/shifts",
        icon: faPlus,
        category: "learn",
        required: true,
      },
      {
        id: "defaultPaymentMethod",
        status: (() => {
          if (defaultPaymentMethod) return "complete";
          return "incomplete";
        })(),
        title: "Add a payment method",
        path: "business-account/billing-and-payment",
        icon: faMoneyBill,
        category: "finalise",
        required: true,
      },
      {
        id: "integrations",
        status: (() => {
          if (integrations?.length > 0) return "complete";
          return "incomplete";
        })(),
        title: "Discover staff scheduling integrations",
        path: "/integrations",
        icon: faLink,
        category: "finalise",
        required: false,
      },
    ];
  }, [activeLocation, availablePositions, defaultPaymentMethod, integrations, shifts]);

  /******************************** Effects & Handles ********************************** */
  useLayoutEffect(() => {
    const timeoutId = setTimeout(() => {
      if (locationLoading || positionsLoading || businessLoading || paymentMethodLoading || intgrationsIsLoading)
        return;
      const incompleteTasks = tasks.filter((t) => t.status !== "complete" && t.required);
      if (incompleteTasks.length > 0 && activeBusiness?._id) {
        setShowTaskList(true);
      } else {
        setShowTaskList(false);
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [
    locationLoading,
    positionsLoading,
    businessLoading,
    paymentMethodLoading,
    intgrationsIsLoading,
    tasks,
    activeBusiness,
  ]);

  if (!showTaskList) return null;

  return (
    <motion.div
      initial={{ height: 28 }}
      animate={{ height: taskBarOpen ? "auto" : 28 }}
      transition={{ duration: 0.3 }}
      className={styling("container")}
    >
      <div className={styling("heading")} onClick={() => setTaskBarOpen((prev) => !prev)}>
        <h6>
          {tasks.filter((t) => t.status === "complete").length} / {tasks.length} tasks complete
        </h6>
        <FontAwesomeIcon icon={faChevronUp} className={styling("chevron", taskBarOpen && "chevron-open")} />
      </div>
      <motion.div className={styling("taskList")}>
        <p className={styling("m-3", "mb-0")}>
          <strong>Learn how to use Shiftly:</strong>
        </p>
        {tasks
          .filter((t) => t.category === "learn")
          .map((task) => (
            <Task task={task} key={task.id} />
          ))}
        <Divider />
        <p className={styling("m-3", "mb-1", "mt-2")}>
          <strong>Finalise your Business Settings:</strong>
        </p>
        {tasks
          .filter((t) => t.category === "finalise")
          .map((task) => (
            <Task task={task} key={task.id} />
          ))}
      </motion.div>
    </motion.div>
  );
};

export default TaskList;

const Task = ({ task }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const navigate = useNavigate();
  const { activeLocation } = useShiftlyLocation();
  return (
    <div
      className={styling("flex", "flex-start", "p-2", "cursor-pointer")}
      key={task.id}
      onClick={() => navigate(task.path, { state: { location: activeLocation } })}
    >
      <div className={styling("task-icon", task.status)}>
        <FontAwesomeIcon icon={task.status === "complete" ? faCheck : task.icon} className={styling("icon")} />
      </div>
      <div className={styling("taskContent", task.status)}>
        <p>{task.title}</p>
      </div>
      <FontAwesomeIcon icon={faChevronRight} className={styling("right-chevron")} />
    </div>
  );
};
