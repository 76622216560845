import styles from "./SchedulerTemplates.module.scss";
import {
  useStyling,
  Modal,
  ModalLabel,
  FormV2,
  Input,
  TextArea,
  Checkbox,
  Button,
  useFetch,
  OrangeSpan,
  normaliseID,
} from "src/shiftly-ui";
import { useCallback, useState } from "react";
import { useScheduler } from "src/contexts/Scheduler";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import useShiftManager from "src/hooks/useShiftManager";

const SchedulerTemplatesSave = ({ showSaveModal, setShowSaveModal }) => {
  /*************************************** State *************************************** */
  const [data, setData] = useState({});
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const { activeLocation } = useShiftlyLocation();
  const { normaliseShift } = useShiftManager();
  const {
    shifts,
    internalShifts,
    periods: { current },
  } = useScheduler();

  /********************************** Refs & Constants ********************************* */
  const totalShifts = shifts?.length ?? 0 + internalShifts?.length ?? 0;

  /************************************** Queries ************************************** */
  const {
    post: saveSchedulerTemplate,
    isLoading,
    isError,
    refresh,
  } = useFetch({
    options: {
      onSuccess: () => {
        setShowSaveModal(false);
        refresh("ScheduleTemplate.GetAllForLocation");
      },
    },
  });

  /******************************** Functions & Memos ********************************** */
  const formatShifts = useCallback(
    (shifts = [], includeStaff = false) => {
      return shifts.map(({ _id, ...shift }) => {
        return {
          ...shift,
          ...normaliseShift(shift),
          user: includeStaff ? shift.user : null,
          position: shift.type === "shiftly" ? shift.position?._id : null,
        };
      });
    },
    [normaliseShift]
  );

  const onSubmit = useCallback(async () => {
    return await saveSchedulerTemplate({
      entity: "ScheduleTemplate",
      method: "create",
      data: {
        ...data,
        location: normaliseID(activeLocation),
        shifts: [...formatShifts(shifts), ...formatShifts(internalShifts, data?.includeInternalStaff)],
      },
    });
  }, [saveSchedulerTemplate, activeLocation, shifts, internalShifts, formatShifts, data]);

  return (
    <Modal
      className={styling("modal")}
      showModal={showSaveModal}
      setShowModal={setShowSaveModal}
      label={
        <ModalLabel
          text={
            <h4>
              <OrangeSpan>Save</OrangeSpan> as a template
            </h4>
          }
        />
      }
    >
      <div className={styling("my-4", "text-center")}>
        <h6>
          Creating a template from the {totalShifts} shift{totalShifts > 1 ? "s" : ""} between{" "}
          {current[0].toLocaleDateString()} and {current[current.length - 1].toLocaleDateString()}
        </h6>
      </div>
      <FormV2 onSubmit={onSubmit} data={data} setData={setData}>
        <Input name={"name"} label={"Name your template"} required placeholder={"Type name here"} />
        <TextArea
          name={"description"}
          label={"Describe this template"}
          placeholder={"Write a description to help you identify this schedule template."}
        />
        <Checkbox
          name={"includeInternalStaff"}
          submitOnEnter
          my={"20px"}
          label={"Save shifts with internal staff members allocated?"}
        />
      </FormV2>
      <div className={styling("footer")}>
        <Button onClick={() => setShowSaveModal(false)} theme="secondary-outline">
          Cancel
        </Button>
        <Button onClick={onSubmit} disabled={isLoading} error={isError}>
          Save Template
        </Button>
      </div>
    </Modal>
  );
};

export default SchedulerTemplatesSave;
