import { useStyling, useFetch, responsibilities, OrangeSpan, Link, ShiftlySuspense } from "src/shiftly-ui";
import { useParams } from "react-router-dom";
import styles from "./PositionLevels.module.scss";
import StandardLayout from "src/components/standard_layout/StandardLayout";
import { useMemo } from "react";

const PositionLevels = () => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const { group } = useParams();

  /************************************** Queries ************************************** */
  const {
    data: [activeGroup],
    isLoading,
  } = useFetch({
    request: {
      entity: "PositionGroup",
      criteria: {
        _id: group,
      },
    },
    dependency: group,
  });

  /******************************** Functions & Memos ********************************** */
  const award_code = useMemo(() => activeGroup?.industry?.award_code, [activeGroup]);
  const positionResponsibilities = useMemo(
    () => responsibilities[award_code]?.[activeGroup?.stream],
    [award_code, activeGroup]
  );

  return (
    <ShiftlySuspense loading={isLoading}>
      <StandardLayout
        heading={
          <>
            {activeGroup?.name} <OrangeSpan>Levels</OrangeSpan>
          </>
        }
        description={
          <>
            Review the responisbilities of each level. <strong>{activeGroup?.name}</strong>: Classified under the
            <Link className={styling("stream")} to={`https://library.fairwork.gov.au/award/?krn=${award_code}`}>
              {activeGroup?.stream}
            </Link>
            stream
          </>
        }
      >
        <div className={styling("position-relative")}>
          <Link to={`https://library.fairwork.gov.au/award/?krn=${award_code}`} className={styling("award")}>
            View Full Award
          </Link>

          <table className={styling("table")}>
            <thead>
              <tr>
                <th>{activeGroup?.name} Levels</th>
                <th>Award Definition of Level</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(positionResponsibilities || {}).map(([level, responsibilities]) => {
                return (
                  <tr key={level}>
                    <td>Level {level}</td>
                    <td>
                      <ul>
                        {responsibilities.map((responsibility, index) => (
                          <li key={index}>{responsibility}</li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </StandardLayout>
    </ShiftlySuspense>
  );
};
export default PositionLevels;
