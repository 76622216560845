import { toast } from "react-toastify";
import { ToastBody } from "../hooks/useToast";

export const customFetch = async ({ payloads = [], node = "live-node", headers: reqHeaders = {} }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("shiftly-auth-token");

      // Conditionally setting headers
      let headers = {
        ...reqHeaders,
      };

      const hasFormData = payloads.some((payload) => payload.data instanceof FormData);
      let body = JSON.stringify(payloads, (key, value) => (value === undefined ? null : value));

      if (!hasFormData) {
        headers["Content-Type"] = "application/json";
      } else {
        body = payloads.find((payload) => payload.data instanceof FormData)?.data;
      }

      if (token) {
        headers["token"] = headers["token"] ?? token;
      }

      // Conditionally setting the body
      const baseUrl = process.env.REACT_APP_API_URL;
      const encodedNode = encodeURIComponent(node);
      const url = `${baseUrl}${encodedNode}`;

      const res = await fetch(url, {
        method: "POST",
        headers,
        body,
      });

      if (!res.ok) {
        const error = await res.json();
        throw new Error(error.prettyError || "Network response was not ok.");
      }

      const data = await res.json();

      if (data.token) {
        localStorage.setItem("shiftly-auth-token", data.token);
      }

      if (data.status !== 200) {
        data?.error?.prettyError &&
          (!data?.error?.field || data?.error?.field === "toast") &&
          toast.error(<ToastBody title={"Aw snap"} message={data?.error?.prettyError} />);
        reject(data);
        return;
      }

      resolve(data);
    } catch (error) {
      reject({ status: 400, data: [], error });
    }
  });
};

export const buildAwardSummaryLink = (award) => {
  return award
    ? `https://www.fairwork.gov.au/employment-conditions/awards/awards-summary/${award}-summary`
    : "https://www.fairwork.gov.au/employment-conditions/awards";
};

export const buildGoogleMapsLink = ({ lat, lng }) => {
  return `https://www.google.com/maps?q=${lat},${lng}`;
};
