import { useCachedQuery, useFetch } from "src/shiftly-ui";
const useIndustries = () => {
  /*************************************** State *************************************** */
  /*************************************** Hooks *************************************** */
  const {
    Industry: { AllIndustries },
  } = useCachedQuery();

  /************************************** Queries ************************************** */
  const { data: industries } = useFetch({ request: { entity: "Industry", id: AllIndustries } });

  return { industries };
};

export default useIndustries;
