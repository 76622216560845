import { useFetch, useCachedQuery, useAuth } from "src/shiftly-ui";
import useBusiness from "./useBusiness";
const usePaymentMethods = () => {
  const { account } = useAuth();
  const { activeBusiness } = useBusiness();

  const {
    PaymentMethod: { GetDefaultPaymentMethod, GetPaymentMethods },
  } = useCachedQuery();

  const {
    data: [defaultPaymentMethod],
    isFetching,
  } = useFetch({
    request: {
      entity: "PaymentMethod",
      method: "get",
      criteria: {
        _id: account?.default_payment_method,
      },
      id: GetDefaultPaymentMethod,
    },
    dependency: account?.default_payment_method,
  });

  const {
    data: [preferredMethod],
    isFetching: prefferedMethod,
  } = useFetch({
    request: {
      entity: "PaymentMethod",
      method: "get",
      id: GetPaymentMethods,
      criteria: {
        _id: activeBusiness?.preferred_payment_method,
      },
    },
    dependency: activeBusiness?.preferred_payment_method,
  });

  return { defaultPaymentMethod, preferredMethod, loading: isFetching || prefferedMethod };
};

export default usePaymentMethods;
