import {
  withDisplay,
  FormV2,
  useAuth,
  Link,
  Button,
  FormFlex,
  Input,
  NumberingIcons,
  OrangeSpan,
  Checkbox,
  useSearchParams,
} from "src/shiftly-ui";
import { useCallback, useRef, useState } from "react";
import clsx from "clsx";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import styles from "./Portal.module.scss";
import { useStyling } from "src/shiftly-ui";
import { FadeInDiv, ButtonGroup, RadioButtons } from "src/shiftly-ui";
import NewBusinessRadioButtonCard from "../../business/new_business_setup/NewBusinessRadioButtonCard";
import { faBuildingUser, faStore } from "@fortawesome/pro-regular-svg-icons";

const Signup = ({ isLaptop, isMobile }) => {
  /*************************************** State *************************************** */
  const [tab, setTab] = useState(0);
  const [data, setData] = useState();
  const [isInvited, setIsInvited] = useState(false);
  /*************************************** Hooks *************************************** */
  const { signup, signupIsLoading: isLoading } = useAuth();
  const navigate = useNavigate();
  const styling = useStyling(styles);

  /********************************** Refs & Constants ********************************* */
  const formRef = useRef();
  const tabs = isInvited ? ["Personal", "Password"] : ["Personal", "Organisation", "Password"];

  /******************************** Functions & Memos ********************************** */
  const onSubmit = useCallback(
    async (data) => {
      const res = await signup(data, "owner");
      if (["first_name", "last_name", "email"].includes(res?.error?.field)) setTab(0);

      return res;
    },
    [signup]
  );

  const handleNextClick = useCallback(async () => {
    if (tab === 0) return (await formRef.current?.validateFields(["first_name", "last_name", "email"])) && setTab(1);
    return (await formRef.current?.validateFields(["organisation_name", "organisation_type"])) && setTab(2);
  }, [tab]);

  /******************************** Effects & Handles ********************************** */
  useSearchParams(
    ["token"],
    ({ token }) => {
      if (token) {
        setData((prev) => ({ ...prev, token }));
        setIsInvited(true);
      }
    },
    []
  );

  return (
    <FadeInDiv className={styling("container")}>
      <h1>
        Welcome to <OrangeSpan>Shiftly</OrangeSpan>
      </h1>
      <p className={styling("my-4")}>
        <strong>Create an account to access more with Shiftly. Don't worry, it'll only take a few seconds!</strong>
      </p>
      {isLaptop && <NumberingIcons activeNumber={tab} numbers={tabs} />}

      <FormV2 onSubmit={onSubmit} ref={formRef} data={data} setData={setData} className={styling("pt-1")}>
        <div
          className={styling("content-container")}
          style={{
            minHeight: !isMobile
              ? data?.organisation_type === "third_party_service_provider" && tab === 1
                ? "310px"
                : "200px"
              : "45vh",
          }}
        >
          <motion.div
            animate={{
              x: tab === 0 ? 0 : "-100%",
              opacity: tab === 0 ? 1 : 0,
            }}
            transition={{ duration: 0.3 }}
            className={clsx(styling("form-content"), tab === 0 && styling("activeSlide"))}
          >
            <FormFlex>
              <Input name="first_name" />
              <Input name="last_name" />
            </FormFlex>
            <Input name="email" onEnterPress={handleNextClick} />
          </motion.div>
          {!isInvited && (
            <motion.div
              initial={{ x: "100%", opacity: 0 }}
              animate={{ x: tab === 1 ? 0 : "100%", opacity: tab === 1 ? 1 : 0 }}
              transition={{ duration: 0.3 }}
              className={clsx(styling("form-content"), tab === 1 && styling("activeSlide"))}
            >
              <div className={styling("px-2")}>
                <RadioButtons
                  required
                  label="What best describes your organisation?"
                  align="center"
                  options={[
                    {
                      label: "Venue",
                      value: "venue",
                      icon: faStore,
                      ie: "i.e Restaurant, Cafe, Bar, etc.",
                    },
                    {
                      label: "Third Party Service Provider",
                      value: "third_party_service_provider",
                      icon: faBuildingUser,
                      ie: "i.e. Bookkeeper, Accountant, Manager, etc.",
                    },
                  ].map(({ label, value, icon, ie }) => ({
                    label,
                    value,
                    content: (
                      <NewBusinessRadioButtonCard
                        label={label}
                        award={ie}
                        icon={icon}
                        active={data?.organisation_type === value}
                      />
                    ),
                  }))}
                  type="cards"
                  name="organisation_type"
                />
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: data?.organisation_type === "third_party_service_provider" ? 1 : 0 }}
                  transition={{ delay: 0.3 }}
                >
                  <Input
                    name="organisation_name"
                    label={"What is your organisation name?"}
                    placeholder={`${data?.first_name}'s Organisation`}
                    required={data?.organisation_type === "third_party_service_provider"}
                    onEnterPress={handleNextClick}
                    tabIndex={-1}
                  />
                </motion.div>
              </div>
            </motion.div>
          )}

          <motion.div
            initial={{ x: "100%", opacity: 0 }}
            animate={{ x: tab === tabs.length - 1 ? 0 : "100%", opacity: tab === tabs.length - 1 ? 1 : 0 }}
            transition={{ duration: 0.3 }}
            className={clsx(styling("form-content"), tab === tabs.length - 1 && styling("activeSlide"))}
          >
            <Input type="password" label="Password" placeholder="Password" name="password" />
            <Input
              type="password"
              label="Confirm Password"
              placeholder="Password"
              name="confirmPassword"
              submitOnEnter
              required
              validate={(v, data) => (v !== data.password ? "Passwords do not match" : true)}
            />
          </motion.div>
        </div>

        <ButtonGroup>
          <Button
            theme="secondary-outline"
            disabled={tab === 0}
            onClick={() => setTab((prev) => Math.max(0, prev - 1))}
            size={"fw"}
          >
            Previous
          </Button>
          <Button
            size={"fw"}
            loading={isLoading}
            theme="primary"
            onClick={() => (tab === tabs.length - 1 ? formRef.current?.submit() : handleNextClick())}
          >
            {tab === tabs.length - 1 ? "Signup" : "Next"}
          </Button>
        </ButtonGroup>

        <div className={styling("navigate", "mt-4")}>
          <p>
            Already have an account?{" "}
            <Link onClick={() => navigate("/portal?page=login")} className={styling("ml-4")}>
              Login
            </Link>
          </p>
        </div>
      </FormV2>
    </FadeInDiv>
  );
};

export default withDisplay(Signup);
