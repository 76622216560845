import {
  Modal,
  Toggle,
  Checkbox,
  Button,
  useFetch,
  useAuth,
  FormV2,
  ModalLabel,
  useStyling,
  useCachedQuery,
} from "src/shiftly-ui";
import { useCallback, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstitution, faMoneyBill } from "@fortawesome/pro-regular-svg-icons";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import BusinessAccountAddNewPaymentMethodForm from "./components/BusinessAccountAddNewPaymentMethodForm";
import styles from "./BusinessAccountAddNewPaymentMethod.module.scss";
import useBusiness from "src/hooks/useBusiness";
import usePaymentMethods from "src/hooks/usePaymentMethods";
import { useUncontrolled } from "uncontrollable";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const BusinessAccountAddNewPaymentMethod = (props) => {
  /*************************************** State *************************************** */
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const {
    tab = 0,
    setTab,
    showModal,
    setShowModal,
    callback,
  } = useUncontrolled(props, {
    tab: "setTab",
    showModal: "setShowModal",
  });
  /*************************************** Hooks *************************************** */
  const { user, refreshAccount, account } = useAuth();
  const { refreshActiveBusiness } = useBusiness();

  const styling = useStyling(styles);
  const {
    PaymentMethod: { GetDefaultPaymentMethod, CreateSetupIntent },
  } = useCachedQuery();
  const { defaultPaymentMethod } = usePaymentMethods();
  /********************************** Refs & Constants ********************************* */
  const stripeRef = useRef();

  /************************************** Queries ************************************** */
  const { data: clientSecret, refresh } = useFetch({
    request: {
      entity: "PaymentMethod",
      method: "createSetupIntent",
      id: CreateSetupIntent,
    },
    options: {
      onSuccess: () => {
        refreshActiveBusiness();
        refreshAccount();
        refresh(GetDefaultPaymentMethod);
      },
      select: (setup_intent) => {
        return setup_intent.client_secret;
      },
    },
  });

  /******************************** Functions & Memos ********************************** */
  const handleSubmit = useCallback(
    async (data) => {
      return await stripeRef.current.handleSubmit(data);
    },
    []
  );

  return (
    <Modal
      className={styling("modal")}
      label={<ModalLabel text="Payment Method" />}
      showModal={showModal}
      setShowModal={setShowModal}
    >
      <FormV2
        onSubmit={handleSubmit}
        resetOnSubmit
        data={data}
        setData={setData}
        initial={{
          first_name: user.first_name,
          last_name: user.last_name,
          account_name: user?.first_name + " " + user?.last_name,
          is_default: !defaultPaymentMethod,
        }}
      >
        <Toggle
          tab={tab}
          setTab={setTab}
          tabs={[
            {
              text: "Account Details",
              icon: <FontAwesomeIcon icon={faInstitution} />,
            },
            {
              text: "Card Details",
              icon: <FontAwesomeIcon icon={faMoneyBill} />,
            },
          ]}
        />
        {typeof clientSecret === "string" && (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <BusinessAccountAddNewPaymentMethodForm
              tab={tab}
              setShowModal={setShowModal}
              ref={stripeRef}
              clientSecret={clientSecret}
              setLoading={setLoading}
              callback={callback}
            />
          </Elements>
        )}

        <div className={styling("flex", "flex-between", "pt-4")}>
          <Checkbox
            label={"Set as default payment method for your account"}
            name={"is_default"}
            disabled={!account?.default_payment_method}
          />
          <Button loading={loading} type="submit" className={styling("submit-btn")}>
            Add Payment Method
          </Button>
        </div>
      </FormV2>
    </Modal>
  );
};
export default BusinessAccountAddNewPaymentMethod;
