import { useUncontrolled } from "uncontrollable";
import styles from "./ImportXeroStaffModal.module.scss";
import {
  useStyling,
  Modal,
  ModalLabel,
  useFetch,
  normaliseID,
  ShiftlySuspense,
  Table,
  ButtonGroup,
  Button,
  useCachedQuery,
} from "src/shiftly-ui";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import { useCallback, useMemo, useState } from "react";

const ImportXeroStaffModal = (props) => {
  /*************************************** State *************************************** */
  const [selectedRows, setSelectedRows] = useState([]);
  const { show = false, setShow } = useUncontrolled(props, {
    show: "setShow",
  });
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const { activeLocation } = useShiftlyLocation();
  const {
    InternalStaff: { GetStaffForLocation, GetAllStaffIncludingIncomplete },
  } = useCachedQuery();
  /********************************** Refs & Constants ********************************* */

  /************************************** Queries ************************************** */

  const {
    post,
    refresh,
    isLoading: postIsLoading,
  } = useFetch({
    options: {
      onSuccess: () => {
        refresh([GetStaffForLocation, GetAllStaffIncludingIncomplete]);
        setSelectedRows([]);
        setShow(false);
      },
    },
  });

  const { data: importableEmployees, isLoading } = useFetch({
    request: {
      entity: "InternalStaff",
      method: "getImportableEmployeesFromXero",
      data: {
        location: normaliseID(activeLocation),
      },
    },
    dependency: normaliseID(activeLocation),
    options: {
      enabled: show,
      select: (data) => {
        return data.map((employee) => ({
          id: employee.employeeID,
          ...employee,
        }));
      },
    },
  });

  /******************************** Functions & Memos ********************************** */
  const handleImportEmployees = useCallback(() => {
    post({
      entity: "InternalStaff",
      method: "importEmployeesFromXero",
      data: {
        location: normaliseID(activeLocation),
        employees: selectedRows,
      },
    });
  }, [selectedRows, activeLocation, post]);

  return (
    <Modal
      showModal={show}
      setShowModal={setShow}
      label={
        <ModalLabel
          text={
            <h4>
              <span>Select employees</span> to import from Xero
            </h4>
          }
        />
      }
    >
      <ShiftlySuspense loading={isLoading}>
        <Table
          checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setSelectedRows(newRowSelectionModel);
          }}
          rowSelectionModel={selectedRows}
          rows={importableEmployees}
          columns={[
            {
              field: "firstName",
              headerName: "First Name",
            },
            {
              field: "lastName",
              headerName: "Last Name",
            },
            {
              field: "email",
              headerName: "Email",
            },
            {
              field: "phone",
              headerName: "Phone",
            },
          ]}
        />
        <ButtonGroup noBottomPadding className={styling("flex-between")}>
          <Button
            theme={"secondary-outline"}
            onClick={() => {
              setShow(false);
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={selectedRows.length === 0}
            onClick={handleImportEmployees}
            theme={"primary"}
            loading={postIsLoading}
          >
            Import {selectedRows.length} Employees
          </Button>
        </ButtonGroup>
      </ShiftlySuspense>
    </Modal>
  );
};

export default ImportXeroStaffModal;
