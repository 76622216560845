import { useCachedQuery, useFetch } from "src/shiftly-ui";
import useMessagesCriteria from "src/pages/app/messages/hooks/useMessagesCriteria";
import useShiftlyLocation from "./useShiftlyLocation";

const useMessages = () => {
  /*************************************** Hooks *************************************** */
  const criteria = useMessagesCriteria();
  const { activeLocation } = useShiftlyLocation();
  const {
    Conversation: { GetConversationsForLocation },
    Message: { GetMessagesWithAccess },
  } = useCachedQuery();

  /************************************** Queries ************************************** */
  const { data: unreadMessages } = useFetch({
    request: {
      entity: "Message",
      method: "getMessagesWithAccess",
      data: { ...criteria },
      id: GetMessagesWithAccess,
    },
    options: {
      enabled: !!activeLocation?._id,
      staleTime: 0,
      select: (data) => data.filter((message) => message.unread && message.for_id === activeLocation?._id),
    },
  });

  const { data: conversations } = useFetch({
    request: {
      entity: "Conversation",
      method: "getExistingConversations",
      criteria: {
        location: activeLocation?._id,
      },
      id: GetConversationsForLocation,
    },
    dependency: activeLocation?._id,
  });

  return { unreadMessages, conversations };
};

export default useMessages;
