import { useStyling, useFetch, Button, Switch, OrangeSpan } from "src/shiftly-ui";
import SearchAndDisplay from "src/components/search_and_display/SearchAndDisplay";
import StandardLayout from "../../../components/standard_layout/StandardLayout";
import styles from "./IntegrationsHome.module.scss";
import { useEffect, useMemo, useState } from "react";
import deputyLogo from "./assets/deputy_logo.webp";
import xeroLogo from "./assets/xero_logo.png";
import useIntegration from "src/hooks/useIntegration";
import SearchAndDisplayCard from "src/components/search_and_display/SearchAndDisplayCard";
import { useNavigate } from "react-router-dom";

function isBlankObject(obj) {
  if (typeof obj === "object" && obj !== null) return Object.keys(obj).length === 0;
  return false;
}

const integrationsTypes = [
  {
    name: "Xero",
    logo: xeroLogo,
    page: "/integrations/xero",
    description: (
      <>
        Integrate your Xero account to export your employee data, timesheets and more into Xero!
        <br />
        <br />
        Link your accounts and let us do the rest.
      </>
    ),
  },
];

const IntegrationsHome = () => {
  /*************************************** Hooks *************************************** */
  const { integrations } = useIntegration();

  /******************************** Functions & Memos ********************************** */

  const elements = useMemo(() => {
    return integrationsTypes.map((integration) => {
      const activeIntegration = integrations?.find((i) => i.type === integration.name?.toLocaleLowerCase());
      integration.status = activeIntegration?.status || "connect";
      return {
        original: integration,
        cardContent: (
          <IntegrationCard
            key={integration.name}
            {...integration}
            _id={activeIntegration?._id}
            locationMap={activeIntegration?.locationMap}
            positionMap={activeIntegration?.positionMap}
          />
        ),
      };
    });
  }, [integrations]);
  /******************************** Effects & Handles ********************************** */

  return (
    <StandardLayout
      heading={
        <>
          <OrangeSpan>Pair us up:</OrangeSpan> Integrate Shiftly
        </>
      }
      breadcrumb={[{ label: "Business Settings", link: "/business-account" }]}
    >
      <SearchAndDisplay elements={elements} hideToggle hideSearchBar defaultView={"grid"} />
    </StandardLayout>
  );
};

const IntegrationCard = ({ status, page, _id, locationMap, positionMap, ...integration }) => {
  /*************************************** State *************************************** */
  const [connected, setConnected] = useState(false);
  const navigate = useNavigate();
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  /************************************** Queries ************************************** */
  const { post: updateIntegration } = useFetch({});

  /******************************** Effects & Handles ********************************** */
  useEffect(() => {
    setConnected(status === "active");
  }, [status]);

  return (
    <SearchAndDisplayCard
      key={integration?._id}
      onClick={() => navigate(page + (status === "connect" ? "/pricing" : ""))}
    >
      <div className={styling("flex", "flex-column", "h-100", "flex-align-start")}>
        <h6>{integration?.name}</h6>
        <p className={styling("label", "my-2")}>{integration?.description}</p>

        <div className={styling("flex", "flex-between", "mt-auto", "w-100")}>
          <div className={styling("logo")}>
            <img src={integration?.logo} alt={integration?.name} />
          </div>
          <Switch
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={styling("ml-auto", "drop")}
            value={connected}
            setValue={setConnected}
            onChange={(v) => {
              if (status === "connect") {
                navigate(page + (status === "connect" ? "/pricing" : ""));
                return;
              }
              updateIntegration({
                entity: "Integration",
                method: "update",
                criteria: {
                  _id,
                },
                data: {
                  status: v ? "active" : "inactive",
                },
              });
            }}
          />
        </div>
      </div>
    </SearchAndDisplayCard>
  );
};

export default IntegrationsHome;
