import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./ManageStaffMember.module.scss";
import { Divider, normaliseID, OrangeSpan, ShiftlySuspense, useStyling } from "src/shiftly-ui";
import mongoose from "mongoose";
import { useFetch, Button, useCachedQuery } from "src/shiftly-ui";
import StandardLayout from "src/components/standard_layout/StandardLayout";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import { useLocation, useNavigate } from "react-router-dom";
import ManageStaffBaseFields from "./components/internal_staff/ManageStaffBase";
import ManageStaffMemberOnboarding from "./components/internal_staff/ManageStaffMemberOnboarding";
import { faBookmark, faPaperPlane, faSave } from "@fortawesome/pro-regular-svg-icons";
import XeroLogo from "src/pages/business/integrations/assets/xero_logo.png";
import useStaffData from "./hooks/useStaffData";
import useIntegration from "src/hooks/useIntegration";
import useBusiness from "src/hooks/useBusiness";

const ManageStaffMember = forwardRef((props, ref) => {
  /*************************************** State *************************************** */
  const [data, setData] = useState({ start_date: new Date() });
  const [saveShiftlyLoading, setSaveShiftlyLoading] = useState(false);
  const [saveXeroLoading, setSaveXeroLoading] = useState(false);

  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { activeLocation } = useShiftlyLocation();
  const { activeBusiness } = useBusiness();
  const { activeIntegration: integration, isLoading: integrationIsLoading } = useIntegration("xero");
  const internalStaff = useMemo(() => state?.internalStaff, [state]);
  const { loading: staffDataLoading, taxDeclaration, employment, employeeOnboard } = useStaffData(internalStaff);

  const {
    InternalStaff: { GetAllStaffIncludingIncomplete, GetStaffForLocation },
  } = useCachedQuery();

  /********************************** Refs & Constants ********************************* */
  const onboardingRef = useRef();
  const baseRef = useRef();
  const allLoading = staffDataLoading || integrationIsLoading;

  /************************************** Queries ************************************** */

  const { post: updateStaffDetails, refresh } = useFetch({
    options: {
      onSuccess: () => {
        refresh([GetAllStaffIncludingIncomplete, GetStaffForLocation]);
        navigate("/people?tab=internal");
      },
      onError: ({ error }) => {
        baseRef.current?.setError(error.field, { type: "manual", message: error.prettyError });
      },
    },
  });

  /******************************** Functions & Memos ********************************** */
  const onSubmit = useCallback(
    async (data, shiftlyOnly = false) => {
      try {
        if (!(await baseRef.current?.validateFields())) {
          const div = document.getElementById("wrapper");
          div.scrollTo({ top: 0, behavior: "smooth" });
          return;
        }

        // Logic Constants
        const hasIntegration = !!activeBusiness?.xero_organisation;
        const isSavingLocally = shiftlyOnly || !hasIntegration;
        const alreadyOnboarded = employeeOnboard?.status === "approved";
        const hasOnboardingRequestPending =
          employeeOnboard?.status === "pending" || (hasIntegration && !isSavingLocally && !alreadyOnboarded);
        const staffMemberIsInSync = internalStaff?.sync_status === "synced";

        // Redirect to integration setup if required
        if (!hasIntegration && !isSavingLocally) {
          navigate("/integrations");
          return;
        }

        // Generate IDs if not present
        const staffID = internalStaff?._id ?? new mongoose.Types.ObjectId();
        const employeeOnboardID = employeeOnboard?._id ?? new mongoose.Types.ObjectId();

        // Extract data fields
        const {
          first_name,
          last_name,
          email,
          job_title,
          start_date,
          hourly_rate,
          award_code,
          classification_level,
          income_stream,
          is_authorised_to_approve_leave,
          is_authorised_to_approve_timesheets,
          notes,
          employment_type,
          employment_basis,
          income_type,
          eligible_to_receive_leave_loading,
        } = data;

        const sharedProps = {
          location: activeLocation?._id,
          internalStaff: staffID,
          employee_onboard: employeeOnboardID,
        };

        const payloads = [
          {
            entity: "InternalStaff",
            method: "update",
            criteria: { _id: staffID },
            data: {
              ...sharedProps,
              _id: staffID,
              first_name,
              last_name,
              email,
              sync_status: alreadyOnboarded ? "synced" : hasOnboardingRequestPending ? "pending" : "unsynced",
            },
            options: { upsert: true },
          },
          {
            entity: "Employment",
            method: "update",
            options: { upsert: true },
            criteria: { internalStaff: internalStaff?._id, employee_onboard: employeeOnboardID },
            data: {
              ...sharedProps,
              type: integration?.type || "shiftly",
              job_title,
              start_date,
              hourly_rate,
              award_code,
              classification_level,
              income_stream,
              is_authorised_to_approve_leave,
              is_authorised_to_approve_timesheets,
              notes,
            },
          },
          {
            entity: "TaxDeclaration",
            method: "update",
            options: { upsert: true },
            criteria: { internalStaff: internalStaff?._id, employee_onboard: employeeOnboardID },
            data: {
              ...sharedProps,
              employment_type,
              employment_basis,
              income_type,
              eligible_to_receive_leave_loading,
            },
          },
        ];

        // If staff member is already onboarded and in sync, send sync request to Xero
        if (alreadyOnboarded && staffMemberIsInSync) {
          payloads.push({
            entity: "InternalStaff",
            method: "syncShiftlyDetailsToXero",
            data: { internalStaff: staffID },
          });
        }

        // Create onboarding request if not already onboarded or pending
        if (hasIntegration && !isSavingLocally && !alreadyOnboarded) {
          setSaveXeroLoading(true);
          payloads.push({
            entity: "EmployeeOnboard",
            method: "create",
            data: {
              ...sharedProps,
              _id: employeeOnboardID,
              status: "pending",
              sent_on: new Date(),
              last_reminded_on: new Date(),
              integration: normaliseID(integration),
            },
          });
        } else {
          setSaveShiftlyLoading(true);
        }

        // If already onboarded, ensure all details are updated before continuing
        if (alreadyOnboarded && !(await onboardingRef.current.submit())) return;

        return await updateStaffDetails(payloads);
      } catch (error) {
        console.error("Error in onSubmit:", error.message);
      } finally {
        setSaveXeroLoading(false);
        setSaveShiftlyLoading(false);
      }
    },
    [activeLocation?._id, integration, updateStaffDetails, navigate, employeeOnboard, internalStaff, activeBusiness]
  );

  /******************************** Effects & Handles ********************************** */
  useEffect(() => {
    setData(() => ({
      is_authorised_to_approve_leave: false,
      is_authorised_to_approve_timesheets: false,
      eligible_to_receive_leave_loading: false,
      ...internalStaff,
      ...employment,
      ...taxDeclaration,
    }));
  }, [internalStaff, employment, taxDeclaration]);

  return (
    <StandardLayout
      heading={
        <>
          {internalStaff ? "Edit " : "Add a new "}
          <OrangeSpan>staff member</OrangeSpan>
        </>
      }
      breadcrumb={[{ label: "People", link: "/people" }]}
      hideBanner
      withCard
      size={"large"}
    >
      <ShiftlySuspense loading={allLoading}>
        <div className={styling("p-2")}>
          <ManageStaffBaseFields ref={baseRef} onSubmit={onSubmit} data={data} setData={setData} />

          {employeeOnboard?.status === "approved" && (
            <>
              <Divider />
              <ManageStaffMemberOnboarding ref={onboardingRef} employeeOnboard={employeeOnboard} data={data} />
            </>
          )}

          <div
            className={styling(
              "flex",
              "flex-between",
              "flex-column-reverse",
              "lg-flex-row",
              "mt-3",
              "lg-mt-0",
              "pb-6",
              "lg-pb-0"
            )}
          >
            <Button
              theme={"secondary-transparent"}
              onClick={() => {
                setData({ start_date: new Date() });
                navigate("/people?tab=internal");
              }}
              className={styling("mb-4", "lg-mb-0")}
            >
              Cancel
            </Button>
            <div
              className={styling(
                "flex",
                "flex-column-reverse",
                "lg-flex-row",
                "flex-align-center",
                "w-100",
                "lg-w-auto"
              )}
            >
              <Button
                theme={!employeeOnboard ? "secondary-outline" : "primary"}
                icon={!employeeOnboard || !activeBusiness?.xero_organisation ? faBookmark : faSave}
                loading={saveShiftlyLoading}
                onClick={() => onSubmit(data, true)}
                className={styling("w-100", "lg-w-auto", "mb-3", "lg-m-0")}
              >
                {!employeeOnboard || !integration ? "Save to Shiftly" : "Save and sync"}
              </Button>

              {!employeeOnboard && (
                <Button
                  theme={"primary"}
                  icon={activeBusiness?.xero_organisation && faPaperPlane}
                  loading={saveXeroLoading}
                  onClick={!activeBusiness?.xero_organisation ? () => navigate("/integrations") : () => onSubmit(data)}
                  className={styling("w-100", "lg-w-auto", "mb-3", "lg-mb-0", "lg-ml-3")}
                >
                  {!activeBusiness?.xero_organisation ? "Upgrade to integrate" : "Next: Send to Employee"}
                </Button>
              )}
              {!activeBusiness?.xero_organisation && (
                <div className={styling("flex", "flex-end")}>
                  <img src={XeroLogo} alt="xero-logo" style={{ width: "30px" }} className={styling("ml-3")} />
                </div>
              )}
            </div>
          </div>
        </div>
      </ShiftlySuspense>
    </StandardLayout>
  );
});

export default ManageStaffMember;
