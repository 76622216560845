import { Button, Input, useFetch, useToast, useSearchParams, FormV2, FadeInDiv, OrangeSpan } from "src/shiftly-ui";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useStyling } from "src/shiftly-ui";

const ResetPassword = () => {
  /*************************************** State *************************************** */
  const [email, setEmail] = useState("");
  const [resetToken, setResetToken] = useState("");
  /*************************************** Hooks *************************************** */
  const toast = useToast();
  const navigate = useNavigate();
  const styling = useStyling();
  /********************************** Refs & Constants ********************************* */

  /************************************** Queries ************************************** */
  const { post, isLoading, isError } = useFetch({
    options: {
      onSuccess: () => {
        toast.success(!resetToken ? "Reset link sent. Check your emails!" : "Password reset successfully");
        navigate("/portal?page=login");
      },
      onError: (error) => {
        toast.error(error.prettyError);
      },
    },
  });
  /******************************** Functions & Memos ********************************** */
  const onSubmitRequest = useCallback(
    async (data) => {
      return await post({
        node: "auth-node",
        method: "resetPasswordRequest",
        data,
      });
    },
    [post]
  );

  const onSubmitNewPassword = useCallback(
    async (data) => {
      return await post({
        node: "auth-node",
        method: "resetPassword",
        data: {
          token: resetToken,
          password: data.password,
          email,
        },
      });
    },
    [post, resetToken, email]
  );
  /******************************** Effects & Handles ********************************** */
  useSearchParams(["token", "email"], ({ token, email }) => {
    setResetToken(token);
    setEmail(email);
  });

  return (
    <FadeInDiv>
      {resetToken ? (
        <>
          <h2 className={styling("mb-3")}>
            Reset <OrangeSpan>Password</OrangeSpan>
          </h2>
          <p className={styling("mb-3")}>You can create your new password below:</p>
          <FormV2 onSubmit={onSubmitNewPassword}>
            <div className={styling("content")}>
              <Input name={"password"} />
              <Input name={"confirm_password"} submitOnEnter />
            </div>
            <div className={styling("w-100", "mt-3")}>
              <Button theme={"secondary"} size={"fw"} type={"submit"} loading={isLoading} error={isError}>
                Save new password
              </Button>
            </div>
          </FormV2>
        </>
      ) : (
        <>
          <h2 className={styling("mb-3")}>
            Reset <OrangeSpan>Password</OrangeSpan>
          </h2>
          <p className={styling("mb-3")}>
            Forgot your password? <br />
            Enter your email address below and we'll send you a link to reset your password.
          </p>
          <FormV2 onSubmit={onSubmitRequest}>
            <Input name={"email"} submitOnEnter />
            <div className={styling("w-100", "mt-3")}>
              <Button type={"submit"} theme={"secondary"} size={"fw"} loading={isLoading} error={isError}>
                Send reset link
              </Button>
              <p
                className={styling("text-center", "mt-3", "cursor-pointer")}
                onClick={() => navigate("/portal?page=login")}
              >
                I've remembered! <OrangeSpan className={styling("ml-2")}>Login</OrangeSpan>
              </p>
            </div>
          </FormV2>
        </>
      )}
    </FadeInDiv>
  );
};

export default ResetPassword;
