import { useCallback } from "react";

const useIsContextActionAllowed = ({ referencingShift, clipBoard, multiSelect, shiftTray }) => {
  const isCopyAllowed = useCallback(() => {
    if (referencingShift?.type === "external" && shiftTray.length === 0) return false;
    return multiSelect ? shiftTray.length > 0 : referencingShift?._id;
  }, [referencingShift?._id, shiftTray, multiSelect, referencingShift?.type]);

  const isPasteAllowed = useCallback(() => {
    return clipBoard.length > 0;
  }, [clipBoard]);

  const isRepeatAllowed = useCallback(() => {
    if (referencingShift?.type === "external" && shiftTray.length === 0) return false;
    return multiSelect ? shiftTray.length > 0 : referencingShift?._id;
  }, [referencingShift?._id, shiftTray.length, multiSelect, referencingShift?.type]);

  const doesShiftHaveStatus = useCallback(
    (statuses) => {
      if (!multiSelect) return statuses.includes(referencingShift?.status) || !referencingShift?.status;
      return shiftTray.map((tray) => tray.shift?.status).some((status) => statuses.includes(status));
    },
    [referencingShift?.status, shiftTray, multiSelect]
  );

  const isShiftOfType = useCallback(
    (type) => {
      if (!multiSelect) return referencingShift?.type === type;
      return shiftTray.map((tray) => tray.shift?.type).some((shiftType) => shiftType === type);
    },
    [referencingShift?.type, shiftTray, multiSelect]
  );

  return { isCopyAllowed, isPasteAllowed, isRepeatAllowed, doesShiftHaveStatus, isShiftOfType };
};
export default useIsContextActionAllowed;
