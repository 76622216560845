import styles from "./TestPage.module.scss";
import { useStyling } from "src/shiftly-ui";
import { DataGrid } from "@mui/x-data-grid";

const TestPage = () => {
  /*************************************** State *************************************** */

  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  /********************************** Refs & Constants ********************************* */

  /******************************** Functions & Memos ********************************** */

  /************************************** Queries ************************************** */
  const rows = Array.from({ length: 40 }, (_, index) => ({ id: index, name: `Row ${index}` }));
  /******************************** Effects & Handles ********************************** */

  return (
    <div className={styling("container")}>
      <h1>Content</h1>
      <div className={styling("wrapper")}>
        <div className={styling("content")}></div>
        <DataGrid columns={[{field: 'name'}]} rows={rows} className={styling("grid")} />
      </div>
    </div>
  );
};

export default TestPage;
