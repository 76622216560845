import { Stepper, useFetch, ModalLabel, FormV2, useStyling, useUI } from "src/shiftly-ui";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import BusinessDetails from "./NewBusinessDetails";
import NewBusinessLocations from "./NewBusinessLocations";
import styles from "./NewBusinessSetup.module.scss";
import NewBusinessIndustry from "./NewBusinessIndustry";
import MultipleLocations from "./MultipleLocations";
import useBusiness from "src/hooks/useBusiness";
import { useNavigate } from "react-router-dom";

const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const NewBusinessSetup = ({ newBusinessModal, setNewBusinessModal }) => {
  /*************************************** State *************************************** */
  const [formData, setFormData] = useState({
    timezone: clientTimezone,
  });
  const [activeStep, setActiveStep] = useState(0);

  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const { refreshAvailableBusinesses, setActiveBusiness } = useBusiness();
  const navigate = useNavigate();
  const {
    settings: { isAThirdPartyProvider },
  } = useUI();

  /********************************** Refs & Constants ********************************* */
  const formRef = useRef();

  /************************************** Queries ************************************** */
  const { post: postBusiness, isLoading } = useFetch({
    options: {
      onSuccess: ([newBusiness]) => {
        refreshAvailableBusinesses();
        setNewBusinessModal(false);
        formRef.current.reset();
        setFormData({ timezone: clientTimezone });
        setActiveBusiness(newBusiness);
        navigate(isAThirdPartyProvider ? "/people" : "/shifts", newBusiness);
      },
    },
  });

  /******************************** Functions & Memos ********************************** */
  const steps = useMemo(
    () => [
      {
        label: "Business Details",
        content: <BusinessDetails />,
        validation: async () => await formRef.current.validateFields(["name"]),
      },
      {
        label: "Industry",
        content: <NewBusinessIndustry data={formData} setActiveStep={setActiveStep} />,
        validation: async () => await formRef.current.validateFields(["industries"]),
      },
      {
        label: "MultipleLocations",
        content: <MultipleLocations setActiveStep={setActiveStep} />,
        validation: async () => await formRef.current.validateFields(["multipleLocations"]),
      },
      {
        label: "Locations",
        content: (
          <NewBusinessLocations
            data={formData}
            setData={setFormData}
            businessName={formData?.name}
            multipleLocations={formData?.multipleLocations === "yes"}
          />
        ),
        validation: async () =>
          await formRef.current.validateFields(["timezone", "street", "city", "state", "post_code"]),
      },
    ],
    [formData]
  );

  const handleSubmit = useCallback(async () => {
    const { name, locationName, timezone } = formData;

    const business = {
      ...formData,
      location: { name: locationName || name, timezone },
    };

    return postBusiness({
      entity: "Business",
      method: "create",
      data: business,
    });
  }, [postBusiness, formData]);

  /******************************** Effects & Handles ********************************** */
  useEffect(() => {
    setFormData({ timezone: clientTimezone });
    setActiveStep(0);
  }, [newBusinessModal]);

  return (
    <FormV2 data={formData} setData={setFormData} ref={formRef} onSubmit={handleSubmit}>
      <Stepper
        label={<ModalLabel text="" />}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        steps={steps}
        className={styling("modal")}
        showStepper={newBusinessModal}
        setShowStepper={setNewBusinessModal}
        onComplete={handleSubmit}
        loading={isLoading}
        keyDown={false}
      ></Stepper>
    </FormV2>
  );
};
export default NewBusinessSetup;
