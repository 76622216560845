import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import styles from "./Alerts.module.scss";
import { useStyling } from "../../hooks/useStyling";
import { useDisplay } from "../../hooks/useDisplay";
import { motion } from "framer-motion";
import { Overlay } from "../../components/modals/Overlay";
import { Input } from "../../components/inputs/Input";
import { Dropdown } from "../../components/inputs/Dropdown";
import clsx from "clsx";
import AlertsModal from "./AlertsModal";

export const AlertsContext = createContext();

export const AlertsContextProvider = ({ children }) => {
  /*************************************** State *************************************** */
  const [options, setOptions] = useState(null);
  const [promptValue, setPromptValue] = useState("");

  /*************************************** Hooks *************************************** */
  const { isMobile } = useDisplay();
  const styling = useStyling(styles);
  /********************************** Refs & Constants ********************************* */
  const awaitingPromiseRef = useRef(null);
  const promptValueRef = useRef(promptValue);

  /******************************** Functions & Memos ********************************** */
  const openModal = useCallback((options) => {
    setOptions(options);
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  }, []);

  const handleClose = useCallback(() => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(false);
    }

    setPromptValue("");
    setOptions(null);
  }, []);

  const handleConfirm = useCallback(
    (value) => {
      if (awaitingPromiseRef.current) {
        awaitingPromiseRef.current.resolve(value ?? promptValue);
      }
      setOptions(null);
      setPromptValue("");
    },
    [promptValue]
  );

  const prompt = useCallback(
    async (props = {}) => {
      const { type = "text", inputProps = {}, className } = props;

      const input =
        type === "dropdown" ? (
          <Dropdown value={promptValue} setValue={setPromptValue} {...inputProps} />
        ) : (
          <Input value={promptValue} setValue={setPromptValue} {...inputProps} />
        );

      const value = await openModal({
        props,
        className,
        input,
      });

      return value;
    },
    [openModal, promptValue]
  );

  const confirm = useCallback(
    async (props) =>
      await openModal({
        props,
      }),
    [openModal]
  );

  /******************************** Effects & Handles ********************************** */
  useEffect(() => {
    promptValueRef.current = promptValue;
  }, [promptValue]);

  return (
    <>
      <AlertsContext.Provider value={{ confirm, prompt, alert }}>{children}</AlertsContext.Provider>
      {!isMobile ? (
        <AlertsModal
          show={!!options}
          setShow={handleClose}
          className={clsx(options?.className)}
          input={options?.input}
          {...options?.props}
          handleConfirm={handleConfirm}
        >
          {options?.props?.content ?? options?.content}
        </AlertsModal>
      ) : (
        <div className={styling("mobile-wrapper", !!options && "mobile-visible")}>
          <Overlay open={!!options} onClick={handleClose} />
          <motion.div
            initial={{
              y: "100%",
            }}
            animate={{
              y: options ? "0%" : "100%",
            }}
            transition={{ duration: 0.2 }}
            className={styling("modal-mobile")}
          >
            <div className={styling("label-mobile")}>
              <h3>{options?.label}</h3>
            </div>
            {options?.content}
          </motion.div>
        </div>
      )}
    </>
  );
};

export const useAlerts = () => useContext(AlertsContext);
