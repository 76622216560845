import clsx from "clsx";
import styles from "./Overlay.module.scss";
import { useStyling } from "src/shiftly-ui";

export const Overlay = ({ open = false, setOpen = () => {}, className, ...props }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  return (
    <div
      id={"OverlayDIV"}
      className={clsx(styling("overlay"), open && styling("visible"), className)}
      onClick={() => setOpen(false)}
      {...props}
    ></div>
  );
};
