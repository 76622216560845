import { useState } from "react";
import styles from "./Authentication.module.scss";
import { useStyling } from "../../hooks/useStyling";
import { useSearchParams } from "../../hooks/useSearchParams";
import { useFetch } from "src/shiftly-ui";

export const Authentication = (props = {}) => {
  /*************************************** State *************************************** */
  const [page, setPage] = useState(<></>);

  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  /************************************** Queries ************************************** */
  const { post } = useFetch();

  /******************************** Effects & Handles ********************************** */
  useSearchParams(
    ["page"],
    ({ page, confirmToken }) => {
      setPage(page ? props[page] : props.welcome ?? props.signup);
      if (!confirmToken) return;
      post({ node: "auth", method: "confirmAccount", data: { token: confirmToken } });
    },
    [props]
  );

  return (
    <div className={styling("container")}>
      <div className={styling("card")}>{page}</div>
    </div>
  );
};
