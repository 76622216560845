import { useFetch, useAlerts, OrangeSpan, RatingDisplay, Table } from "src/shiftly-ui";

import { useCallback } from "react";
import { faPencil, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { useNavigate } from "react-router-dom";
import StandardLayout from "src/components/standard_layout/StandardLayout";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";

const LocationsHome = () => {
  /*************************************** Hooks *************************************** */
  const { allLocations, refreshAvailableLocations, isLoading, setActiveLocation } = useShiftlyLocation();
  const { confirm } = useAlerts();
  const navigate = useNavigate();

  /************************************** Queries ************************************** */
  const { post: deleteLocation } = useFetch({
    options: {
      onSuccess: () => {
        refreshAvailableLocations();
      },
    },
  });

  /******************************** Functions & Memos ********************************** */
  const handleDeleteLocation = useCallback(
    async (location) => {
      if (!location?._id) return;
      if (
        !(await confirm({
          label: (
            <>
              Are you sure you want to <span>delete this location?</span>
            </>
          ),
          text: "Penalties may apply for confirmed shifts. This cannot be undone.",
          mode: "danger",
          confirmText: "Delete",
          inverse: true,
        }))
      )
        return;
      deleteLocation({
        entity: "Location",
        method: "delete",
        criteria: { _id: location?._id },
      });
    },
    [deleteLocation, confirm]
  );

  const handleEditLocation = useCallback(
    (location) => {
      setActiveLocation(location);
      navigate("/business-account/locations/edit-location", { state: { location } });
    },
    [setActiveLocation, navigate]
  );

  return (
    <StandardLayout
      heading={
        <>
          Business <OrangeSpan>Locations</OrangeSpan>
        </>
      }
      breadcrumb={[{ label: "Business Settings", link: "/business-account" }]}
      buttonText={"Create New Location"}
      buttonEvent={() => navigate("/business-account/locations/edit-location")}
    >
      <Table
        rows={allLocations}
        onRowDoubleClick={({ row }) => handleEditLocation(row)}
        columns={[
          {
            field: "name",
            headerName: "Location Name",
          },
          {
            field: "address",
            headerName: "Address",
            flex: 2,
            render: ({ address }) => {
              return <p>{address?.full_address}</p>;
            },
          },
          {
            field: "rating",
            headerName: "Rating",
            align: "center",
            render: ({ rating }) => {
              return <RatingDisplay rating={rating} />;
            },
          },
        ]}
        actions={[
          {
            icon: faPencil,
            onClick: handleEditLocation,
            label: "Edit",
          },
          {
            icon: faTrash,
            onClick: handleDeleteLocation,
            label: "Delete",
          },
        ]}
      />
    </StandardLayout>
  );
};

export default LocationsHome;
