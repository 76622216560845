import styles from "./RatingSlider.module.css";
import { useStyling } from "../../hooks/useStyling.js";
import { withInputBase } from "../HOC/withInputBase";
import { useCallback, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/pro-solid-svg-icons";
import { faStar as outlineStar } from "@fortawesome/pro-light-svg-icons";
import clsx from "clsx";

export const RatingSlider = withInputBase(
  ({
    value: rating = 0,
    setValue: setRating,
    numberOfStars = 5,
    error,
    label,
    required,
    icon = outlineStar,
    activeIcon = faStar,
    disabled,
    className,
    formProps,
    name,
    validate,
    onChange = () => {},
    ...props
  }) => {
    /*************************************** State *************************************** */
    const [hoverRating, setHoverRating] = useState(0);

    /*************************************** Hooks *************************************** */
    const styling = useStyling(styles);

    /********************************** Refs & Constants ********************************* */
    const starContainerRef = useRef(null);

    /******************************** Functions & Memos ********************************** */
    const handleTouchMove = useCallback(
      (e) => {
        if (disabled) return;
        const touch = e.touches[0];
        const stars = Array.from(starContainerRef.current.children);
        stars.forEach((star, index) => {
          const rect = star.getBoundingClientRect();
          if (touch.clientX >= rect.left && touch.clientX <= rect.right) {
            setHoverRating(index + 1);
          }
        });
      },
      [setHoverRating, disabled]
    );

    const handleMouseMove = useCallback(
      (e) => {
        if (disabled) return;
        const stars = Array.from(starContainerRef.current.children);
        stars.forEach((star, index) => {
          const rect = star.getBoundingClientRect();
          if (e.clientX >= rect.left && e.clientX <= rect.right) {
            setHoverRating(index + 1);
          }
        });
      },
      [setHoverRating, disabled]
    );

    /******************************** Effects & Handles ********************************** */
    useEffect(() => {
      if (disabled) return;
      const handleTouchEnd = () => setRating(hoverRating);
      const handleMouseLeave = () => setHoverRating(rating);

      const starContainer = starContainerRef.current;

      starContainer.addEventListener("touchmove", handleTouchMove);
      starContainer.addEventListener("mousemove", handleMouseMove);
      starContainer.addEventListener("mouseleave", handleMouseLeave);
      window.addEventListener("touchend", handleTouchEnd);

      return () => {
        starContainer.removeEventListener("touchmove", handleTouchMove);
        starContainer.removeEventListener("mousemove", handleMouseMove);
        starContainer.removeEventListener("mouseleave", handleMouseLeave);
        window.removeEventListener("touchend", handleTouchEnd);
      };
    }, [hoverRating, rating, setHoverRating, setRating, handleTouchMove, handleMouseMove, disabled]);

    return (
      <div className={clsx(styling("container"), className)}>
        {label && (
          <h6>
            {label}:{required && <span>*</span>}
          </h6>
        )}
        <input type="number" {...props} {...formProps} value={rating} style={{ display: "none" }} />
        <div className={styling("stars")} ref={starContainerRef}>
          {Array.from({ length: numberOfStars }, (_, i) => (
            <div
              key={i}
              className={styling(`star${i + 1 <= (hoverRating || rating) ? "-active" : ""}`, disabled && "disabled")}
              onClick={() => {
                setRating(i + 1);
                onChange(i + 1);
              }}
            >
              <FontAwesomeIcon icon={i + 1 <= (hoverRating || rating) ? activeIcon : icon} />
            </div>
          ))}
        </div>
        {error && (
          <div className={styling("error-container")}>
            <p className={styling("message")}>{error}</p>
          </div>
        )}
      </div>
    );
  }
);

export default RatingSlider;
