import { useEffect } from "react";

export const useAsyncEffect = (asyncFunction, deps = []) => {
  /******************************** Effects & Handles ********************************** */
  useEffect(() => {
    (async () => {
      await asyncFunction?.();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
