import { Dropdown } from "src/shiftly-ui";
import { faPencil } from "@fortawesome/pro-light-svg-icons";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import { useNavigate } from "react-router-dom";

const BusinessLocationSelector = ({ locationFilter, setlocationFilter, setShowNav = () => {} }) => {
  /*************************************** Hooks *************************************** */
  const { allLocations, activeLocation } = useShiftlyLocation();
  const navigate = useNavigate();

  return (
    <>
      <Dropdown
        enableSearch={false}
        specialButton={{
          icon: faPencil,
          action: () => {
            navigate("/business-account/locations/edit-location", { state: { location: activeLocation } });
            setShowNav(false);
          },
        }}
        options={allLocations.map((location) => ({ label: location.name, value: location._id }))}
        value={locationFilter}
        setValue={setlocationFilter}
        truncateLength={13}
      />
    </>
  );
};
export default BusinessLocationSelector;
