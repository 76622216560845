import { Each, useUI, useToday, useFetch, useStyling } from "src/shiftly-ui";
import styles from "./MobileShifts.module.css";
import Skeleton from "react-loading-skeleton";
import MobileShiftCard from "./components/MobileShiftCard";

import { useMemo } from "react";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import Illustration from "src/shared/components/decorative/Illustration";

const MobileShifts = () => {
  const styling = useStyling(styles);
  const { activeLocation } = useShiftlyLocation();
  const {
    shifts: { viewMode },
  } = useUI();
  const { data: shifts, isLoading } = useFetch({
    request: {
      entity: "Shift",
      method: "getWithCalculatedFields",
      criteria: {
        status: "confirmed",
        location: activeLocation?._id,
        start_time: { $gte: new Date().setHours(0, 0, 0, 0) },
      },
      id: "Shift.GetMobileUpcomingShifts",
    },
    dependency: activeLocation?._id,
  });
  const { today } = useToday();
  const todaysShifts = useMemo(
    () =>
      shifts.filter((shift) => {
        today.setHours(0, 0, 0, 0); // Set to the start of today
        const shiftDate = new Date(shift.start_time);
        shiftDate.setHours(0, 0, 0, 0); // Set to the start of the shift's date
        return shiftDate.getTime() === today.getTime();
      }),
    [today, shifts]
  );
  return (
    <div className={styling("container")}>
      {isLoading ? (
        Array.from({ length: 3 }).map((_, index) => <SkeletonCard key={index} />)
      ) : shifts.length > 0 ? (
        viewMode === "scroll" ? (
          <Each
            of={shifts.filter((s) => !todaysShifts.map((sh) => sh._id).includes(s._id))}
            render={({ key, ...shift }) => <MobileShiftCard key={key} shift={shift} />}
          ></Each>
        ) : todaysShifts.length > 0 ? (
          <Each
            of={todaysShifts}
            render={({ key, ...shift }) => <MobileShiftCard key={key} shift={shift} isToday />}
          ></Each>
        ) : (
          <NoShifts />
        )
      ) : (
        <NoShifts />
      )}
    </div>
  );
};

const NoShifts = () => {
  const styling = useStyling(styles);
  return (
    <div className={styling("no-shifts")}>
      <Illustration image="NoShifts.svg" />
      <p>
        You don't have any upcoming shifts yet.
        <br />
        Accept a shifter to see them here
      </p>
    </div>
  );
};

const SkeletonCard = () => {
  const styling = useStyling(styles);
  return (
    <div className={styling("skeleton-container")}>
      <div className={styling("skeleton-header")}>
        <Skeleton height={30} width={150} />
        <div className={styling("skeleton-header-btns")}>
          <div className={styling("sk-btn")}>
            <Skeleton height={40} width={40} />
          </div>
          <div className={styling("sk-btn")}>
            <Skeleton height={40} width={40} />
          </div>
        </div>
      </div>
      <div className={styling("skeleton-body")}>
        <div>
          <Skeleton height={15} width={150} />
          <Skeleton height={15} width={150} />
        </div>
        <div>
          <Skeleton height={15} width={150} />
          <Skeleton height={15} width={150} />
        </div>
      </div>
    </div>
  );
};
export default MobileShifts;
