import { normaliseID, useAuth, useCachedQuery, useFetch } from "src/shiftly-ui";
import { useMemo } from "react";

const useIntegration = (type) => {
  const { account } = useAuth();

  const {
    Integration: { GetActiveIntegrationByType, GetAllIntegrations },
  } = useCachedQuery();

  const {
    data: [activeIntegration],
    isLoading,
    isFetching,
    refetch,
  } = useFetch({
    request: {
      entity: "Integration",
      criteria: {
        type,
        account_id: normaliseID(account),
      },
      id: GetActiveIntegrationByType,
    },
    dependency: account?._id + type,
    options: {
      staleTime: 0,
    },
  });

  const { data: locationMap } = useFetch({
    request: {
      entity: "Location",
      method: "get",
      criteria: {
        _id: { $in: Object.values(activeIntegration?.locationMap || {}) },
      },
    },
    dependency: activeIntegration?.locationMap,
    options: {
      select: (data) => {
        const map = {};
        data.forEach((loc) => {
          const id = Object.entries(activeIntegration?.locationMap || {}).find(
            ([key, value]) => value === loc._id
          )?.[0];
          if (id) {
            map[id] = loc;
          }
        });
        return map;
      },
    },
  });

  const { data: positionMap } = useFetch({
    request: {
      entity: "Position",
      method: "get",
      criteria: {
        _id: { $in: Object.values(activeIntegration?.positionMap || {}) },
      },
    },
    dependency: activeIntegration,
    options: {
      select: (data) => {
        const map = {};
        data.forEach((pos) => {
          const id = Object.entries(activeIntegration?.positionMap || {}).find(
            ([key, value]) => value === pos._id
          )?.[0];
          if (id) {
            map[id] = pos;
          }
        });
        return map;
      },
    },
  });

  const {
    data: integrations,
    isFetching: integrationsisFetching,
    isLoading: integrationIsLoading,
  } = useFetch({
    request: {
      entity: "Integration",
      method: "get",
      criteria: {
        account_id: normaliseID(account),
      },
      id: GetAllIntegrations,
    },
    dependency: account?._id,
  });

  return useMemo(
    () => ({
      isLoading: isLoading || integrationIsLoading,
      refetch,
      activeIntegration,
      integrations,
      locationMap,
      positionMap,
      isFetching: isFetching || integrationsisFetching,
    }),
    [
      isLoading,
      refetch,
      activeIntegration,
      locationMap,
      positionMap,
      isFetching,
      integrationsisFetching,
      integrationIsLoading,
      integrations,
    ]
  );
};
export default useIntegration;
