import {
  Input,
  Button,
  FileUploader,
  useFetch,
  TextArea,
  useToast,
  Dropdown,
  Address,
  FormFlex,
  FormV2,
  useStyling,
  RatingDisplay,
  OrangeSpan,
  ButtonGroup,
  newGUID,
} from "src/shiftly-ui";
import { useLocation, useNavigate } from "react-router-dom";
import StandardLayout from "../../../components/standard_layout/StandardLayout";
import styles from "./BusinessAccountLocationEdit.module.scss";
import { faSave } from "@fortawesome/pro-regular-svg-icons";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import NoImage from "src/shared/assets/png/no_img_placeholder.jpeg";
import { faFileUpload } from "@fortawesome/pro-regular-svg-icons";
import DraggableList from "src/components/DraggableList";
import useBusiness from "src/hooks/useBusiness";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";

const BusinessAccountLocationEdit = () => {
  /*************************************** State *************************************** */
  const [images, setImages] = useState([]);
  const [updatedLocation, setUpdatedLocation] = useState({ timezone: moment.tz.guess() });
  const [uploading, setUploading] = useState("");

  /*************************************** H2ooks *************************************** */
  const { activeBusiness } = useBusiness();
  const { refreshAvailableLocations } = useShiftlyLocation();
  const toast = useToast();
  const navigate = useNavigate();
  const { state } = useLocation();
  const styling = useStyling(styles);

  /********************************** Refs & Constants ********************************* */
  const uploaderRef = useRef(null);

  /************************************** Queries ************************************** */
  const {
    post: saveLocation,
    isLoading,
    isError,
  } = useFetch({
    options: {
      onSuccess: () => {
        !location && navigate("/business-account/locations");
        refreshAvailableLocations();
      },
    },
  });

  const {
    data: [location],
  } = useFetch({
    request: {
      entity: "Location",
      method: "get",
      populate: ["address"],
      criteria: {
        _id: state?.location?._id,
      },
    },
    dependency: state?.location?._id,
    options: {
      onSuccess: ([data = {}]) => {
        setUpdatedLocation({ timezone: moment.tz.guess(), ...data.address, ...data });
      },
      staleTime: 0,
    },
  });

  /******************************** Functions & Memos ********************************** */
  const handleFileUploaded = useCallback(
    (url = []) => {
      if (!url?.length) return;
      if (uploading === "images") {
        setImages((prev) => [...prev, { key: url[0], content: <img src={url[0]} alt="Location" /> }]);
      } else {
        setUpdatedLocation((prev) => ({ ...prev, [uploading]: url[0] }));
      }
    },
    [uploading]
  );

  const handleSave = useCallback(
    async (data) => {
      if (images.length < 1) {
        toast.error("Please upload at least one image.");
        return;
      }
      return await saveLocation({
        entity: "Location",
        method: location ? "update" : "create",
        data: { ...data, business: activeBusiness?._id },
        criteria: location?._id ? { _id: location?._id } : {},
      });
    },
    [toast, saveLocation, activeBusiness, images, location]
  );

  /******************************** Effects & Handles ********************************** */
  useEffect(() => {
    if (!location) return;
    setImages(
      location?.images?.flatMap((img) => {
        if (img === "/defaults/location_default.png") return [];
        return { key: img, content: <img src={img} alt="Location" /> };
      }) || []
    );
  }, [location]);

  useEffect(() => {
    setUpdatedLocation((prev) => ({ ...prev, images: images.map((img) => img.key) }));
  }, [images]);

  return (
    <StandardLayout
      heading={<>{location?.name ?? "Create a new location"}</>}
      breadcrumb={[
        { label: "Business Settings", link: "/business-account" },
        { label: "Locations", link: "/business-account/locations" },
      ]}
      loading={isLoading}
      buttonProps={{ icon: faSave }}
      withCard
    >
      <FileUploader
        multiple={false}
        visible={false}
        ref={uploaderRef}
        onUploadComplete={handleFileUploaded}
        accepts={"image/*"}
      />
      <FormV2 data={updatedLocation} setData={setUpdatedLocation} onSubmit={handleSave}>
        <Heading location={location} />
        <Logos uploaderRef={uploaderRef} updatedLocation={updatedLocation} setUploading={setUploading} />
        <TextFields />
        <Images uploaderRef={uploaderRef} images={images} setImages={setImages} setUploading={setUploading} />
        <ButtonGroup className={styling("mt-4")}>
          <Button loading={isLoading} type={"submit"} error={isError} theme={"secondary"} icon={faSave}>
            Save Changes
          </Button>
        </ButtonGroup>
      </FormV2>
    </StandardLayout>
  );
};

const Heading = ({ location }) => {
  const styling = useStyling(styles);
  return (
    <div className={styling("flex", "flex-row")}>
      <Input label={"Location Name"} placeholder={"Cozy Cafe Around the Corner"} name={"name"} required />
      {location?.rating && (
        <div className={styling("flex", "pl-3")}>
          <p className={styling("mr-2")} style={{ whiteSpace: "nowrap" }}>
            You're rated
          </p>
          <RatingDisplay rating={location?.rating ?? 5} />
        </div>
      )}
    </div>
  );
};

const Logos = ({ setUploading, uploaderRef, updatedLocation }) => {
  const styling = useStyling(styles);
  return (
    <div className={styling("flex")}>
      <div className={styling("w-100", "md-w-50", "sm-pr-3")}>
        <h5 className={styling("mb-3")}>Primary Logo</h5>
        <p className={styling("label")}>
          <OrangeSpan>Please note:</OrangeSpan> the box below is how your logo will be horizontally displayed for
          applicants.
        </p>
        <div
          className={styling("primary-image")}
          onClick={() => {
            setUploading("logo");
            uploaderRef.current?.click();
          }}
        >
          <img src={updatedLocation?.logo || NoImage} alt="Primary logo" className={styling("cover")} />
        </div>
        <Button
          icon={faFileUpload}
          iconSide="left"
          onClick={() => {
            setUploading("logo");
            uploaderRef.current?.click();
          }}
          theme={"secondary-outline"}
        >
          Upload File
        </Button>
      </div>
      <div className={styling("w-100", "md-w-50", "sm-pl-3", "my-3")}>
        <h5 className={styling("mb-3")}>Icon</h5>
        <p className={styling("label")}>
          <OrangeSpan>Please note:</OrangeSpan> the box below is how your icon will be viewed by applicants.
        </p>
        <div
          className={styling("icon-image")}
          onClick={() => {
            setUploading("icon");
            uploaderRef.current?.click();
          }}
        >
          <img src={updatedLocation?.icon || NoImage} alt="Primary logo" className={styling("cover")} />
        </div>
        <Button
          icon={faFileUpload}
          iconSide="left"
          onClick={() => {
            setUploading("icon");
            uploaderRef.current?.click();
          }}
          theme={"secondary-outline"}
        >
          Upload File
        </Button>
      </div>
    </div>
  );
};

const TextFields = () => {
  const styling = useStyling(styles);
  return (
    <>
      <TextArea
        name={"description"}
        label={"Description"}
        required
        placeholder={"Tell us about your location..."}
        rows={7}
        className={styling("mt-3")}
      />
      <FormFlex>
        <Input name={"instagram"} label={"Instagram username"} placeholder={"https://www.instagram.com/shiftly.au/"} />
        <Input name={"facebook"} label={"Facebook page"} placeholder={"https://www.facebook.com/shiftly.au"} />
        <Input name={"website"} label={"Website URL"} placeholder={"https://www.shiftly.au/"} />
      </FormFlex>
      <Address name={"address"} />
      <Dropdown mt={"40px"} name={"timezone"} />
    </>
  );
};

const Images = ({ uploaderRef, images, setImages, setUploading }) => {
  const styling = useStyling(styles);

  return (
    <>
      <div className={styling("flex", "w-100", "flex-between", "py-2")}>
        <h5>Your Images:</h5>
        <Button
          icon={faPlus}
          onClick={() => {
            setUploading("images");
            uploaderRef.current?.click();
          }}
          theme={"primary-transparent"}
          iconSide="right"
        >
          Add Images
        </Button>
      </div>
      <div style={{ height: "200px" }}>
        <DraggableList
          items={images}
          setItems={setImages}
          layout="horizontal"
          draggableContainerClass={styling("draggable-image-container")}
          dropBoxContainerClass={styling("flex", "flex-start")}
          paddingAmount={4}
          paddingElement={
            <div
              className={styling("no-image")}
              onClick={() => {
                setUploading("images");
                uploaderRef.current?.click();
              }}
            >
              <div className={styling("no-circle")}>
                <FontAwesomeIcon icon={faPlus} />
              </div>
            </div>
          }
        />
      </div>
    </>
  );
};

export default BusinessAccountLocationEdit;
