import { useFetch, responsibilities, arrayToMap, normaliseID } from "src/shiftly-ui";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

const levels = ["Level 1", "Level 2", "Level 3", "Level 4", "Level 5", "Level 6", "Level 7"];

const usePositions = () => {
  const business = useSelector((state) => state.business);
  const activeLocation = useMemo(() => business.activeLocation, [business]);
  const activeBusiness = useMemo(() => business.activeBusiness, [business]);

  const { data: awards } = useFetch({
    request: {
      id: "Award.6dd6b610-0903-4268-8c69-d23c7fe678b2",
      entity: "Award",
      criteria: {
        award: {
          $in: activeBusiness?.industries?.map((industry) => industry.award_code),
        },
      },
    },
    dependency: activeBusiness?.industries,
  });

  const { data: allGroups } = useFetch({
    request: {
      entity: "PositionGroup",
      method: "get",
      criteria: { award: { $in: activeBusiness?.industries?.map((industry) => industry.award_code) } },
    },
    dependency: activeBusiness?.industries,
    options: {
      select: (data) => {
        return data.filter((group) => {
          if (responsibilities[group?.industry?.award_code]?.[group.stream]) return true;
          return false;
        });
      },
    },
  });

  const groupMap = useMemo(() => arrayToMap(allGroups, "_id"), [allGroups]);

  const {
    data: allPositions,
    refetch: refreshAvailablePositions,
    isLoading,
  } = useFetch({
    request: {
      entity: "Position",
      method: "get",
      populate: ["group"],
      criteria: { business: activeBusiness?._id },
      id: "Position.GetPositionsByBusiness",
    },
    dependency: business?.activeBusiness?._id,
    options: {
      select: (data) => {
        return data.flatMap((position) => {
          const group = allGroups.find((g) => g._id === position.group?._id);

          if (!group) return [];
          const award_code = position?.industry?.award_code;
          const responsibilitiesForPosition =
            responsibilities[award_code]?.[group.stream]?.[position.classification_level];
          if (!responsibilitiesForPosition) return [];
          return {
            ...position,
            group,
            responsibilities: responsibilitiesForPosition || [],
          };
        }, []);
      },
    },
  });

  const availablePositions = useMemo(
    () => allPositions.filter((p) => p.location === activeLocation?._id),
    [activeLocation, allPositions]
  );

  const availablePositionsSummary = useMemo(() => {
    const map = availablePositions.reduce((acc, position) => {
      if (!acc[position.name]) {
        acc[position.name] = position;
      }
      return acc;
    }, {});
    return Object.values(map);
  }, [availablePositions]);

  const groupedPositions = useMemo(() => {
    const grouped = {};
    availablePositions.forEach((position) => {
      if (!grouped[position.group._id]) {
        grouped[position.group._id] = [];
      }
      grouped[position.group._id].push(position);
    });
    return grouped;
  }, [availablePositions]);

  const dropdownPositions = useMemo(() => {
    return availablePositions.reduce((acc, position) => {
      const group = acc.find((g) => g.value === position.group._id);
      if (!group) {
        acc.push({
          value: position.group?._id,
          label: position.name,
        });
      }
      return acc;
    }, []);
  }, [availablePositions]);

  const availableLevels = useMemo(
    () =>
      Array.from(new Set(awards.map((award) => Number(award.level))))
        .sort((a, b) => a - b)
        .map((l) => ({
          value: l,
          label: `Level ${l}`,
        })),
    [awards]
  );

  const getAvailableLevelsForGroup = useCallback(
    (g) => {
      const groupID = normaliseID(g);

      const group = groupMap[groupID];
      if (!group) return [];

      const { award } = group;

      const awardLevels = awards
        .filter((a) => a.award === award)
        .reduce((acc, award) => {
          const level = Number(award.level);
          if (!acc.find((l) => l.value === level)) acc.push({ value: level, label: award.classification });
          return acc;
        }, [])
        .sort((a, b) => a.value - b.value);

      return awardLevels;
    },
    [groupMap, awards]
  );

  const getPositionFromLevelAndGroup = useCallback(
    (group, level) => {
      return availablePositions.find((p) => p.group?._id === group && p.classification_level === level);
    },
    [availablePositions]
  );

  return useMemo(
    () => ({
      availablePositions,
      availablePositionsSummary,
      allPositions,
      allGroups,
      isLoading,
      refreshAvailablePositions,
      groupedPositions,
      dropdownPositions,
      availableLevels,
      getAvailableLevelsForGroup,
      getPositionFromLevelAndGroup,
      levels,
      groupMap,
    }),
    [
      allPositions,
      availablePositions,
      availablePositionsSummary,
      allGroups,
      isLoading,
      refreshAvailablePositions,
      groupedPositions,
      dropdownPositions,
      availableLevels,
      getPositionFromLevelAndGroup,
      getAvailableLevelsForGroup,
      groupMap,
    ]
  );
};
export default usePositions;
