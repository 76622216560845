import { useAuth, useStyling, Each, Button, withDisplay, Overlay } from "src/shiftly-ui";
import styles from "./BusinessNavDropDown.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { useNavigate, useLocation } from "react-router-dom";
import {
  faCoins,
  faGear,
  faGrid2,
  faHeadset,
  faLocationDot,
  faPlug,
  faShuffle,
  faStore,
  faUserAlt,
} from "@fortawesome/pro-regular-svg-icons";
import useBusiness from "src/hooks/useBusiness";

const businessLinks = [
  {
    icon: faLocationDot,
    text: "Locations",
    link: "/business-account/locations",
  },
  { icon: faCoins, text: "Billing & Payment", link: "/business-account/billing-and-payment" },
  { icon: faUserAlt, text: "Managers", link: "/business-account/managers" },
  { icon: faPlug, text: "Integrations", link: "/integrations" },
];

const BusinessNavDropDown = ({ open, setOpen, width }) => {
  /*************************************** State *************************************** */
  const [activeNav, setActiveNav] = useState({});

  /*************************************** Hooks *************************************** */
  const { user } = useAuth();
  const location = useLocation();
  const styling = useStyling(styles);
  const navigate = useNavigate();
  const { activeBusiness } = useBusiness();

  /******************************** Effects & Handles ********************************** */
  useEffect(() => {
    const path = window.location.pathname;
    const foundLink = businessLinks.find((link) => link.link === path);

    if (path === "/") {
      setActiveNav("swap");
      return;
    }

    if (path.includes("business-account") && !foundLink) {
      setActiveNav("business-account");
      return;
    }
    if (path.includes("account-management") && !foundLink) {
      setActiveNav("account-management");
      return;
    }

    if (foundLink) {
      setActiveNav(foundLink);
      return;
    }

    setActiveNav("dashboard");
  }, [location]);

  return (
    <>
      <Overlay open={open} setOpen={setOpen} />

      {width >= 1280 && (
        <div className={styling("flex")} onClick={() => setOpen((prev) => !prev)}>
          <div className={styling("account-icon")}>
            <p>
              {user?.first_name?.charAt(0)?.toUpperCase()}
              {user?.last_name?.charAt(0)?.toUpperCase()}
            </p>
          </div>
          <FontAwesomeIcon
            icon={faChevronDown}
            className={clsx(styling("icon-chevron"), open && styling("spin-chevron"))}
          />
        </div>
      )}
      <div className={clsx(styling("menu-container"), open && styling("show"))}>
        {activeBusiness && (
          <div className={styling("mb-4")}>
            <p className={styling("label", "mb-3")}>{activeBusiness?.name}</p>
            <div
              className={styling("parent-nav-item", "mb-3", activeNav === "dashboard" && "active")}
              onClick={() => {
                navigate("/shifts");
                setOpen(false);
              }}
            >
              <FontAwesomeIcon icon={faGrid2} />
              <p className={styling("link-text")}>Dashboard</p>
            </div>

            <div
              className={styling("parent-nav-item", activeNav === "business-account" && "active")}
              onClick={() => {
                navigate("/business-account");
                setOpen(false);
              }}
            >
              <FontAwesomeIcon icon={faStore} />
              <p className={styling("link-text")}>Business Settings</p>
            </div>
            <Each
              of={businessLinks}
              render={({ key, ...link }) => (
                <SubNavItem {...link} key={key} active={activeNav?.text === link.text} setOpen={setOpen} />
              )}
            />
          </div>
        )}
        <div
          className={styling("parent-nav-item", "mb-3")}
          onClick={() => {
            navigate("/");
            setOpen(false);
          }}
        >
          <FontAwesomeIcon icon={faShuffle} />
          <p className={styling("link-text", activeNav === "swap" && "active")}>Swap Businesses</p>
        </div>
        <div className={styling("nav-section")}>
          <div
            className={styling("parent-nav-item")}
            onClick={() => {
              navigate("/account-management");
              setOpen(false);
            }}
          >
            <FontAwesomeIcon icon={faGear} />
            <p className={styling("link-text", activeNav === "account-management" && "active")}>Account Management</p>
          </div>
        </div>
        <div
          className={styling("parent-nav-item", "mt-3")}
          onClick={() => {
            window.open("https://shiftly.au/contact", "_blank");
            setOpen(false);
          }}
        >
          <FontAwesomeIcon icon={faHeadset} />
          <p className={styling("link-text")}>Resources & Support</p>
        </div>
        <div className={styling("logout-container")}>
          <Button
            theme={"secondary-outline"}
            className={styling("mt-3")}
            onClick={() => {
              localStorage.removeItem("shiftly-auth-token");
              window.location.reload();
            }}
            size={"fw"}
          >
            LOG OUT
          </Button>
        </div>
      </div>
    </>
  );
};
const SubNavItem = ({ icon, text, link, active, setOpen, type }) => {
  /*************************************** Hooks *************************************** */
  const navigate = useNavigate();
  const styling = useStyling(styles);

  return (
    <div className={styling("sub-nav-item")}>
      <div className={styling("sub-nav-side")}></div>
      <div
        className={clsx(styling("flex", "flex-start", "ml-4"), active && styling("active"))}
        onClick={() => {
          setOpen(false);
          if (type === "external") return window.open(link, "_blank");
          navigate(link);
        }}
      >
        <FontAwesomeIcon icon={icon} className={styling("sub-nav-icon")} />
        <p className={styling("link-text")}>{text}</p>
      </div>
    </div>
  );
};
export default withDisplay(BusinessNavDropDown);
