import styles from "./MobileRating.module.scss";
import {
  useStyling,
  MobileStepper,
  SlideFrom,
  useProfile,
  TextArea,
  RatingSlider,
  OrangeSpan,
  normaliseID,
} from "src/shiftly-ui";
import { useMemo } from "react";
import useRating from "./useRating";

const MobileRating = ({ open, setOpen, shifts }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const { ratings, comments, setRatings, setComments, handleSave } = useRating({
    shifts,
    setOpen,
  });

  /******************************** Functions & Memos ********************************** */
  const steps = useMemo(
    () =>
      shifts.map((shift) => ({
        label: shift?.user?.name,
        key: normaliseID(shift),
        content: (
          <RateAction
            user={shift.user}
            rating={ratings[normaliseID(shift)] || 0}
            setRating={(value) => setRatings((prev) => ({ ...prev, [normaliseID(shift)]: value }))}
            comment={comments[normaliseID(shift)] || ""}
            setComment={(value) => setComments((prev) => ({ ...prev, [normaliseID(shift)]: value }))}
          />
        ),
      })),
    [shifts, ratings, comments, setRatings, setComments]
  );

  return (
    <SlideFrom open={open} setOpen={setOpen} className={styling("slide-container", open && "show")}>
      <MobileStepper showNav={false} steps={steps} onNext={handleSave} onComplete={handleSave}></MobileStepper>;
    </SlideFrom>
  );
};

const RateAction = ({ user, rating, setRating, comment, setComment }) => {
  const styling = useStyling(styles);
  const { profile } = useProfile({ criteria: { user: normaliseID(user) } });

  return (
    <div className={styling("w-100", "h-100", "position-relative")}>
      <div className={styling("profile-picture")}>
        <img src={profile?.profile_picture ?? "/defaults/shifter.png"} alt="Shifter" />
      </div>

      <div className={styling("text-center")}>
        <h4>
          Give {user?.first_name} {user?.last_name} a <OrangeSpan>rating</OrangeSpan>
        </h4>
      </div>
      <div className={styling("stars-container")}>
        <RatingSlider value={rating} setValue={setRating} />
      </div>
      <TextArea
        className={styling("px-3")}
        value={comment}
        setValue={setComment}
        label={"Leave a review"}
        placeholder={`Write something nice about ${user?.first_name}...`}
      />
    </div>
  );
};

export default MobileRating;
