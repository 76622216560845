import StandardLayout from "src/components/standard_layout/StandardLayout";
import { useFetch, useProfile, useStyling } from "src/shiftly-ui";
import { useLocation, useParams } from "react-router-dom";
import ApplicantProfileHeader from "./components/applicant_profile/ApplicantProfileHeader";
import { useMemo } from "react";
import ApplicantProfileShiftApplications from "./components/applicant_profile/ApplicantProfileShiftApplications";
import ApplicantProfileTestimonials from "./components/applicant_profile/ApplicantProfileTestimonials";
import ApplicantProfileShiftHistory from "./components/applicant_profile/ApplicantProfileShiftHistory";
import ApplicantProfileImages from "./components/applicant_profile/ApplicantProfileImages";
import ApplicantProfileDigitalCV from "./components/applicant_profile/ApplicantProfileDigitalCV";

const ApplicantProfile = () => {
  /*************************************** Hooks *************************************** */
  const { user: user_id } = useParams();
  const { state } = useLocation();
  const { profile = {}, isLoading: profileIsLoading } = useProfile({ criteria: { user: user_id } });
  const styling = useStyling();
  /********************************** Refs & Constants ********************************* */
  const shiftId = state?.shift;
  const { user } = profile;

  /************************************** Queries ************************************** */
  const {
    data: [shift],
    isLoading: shiftIsLoading,
  } = useFetch({
    request: {
      entity: "Shift",
      method: "get",
      criteria: {
        _id: shiftId,
      },
    },
    dependency: shiftId,
  });

  /******************************** Functions & Memos ********************************** */
  const sharedProps = useMemo(
    () => ({
      user,
      profile,
      shift,
    }),
    [user, profile, shift]
  );

  /******************************** Effects & Handles ********************************** */

  return (
    <StandardLayout withCard loading={shiftIsLoading || profileIsLoading} className={styling("px-3", "sm-px-0")}>
      <ApplicantProfileHeader {...sharedProps} />
      <ApplicantProfileShiftApplications {...sharedProps} />
      <ApplicantProfileShiftHistory {...sharedProps} />
      <ApplicantProfileTestimonials {...sharedProps} />
      <ApplicantProfileImages {...sharedProps} />
      <ApplicantProfileDigitalCV {...sharedProps} />
    </StandardLayout>
  );
};

export default ApplicantProfile;
