import { usePushNotifications, Notifications, useStyling, Overlay } from "src/shiftly-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";

import { faBell } from "@fortawesome/pro-regular-svg-icons";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";

import styles from "./BusinessNotificationCentre.module.scss";

const BusinessNotificationCentre = () => {
  /*************************************** State *************************************** */
  const [open, setOpen] = useState(false);

  /*************************************** Hooks *************************************** */
  const { activeLocation } = useShiftlyLocation();
  const { unreadNotifications, readNotifications, markAsRead } = usePushNotifications(activeLocation?._id);
  const styling = useStyling(styles);

  /******************************** Effects & Handles ********************************** */
  useEffect(() => {
    if (!open) markAsRead();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div className={styling("position-relative")}>
      {/* Notification Icon */}
      <div className={styling("icon", open && "selected")} onClick={() => setOpen((prev) => !prev)}>
        <FontAwesomeIcon icon={faBell} />
        {unreadNotifications.length > 0 && <div className={styling("badge")}>{unreadNotifications.length}</div>}
      </div>

      {/* Overlay */}
      <Overlay open={open} setOpen={setOpen} />

      {/* Notification Panel */}
      <motion.div
        initial={{ x: "100%" }}
        animate={{
          x: open ? "0" : '100%',
          transition: {
            duration: 0.2,
          },
        }}
        className={styling("notifications-container")}
        onClick={() => setOpen((prev) => !prev)}
      >
        <Notifications unreadNotifications={unreadNotifications} readNotifications={readNotifications} />
      </motion.div>
    </div>
  );
};

export default BusinessNotificationCentre;
