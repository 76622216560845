import {
  withDisplay,
  useSearchParams,
  useFetch,
  useCachedQuery,
  EmptyState,
  useStyling,
  Button,
  useUI,
} from "src/shiftly-ui";
import { useRef, useState, useMemo } from "react";
import useShiftlyApplicants from "../../hooks/useShiftlyApplicants";
import ApplicantsTable from "./ApplicantsTable";
import ApplicantsMobile from "./ApplicantsMobile";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import ConfirmApplicant from "../ConfirmApplicant";

const ShiftlyApplicants = ({ isMobile }) => {
  /*************************************** State *************************************** */
  const [confirmingApplicant, setConfirmingApplicant] = useState(null);

  /*************************************** Hooks *************************************** */
  const { activeLocation } = useShiftlyLocation();
  const { activeShift, setActiveShift } = useShiftlyApplicants();
  const {
    ShiftApplications: { GetApplicationsForSpecificShift },
    Shift: { GetShiftsByLocation },
  } = useCachedQuery();
  const styling = useStyling();
  const { showNewShiftModal, setNewShiftModalDefaults } = useUI();
  /********************************** Refs & Constants ********************************* */
  const tabRefSet = useRef();

  const criteria = useMemo(() => {
    if (activeShift)
      return {
        shift: activeShift._id,
      };

    if (isMobile)
      return {
        status: "pending",
        location: activeLocation?._id,
      };

    return {
      status: "pending",
      location: activeLocation?._id,
    };
  }, [activeShift, activeLocation, isMobile]);

  /************************************** Queries ************************************** */

  const { data: shifts } = useFetch({
    request: {
      entity: "Shift",
      method: "get",
      populate: ["position", "user"],
      criteria: {
        location: activeLocation?._id,
        status: {
          $in: isMobile ? ["published"] : ["published", "confirmed", "active"],
        },
      },
      options: {
        sort: {
          start_time: 1,
        },
      },
      id: GetShiftsByLocation,
    },
    dependency: activeLocation?._id,
  });

  const { data: applicants } = useFetch({
    request: {
      entity: "ShiftApplication",
      method: "get",
      populate: ["user"],
      criteria,
      id: GetApplicationsForSpecificShift,
    },
    dependency: activeShift?._id,
    options: {
      enabled: true,
    },
  });

  const { data: profiles } = useFetch({
    request: {
      entity: "Profile",
      method: "get",
      criteria: {
        user: applicants.map((applicant) => applicant.user._id),
      },
    },
    dependency: applicants.length,
    options: {
      select: (profiles) => {
        return profiles.reduce((acc, profile) => {
          acc[profile.user] = profile;
          return acc;
        }, {});
      },
    },
  });

  /******************************** Effects & Handles ********************************** */
  useSearchParams(
    ["shift"],
    ({ shift }) => {
      if (tabRefSet.current) return;

      if (shift) {
        const shiftIndex = shifts.findIndex((s) => s._id === shift);
        if (shiftIndex !== -1) {
          setActiveShift(shifts[shiftIndex]);
          tabRefSet.current = true;
        }
      }
    },
    [applicants, profiles]
  );

  return (
    <EmptyState
      items={applicants}
      image="empty_applicants.webp"
      component={
        <>
          <p className={styling("my-3")}>
            <strong>You don't have any applicants... yet.</strong>
          </p>
          <Button
            theme={"primary"}
            onClick={() => {
              showNewShiftModal(true);
              setNewShiftModalDefaults({
                shiftDate: new Date().toLocaleDateString(),
              });
            }}
          >
            Create a new Shift
          </Button>
        </>
      }
    >
      {!!confirmingApplicant && (
        <ConfirmApplicant
          showModal={!!confirmingApplicant}
          setShowModal={setConfirmingApplicant}
          user={confirmingApplicant?.user}
          shift={confirmingApplicant?.shift}
          profile={confirmingApplicant?.profile}
          application={confirmingApplicant}
        />
      )}

      {!isMobile ? (
        <ApplicantsTable
          applicants={applicants}
          profiles={profiles}
          shifts={shifts}
          setActiveShift={setActiveShift}
          activeShift={activeShift}
          setConfirmingApplicant={setConfirmingApplicant}
        />
      ) : (
        <ApplicantsMobile setConfirmingApplicant={setConfirmingApplicant} applicants={applicants} profiles={profiles} />
      )}
    </EmptyState>
  );
};
export default withDisplay(ShiftlyApplicants);
