import { useFetch, businessActions, useCachedQuery } from "src/shiftly-ui";
import { useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

const useShiftlyLocation = () => {
  /*************************************** Hooks *************************************** */
  const dispatch = useDispatch();
  const business = useSelector((state) => state.business);
  const ui = useSelector((state) => state.ui);

  /************************************** Queries ************************************** */
  const {
    Location: { AvailableLocations },
  } = useCachedQuery();

  const {
    data: allLocations,
    refetch: refreshAvailableLocations,
    isLoading,
  } = useFetch({
    request: {
      entity: "Location",
      criteria: { business: business.activeBusiness?._id },
      method: "get",
      populate: ["address"],
      id: AvailableLocations,
    },
    dependency: business.activeBusiness?._id,
    options: {
      enabled: ui?.settings?.mode === "owner" && !!business.activeBusiness?._id,
    },
    onDataChange: (data) => {
      if (!business.activeLocation) {
        setActiveLocation(data[0]);
      } else {
        const loc = data.find((loc) => loc._id === business.activeLocation._id);
        if (loc) {
          setActiveLocation(loc);
        } else {
          setActiveLocation(data[0]);
        }
      }
    },
  });

  /******************************** Functions & Memos ********************************** */
  const activeLocation = useMemo(() => business?.activeLocation, [business?.activeLocation]);

  const isLocationSetup = useMemo(() => {
    if (!activeLocation) return false;
    //Logo
    if (activeLocation?.logo && activeLocation.logo !== "/defaults/location_default.png") return true;
    //Icon
    if (!activeLocation?.icon || activeLocation.icon === "/defaults/location_default.png") return false;
    //Description
    if (!activeLocation?.description) return false;
    //Images
    if (!activeLocation?.images || activeLocation.images.length === 0) return false;
  }, [activeLocation]);

  const setActiveLocation = useCallback(
    (location) => {
      let loc = allLocations.find((loc) => loc._id === location._id);
      if (!loc) {
        loc = allLocations[0];
      }
      if (!loc) return;
      dispatch(businessActions.setActiveLocation(loc));
      localStorage.setItem("activeLocation", JSON.stringify(loc));
    },
    [dispatch, allLocations]
  );

  return useMemo(
    () => ({ activeLocation, allLocations, setActiveLocation, refreshAvailableLocations, isLoading, isLocationSetup }),
    [activeLocation, allLocations, setActiveLocation, refreshAvailableLocations, isLoading, isLocationSetup]
  );
};
export default useShiftlyLocation;
