import useInternalStaff from "src/hooks/useInternalStaff";
import styles from "./NewShiftModalCostCalculator.module.scss";
import { useStyling, roundNumber } from "src/shiftly-ui";

const NewShiftModalCostCalculator = ({
  tab = 0,
  duration,
  baseWageFromAward,
  totalEmployerContribution,
  lateNightPenalty,
  superContribution,
  shiftlyFees,
  internalStaffTotals,
}) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  return tab === 0 ? (
    // Shifter
    <div className={styling("total-container")}>
      <div className={styling("total-lines")}>
        <div className={styling("total-line")}>
          <p>Award Rate ({duration} hours)</p>
          <div></div>
          <p>
            ${roundNumber(baseWageFromAward?.min * duration, 2)} <span>-</span> $
            {roundNumber(baseWageFromAward?.max * duration, 2)}
          </p>
        </div>
        <div className={styling("total-line")}>
          <p>Employer Contribution</p>
          <div></div>
          <p>${totalEmployerContribution}</p>
        </div>
        <div className={styling("total-line")}>
          <p>Late night penalty</p>
          <div></div>
          <p>${lateNightPenalty}</p>
        </div>
        <div className={styling("total-line")}>
          <p>Super Contribution</p>
          <div></div>
          <p>
            ${superContribution?.min} <span>-</span> ${superContribution?.max}
          </p>
        </div>
        <div className={styling("total-line")}>
          <p>Shiftly Fee</p>
          <div></div>
          <p>
            ${shiftlyFees?.min} <span>-</span> ${shiftlyFees?.max}
          </p>
        </div>
      </div>
    </div>
  ) : (
    //Internal Staff
    <div className={styling("total-container")}>
      <div className={styling("total-lines")}>
        <div className={styling("total-line")}>
          <p>
            Hourly Rate ({internalStaffTotals?.duration} hours @ ${internalStaffTotals?.hourlyRate})
          </p>
          <div></div>
          <p>${internalStaffTotals?.subTotal}</p>
        </div>
        <div className={styling("total-line")}>
          <p>Late night penalty</p>
          <div></div>
          <p>${internalStaffTotals?.lateNightPenalty}</p>
        </div>
        <div className={styling("total-line")}>
          <p>Super Contribution</p>
          <div></div>
          <p>${internalStaffTotals?.superContribution}</p>
        </div>
      </div>
    </div>
  );
};

export default NewShiftModalCostCalculator;
