import { Button, withDisplay, useAuth, FormV2, Link, Input, OrangeSpan, ButtonGroup, FadeInDiv } from "src/shiftly-ui";
import styles from "./Portal.module.scss";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useStyling } from "src/shiftly-ui";

const Login = ({ isMobile }) => {
  /*************************************** Hooks *************************************** */
  const { login, loginIsLoading: isLoading } = useAuth();
  const navigate = useNavigate();
  const styling = useStyling(styles);

  /******************************** Functions & Memos ********************************** */
  const onSubmit = useCallback(
    async (data) => {
      return login(data, "owner");
    },
    [login]
  );

  return (
    <FadeInDiv className={styling("container")}>
      <h1>
        Welcome <OrangeSpan>Back!</OrangeSpan>
      </h1>
      <p className={styling("my-4")}>
        <strong> Log in to access Shiftly and take the next step to finding flexible staff!</strong>
      </p>

      <FormV2 onSubmit={onSubmit}>
        <div className={styling("content-container")}>
          <Input name={"email"} />
          <Input name={"password"} submitOnEnter validate={false} />

          <div className={styling("mb-", "text-left")}>
            <Link onClick={() => navigate("/portal?page=reset")} className={styling("label")}>
              Forgotten your password?
            </Link>
          </div>
        </div>
        <ButtonGroup>
          {isMobile && (
            <Button
              theme={"secondary-outline"}
              onClick={() => navigate(process.env.REACT_APP_SHIFTER_APP_URL)}
              size={"fw"}
            >
              I'm looking for work
            </Button>
          )}
          <Button theme={"primary"} type="submit" loading={isLoading} size={"fw"}>
            Login
          </Button>
        </ButtonGroup>

        <div className={styling("navigate", "mt-4")}>
          <p>
            Are you new here?{" "}
            <Link onClick={() => navigate("/portal?page=signup")} className={styling("ml-4")}>
              Create an account
            </Link>
          </p>
        </div>
      </FormV2>
    </FadeInDiv>
  );
};

export default withDisplay(Login);
