import { Modal, TextArea, roundNumber, Button, useFetch, useToast, useCachedQuery, useStyling } from "src/shiftly-ui";
import styles from "./CancelShift.module.css";
import { useUncontrolled } from "uncontrollable";
import logo from "src/shared/assets/logos/icon.svg";
import { useCallback, useState } from "react";

const feePercent = roundNumber(process.env.REACT_APP_SHIFTLY_REFUND_AMOUNT * 100, 0);

const CancelShift = (props) => {
  /*************************************** State *************************************** */
  const [cancellationReason, setCancellationReason] = useState("");

  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const toast = useToast();
  const { shift, showModal = false, setShowModal } = useUncontrolled(props, { showModal: "setShowModal" });
  const {
    Shift: { ShiftsBetweenDates },
  } = useCachedQuery();

  /************************************** Queries ************************************** */
  const {
    post: submitComment,
    isLoading,
    refresh,
  } = useFetch({
    options: {
      onSuccess: () => {
        setShowModal(false);
        refresh(ShiftsBetweenDates);
        setCancellationReason("");
        toast.success("Shift cancelled successfully");
      },
    },
  });

  /******************************** Functions & Memos ********************************** */
  const handleSubmit = useCallback(() => {
    submitComment({
      entity: "Shift",
      method: "delete",
      criteria: {
        _id: shift._id,
      },
      data: {
        cancellationReason,
      },
      options: {
        chargeCancellationFee: true,
      },
    });
  }, [shift, submitComment, cancellationReason]);

  return (
    <div className={styling("container")}>
      <Modal
        className={styling("modal")}
        showModal={showModal}
        setShowModal={setShowModal}
        label={
          <div className={styling("label")}>
            <img src={logo} alt="Shiftly Logo" className={styling("shiftly-logo")} />
            <h1>
              <span>Cancel</span> shift
            </h1>
          </div>
        }
      >
        <div className={styling("content")}>
          <div className={styling("heading")}>
            <h3>We're sorry this shift hasn't worked out.</h3>
            <p>
              <strong>
                Cancelling a shift will incur a cancellation <span>fee of {feePercent}%</span> on the shift's total
                cost. <br />
              </strong>
              <br />
              If your cancellation is due to issues with employee standards, an emergency, or any other specific
              circumstance, please provide details in the text box below. We take these matters very seriously and will
              investigate every submission thoroughly to ensure the highest standards are maintained.
            </p>
          </div>
          <div className={styling("cancellation-reason")}>
            <TextArea
              rows={8}
              value={cancellationReason}
              setValue={setCancellationReason}
              label={"Please provide cancellation reason"}
              placeholder={
                "Provide examples of the expected standards and how the shifter failed to meet them. Describe what actions you and the shifter took to resolve the mistakes, and mention any team members who witnessed the situation."
              }
            />
            <div className={styling("buttons")}>
              <Button
                theme={"secondary"}
                onClick={() => {
                  setShowModal(false);
                  setCancellationReason("");
                }}
              >
                Back
              </Button>
              <Button theme={"primary"} onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? "Loading..." : "Cancel Shift"}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CancelShift;
