import { useAuth, useUI } from "src/shiftly-ui";
import { useMemo } from "react";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
const useMessagesCriteria = (activeConversation, creating) => {
  const { businessMode } = useUI();
  const { user } = useAuth();
  const { activeLocation } = useShiftlyLocation();
  const criteria = useMemo(() => {
    const criteria = {};
    if (businessMode || creating) {
      criteria["location"] = activeLocation?._id;
    }
    if (!businessMode || creating) {
      criteria["user"] = activeConversation?.user?._id || user?._id;
    }
    return criteria;
  }, [activeConversation, businessMode, user, activeLocation, creating]);
  return criteria;
};
export default useMessagesCriteria;
