import { RadioButtons, useStyling, OrangeSpan } from "src/shiftly-ui";
import styles from "./NewBusinessSetup.module.scss";
import NewBusinessRadioButtonCard from "./NewBusinessRadioButtonCard";
import { faTimes, faCheck } from "@fortawesome/pro-solid-svg-icons";

const MultipleLocations = ({ setActiveStep }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  return (
    <div className={styling("container")}>
      <div className={styling("heading")}>
        <h2>
          Do you have <OrangeSpan>multiple locations</OrangeSpan>?
        </h2>
      </div>
      <div className={styling("content")}>
        <RadioButtons
          required
          align="center"
          name={"multipleLocations"}
          className={styling("sm-w-50", "mx-auto")}
          options={[
            {
              label: "Yes",
              value: "yes",
              content: (
                <NewBusinessRadioButtonCard
                  icon={faCheck}
                  label={"Yes"}
                  award={"We have more than one location."}
                  active={false}
                  onClick={() => {
                    setActiveStep(3);
                  }}
                />
              ),
            },
            {
              label: "No",
              value: "no",
              content: (
                <NewBusinessRadioButtonCard
                  icon={faTimes}
                  label={"No"}
                  award={"We only have one location."}
                  onClick={() => {
                    setActiveStep(3);
                  }}
                />
              ),
            },
          ]}
          type="cards"
        />
      </div>
    </div>
  );
};
export default MultipleLocations;
