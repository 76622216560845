import { Each, Button, newGUID, useStyling, OrangeSpan, useDisplay } from "src/shiftly-ui";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import styles from "./NewPosition.module.scss";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import NewPositionQualification from "./NewPositionQualification";

const NewPositionQualifications = ({ qualifications, setQualifications }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  /********************************** Refs & Constants ********************************* */
  const [parent] = useAutoAnimate();

  return (
    <div className={styling("container")}>
      <div className={styling("heading")}>
        <h3>
          What are some of the <OrangeSpan>qualifications</OrangeSpan>
          <br />
          your employee will require?
        </h3>
        <p>No qualifications required? Just skip this step.</p>
      </div>
      <div className={styling("content")}>
        <div
          className={styling("qualifications-container")}
          ref={parent}
          style={
            qualifications.length > 1
              ? {
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                }
              : {}
          }
        >
          <Each
            of={qualifications}
            render={({ key, ...qualification }, index) => (
              <NewPositionQualification
                key={key}
                {...qualification}
                setQualifications={setQualifications}
                index={index}
                qualifications={qualifications}
              />
            )}
          />
        </div>
        <Button
          icon={faPlus}
          onClick={() => qualifications.length < 4 && setQualifications((prev) => [...prev, { id: newGUID() }])}
          disabled={qualifications.length >= 4}
          theme={"secondary-outline"}
        >
          Add New Qualification
        </Button>
      </div>
    </div>
  );
};
export default NewPositionQualifications;
