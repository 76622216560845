import { forwardRef, useState, useCallback, useImperativeHandle } from "react";
import styles from "./MobileStepper.module.css";
import { FooterControl } from "./FooterControl";
import { useToast } from "../../hooks/useToast";
import clsx from "clsx";

export const MobileStepper = forwardRef(
  (
    {
      steps = [],
      onNext,
      onPrev,
      onComplete = () => new Promise((resolve) => resolve()),
      skipButtonText = "Skip",
      prevButtonText = "Back",
      actionButtonText = "Next",
      actionButtonCompleteText = "Complete",
      skippable = true,
      loading = false,
      showNav = true,
    },
    ref
  ) => {
    /*************************************** State *************************************** */
    const [activeStep, setActiveStep] = useState(0);

    /*************************************** Hooks *************************************** */
    const toast = useToast();

    /******************************** Functions & Memos ********************************** */
    const handleStepClick = useCallback((i) => setActiveStep(i), []);

    const isValid = useCallback(() => {
      if (steps[activeStep].validation && !steps[activeStep].validation()) {
        toast.error(
          steps[activeStep].message || "Looks like something's missing. Please fill in the required fields.",
          steps[activeStep].title
        );
        return false;
      }
      return true;
    }, [activeStep, steps, toast]);

    const handleNext = useCallback(async () => {
      if (!isValid()) return;

      if (activeStep < steps?.length - 1) {
        await onNext?.();
        handleStepClick(activeStep + 1);
      } else {
        await onComplete();
      }
    }, [activeStep, handleStepClick, steps, isValid, onComplete, onNext]);

    const handlePrev = useCallback(async () => {
      if (activeStep > 0) {
        await onPrev?.();
        handleStepClick(activeStep - 1);
      }
    }, [activeStep, handleStepClick, onPrev]);

    /******************************** Effects & Handles ********************************** */
    useImperativeHandle(
      ref,
      () => ({
        getCurrentStep: () => activeStep,
        handleNext,
        handlePrev,
        handleStepClick,
      }),
      [activeStep, handleNext, handlePrev, handleStepClick]
    );

    return (
      <div className={styles["container"]}>
        <div className={styles["progress-container"]}>
          <div className={styles["bar"]} style={{ width: ((activeStep + 1) / steps?.length) * 100 + "%" }}></div>
        </div>
        {showNav && (
          <div className={styles["numbers-container"]}>
            {steps.map((step, i) => (
              <div
                key={i}
                onClick={() => skippable && handleStepClick(i)}
                className={clsx(styles["number"], i <= activeStep && styles["active"])}
              >
                <p>{i + 1}</p>
              </div>
            ))}
          </div>
        )}

        <div className={styles["content-container"]}>
          {steps.map((step, index) => (
            <div
              className={styles["step-content"]}
              style={{
                transform: `translateX(${100 * -activeStep}%)`,
                transition: "transform 0.5s ease-in-out",
              }}
              key={step.key ?? index}
            >
              {step.content}
            </div>
          ))}
        </div>
        <FooterControl
          buttonText={
            loading ? "Loading..." : activeStep === steps?.length - 1 ? actionButtonCompleteText : actionButtonText
          }
          onButtonClick={handleNext}
          onNextClick={handleNext}
          buttonProps={{ disabled: loading }}
          nextButtonDisabled={!steps[activeStep]?.skippable || loading}
          onPrevClick={handlePrev}
          prevButtonDisabled={activeStep === 0 || loading}
          nextButton={<p>{skipButtonText}</p>}
          prevButton={<p>{prevButtonText}</p>}
        />
      </div>
    );
  }
);
