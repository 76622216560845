import styles from "./NotFound404.module.scss";
import { Button, OrangeSpan, useStyling } from "src/shiftly-ui";
import { useNavigate } from "react-router-dom";
import Illustration from "src/shared/components/decorative/Illustration";

const NotFound404 = () => {
  const styling = useStyling(styles);

  return (
    <div className={styling("container")}>
      <Illustration image="404.webp" />

      <h1 className={styling("my-4")}>
        Lost, but <OrangeSpan>vibing</OrangeSpan>.
      </h1>

      <p>
        <strong>404:</strong> Page not found. But hey, you found a dead end in style.
      </p>

      <Button link="/" className={styling("my-4")}>
        Take me back to Shiftly
      </Button>
    </div>
  );
};

export default NotFound404;
