import { useFetch, useSearchParams, LoadingSpinner } from "src/shiftly-ui";
import { useMemo, useRef } from "react";
import DeputyIntegrationBase from "./components/Deputy/DeputyIntegrationBase";
import useBusiness from "src/hooks/useBusiness";
import useIntegration from "src/hooks/useIntegration";

const buildDeputyOAuthUrl = () => {
  const params = new URLSearchParams({
    client_id: process.env.REACT_APP_DEPUTY_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_FRONT_END_URL + "integrations/deputy",
    response_type: "code",
    scope: "longlife_refresh_token",
  });
  return `https://once.deputy.com/my/oauth/login?${params.toString()}`;
};

const Deputy = () => {
  const { activeBusiness } = useBusiness();
  const link = useMemo(() => buildDeputyOAuthUrl(), []);
  const connectedRef = useRef(false);

  const { activeIntegration, refetch, isLoading: fetchingIntegration } = useIntegration("deputy");

  const { post: createIntegration, isLoading } = useFetch({
    options: {
      onSuccess: () => {
        refetch();
      },
    },
  });

  useSearchParams(
    ["code"],
    ({ code }) => {
      if (code && !activeIntegration && !fetchingIntegration && !connectedRef.current && activeBusiness?._id) {
        connectedRef.current = true;
        createIntegration({
          entity: "Integration",
          method: "init",
          data: {
            business: activeBusiness?._id,
            code,
            type: "deputy",
          },
        });
      }
    },
    [activeIntegration, activeBusiness, fetchingIntegration]
  );

  const { data } = useFetch({
    request: {
      entity: "Integration",
      method: "getElements",
      data: {
        type: "deputy",
        business: activeBusiness?._id,
      },
    },
    dependency: activeBusiness?._id,
    options: {
      enabled: Boolean(activeIntegration),
    },
  });

  return fetchingIntegration ? (
    <LoadingSpinner />
  ) : (
    <DeputyIntegrationBase
      connectionLink={link}
      isLoading={isLoading}
      activeIntegration={activeIntegration}
      thirdPartyLocations={data.locations || []}
      thirdPartyPositions={data.positions || []}
    ></DeputyIntegrationBase>
  );
};
export default Deputy;
