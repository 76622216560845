import styles from "./Modal.module.scss";
import { Overlay } from "./Overlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useStyling } from "../../hooks/useStyling";
import clsx from "clsx";

export const Modal = ({ label, children, showModal, setShowModal, className, overlayClassName, iconClassName }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);


  return (
    <>
      <Overlay open={showModal} onClick={() => setShowModal(false)} className={overlayClassName} />
      <div className={clsx(styling("modal", showModal && "visible"), className)}>
        <div className={styling("modal-header")}>
          {typeof label === "string" ? <p className={styling("label")}>{label}</p> : label}
          <FontAwesomeIcon
            icon={faTimes}
            onClick={() => setShowModal(false)}
            className={clsx(styling("close-icon"), iconClassName)}
          />
        </div>

        <div className={styling("modal-divider")}></div>
        <div className={styling("content")}>{children}</div>
      </div>
    </>
  );
};
