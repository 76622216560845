import { useState, useCallback, useEffect } from "react";
import styles from "./SchedulerTemplates.module.scss";
import {
  useStyling,
  OrangeSpan,
  Modal,
  ModalLabel,
  Input,
  useFetch,
  Button,
  Link,
  Each,
  useAlerts,
  useToast,
  useCachedQuery,
  EmptyState,
} from "src/shiftly-ui";
import { faMagnifyingGlass, faTrash, faUser, faUserGroup } from "@fortawesome/pro-regular-svg-icons";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useScheduler } from "src/contexts/Scheduler";
import moment from "moment-timezone";

const SchedulerTemplatesLoad = ({ showLoadModal, setShowLoadModal, setShowSaveModal }) => {
  /*************************************** State *************************************** */
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const toast = useToast();
  const { activeLocation } = useShiftlyLocation();
  const {
    periods: { current },
  } = useScheduler();
  const {
    InternalShift: { GetInternalShifts },
    Shift: { ShiftsBetweenDates },
  } = useCachedQuery();

  /************************************** Queries ************************************** */
  const {
    post: applyTemplate,
    isLoading,
    isError,
    refresh,
  } = useFetch({
    options: {
      onSuccess: () => {
        setShowLoadModal(false);
        refresh([ShiftsBetweenDates, GetInternalShifts]);
      },
    },
  });

  const { data: templates } = useFetch({
    request: {
      id: "ScheduleTemplate.GetAllForLocation",
      entity: "ScheduleTemplate",
      criteria: {
        location: activeLocation?._id,
      },
    },
    dependency: activeLocation?._id,
  });
  /******************************** Functions & Memos ********************************** */
  const loadTemplate = useCallback(async () => {
    if (!selectedTemplate) return toast.info("Please select a template to load.");

    const { shifts } = selectedTemplate;

    const updatedShifts = shifts.map(({ _id, ...shift }) => {
      const originalShiftStartTime = moment.tz(shift.start_time, shift.timezone);
      const originalShiftEndTime = moment.tz(shift.end_time, shift.timezone);

      const originalDayOfWeekStart = originalShiftStartTime.day();
      const originalDayOfWeekEnd = originalShiftEndTime.day();

      const adjustedDayOfWeekStart = originalDayOfWeekStart === 0 ? 6 : originalDayOfWeekStart - 1;
      const adjustedDayOfWeekEnd = originalDayOfWeekEnd === 0 ? 6 : originalDayOfWeekEnd - 1;

      const newDateBaseStartTime = moment(current[adjustedDayOfWeekStart]).tz(shift.timezone);
      const newDateBaseEndTime = moment(current[adjustedDayOfWeekEnd]).tz(shift.timezone);

      newDateBaseStartTime
        .set("hours", originalShiftStartTime.hours())
        .set("minutes", originalShiftStartTime.minutes())
        .set("seconds", originalShiftStartTime.seconds());

      newDateBaseEndTime
        .set("hours", originalShiftEndTime.hours())
        .set("minutes", originalShiftEndTime.minutes())
        .set("seconds", originalShiftEndTime.seconds());

      return {
        ...shift,
        start_time: newDateBaseStartTime.toISOString(),
        end_time: newDateBaseEndTime.toISOString(),
      };
    });

    //2. Split shifts into internal and shiftly
    const internalShifts = updatedShifts.filter((shift) => shift.type === "internal");
    const shiftlyShifts = updatedShifts.filter((shift) => shift.type === "shiftly");

    await applyTemplate([
      { entity: "Shift", method: "create", data: shiftlyShifts },
      { entity: "InternalShift", method: "create", data: internalShifts },
    ]);
  }, [selectedTemplate, toast, current, applyTemplate]);

  /******************************** Effects & Handles ********************************** */
  useEffect(() => {
    setSelectedTemplate(null);
  }, [showLoadModal]);

  useEffect(() => {
    if (!searchTerm) return setFilteredTemplates(templates);
    setFilteredTemplates(
      templates.filter((template) => template.name?.toLowerCase().includes(searchTerm?.toLowerCase()))
    );
  }, [templates, searchTerm]);

  return (
    <Modal
      showModal={showLoadModal}
      setShowModal={setShowLoadModal}
      label={
        <ModalLabel
          text={
            <h4>
              <OrangeSpan>Load</OrangeSpan> a template
            </h4>
          }
        />
      }
    >
      <Input
        my={"20px"}
        value={searchTerm}
        setValue={setSearchTerm}
        label={"Search Templates"}
        icon={{ icon: faMagnifyingGlass, side: "left" }}
        placeholder={"Search by name"}
      />
      <div className={styling("loaded-templates")}>
        <EmptyState
          items={templates}
          text={
            <>
              You haven't saved any templates. Would you like to{" "}
              <Link
                onClick={() => {
                  setShowLoadModal(false);
                  setShowSaveModal(true);
                }}
              >
                save one{" "}
              </Link>
              now?
            </>
          }
        >
          <Each
            of={filteredTemplates}
            render={(template) => (
              <Template
                key={template?._id}
                template={template}
                onClick={(template) => setSelectedTemplate(template)}
                isSelected={selectedTemplate?._id === template._id}
                setShowLoadModal={setShowLoadModal}
              />
            )}
          />
        </EmptyState>
      </div>
      <div className={styling("footer")}>
        <Button onClick={() => setShowLoadModal(false)} theme="secondary-outline">
          Cancel
        </Button>
        <Button onClick={loadTemplate} disabled={isLoading} error={isError}>
          Load Template
        </Button>
      </div>
    </Modal>
  );
};

const Template = ({ isSelected, template = {}, onClick = () => {}, setShowLoadModal = () => {} }) => {
  const styling = useStyling(styles);

  const { confirm } = useAlerts();

  const internalShifts = template.shifts.filter((shift) => shift.type === "internal");
  const shiftlyShifts = template.shifts.filter((shift) => shift.type === "shiftly");

  const {
    post: deleteTemplate,
    updateCache,
    refresh,
  } = useFetch({
    options: {
      onMutate: ({ criteria }) => {
        updateCache("ScheduleTemplate.GetAllForLocation", (data) => {
          return data.filter((template) => template._id !== criteria._id);
        });
      },
      onSuccess: () => {
        setShowLoadModal(true);
        refresh("ScheduleTemplate.GetAllForLocation");
      },
    },
  });

  const handleDelete = useCallback(async () => {
    setShowLoadModal(false);
    if (
      !(await confirm({
        label: (
          <>
            Are you sure you would like to <span>delete this template?</span>
          </>
        ),
        text: `This cannot be undone.`,
        confirmText: "Delete",
        cancelText: "Cancel",
        inverse: true,
      }))
    )
      return setShowLoadModal(true);

    await deleteTemplate({
      entity: "ScheduleTemplate",
      method: "delete",
      criteria: {
        _id: template._id,
      },
    });
  }, [confirm, setShowLoadModal, deleteTemplate, template]);

  return (
    <div className={styling("template", isSelected && "selected")} onClick={() => onClick(template)}>
      <h5 className={styling("mb-2")}>{template.name}</h5>
      <div
        className={styling("template-icons")}
        onClick={(e) => {
          e.stopPropagation();
          handleDelete();
        }}
      >
        <FontAwesomeIcon icon={faTrash} />
      </div>
      <div className={styling("flex", "flex-start")}>
        <div className={styling("flex", "flex-start", "mr-3")}>
          <div className={styling("template-icon")}>
            <FontAwesomeIcon icon={faUserGroup} />
          </div>
          <p className={styling("label")}>
            {internalShifts.length} Internal Shift{internalShifts.length > 1 ? "s" : ""}
          </p>
        </div>
        <div className={styling("flex", "flex-start")}>
          <FontAwesomeIcon icon={faUser} className={styling("mr-2")} />
          <p className={styling("label")}>
            <strong>
              {shiftlyShifts.length} Shiftly Shift{internalShifts.length > 1 ? "s" : ""}
            </strong>
          </p>
        </div>
      </div>
      <div className={styling("mt-1")}>
        <p>{template.description}</p>
      </div>
    </div>
  );
};

export default SchedulerTemplatesLoad;
