import {
  useFetch,
  OrangeSpan,
  SearchAndDisplayRow,
  truncate,
  useUI,
  useSearchParams,
  useStyling,
} from "src/shiftly-ui";
import useBusiness from "src/hooks/useBusiness";
import NewBusinessSetup from "./new_business_setup/NewBusinessSetup";
import { useEffect, useMemo, useState } from "react";
import SearchAndDisplay from "src/components/search_and_display/SearchAndDisplay";
import { useNavigate } from "react-router-dom";
import StandardLayout from "../../components/standard_layout/StandardLayout";
import { faGear } from "@fortawesome/pro-regular-svg-icons";
import styles from "./BusinessHome.module.scss";
const BusinessHome = () => {
  /*************************************** State *************************************** */
  const [newBusinessModal, setNewBusinessModal] = useState(false);

  /*************************************** Hooks *************************************** */
  const { availableBusinesses, setActiveBusiness, isLoading } = useBusiness();
  const {
    settings: { isAThirdPartyProvider },
  } = useUI();

  /******************************** Functions & Memos ********************************** */
  const elements = useMemo(
    () =>
      availableBusinesses.map((business, index) => ({
        original: business,
        listContent: (
          <BusinessList {...business} index={index} business={business} setActiveBusiness={setActiveBusiness} />
        ),
      })),
    [availableBusinesses, setActiveBusiness]
  );

  /******************************** Effects & Handles ********************************** */
  useEffect(() => {
    //Open New Busienss Modal if no businesses are available
    setNewBusinessModal(!isLoading && !availableBusinesses.length);
  }, [isLoading, availableBusinesses]);

  useSearchParams(
    ["open"],
    ({ open }) => {
      if (open) setNewBusinessModal(true);
    },
    []
  );

  return (
    <>
      <NewBusinessSetup newBusinessModal={newBusinessModal} setNewBusinessModal={setNewBusinessModal} />
      <StandardLayout
        heading={
          <>
            All <OrangeSpan>Businesses</OrangeSpan>
          </>
        }
        breadcrumb={[{ label: "Account Management", link: "/account-management" }]}
        buttonText={"Create New Business"}
        buttonEvent={() => setNewBusinessModal(true)}
        showBackButton={false}
      >
        <SearchAndDisplay
          isLoading={isLoading}
          elementsName="Businesses"
          elements={elements}
          image="empty_business.webp"
          hideSearchBar={isAThirdPartyProvider}
          searchFields={["name"]}
          emptyStateText={
            <>
              You have no businesses setup.
              <br />
              Click "Create New Business" to get started.
            </>
          }
          defaultView={"list"}
        />
      </StandardLayout>
    </>
  );
};

const BusinessList = ({ name, setActiveBusiness, business, _id }) => {
  /*************************************** Hooks *************************************** */
  const navigate = useNavigate();
  const {
    settings: { isAThirdPartyProvider },
  } = useUI();
  const styling = useStyling(styles);
  const { activeBusiness } = useBusiness();
  /************************************** Queries ************************************** */
  const { data: locations } = useFetch({
    request: {
      entity: "Location",
      criteria: { business: _id },
      method: "get",
    },
    dependency: _id,
  });

  return (
    <SearchAndDisplayRow
      active={activeBusiness?._id === _id}
      className={styling("row-container")}
      cells={[
        {
          key: "name",
          className: "w-100",
          content: (
            <div className={styling("flex", "flex-row")}>
              <div className={styling("initial")}>
                <p>{name?.[0]}</p>
              </div>
              <p>
                <strong>{truncate(name, 45)}</strong>
              </p>
            </div>
          ),
        },
        {
          key: "numberOfLocations",
          content: (
            <>
              <p className={styling("text-right", "w-100")}>
                <strong>
                  {locations?.length} location{locations?.length > 1 ? "s" : ""}
                </strong>
              </p>
            </>
          ),
        },
      ]}
      actions={[
        {
          key: "settings",
          icon: faGear,
          onClick: (e) => {
            e.stopPropagation();
            setActiveBusiness(business);
            navigate("/business-account");
          },
        },
      ]}
      onClick={(e) => {
        e.stopPropagation();
        setActiveBusiness(business);
        navigate(isAThirdPartyProvider ? "/people" : "/shifts", business);
      }}
    />
  );
};
export default BusinessHome;
