import { newGUID } from "src/shiftly-ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";
import clsx from "clsx";
import styles from "./DraggableList.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPlus } from "@fortawesome/pro-solid-svg-icons";

const DraggableList = ({
  items = [],
  setItems = () => {},
  layout = "horizontal",
  draggableContainerClass = "",
  dropBoxContainerClass = "",
  paddingAmount = 0,
  paddingElement = <></>,
}) => {
  /*************************************** State *************************************** */
  const [draggableItems, setDraggableItems] = useState(items);

  /******************************** Functions & Memos ********************************** */
  const id = useMemo(() => newGUID(), []);

  const handleOnDragEnd = useCallback(
    (result) => {
      if (!result.destination) return;
      const items = Array.from(draggableItems);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setItems(items);
      setDraggableItems(items);
    },
    [draggableItems, setItems]
  );

  const handleRemoveItem = useCallback(
    (key) => {
      const items = draggableItems.filter((item) => item.key !== key);
      setItems(items);
      setDraggableItems(items);
    },
    [draggableItems, setItems]
  );

  /******************************** Effects & Handles ********************************** */
  useEffect(() => {
    setDraggableItems(items.map((item) => ({ key: newGUID(), ...item })));
  }, [items]);

  const paddedItems = useMemo(() => {
    if (!paddingAmount) return draggableItems;
    const itemsArr = [];
    for (let i = 0; i < paddingAmount; i++) {
      const selectedItem = draggableItems[i];
      if (selectedItem) itemsArr.push(selectedItem);
      else
        itemsArr.push({
          key: newGUID(),
          isDragDisabled: true,
          content: paddingElement,
        });
    }

    return itemsArr;
  }, [draggableItems, paddingAmount, paddingElement]);

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId={id} direction={layout}>
        {(provider) => (
          <div ref={provider.innerRef} {...provider.droppableProps} className={dropBoxContainerClass}>
            {paddedItems.map((item, index) => (
              <Draggable key={item.key} draggableId={item.key} index={index} isDragDisabled={item.isDragDisabled}>
                {(provider) => (
                  <div
                    ref={provider.innerRef}
                    {...provider.draggableProps}
                    {...provider.dragHandleProps}
                    className={clsx(styles["draggableElement"], draggableContainerClass)}
                  >
                    {!item.isDragDisabled && (
                      <div className={styles["trash-icon"]} onClick={() => handleRemoveItem(item.key)}>
                        <FontAwesomeIcon icon={faTimes} />
                      </div>
                    )}
                    {item.content}
                  </div>
                )}
              </Draggable>
            ))}
            {provider.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
export default DraggableList;
