import styles from "./ShiftCard.module.css";
import { useFetch } from "../../hooks/useFetch";
import { useStyling } from "src/shiftly-ui";

export const ShiftCard = ({ shift = {}, setShowDetails = () => {} }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  /************************************** Queries ************************************** */
  const {
    data: [filledShift = {}],
  } = useFetch({
    request: {
      id: "Shift.GetAdditionalShiftDetailsForShiftCard",
      entity: "Shift",
      method: "getWithCalculatedFields",
      criteria: {
        _id: shift?._id,
      },
      data: {
        user: shift?.user,
      },
    },
  });

  return (
    <>
      <div className={styling("shift-card")} onClick={() => setShowDetails(filledShift)}>
        <h4>
          {filledShift?.day_of_week} {filledShift?.prettyDate}, {filledShift?.position?.name}
        </h4>
        <p>
          <span>{filledShift?.shiftPeriod}</span>
          <span>{filledShift?.timezone}</span>
        </p>
        <p className={styling("shift-location")}>{filledShift?.location?.name}</p>
        <p className={styling("shift-address")}>
          {filledShift?.address?.full_address || filledShift?.location?.address?.full_address}
        </p>
      </div>
    </>
  );
};

export default ShiftCard;
