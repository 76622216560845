import styles from "./SearchAndDisplayCard.module.scss";
import { useStyling } from "src/shiftly-ui";

const SearchAndDisplayCard = ({ children, noHover, ...props }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  return (
    <div className={styling("container", noHover && "no-hover")} {...props}>
      {children}
    </div>
  );
};

export default SearchAndDisplayCard;
