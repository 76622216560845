import { withInputBase } from "../HOC/withInputBase";
import styles from "./Dropdown.module.scss";
import { useStyling } from "../../hooks/useStyling";
import { withInputWrapper } from "../HOC/withInputWrapper";
import { useState, useRef, useEffect, useCallback, forwardRef, useImperativeHandle, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { truncate } from "../../utility/format.js";

export const Dropdown = withInputBase(
  withInputWrapper(
    forwardRef(
      (
        {
          value = [],
          setValue,
          disabled,
          formProps,
          onChange,
          defaults,
          placeholder = "Select options",
          options: overrideOptions,
          renderOption,
          disabledFunc = () => false,
          specialButton = null,
          enableSearch = true,
          multiSelect = false,
          truncateLength = 50,
        },
        ref
      ) => {
        /*************************************** State *************************************** */
        const [selectedValues, setSelectedValues] = useState(multiSelect ? [] : "");
        const [isOpen, setIsOpen] = useState(false);
        const [focusedIndex, setFocusedIndex] = useState(-1);
        const [searchQuery, setSearchQuery] = useState("");
        const [inputFocused, setInputFocused] = useState(false);

        /*************************************** Hooks *************************************** */
        const styling = useStyling(styles);

        /********************************** Refs & Constants ********************************* */
        const dropdownRef = useRef();
        const containerRef = useRef();

        /******************************** Functions & Memos ********************************** */
        const options = useMemo(() => overrideOptions ?? defaults.options ?? [], [overrideOptions, defaults]);
        const filteredOptions = useMemo(
          () =>
            searchQuery === "" || !inputFocused
              ? options
              : options.filter((option) => option?.label?.toLowerCase().includes(searchQuery?.toLowerCase())),
          [options, searchQuery, inputFocused]
        );

        const handleOptionClick = useCallback(
          (option) => {
            if (disabled) return;
            setInputFocused(false);
            if (multiSelect) {
              const isSelected = selectedValues.some((val) => val?.value === option?.value);

              const newSelectedValues = isSelected
                ? selectedValues.filter((val) => val?.value !== option?.value)
                : [...selectedValues, option];

              const newval = newSelectedValues.map((val) => val?.value);
              const newDisplayVal = newSelectedValues.map((val) => val.chip ?? val?.label).join(", ");
              setSelectedValues(newSelectedValues);
              setValue(newval);
              setSearchQuery(newDisplayVal);
              onChange?.(newval);

              const e = {
                target: {
                  value: newval,
                },
              };

              formProps?.onChange?.(e);
            } else {
              setSelectedValues(option);
              setValue(option?.value);
              setSearchQuery(option.label);
              setIsOpen(false);
              onChange?.(option?.value);

              const e = {
                target: {
                  value: option?.value,
                },
              };

              formProps?.onChange?.(e);
            }
          },
          [setValue, selectedValues, multiSelect, disabled, onChange, formProps]
        );

        const handleKeyDown = useCallback(
          (e) => {
            if (disabled) return;
            if (e.key === "ArrowDown") {
              setIsOpen(true);
              setFocusedIndex((prevIndex) => (prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : prevIndex));
            } else if (e.key === "ArrowUp") {
              setFocusedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
            } else if (e.key === "Enter" && focusedIndex >= 0) {
              e.preventDefault();
              handleOptionClick(filteredOptions[focusedIndex]);
            } else if (e.key === "Escape") {
              setIsOpen(false);
              setFocusedIndex(-1);
            }
          },
          [focusedIndex, filteredOptions, handleOptionClick, disabled]
        );

        /******************************** Effects & Handles ********************************** */
        useEffect(() => {
          if (multiSelect) {
            const valueArr = Array.isArray(value) ? value : [value];
            const selected = valueArr.map((val) => options.find((opt) => opt.value === val)).filter((val) => val);
            setSelectedValues(selected);
            setSearchQuery(selected?.map((val) => val?.chip ?? val?.label).join(", ") || "");
          } else {
            const selected = options.find((opt) => opt.value?.toString() === value?.toString());
            setSelectedValues(selected);
            setSearchQuery(selected?.label || "");
          }
        }, [value, options, multiSelect]);

        useEffect(() => {
          function handleClickOutside(event) {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
              setIsOpen(false);
              setFocusedIndex(-1);
            }
          }
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [containerRef]);

        useEffect(() => {
          if (isOpen && focusedIndex >= 0) {
            const focusedElement = dropdownRef.current?.children[focusedIndex];
            focusedElement?.scrollIntoView({ block: "nearest" });
          }
        }, [focusedIndex, isOpen]);

        return (
          <div className={styling("w-100", "text-left", "pr-4")} ref={containerRef}>
            <input {...formProps} style={{ visibility: "hidden", position: "absolute", opacity: 0 }} />
            {specialButton && (
              <div className={styling("special-btn")} onClick={specialButton.action}>
                <FontAwesomeIcon icon={specialButton.icon} />
              </div>
            )}
            <div onKeyDown={handleKeyDown} role="button" aria-expanded={isOpen}>
              <FontAwesomeIcon
                icon={faChevronDown}
                className={styling("chevron", isOpen && "chev-open")}
                onClick={(e) => {
                  if (disabled) return;
                  e.stopPropagation();
                  setIsOpen((prev) => !prev);
                }}
              />

              <div
                className={styling("dropdown")}
                ref={dropdownRef}
                onClick={() => {
                  if (disabled) return;
                  setIsOpen((prev) => !prev);
                  setSearchQuery("");
                }}
              >
                {enableSearch ? (
                  <input
                    className={styling("search-input")}
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    disabled={disabled}
                    onChange={(e) => {
                      setSearchQuery(e.target?.value);
                      setIsOpen(true);
                    }}
                    onBlur={(e) => {
                      setTimeout(() => {
                        const option = options.find(
                          (opt) =>
                            opt.label?.toString()?.toLowerCase() === e.target?.value?.toString()?.toLocaleLowerCase()
                        );
                        if (option) {
                          handleOptionClick(option);
                        } else if (selectedValues && !isOpen) {
                          setSearchQuery(
                            multiSelect
                              ? selectedValues?.map((val) => val.chip ?? val?.label).join(", ")
                              : selectedValues?.label
                          );
                        }
                        setInputFocused(false);
                      }, 200);
                    }}
                    onFocus={() => setInputFocused(true)}
                    ref={ref}
                  />
                ) : (
                  <div className={styling("selected-values")}>
                    {multiSelect ? (
                      selectedValues.length > 0 ? (
                        truncate(
                          selectedValues.map((val) => (
                            <p key={val?.value} className={styling("selected-value")}>
                              {val.label}
                            </p>
                          )),
                          truncateLength
                        )
                      ) : (
                        <p className={styling("placeholder")}>{placeholder}</p>
                      )
                    ) : (
                      truncate(selectedValues?.label, truncateLength) ?? (
                        <p className={styling("placeholder")}>{placeholder}</p>
                      )
                    )}
                  </div>
                )}
              </div>

              <ul className={styling("dropdown-menu", isOpen && "isOpen")} ref={dropdownRef}>
                {filteredOptions.map((option, index) => (
                  <li
                    key={index}
                    className={styling(
                      "option",
                      multiSelect
                        ? selectedValues.some((val) => val?.value === option?.value) && "selected-option"
                        : selectedValues?.value === option.value && "selected-option",
                      focusedIndex === index && "focused-option",
                      disabledFunc(option) && "disabled"
                    )}
                    onClick={(e) => {
                      e.stopPropagation();
                      !disabledFunc(option) && handleOptionClick(option);
                    }}
                    role="option"
                    aria-selected={
                      multiSelect
                        ? selectedValues.some((val) => val?.value === option?.value)
                        : selectedValues?.value === option.value
                    }
                  >
                    {renderOption ? renderOption(option) : option.label}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        );
      }
    )
  )
);
