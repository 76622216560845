import styles from "./ApplicantProfileShiftHistory.module.scss";
import {
  Each,
  formatDateToPrettyTime,
  normaliseID,
  RatingDisplay,
  truncate,
  useFetch,
  useStyling,
} from "src/shiftly-ui";

const ApplicantProfileShiftHistory = ({ user }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  /************************************** Queries ************************************** */
  const { data: shiftHistory } = useFetch({
    request: {
      entity: "ShiftHistory",
      method: "get",
      criteria: {
        user: normaliseID(user),
      },
      populate: [
        "location",
        "rating",
        "shift",
        {
          path: "position",
          populate: "group",
        },
      ],
    },
    dependency: normaliseID(user),
  });
  /******************************** Effects & Handles ********************************** */

  if (!shiftHistory.length) return null;
  return (
    <div className={styling("mt-4")}>
      <h6>Shifts Completed</h6>
      <div className={styling("cards", "mt-1")}>
        <Each of={shiftHistory} render={({ key, ...shift }) => <ShiftCard key={key} {...shift} />} />
      </div>
    </div>
  );
};

const ShiftCard = ({ location, shift, position, rating }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  return (
    <div className={styling("card")}>
      <div className={styling("location-profile")}>
        <img src={location?.logo} alt="Location Profile" className={styling("cover")} />
      </div>
      <p className={styling("my-2")}>
        <strong>{truncate(location?.name, 20)}</strong>
      </p>
      <p className={styling("my-1")}>{formatDateToPrettyTime(shift?.start_time)}</p>
      <p className={styling("mb-1")}>
        <strong>{position?.group?.name}</strong>
      </p>
      <RatingDisplay rating={rating?.rating} />
    </div>
  );
};

export default ApplicantProfileShiftHistory;
