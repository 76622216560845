import { withInputBase } from "../HOC/withInputBase";

import { useStyling } from "../../hooks/useStyling";
import { withInputWrapper } from "../HOC/withInputWrapper";
import { forwardRef } from "react";

const roundMinutes = (time) => {
  const [hours, minutes] = time.split(":").map(Number);
  const roundedMinutes = Math.round(minutes / 15) * 15;
  return `${String(hours).padStart(2, "0")}:${String(roundedMinutes).padStart(2, "0")}`;
};

export const TimeSelector = withInputBase(
  withInputWrapper(
    forwardRef(
      (
        {
          formProps,
          type = "text",
          value,
          setValue,
          submitOnEnter,
          validate,
          defaults: { validate: defaultValidate, required: defaultRequired, ...defaults },
          required,
          specialButton,
          ...props
        },
        ref
      ) => {
        /*************************************** Hooks *************************************** */
        const styling = useStyling();

        return (
          <div className={styling("w-100")}>
            <input
              ref={ref}
              type="time"
              {...defaults}
              {...props}
              {...formProps}
              onBlur={(e) => {
                const roundedTime = roundMinutes(e.target.value);
                setValue(roundedTime);
              }}
              placeholder="HH:MM"
              step="60"
              style={{
                color: "var(--secondary-900) !important",
              }}
            />
          </div>
        );
      }
    )
  )
);
