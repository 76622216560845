import {
  useFetch,
  useSearchParams,
  useCachedQuery,
  ShiftlySuspense,
  useToast,
  useAuth,
  normaliseID,
} from "src/shiftly-ui";
import useIntegration from "src/hooks/useIntegration";
import XeroIntegrationBase from "./components/Xero/XeroIntegrationBase";
import { useRef, useState } from "react";
import AlreadyConnected from "./components/Xero/AlreadyConnected";

const Xero = () => {
  /*************************************** State *************************************** */
  const [alreadyConnected, setAlreadyConnected] = useState();
  const [activeStep, setActiveStep] = useState(1);
  /*************************************** Hooks *************************************** */
  const { account } = useAuth();
  const { activeIntegration, refetch, isLoading: fetchingIntegration } = useIntegration("xero");
  const toast = useToast();
  const {
    Integration: { GetXeroElements },
  } = useCachedQuery();

  /********************************** Refs & Constants ********************************* */
  const connectedRef = useRef(false);

  /************************************** Queries ************************************** */
  const { data: link } = useFetch({
    request: {
      entity: "Integration",
      method: "buildConsentUrl",
      data: {
        type: "xero",
        account_id: normaliseID(account),
      },
    },
    dependency: account?._id,
  });

  //Get all businesses and xero organisations
  const { data: xeroOrganisations, isLoading } = useFetch({
    request: {
      entity: "XeroOrganisation",
      method: "get",
      id: GetXeroElements,
    },
    dependency: activeIntegration?._id,
    options: {
      enabled: Boolean(activeIntegration),
    },
  });

  /******************************** Functions & Memos ********************************** */
  const {
    post: createIntegration,
    isLoading: initIsLoading,
    refresh,
  } = useFetch({
    options: {
      onSuccess: () => {
        refetch();
        refresh(GetXeroElements);
        setActiveStep(2);
      },
      onError: ({ error }) => {
        if (error.code === 409) setAlreadyConnected(error.value || true);
        toast.error("We could not connect to Xero. Please try again.");
      },
    },
  });

  /******************************** Effects & Handles ********************************** */
  useSearchParams(
    ["code", "state"],
    ({ code, state }) => {
      if (code && !fetchingIntegration && !connectedRef.current && account?._id) {
        connectedRef.current = true;
        createIntegration({
          entity: "Integration",
          method: "init",
          data: {
            account_id: normaliseID(account),
            type: "xero",
            state,
            urlPath: window.location.href,
          },
        });
      }
    },
    [activeIntegration, account, fetchingIntegration]
  );

  return (
    <ShiftlySuspense loading={fetchingIntegration || initIsLoading}>
      <AlreadyConnected show={!!alreadyConnected} setShow={setAlreadyConnected} organisationName={alreadyConnected} />
      <XeroIntegrationBase
        initIsLoading={initIsLoading}
        isLoading={isLoading}
        connectionLink={link}
        activeIntegration={activeIntegration}
        xeroOrganisations={xeroOrganisations ?? []}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />
    </ShiftlySuspense>
  );
};

export default Xero;
