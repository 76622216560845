import React, { useCallback, useMemo } from "react";
import usePositions from "src/hooks/usePositions";
import { useScheduler } from "src/contexts/Scheduler";
import styles from "./SchedulerCalendarGrid.module.scss";
import { useStyling, Each, normaliseID } from "src/shiftly-ui";
import SchedulerPositionRow from "./SchedulerPositionRow";

const SchedulerCalendarGrid = React.memo((props) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const { shifts = [], applications = [], isLoading, thirdPartyShifts = [], internalShifts = [] } = useScheduler();
  const { groupedPositions } = usePositions();
  /********************************** Refs & Constants ********************************* */

  const sortedPositionsMap = useMemo(() => {
    return Object.entries(groupedPositions)
      .filter(([id, positions]) => positions.some((p) => p.is_active))
      .map(([group, positions]) => {
        return {
          group,
          sortedPositions: positions.sort((a, b) => a.classification_level - b.classification_level),
          firstPosition: positions[0],
        };
      });
  }, [groupedPositions]);

  const groupShiftsMap = useMemo(() => {
    return sortedPositionsMap.map(({ group }) => ({
      group,
      groupExternalShifts: thirdPartyShifts.filter((s) => s.group === group),
      groupInternalShifts: internalShifts.filter((s) => s.position_group === group),
      groupShifts: shifts.filter((s) => s.position_group === group),
    }));
  }, [sortedPositionsMap, shifts, thirdPartyShifts, internalShifts]);

  const renderPositionRow = useCallback((position, groupShifts, applications) => {
    const filteredShifts = groupShifts.filter((shift) => normaliseID(shift.position) === normaliseID(position));
    const filteredApplicants = applications.filter((app) =>
      filteredShifts.some((shift) => normaliseID(shift) === normaliseID(app.shift))
    );

    return (
      <SchedulerPositionRow
        key={position?._id}
        position={position}
        shifts={filteredShifts}
        applicants={filteredApplicants}
      />
    );
  }, []);

  const memoizedSchedulerPositionRow = useMemo(() => {
    return sortedPositionsMap.map(({ group, sortedPositions, firstPosition }) => {
      const { groupShifts, groupInternalShifts, groupExternalShifts } =
        groupShiftsMap.find((g) => g.group === group) || {};

      return (
        <div className={styling("container")} key={normaliseID(group)}>
          <p className={styling("mb-1")}>
            <strong>{firstPosition?.name}</strong>
          </p>
          <Each of={sortedPositions} render={(position) => renderPositionRow(position, groupShifts, applications)} />

          {/* Internal Shifts */}
          <SchedulerPositionRow
            key={`internal-${group}`}
            position={{ type: "internal", group: firstPosition?.group }}
            shifts={groupInternalShifts}
            applicants={[]}
          />

          {/* Third Party Shifts */}
          <SchedulerPositionRow
            key={`thirdParty-${group}`}
            position={{ type: "external" }}
            shifts={groupExternalShifts}
            applicants={[]}
          />

          {/* Additive Row */}
          <SchedulerPositionRow
            key={`${firstPosition?._id}_additive`}
            position={firstPosition}
            shifts={[]}
            applicants={[]}
            additive
          />
        </div>
      );
    });
  }, [sortedPositionsMap, groupShiftsMap, applications, renderPositionRow, styling]);

  return <div style={{ opacity: isLoading ? 0.5 : 1 }}>{memoizedSchedulerPositionRow}</div>;
});
export default SchedulerCalendarGrid;
