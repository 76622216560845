import clsx from "clsx";
import styles from "./ButtonGroup.module.scss";
import { useStyling } from "src/shiftly-ui";

export const ButtonGroup = ({ children, noBottomPadding, className, ...props }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  return (
    <div className={clsx(styling("container", noBottomPadding && "noBottomPadding"), className)} {...props}>
      {children}
    </div>
  );
};
