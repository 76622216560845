import clsx from "clsx";
import { useStyling } from "../../hooks/useStyling";
import styles from "./Tooltip.module.css";

export const Tooltip = ({ title, text, position, children, className, style }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  return (
    <div className={clsx(styling("container"), className)}>
      <div className={clsx(styling("tooltip-container"), styling(position))} style={style}>
        <div className={clsx(styling("tooltip-arrow"), styling(position))}></div>
        <div className={styling("tooltip-content")}>
          <h6>{title}</h6>
          <p>{text}</p>
        </div>
      </div>
      {children}
    </div>
  );
};
