import { useUI, Authentication, useDisplay, useAuth, FunctionalWrapper, ErrorBoundary } from "src/shiftly-ui";
import { Route, Routes, useLocation } from "react-router-dom";
import BusinessHome from "./pages/business/BusinessHome";
import PositionsHome from "./pages/app/positions/PositionsHome";
import LocationsHome from "./pages/business/business_account/BusinessAccountLocationsHome";
import MessagesHome from "./pages/app/messages/MessagesHome";
import {
  BusinessAccountHome,
  BusinessAccountBusinessDetails,
  BusinessAccountBillingAndPayment,
  BusinessAccountPaymentHistory,
} from "./pages/business";
import Main from "./components/Main";
import { AccountSettings, AccountSettingsYourDetails } from "./pages/settings";
import AccountSettingsPassword from "./pages/settings/AccountSettingsPassword";
import BusinessAccountLocationEdit from "./pages/business/business_account/BusinessAccountLocationEdit";
import Scheduler from "./pages/app/shifts/scheduler/Scheduler";
import BusinessWrapper from "./components/BusinessWrapper";
import MobileShifts from "./pages/app/shifts/mobile_shifts/MobileShifts";
import IntegrationsHome from "./pages/business/integrations/IntegrationsHome";
import Deputy from "./pages/business/integrations/pages/Deputy";
import PositionLevels from "./pages/app/positions/PositionLevels/PositionLevels";
import People from "./pages/app/people/People";
import MobileAccount from "./pages/settings/MobileAccount";
import { SchedulerContextProvider } from "src/contexts/Scheduler";
import useShiftlyLocation from "./hooks/useShiftlyLocation";
import Signup from "./pages/public/authentication/Signup";
import Login from "./pages/public/authentication/Login";
import ResetPassword from "./pages/public/authentication/ResetPassword";
import Xero from "./pages/business/integrations/pages/Xero";
import PricingHome from "./pages/business/pricing/PricingHome";
import PricingScheduler from "./pages/business/pricing/PricingScheduler";
import PricingOnDemand from "./pages/business/pricing/PricingOnDemand";
import PricingOnboarding from "./pages/business/pricing/PricingOnboarding";
import ManageStaffMember from "./pages/app/people/ManageStaffMember";
import Onboarding from "./pages/public/onboarding/Onboarding";
import Status from "./pages/public/onboarding/Status";
import Confirm from "./pages/public/authentication/Confirm";
import ApplicantProfile from "./pages/app/people/ApplicantProfile";
import AccountSettingsOrganisationSettings from "./pages/settings/AccountSettingsOrganisationSettings";
import AccountSettingsUserAccess from "./pages/settings/AccountSettingsUserAccess";
import NotFound404 from "./pages/public/404/NotFound404";
import Managers from "./pages/business/managers/Managers";
import Timesheets from "./pages/app/timesheets/Timsheets";
import XeroPricing from "./pages/business/integrations/pages/components/Xero/XeroPricing";
import TestPage from "./components/temp/TestPage";

const App = () => {
  const { mode } = useUI();
  const location = useLocation();
  const { isMobile } = useDisplay();
  const { user } = useAuth();
  const { activeLocation } = useShiftlyLocation();

  return (
    <ErrorBoundary>
      <FunctionalWrapper init_id={activeLocation?._id ?? user?._id}>
        <Main>
          {/* Public Routes */}
          <Routes location={location} key={location.pathname}>
            <Route
              path="/portal"
              exact
              element={
                <Authentication signup={<Signup />} login={<Login />} reset={<ResetPassword />} confirm={<Confirm />} />
              }
            />
            <Route path={"/onboarding/status"} exact element={<Status />} />
            <Route path={"/onboarding/:token"} element={<Onboarding />} />
          </Routes>

          {mode === "owner" && (
            <BusinessWrapper>
              <Routes location={location} key={location.pathname}>
                <Route path="/" exact element={<BusinessHome />} />
                <Route path={"/test"} element={<TestPage />} />
                {/* Main Routes */}
                <Route
                  path="/shifts"
                  exact
                  element={
                    <SchedulerContextProvider>
                      <Scheduler />
                    </SchedulerContextProvider>
                  }
                />
                <Route path="/mobile-shifts" exact element={<MobileShifts />} />
                <Route path="/people" exact element={<People />} />
                <Route path="/people/:user" exact element={<ApplicantProfile />} />
                <Route path="/people/internal" exact element={<ManageStaffMember />} />
                <Route path="/timesheets" exact element={<Timesheets />} />
                <Route path="/positions" exact element={<PositionsHome />} />
                <Route path="/positions/position-levels/:group" exact element={<PositionLevels />} />
                <Route path="/messaging" exact element={<MessagesHome />} />

                {/* Business Account */}
                {isMobile ? (
                  <Route path="/business-account/*" element={<MobileAccount />} />
                ) : (
                  <>
                    <Route path="/business-account" exact element={<BusinessAccountHome />} />
                    <Route
                      path="/business-account/business-details"
                      exact
                      element={<BusinessAccountBusinessDetails />}
                    />
                    <Route path="/business-account/locations" exact element={<LocationsHome />} />
                    <Route path="/business-account/managers" exact element={<Managers />} />
                    <Route
                      path="/business-account/locations/edit-location"
                      exact
                      element={<BusinessAccountLocationEdit />}
                    />
                    <Route
                      path="/business-account/billing-and-payment"
                      exact
                      element={<BusinessAccountBillingAndPayment />}
                    />
                    <Route
                      path="/business-account/billing-and-payment/payment-history"
                      exact
                      element={<BusinessAccountPaymentHistory />}
                    />
                  </>
                )}

                {/* Pricing */}
                <Route path="/business-account/billing-and-payment/pricing" exact element={<PricingHome />} />
                <Route
                  path="/business-account/billing-and-payment/pricing/scheduler"
                  exact
                  element={<PricingScheduler />}
                />
                <Route
                  path="/business-account/billing-and-payment/pricing/ondemand"
                  exact
                  element={<PricingOnDemand />}
                />
                <Route
                  path="/business-account/billing-and-payment/pricing/onboarding"
                  exact
                  element={<PricingOnboarding />}
                />

                {/* Integrations */}
                <Route path="/integrations" exact element={<IntegrationsHome />} />
                <Route path="/integrations/deputy" exact element={<Deputy />} />
                <Route path="/integrations/xero" exact element={<Xero />} />
                <Route path="/integrations/xero/pricing" exact element={<XeroPricing />} />

                {/* Account */}
                <Route path="/account-management" exact element={<AccountSettings />} />
                <Route
                  path="/account-management/organisation-settings"
                  exact
                  element={<AccountSettingsOrganisationSettings />}
                />
                <Route path="/account-management/user-access" exact element={<AccountSettingsUserAccess />} />
                <Route path="/account-management/personal-details" exact element={<AccountSettingsYourDetails />} />
                <Route
                  path="/account-management/personal-details/manage-password"
                  exact
                  element={<AccountSettingsPassword />}
                />

                <Route path="*" element={<NotFound404 />} />
              </Routes>
            </BusinessWrapper>
          )}
        </Main>
      </FunctionalWrapper>
    </ErrorBoundary>
  );
};
export default App;
