import { useFetch, useStyling, roundNumber, truncate, formatDateToPrettyTime, useProfile } from "src/shiftly-ui";
import styles from "./MobileShiftCard.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/pro-solid-svg-icons";
import { faCalendar, faClock, faComment, faDollar, faUser } from "@fortawesome/pro-regular-svg-icons";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";
import moment from "moment";

const MobileShiftCard = ({ shift, isToday = false }) => {
  const { user, position, day_of_week, prettyDate, shiftPeriod, shiftTotal } = shift;
  const [showDetails, setShowDetails] = useState(false);
  const [time, setTime] = useState(0);
  const [checkInTime, setCheckInTime] = useState("");
  const styling = useStyling(styles);
  const navigate = useNavigate();
  const { profile } = useProfile();
  const {
    data: [timesheet],
  } = useFetch({
    request: {
      entity: "Timesheet",
      method: "get",
      criteria: {
        shift: shift._id,
      },
      id: "Timesheet.GetTimesheetForShift",
    },
    dependency: shift._id,
    options: {
      enabled: isToday,
    },
  });

  useEffect(() => {
    const { start_time } = shift;
    const prettyCheckInTime = formatDateToPrettyTime(moment(start_time).subtract(15, "minutes"));
    setCheckInTime("Check in opens: " + prettyCheckInTime);
    if (!timesheet) {
      setTime("0h 00m 00s");
      return;
    }
    setCheckInTime("Checked in at: " + moment(timesheet.sign_on_time).format("hh:mm A"));
    setInterval(() => {
      const { start_time } = shift;
      const currentTime = moment();
      const diff = moment.duration(currentTime.diff(start_time));
      const hours = Math.max(0, diff.hours()).toString();
      const minutes = Math.max(0, diff.minutes()).toString().padStart(2, "0");
      const seconds = Math.max(0, diff.seconds()).toString().padStart(2, "0");
      setTime(`${hours}h ${minutes}m ${seconds}s`);
    }, 1000);
  }, [shift, timesheet]);

  //Todo update Shift details to new component
  return (
    <>
      {/* <ShiftDetails showDetails={showDetails} setShowDetails={setShowDetails} minimised /> */}
      <div
        className={styling("container")}
        onClick={() => {
          setShowDetails(shift);
        }}
      >
        <div className={styling("header")}>
          <h5>
            {truncate(`${user?.first_name} ${user?.last_name}`, 20)}
            <span>
              {profile?.rating} <FontAwesomeIcon icon={faStar} />
            </span>
          </h5>
          <div className={styling("header-buttons")}>
            <div
              className={styling("header-btn")}
              onClick={(e) => {
                e.stopPropagation();
                navigate("/people/" + user?._id);
              }}
            >
              <FontAwesomeIcon icon={faUser} />
            </div>
            <div
              className={styling("header-btn")}
              onClick={(e) => {
                e.stopPropagation();
                navigate("/messaging?user=" + user?._id);
              }}
            >
              <FontAwesomeIcon icon={faComment} />
            </div>
          </div>
        </div>
        <div className={styling("body")}>
          <div className={styling("body-row")}>
            <div className={styling("body-item")}>
              <FontAwesomeIcon icon={faUser} />
              <p>{position?.name}</p>
            </div>
            <div className={styling("body-item")}>
              <FontAwesomeIcon icon={faCalendar} />
              <p>
                {day_of_week} {prettyDate}
              </p>
            </div>
          </div>
          <div className={styling("body-row")}>
            <div className={styling("body-item")}>
              <FontAwesomeIcon icon={faDollar} />
              <p>${roundNumber(shiftTotal)}</p>
            </div>
            <div className={styling("body-item")}>
              <FontAwesomeIcon icon={faClock} />
              <p>{shiftPeriod}</p>
            </div>
          </div>
        </div>
        {isToday && (
          <div className={styling("footer")}>
            {timesheet ? (
              <div className={styling("checked-in")}>
                <p>{checkInTime}</p>
                <div className={styling("timer")}>
                  <p>{time}</p>
                </div>
              </div>
            ) : (
              <div className={styling("pin-container")}>
                <p>PIN for this shift</p>
                <div className={styling("pins")}>
                  {shift?.pin
                    ?.toString()
                    .split("")
                    .map((pin, index) => (
                      <div key={index} className={styling("pin")}>
                        {pin}
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default MobileShiftCard;
