import { Each, getDayOfWeek, getMonthShortName, useStyling, useToday } from "src/shiftly-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { motion } from "framer-motion";
import { throttle } from "lodash";
import { useScheduler } from "src/contexts/Scheduler";
import { useCallback } from "react";
import styles from "./SchedulerCalendarNav.module.scss";

const SchedulerCalendarNav = () => {
  /*************************************** Hooks *************************************** */
  const { periods, direction, setDirection, duration, setIsLoading, setDuration, handleNextPeriod, handlePrevPeriod } =
    useScheduler();
  const styling = useStyling(styles);

  /******************************** Functions & Memos ********************************** */
  const handleSetDirection = throttle((newDirection) => {
    setIsLoading(true);
    setDirection(newDirection);
  }, 500);

  const onComplete = useCallback(() => {
    setDuration(0);
    setDirection(0);

    if (direction === "100%") {
      handlePrevPeriod();
    } else if (direction === "-100%") {
      handleNextPeriod();
    }

    setTimeout(() => {
      setDuration(0.5);
      setIsLoading(false);
    }, 100);
  }, [direction, handleNextPeriod, handlePrevPeriod, setDirection, setIsLoading, setDuration]);

  return (
    <div className={styling("w-100", "position-relative")}>
      <div className={styling("control", "control-left")} onClick={() => handleSetDirection("100%")}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
      <div className={styling("dates")}>
        <motion.div
          animate={{ x: direction === "100%" ? direction : 0 }}
          transition={{ duration }}
          className={styling("date", "prev")}
        >
          <DateFields list={periods.prev} />
        </motion.div>
        <motion.div
          onAnimationComplete={onComplete}
          animate={{ x: direction }}
          transition={{ duration }}
          className={styling("date", "current")}
        >
          <DateFields list={periods.current} />
        </motion.div>
        <motion.div
          animate={{ x: direction === "-100%" ? direction : 0 }}
          transition={{ duration }}
          className={styling("date", "next")}
        >
          <DateFields list={periods.next} />
        </motion.div>
      </div>
      <div className={styling("control", "control-right")} onClick={() => handleSetDirection("-100%")}>
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    </div>
  );
};

const DateFields = ({ list }) => {
  const styling = useStyling(styles);
  const { today, yesterday } = useToday();

  return (
    <>
      <div className={styling("header-column")}></div>
      <Each
        of={list.map((date) => ({
          dayOfWeek: getDayOfWeek(date),
          dayOfMonth: date.getDate(),
          month: getMonthShortName(date),
          year: date.getFullYear(),
          date,
        }))}
        render={(date, index) => {
          const isToday = date.date.toDateString() === today.toDateString();
          const isLessThanToday = date.date < yesterday;
          return (
            <div key={index} className={styling("date-section")}>
              <p className={styling("label", "text-center", isToday && "orange", isLessThanToday && "disabled-text")}>
                <strong>
                  {date.dayOfWeek} {date.dayOfMonth}
                </strong>
              </p>
            </div>
          );
        }}
      />
    </>
  );
};

export default SchedulerCalendarNav;
