import { useDispatch, useSelector } from "react-redux";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useFetch } from "./useFetch";
import { useUI } from "./useUI";
import { useDisplay } from "./useDisplay";
import { authActions } from "../redux/authSlice";
import { uiActions } from "../redux/uiSlice";
import { useCachedQuery } from "./useCacheQuery";
import { normaliseID } from "src/shiftly-ui";

export const useAuth = () => {
  /*************************************** Hooks *************************************** */
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const UI = useSelector((state) => state.ui);
  const navigate = useNavigate();
  const { isMobile } = useDisplay();
  const { startLoading, stopLoading, updateSetting } = useUI();
  const {
    Auth: { GetAccountDetails },
  } = useCachedQuery();

  /******************************** Functions & Memos ********************************** */
  const createFetchOptions = useCallback(
    (actionType, path) => {
      return {
        options: {
          onMutate: () => {
            startLoading(actionType);
            dispatch(authActions.setIsAuthenticated(true));
          },
          onSuccess: ({ user, setting }) => {
            dispatch(authActions[actionType](user));
            dispatch(
              uiActions.setSettings({
                ...UI.settings,
                ...setting,
              })
            );
            user && navigate(path);
            stopLoading(actionType);
          },
          onError: () => {
            stopLoading(actionType);
            dispatch(authActions.setIsAuthenticated(false));
          },
        },
      };
    },
    [UI.settings, dispatch, navigate, startLoading, stopLoading]
  );

  const logout = useCallback(() => {
    localStorage.removeItem("activeBusiness");
    localStorage.removeItem("activeLocation");
    localStorage.removeItem("shiftly-auth-token");

    startLoading("logout");
    dispatch(authActions.logout());
    navigate("/portal");
    stopLoading("logout");
  }, [dispatch, navigate, startLoading, stopLoading]);

  /************************************** Queries ************************************** */
  const { post: postLogin, isLoading: loginIsLoading } = useFetch(createFetchOptions("login", "/"));
  const { post: postSignUp, isLoading: signupIsLoading } = useFetch(createFetchOptions("signup", "/"));

  const { refetch: refreshAccount } = useFetch({
    request: {
      entity: "Account",
      method: "get",
      criteria: {
        _id: normaliseID(auth?.user?.account_id),
      },
      id: GetAccountDetails,
    },
    dependency: normaliseID(auth?.user?.account_id),
    options: {
      onSuccess: ([account]) => {
        dispatch(authActions.setAccount(account));
        dispatch(
          uiActions.updateSetting({
            setting: "isAThirdPartyProvider",
            value: account?.organisation_type === "third_party_service_provider",
          })
        );
      },
    },
  });

  return useMemo(
    () => ({
      auth,
      login: ({ email, password }, mode) =>
        postLogin({
          method: "login",
          data: { email, password, mode, device: isMobile ? "mobile" : "desktop" },
          node: "auth-node",
        }),
      signup: (data, mode) =>
        postSignUp({
          method: "signup",
          data: { ...data, mode },
          node: "auth-node",
        }),
      logout,
      isLoading: loginIsLoading || signupIsLoading,
      loginIsLoading,
      signupIsLoading,
      startLoading,
      stopLoading,
      isAdmin: normaliseID(auth?.user) === normaliseID(auth?.account?.admin),
      refreshAccount,
      ...auth,
    }),
    [
      auth,
      postLogin,
      postSignUp,
      logout,
      loginIsLoading,
      signupIsLoading,
      startLoading,
      stopLoading,
      isMobile,
      refreshAccount,
    ]
  );
};
