import React, { forwardRef, useImperativeHandle, useState } from "react";

import { useStyling, FormFlex, Input, Checkbox, OrangeSpan } from "src/shiftly-ui";
import AnimatedContainer from "../components/AnimatedContainer";
import Title from "../components/Title";
import FormContainer from "../components/FormContainer";

const valueMap = {
  FULLTIME: "Full Time",
  PARTTIME: "Part Time",
  CASUAL: "Casual",
  LABOURHIRE: "Labour Hire",
  NONEMPLOYEE: "Non-Employee",
  SALARYANDWAGES: "Salary and Wages",
  WORKINGHOLIDAYMAKER: "Working Holiday Maker",
  CLOSELYHELDPAYEES: "Closely Held Payees",
  EMPLOYEE: "Employee",
  CONTRACTOR: "Contractor",
};

const Employment = forwardRef(({ employment, setActiveTab, tax_declaration }, ref) => {
  /*************************************** State *************************************** */
  const [employmentDate, setEmploymentDate] = useState(employment?.start_date);
  /*************************************** Hooks *************************************** */
  const styling = useStyling();

  /******************************** Effects & Handles ********************************** */
  useImperativeHandle(ref, () => ({
    submit: () => {
      setActiveTab((prev) => prev + 1);
    },
  }));

  return (
    <AnimatedContainer className={styling.container}>
      <Title
        title={
          <>
            Your <OrangeSpan>Employment</OrangeSpan> Details
          </>
        }
        subtitle={
          "These fields are set by your employer. Please confirm they are correct or contact your employer if you need to make changes."
        }
      />
      <FormContainer>
        <FormFlex>
          <Input
            label={"Employment Type"}
            value={valueMap[tax_declaration.employment_type] ?? "Not Specified"}
            placeholder={"Employment Type"}
            disabled
          />
          <Input
            label={"Employment Basis"}
            value={valueMap[tax_declaration.employment_basis] ?? "Not Specified"}
            placeholder={"Employment Basis"}
            disabled
          />
          <Input
            label={"Income Type"}
            value={valueMap[tax_declaration.income_type] ?? "Not Specified"}
            placeholder={"Employment Basis"}
            disabled
          />
        </FormFlex>
        <FormFlex>
          <Input
            label={"Job Title"}
            value={employment?.job_title ?? "Not Specified"}
            placeholder={"Job Title"}
            disabled
          />
          <Input
            label={"Hourly Rate"}
            value={"$" + employment?.hourly_rate ?? "Not Specified"}
            placeholder={"0.00"}
            disabled
          />
          <Input type={"date"} label={"Start Date"} value={employmentDate} setValue={setEmploymentDate} disabled />
        </FormFlex>
        {tax_declaration?.employment_type !== "CONTRACTOR" && (
          <FormFlex>
            <Input
              label={"Award Code"}
              value={employment?.award_code || "Not specified"}
              placeholder={"Award Code"}
              disabled
            />
            <Input
              label={"Award Stream"}
              value={employment?.income_stream || "Not specified"}
              placeholder={"Award Stream"}
              disabled
            />
            <Input
              label={"Award Level"}
              value={employment?.classification_level ? "Level " + employment?.classification_level : "Not Specified"}
              placeholder={"Award Stream"}
              disabled
            />
          </FormFlex>
        )}
        <div className={styling("mt-3", "flex", "flex-column", "md-flex-row")}>
          <Checkbox label={"Authorised to approve leave"} value={employment?.is_authorised_to_approve_leave} disabled />
          <Checkbox
            label={"Authorised to approve timesheets"}
            value={employment?.is_authorised_to_approve_timesheets}
            disabled
          />
          <Checkbox
            label={"Eligible to receive leave loading"}
            value={tax_declaration?.eligible_to_receive_leave_loading}
            disabled
          />
        </div>
      </FormContainer>
    </AnimatedContainer>
  );
});

export default Employment;
