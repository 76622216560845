import Illustration from "../decorative/Illustration";
import styles from "./Fallback.module.scss";
import { useStyling, OrangeSpan, Link } from "src/shiftly-ui";

const Fallback = () => {
  const styling = useStyling(styles);

  return (
    <div className={styling("container")}>
      <Illustration image="505.webp" />
      
      <h1 className={styling("my-4")}>
        Oops, we <OrangeSpan>broke</OrangeSpan> the thing.
      </h1>

      <p>
        <strong>500:</strong> Our servers are having a moment.
      </p>
      <p>Nothing to worry about. We’ll sweep it up and reboot the vibes.</p>

      <p className={styling("mt-4")}>
        If you have an urgent matter, please{" "}
        <Link to={process.env.REACT_APP_SHIFTLY_WEBSITE + "contact"}>contact us</Link> for help.
      </p>
    </div>
  );
};

export default Fallback;
