import { useUncontrolled } from "uncontrollable";
import styles from "./TabHeader.module.scss";
import { Button, useStyling } from "src/shiftly-ui";
import { Each } from "../layout/Each";
import clsx from "clsx";

const TabHeader = ({ tabs = [], rightActions = [], leftActions = [], ...props }) => {
  /*************************************** State *************************************** */
  const { tab = 0, setTab } = useUncontrolled(props, {
    tab: "setTab",
  });
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  /********************************** Refs & Constants ********************************* */

  /******************************** Functions & Memos ********************************** */

  /************************************** Queries ************************************** */

  /******************************** Effects & Handles ********************************** */

  return (
    <div className={styling("container")}>
      <div className={styling("left")}>
        <div className={styling("tabs-wrapper", "mr-3")}>
          <div className={styling("tabs")}>
            <Each
              of={tabs}
              render={({ key, ...props }, index) => (
                <Tab key={key ?? index} index={index} active={index === tab} setTab={setTab} {...props} />
              )}
            />
          </div>

          <div
            className={styling("slider-container")}
            style={{
              width: tabs?.length * 180 + "px",
            }}
          >
            <div
              className={styling("slider")}
              style={{
                left: `${tab * 180}px`,
                width: `${180}px`,
              }}
            ></div>
          </div>
        </div>

        {leftActions.map(({ text, className, ...buttonProps }, index) => (
          <Button key={index} {...buttonProps} className={clsx(styling("left-action", "action-button"), className)}>
            {text}
          </Button>
        ))}
      </div>
      <div className={styling("right")}>
        {rightActions.map(({ text, className, ...buttonProps }, index) => (
          <Button key={index} {...buttonProps} className={clsx(styling("right-action", "action-button"), className)}>
            {text}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default TabHeader;

const Tab = ({ text, active, setTab, count = 0, icon, index }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  return (
    <div className={clsx(styling("tab", active && "active"))} onClick={() => setTab(index)}>
      <div className={styling(icon && "with-icon", "flex", "flex-row")}>
        {text} {count > 0 && `(${count})`} {icon}
      </div>
    </div>
  );
};
