import { useAuth, useDisplay, useUI, useStyling } from "src/shiftly-ui";
import { useEffect } from "react";
import styles from "./Main.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import NewShiftModal from "src/pages/app/shifts/components/new_shift_modal/NewShiftModal";
import BusinessNavigation from "./business_navigation/BusinessNavigation";

const publicPaths = ["portal", "onboarding"];

const Main = ({ children }) => {
  /*************************************** Hooks *************************************** */
  const {
    auth: { isAuthenticated },
  } = useAuth();
  const { loading } = useUI();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isMobile, isStandalone } = useDisplay();
  const styling = useStyling(styles);

  /******************************** Effects & Handles ********************************** */
  useEffect(() => {
    // Redirect to App Store if user is on browser version of the app
    if (process.env.REACT_APP_SHIFTLY_ENV === "LIVE" && isMobile && !isStandalone)
      window.location.href = process.env.REACT_APP_HOST_APPSTORE_URL;
  }, [isMobile, isStandalone]);

  useEffect(() => {
    if (loading || publicPaths.some((path) => pathname.includes(path))) {
      return;
    }
    if (!isAuthenticated) {
      navigate("/portal?page=signup");
    }
  }, [isAuthenticated, navigate, loading, pathname]);

  return (
    <div className={styling("container")} id="main">
      <BusinessNavigation />
      <NewShiftModal />
      <div className={styling("content-wrapper")}>{children}</div>
    </div>
  );
};

export default Main;
