import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./NotificationWithLink.module.css";
import { faCheckDouble } from "@fortawesome/pro-solid-svg-icons";
import placeholderImage from "../../assets/png/no_img_placeholder.jpeg";
import { Link } from "../buttons/Link";
import { useNavigate } from "react-router-dom";
import { truncate } from "../../utility/format.js";
import { useStyling } from "src/shiftly-ui";

export const NotificationWithLink = ({ title, icon, link = {}, text }) => {
  /*************************************** Hooks *************************************** */
  const navigate = useNavigate();
  const styling = useStyling(styles);

  return (
    <div className={styling("container")} onClick={() => navigate(link?.url)}>
      <div className={styling("image-container")}>
        <img src={icon || placeholderImage} alt="" className={styling("image")} />
        <div className={styling("checks")}>
          <FontAwesomeIcon icon={faCheckDouble} />
        </div>
      </div>
      <div className={styling("content")}>
        <div className={styling("body")}>
          <p>
            <strong>{title}: </strong>
            {truncate(text, 50)}
          </p>
        </div>
        <div className={styling("link")}>
          <Link to={link.url}>{link.text}</Link>
        </div>
      </div>
    </div>
  );
};
