import { forwardRef, useCallback, useRef, useImperativeHandle, useState } from "react";
import { Button } from "../buttons/Button";
import { useFetch } from "../../hooks/useFetch";
import { useToast } from "../../hooks/useToast";
import { withInputBase } from "../HOC/withInputBase";
import { useStyling, OrangeSpan } from "src/shiftly-ui";

export const FileUploader = withInputBase(
  forwardRef(
    (
      {
        buttonText = "Upload",
        buttonProps,
        uploadOnSubmit = true,
        onUploadComplete = () => {},
        maxFileCount = Infinity,
        multiple = true,
        accepts = "*",
        visible = true,
        showToast = true,
        formProps: { value: filesArray, ...formProps },
        value,
        setValue,
        submitOnEnter,
        validate,
        label,
        defaults: { validate: defaultValidate, required: defaultRequired, ...defaults },
        required,
        ...props
      },
      ref
    ) => {
      /*************************************** State *************************************** */
      const [files, setFiles] = useState([]);

      /*************************************** Hooks *************************************** */
      const toast = useToast();
      const styling = useStyling();

      /********************************** Refs & Constants ********************************* */
      const fileUploaderRef = useRef(formProps.ref);

      /************************************** Queries ************************************** */
      const { post: postFiles, isLoading } = useFetch({
        options: {
          onMutate: () => {
            showToast && toast.info("Uploading your files, please wait...");
          },
          onSuccess: async (data) => {
            await onUploadComplete(data);
            setValue(data);
          },
        },
      });

      /******************************** Functions & Memos ********************************** */
      const submitFiles = useCallback(
        async (filesParam) => {
          const filesToUpload = filesParam || files;

          if (!filesToUpload.length) return;

          if (filesToUpload.length > maxFileCount) {
            toast.info("Too many files", `You can only upload ${maxFileCount} files.`);
          }
          const formdata = new FormData();
          for (let i = 0; i < Math.min(filesToUpload.length, maxFileCount); i++) {
            formdata.append("files", filesToUpload[i]);
          }

          postFiles({
            node: "docs-node",
            data: formdata,
          });
        },

        [files, maxFileCount, postFiles, toast]
      );

      const handleFileUpload = useCallback(
        async (e) => {
          const files = e.target.files;

          setFiles(files);

          if (uploadOnSubmit) {
            await submitFiles(files);
          }
          e.target.value = null;
        },
        [submitFiles, uploadOnSubmit]
      );

      /******************************** Effects & Handles ********************************** */
      useImperativeHandle(ref, () => ({
        click: () => fileUploaderRef.current?.click(),
        getFiles: () => files,
        submitFiles,
        isLoading,
      }));

      return (
        <div
          className={styling("mb-3")}
          style={{
            display: visible ? "block" : "none",
          }}
        >
          <label className={styling("input-label")}>
            {label}: {required && <OrangeSpan>*</OrangeSpan>}
          </label>
          {visible && (
            <Button
              loading={isLoading}
              {...buttonProps}
              onClick={() => fileUploaderRef.current?.click()}
              disabled={isLoading}
              size={"fw"}
            >
              {buttonText}
            </Button>
          )}
          <input
            {...props}
            {...formProps}
            {...defaults}
            type="file"
            style={{ display: "none" }}
            accept={accepts}
            multiple={multiple}
            ref={(e) => {
              fileUploaderRef.current = e;
              formProps?.ref?.(e);
            }}
            onChange={handleFileUpload}
          />
        </div>
      );
    }
  )
);
