import { withInputBase } from "../HOC/withInputBase";
import { useStyling } from "../../hooks/useStyling.js";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";
import { Input } from "./Input";
import { Dropdown } from "./Dropdown";
import { FormFlex } from "../layout/FormFlex";

export const Address = withInputBase(({ setValue, name }) => {
  /*************************************** State *************************************** */
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [post_code, setPostCode] = useState("");
  const [fullAddress, setFullAddress] = useState("");
  const [verified, setVerified] = useState(false);

  /*************************************** Hooks *************************************** */
  const styling = useStyling();

  /******************************** Functions & Memos ********************************** */
  useEffect(() => {
    setFullAddress(`${street || ""} ${city || ""} ${state || ""} ${post_code || ""}`);
    setVerified(false);
  }, [street, city, state, post_code]);

  useEffect(() => {
    if (!street || !city || !state || !post_code || verified) return;

    const timeoutId = setTimeout(() => {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode(
        {
          address: fullAddress,
        },
        function (results, status) {
          if (status === window.google.maps.GeocoderStatus.OK) {
            var latitude = results[0].geometry.location.lat();
            var longitude = results[0].geometry.location.lng();

            setValue(
              {
                street,
                city,
                state,
                country: "Australia",
                post_code,
                full_address: fullAddress,
                latitude,
                longitude,
                pinpoint: {
                  type: "Point",
                  coordinates: [longitude, latitude],
                },
              },
              name ?? "address"
            );

            setVerified(true);
          }
        }
      );
    }, 200);

    return () => clearTimeout(timeoutId);
  }, [street, city, state, post_code, fullAddress, setValue, verified, name]);

  return (
    <div className={styling("mb-2")}>
      <Input name={"street"} value={street} setValue={setStreet} />
      <FormFlex className={styling()}>
        <Input name={"city"} value={city} setValue={setCity} />
        <Dropdown name={"state"} value={state} setValue={setState} />
        <Input name={"post_code"} value={post_code} setValue={setPostCode} />
      </FormFlex>

      <div className={styling("flex", "flex-row", "flex-start")}>
        {fullAddress.trim() && <FontAwesomeIcon icon={verified ? faCheck : faMagnifyingGlass} />}
        <p className={styling("ml-3")}>{fullAddress}</p>
      </div>
    </div>
  );
});
