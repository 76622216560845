import clsx from "clsx";
import styles from "./FormFlex.module.scss";
import { useStyling } from "src/shiftly-ui";
import { forwardRef } from "react";

export const FormFlex = forwardRef(({ children, className, reverse = false, ...props }, ref) => {
  // Hooks
  const styling = useStyling(styles);

  return (
    <div
      className={clsx(
        styling(
          "flex",
          !reverse && "flex-column",
          !reverse && "sm-flex-row",
          "flex-align-start",
          "flex-nowrap",
          "flex-container"
        ),
        className
      )}
      {...props}
      ref={ref}
    >
      {children}
    </div>
  );
});
