import {
  useAuth,
  useFetch,
  Input,
  Button,
  FileUploader,
  useProfile,
  FormV2,
  useCachedQuery,
  OrangeSpan,
  useStyling,
  ButtonGroup,
} from "src/shiftly-ui";
import { useCallback, useRef } from "react";
import styles from "./AccountSettingsYourDetails.module.scss";
import StandardLayout from "../../components/standard_layout/StandardLayout";
import { faLock, faSave, faUpload } from "@fortawesome/pro-regular-svg-icons";
import StandardLayoutLink from "../../components/standard_layout/StandardLayoutLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";

const AccountSettingsYourDetails = () => {
  /*************************************** Hooks *************************************** */
  const { user } = useAuth();
  const { profile, updateProfile } = useProfile();
  const {
    Auth: { InitAuth },
  } = useCachedQuery();
  const styling = useStyling(styles);

  /********************************** Refs & Constants ********************************* */
  const uploaderRef = useRef();

  /************************************** Queries ************************************** */
  const {
    post: updateDetails,
    isLoading,
    isError,
    refresh,
  } = useFetch({
    options: {
      onSuccess: (data) => {
        refresh(["Profile.GetUserProfile", InitAuth]);
      },
    },
  });

  /******************************** Functions & Memos ********************************** */
  const onProfilePicUpload = (url) => {
    if (!url[0]) return;
    updateProfile({
      data: {
        profile_picture: url[0],
      },
    });
  };

  const handleSave = useCallback(
    async ({ first_name, last_name, email, phone_number }) => {
      return await updateDetails({
        entity: "User",
        method: "update",
        criteria: {
          _id: user?._id,
        },
        data: {
          first_name,
          last_name,
          email,
          phone_number,
        },
      });
    },
    [user, updateDetails]
  );

  const defaultPhoto = profile?.profile_picture === "/defaults/shifter.png";

  return (
    <StandardLayout
      heading={
        <>
          Personal <OrangeSpan>Details & Security</OrangeSpan>
        </>
      }
      breadcrumb={[{ label: "Account Management", link: "/account-management" }]}
    >
      <FileUploader ref={uploaderRef} visible={false} onUploadComplete={onProfilePicUpload} />
      <FormV2
        initial={{
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          phone_number: user.phone_number,
        }}
        onSubmit={handleSave}
      >
        <div className={styling("container")}>
          <div className={styling("profile-container", "w-100", "md-w-50", "p-3", "md-py-0")}>
            <h5 className={styling("mb-3")}>Profile Photo</h5>
            <p className={styling("label")}>
              <OrangeSpan>Please note:</OrangeSpan> Please ensure your full face is displayed centrally within the
              circle below:
            </p>
            <div
              className={styling("profile-photo-container", defaultPhoto && "with-border")}
              onClick={() => uploaderRef?.current?.click()}
            >
              {!defaultPhoto ? (
                <img src={profile?.profile_picture} alt="profile" />
              ) : (
                <div className={styling("add-container")}>
                  <FontAwesomeIcon icon={faPlus} className={styling("add-icon")} />
                </div>
              )}
            </div>
            <Button
              onClick={() => uploaderRef.current?.click()}
              className={styling("upload-btn")}
              theme={"secondary-outline"}
              icon={faUpload}
              iconSide="left"
              size={"fw"}
            >
              Upload
            </Button>
          </div>
          <div className={styling("w-100", "md-w-50", "p-3", "md-py-0")}>
            <Input name="first_name" />
            <Input name="last_name" />
            <Input name="email" />
            <Input name="phone_number" />
          </div>
        </div>
        <StandardLayoutLink
          icon={faLock}
          text={"Change Your Password"}
          link={"/account-management/personal-details/manage-password"}
        />
        <ButtonGroup>
          <Button type="submit" icon={faSave} theme={"secondary"} loading={isLoading} error={isError}>
            Save
          </Button>
        </ButtonGroup>
      </FormV2>
    </StandardLayout>
  );
};

export default AccountSettingsYourDetails;
