import StandardLayout from "src/components/standard_layout/StandardLayout";
import styles from "./AccountSettingsUserAccess.module.scss";
import {
  Button,
  ButtonGroup,
  FormFlex,
  FormV2,
  Input,
  Modal,
  ModalLabel,
  normaliseID,
  OrangeSpan,
  SearchAndDisplayRow,
  Table,
  useAuth,
  useFetch,
  useProfile,
  useStyling,
  useToast,
} from "src/shiftly-ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import SearchAndDisplay from "src/components/search_and_display/SearchAndDisplay";
import { faExclamationCircle, faPencil, faTrash, faUserShield } from "@fortawesome/pro-regular-svg-icons";
import { useCachedQuery } from "../../shared/hooks/useCacheQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const AccountSettingsUserAccess = () => {
  /*************************************** State *************************************** */
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState({});

  /*************************************** Hooks *************************************** */
  const { account, user } = useAuth();
  const styling = useStyling(styles);
  const navigate = useNavigate();
  const toast = useToast();
  const {
    Auth: { GetAccountDetails },
  } = useCachedQuery();

  /************************************** Queries ************************************** */
  const { data: users } = useFetch({
    request: {
      entity: "User",
      method: "get",
    },
  });

  const {
    post: sendInvite,
    isLoading: inviteIsLoading,
    isError,
  } = useFetch({
    options: {
      onSuccess: () => {
        setShowModal(false);
        toast.success("Invite sent successfully");
      },
    },
  });

  const { post, refresh } = useFetch({
    options: {
      onSuccess: () => {
        refresh(GetAccountDetails);
      },
    },
  });

  /******************************** Functions & Memos ********************************** */
  const handleSubmit = useCallback(() => {
    return sendInvite({
      entity: "Account",
      method: "inviteSuperUser",
      data: { ...data, account_id: account._id, organisation: account.organisation_name },
    });
  }, [data, sendInvite, account]);

  const columns = useMemo(
    () => [
      { field: "name", headerName: "Name", minWidth: 200 },
      { field: "email", headerName: "Email", minWidth: 150 },
      { field: "access", headerName: "Access", minWidth: 150 },
    ],
    []
  );

  const rows = useMemo(() => {
    return users
      .sort((a, b) => (a._id === account?.admin ? -1 : b._id === account?.admin ? 1 : 0))
      .map((user) => {
        return {
          id: user._id,
          name: user.first_name + " " + user.last_name,
          email: user.email,
          access: user._id === account?.admin ? "Admin" : "Super User",
        };
      });
  }, [users, account]);

  const handleRemoveUserAccess = useCallback((row) => {}, []);

  const handleMakeAdmin = useCallback(
    (row) => {
      post({
        entity: "Account",
        method: "update",
        criteria: {
          _id: normaliseID(account),
        },
        data: { admin: row._id },
      });
    },
    [post, account]
  );

  /******************************** Effects & Handles ********************************** */
  useEffect(() => {
    setData({});
  }, [showModal]);

  return (
    <>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        label={<ModalLabel text="Invite a super user" />}
        className={styling("modal")}
      >
        <h2 className={styling("my-4")}>
          Invite a <OrangeSpan>Super User</OrangeSpan>
        </h2>
        <div className={styling("mb-4", "warning-message")}>
          <FontAwesomeIcon icon={faExclamationCircle} />
          <p className={styling("ml-2")}>
            Super users can access all of your businesses. Only assign access to people you trust.
          </p>
        </div>
        <FormV2 data={data} setData={setData} onSubmit={handleSubmit} className={styling("px-4")}>
          <FormFlex>
            <Input name={"first_name"} />
            <Input name={"last_name"} />
          </FormFlex>
          <Input name={"email"} />
          <ButtonGroup className={styling("flex-between")}>
            <Button theme={"secondary-outline"} onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button theme={"primary"} type="submit" loading={inviteIsLoading} error={isError}>
              Send Invite
            </Button>
          </ButtonGroup>
        </FormV2>
      </Modal>
      <StandardLayout
        heading={
          <>
            <OrangeSpan>Super User</OrangeSpan> Access
          </>
        }
        breadcrumb={[{ label: "Account Management", link: "/account-management" }]}
        buttonText={"Add  Super User"}
        buttonEvent={() => setShowModal(true)}
      >
        <Table
          columns={columns}
          rows={rows}
          onRowDoubleClick={({ row }) => {
            if (row.id !== normaliseID(user)) return;
            navigate("/account-management/personal-details", { state: { user: row } });
          }}
          actions={[
            {
              icon: faUserShield,
              onClick: handleMakeAdmin,
              disabled: (row) => row.access === "Admin",
              label: "Edit",
            },
            {
              icon: faTrash,
              onClick: handleRemoveUserAccess,
              disabled: (row) => row.access === "Admin",
              label: "Delete",
            },
            {
              icon: faPencil,
              onClick: () => {
                navigate("/account-management/personal-details");
              },
              label: "Edit",
              disabled: (row) => row.id !== normaliseID(user),
            },
          ]}
        />
      </StandardLayout>
    </>
  );
};

export default AccountSettingsUserAccess;
