import { useCallback, useMemo, useState } from "react";
import styles from "./Timesheets.module.scss";
import {
  useStyling,
  useFetch,
  Table,
  normaliseID,
  Button,
  subtractDays,
  useCachedQuery,
  Modal,
  ModalLabel,
  FormV2,
} from "src/shiftly-ui";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import usePayCalendars from "src/hooks/usePayCalendars";
import useBusiness from "src/hooks/useBusiness";
import { faCheck, faPlus, faRefresh, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { faCalendar, faDownload } from "@fortawesome/pro-regular-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ActionsDropdown from "../people/components/ActionsDropdown";
import TabHeader from "src/shared/components/tabs/TabHeader";
import { DataGrid } from "@mui/x-data-grid";

const formatDate = (date) =>
  new Date(date).toLocaleDateString("en-AU", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

const formatTime = (date) =>
  new Date(date).toLocaleTimeString("en-AU", {
    hour: "2-digit",
    minute: "2-digit",
  });

const Timesheets = () => {
  /*************************************** State *************************************** */
  const [showManualTimeEntyModal, setShowManualTimeEntyModal] = useState(false);
  const [showCustomDateRangeModal, setShowCustomDateRangeModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedDates, setSelectedDates] = useState([subtractDays(new Date(), 7), new Date()]);
  const [timesheetData, setTimesheetData] = useState({});
  const [tab, setTab] = useState(0);
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const { activeLocation } = useShiftlyLocation();
  const { activeBusiness } = useBusiness();
  const { currentPayPeriod } = usePayCalendars(activeBusiness?.xero_organisation);
  const {
    Timesheet: { GetFilteredTimesheets },
  } = useCachedQuery();
  /********************************** Refs & Constants ********************************* */

  const tabs = useMemo(
    () => [
      {
        text: "All",
        value: "all",
      },
      {
        text: "Xero payrun",
        value: "xero_payrun",
        icon: <img src={"/global/xero_logo.png"} className={styling("xero-logo")} />,
      },
    ],
    [styling]
  );
  /************************************** Queries ************************************** */

  const { data: timesheets } = useFetch({
    request: {
      entity: "Timesheet",
      method: "get",
      criteria: {
        location: normaliseID(activeLocation),
        type: "internal",
      },
      populate: ["internalShift", "user"],
      id: GetFilteredTimesheets,
    },
    dependency: normaliseID(activeLocation),
  });

  const {
    post: actionTimesheet,
    refresh,
    updateCache,
  } = useFetch({
    options: {
      onMutate: ({ criteria, data }) => {
        const { _id } = criteria;
        const { status } = data;
        if (!_id || !status) return;
        updateCache(GetFilteredTimesheets, (oldData = []) => {
          const updatedTimesheets = oldData.flatMap((timesheet) => {
            if (timesheet._id === _id) {
              if (status === "delete") {
                return [];
              }
              return { ...timesheet, status };
            }
            return timesheet;
          });
          return updatedTimesheets;
        });
      },
      onSettled: () => {
        refresh([GetFilteredTimesheets]);
      },
    },
  });

  /******************************** Functions & Memos ********************************** */
  const statusMap = useMemo(() => {
    return timesheets.reduce((acc, timesheet) => {
      const { _id, status } = timesheet;
      if (!acc[_id]) {
        acc[_id] = status;
      }

      return acc;
    }, {});
  }, [timesheets]);

  const selectedAndApprovedTimesheets = useMemo(
    () => selectedRows.filter((sheet) => statusMap[sheet] === "pending"),
    [selectedRows, statusMap]
  );

  const handleBulkApprove = useCallback(() => {
    selectedAndApprovedTimesheets.forEach((sheet) => {
      actionTimesheet({
        entity: "Timesheet",
        method: "update",
        criteria: {
          _id: sheet,
        },
        data: {
          status: "approved",
        },
      });
    });
  }, [actionTimesheet, selectedAndApprovedTimesheets]);

  const handleActionTimesheet = useCallback(
    ({ status, _id }) => {
      if (status === "delete") {
        actionTimesheet({
          entity: "Timesheet",
          method: "delete",
          criteria: {
            _id,
          },
          data: {
            status,
          },
        });
      } else {
        actionTimesheet({
          entity: "Timesheet",
          method: "update",
          criteria: {
            _id,
          },
          data: {
            status,
          },
        });
      }
    },
    [actionTimesheet]
  );

  const columns = useMemo(() => {
    return [
      {
        field: "employee",
        headerName: "Employee",
        sortable: true,
        align: "left",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "shiftDate",
        headerName: "Shift Date",
        sortable: true,
        align: "left",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "startTime",
        headerName: "Start Time",
        sortable: true,
        align: "left",
        minWidth: 150,
        editable: true,
        flex: 1,
      },
      {
        field: "endTime",
        headerName: "End Time",
        sortable: true,
        align: "left",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "totalHours",
        headerName: "Total Hours",
        sortable: true,
        align: "left",
        minWidth: 150,
        flex: 1,
      },

      {
        field: "actions",
        headerName: "",
        sortable: false,
        align: "center",
        minWidth: 300,
        render: (row) => {
          const actions = [];

          const { status } = row;
          let additionalElement = <></>;

          const rejectedActions = [
            {
              label: "Reject timesheet",
              action: () => handleActionTimesheet({ status: "rejected", _id: row.id }),
            },
            {
              label: "Reject and delete timesheet",
              action: () => handleActionTimesheet({ status: "delete", _id: row.id }),
            },
          ];

          const spacer = {
            spacer: true,
          };

          const revert = {
            label: "Revert to pending review",
            action: () => handleActionTimesheet({ status: "pending", _id: row.id }),
            disabled: status === "pending",
          };

          const deleteTimesheet = {
            label: "Delete timesheet",
            action: () => handleActionTimesheet({ status: "delete", _id: row.id }),
          };

          switch (status) {
            case "pending":
              actions.push(...rejectedActions, spacer, revert);
              additionalElement = (
                <Button
                  theme={"primary"}
                  className={styling("action-button")}
                  onClick={() => handleActionTimesheet({ status: "approved", _id: row.id })}
                >
                  Approve
                </Button>
              );
              break;
            case "approved":
              actions.push(deleteTimesheet, spacer, revert);
              additionalElement = (
                <div className={styling("status-pill", "green")}>
                  <FontAwesomeIcon icon={faCheck} />
                  <p>Approved</p>
                </div>
              );
              break;
            case "rejected":
              actions.push(deleteTimesheet, spacer, revert);
              additionalElement = (
                <div className={styling("status-pill", "red")}>
                  <FontAwesomeIcon icon={faTimes} />
                  <p>Rejected</p>
                </div>
              );
              break;
            case "incomplete":
              actions.push(deleteTimesheet);
              additionalElement = (
                <div className={styling("status-pill", "grey")}>
                  <FontAwesomeIcon icon={faRefresh} />
                  <p>In Progress</p>
                </div>
              );
              break;
            case "posted":
              additionalElement = (
                <div className={styling("status-pill")}>
                  <FontAwesomeIcon icon={faCheck} />
                  <p>Posted</p>
                </div>
              );
              break;
          }

          return (
            <>
              <div className={styling("position-relative", "flex")}>
                <ActionsDropdown actions={actions} />
              </div>
              {additionalElement}
            </>
          );
        },
      },
    ];
  }, [styling, handleActionTimesheet]);

  const rows = useMemo(() => {
    const calculateTotalHours = (start, end) => Math.round((new Date(end) - new Date(start)) / 3600000);

    return timesheets.map(({ user, internalShift, _id, status }) => {
      const start = internalShift?.start_time;
      const end = internalShift?.end_time;

      return {
        id: _id,
        employee: `${user?.first_name ?? ""} ${user?.last_name ?? ""}`.trim(),
        shiftDate: formatDate(start),
        startTime: formatTime(start),
        endTime: formatTime(end),
        totalHours: calculateTotalHours(start, end),
        status,
      };
    });
  }, [timesheets]);



  const leftActions = useMemo(() => {
    const actions = [];

    if (selectedAndApprovedTimesheets.length > 0) {
      actions.push({
        text: `Approve ${selectedAndApprovedTimesheets.length} timesheets`,
        icon: faPlus,
        action: handleBulkApprove,
        iconSide: "right",
      });
    }

    return actions;
  }, [handleBulkApprove, selectedAndApprovedTimesheets.length]);

  const rightActions = useMemo(() => {
    const actions = [
      {
        text: "Add manual time entry",
        icon: faPlus,
        onClick: () => setShowManualTimeEntyModal(true),
        theme: "secondary-outline",
      },
      {
        theme: "secondary-outline",
        icon: faCalendar,
        onClick: () => {
          setShowCustomDateRangeModal((prev) => !prev);
          console.log("clicked");
        },
        text: (
          <div className={styling("date-range", "mx-2")} onClick={() => setShowCustomDateRangeModal((prev) => !prev)}>
            <p>
              {selectedDates?.[0]?.toLocaleString("en-AU", {
                day: "numeric",
                month: "short",
              })}
            </p>
            <p className={styling("mx-2")}>-</p>
            <p>
              {selectedDates?.[1]?.toLocaleString("en-AU", {
                day: "numeric",
                month: "short",
              })}
            </p>
          </div>
        ),
      },
      {
        text: `Export ${selectedRows.length ? `${selectedRows.length} selected` : ""}`,
        icon: faDownload,
        theme: "secondary",
        onClick: () => {
          console.log("Export");
        },
      },
    ];

    return actions;
  }, [selectedRows, selectedDates, styling]);

  /******************************** Effects & Handles ********************************** */

  return (
    <>
      <Modal
        showModal={showManualTimeEntyModal}
        setShowModal={setShowManualTimeEntyModal}
        label={<ModalLabel text={`${timesheetData?._id ? "Edit" : "Create"} timesheet`} />}
        className={styling("timesheet-modal")}
      >
        <FormV2 data={timesheetData} setData={setTimesheetData}>
          FORM
        </FormV2>
      </Modal>
      <div className={styling("container")}>
        <TabHeader tabs={tabs} tab={tab} setTab={setTab} leftActions={leftActions} rightActions={rightActions} />

        <Table
          columns={columns}
          onRowDoubleClick={({ row }) => {}}
          loading={false}
          rows={rows}
          checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setSelectedRows(newRowSelectionModel);
          }}
          rowSelectionModel={selectedRows}
          getRowClassName={({ row }) => {
            return row.status === "rejected" || row.status === "posted" ? styling("disabled-row") : "";
          }}
        />
      </div>
    </>
  );
};

export default Timesheets;
