import styles from "./NewShiftModalContent.module.scss";
import { useStyling, Button, TimeSelector, Input, Dropdown, TextArea, Calendar, useUI } from "src/shiftly-ui";
import { faCircleDollar } from "@fortawesome/pro-regular-svg-icons";
import usePositions from "src/hooks/usePositions";
import { useNavigate } from "react-router-dom";
import useInternalStaff from "src/hooks/useInternalStaff";
import { forwardRef, useMemo } from "react";
import NewShiftModalCostCalculator from "./NewShiftModalCostCalculator";
import NewShiftModalTotal from "./NewShiftModalTotal";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";

const NewShiftModalContent = forwardRef(
  (
    {
      selectedGroup,
      selectedLevel,
      selectedStaffMember,
      dispatch,
      shiftDispatch,
      setShiftFields,
      handleDeleteShift,
      handleSubmit,
      startTime,
      endTime,
      duration,
      increasedHourlyRateModifier,
      baseWageFromAward,
      totalEmployerContribution,
      lateNightPenalty,
      superContribution,
      shiftlyFees,
      notes,
      totalRange,
      isLoading,
      shiftDate,
      tab,
      mode,
    },
    calendarRef
  ) => {
    /*************************************** Hooks *************************************** */
    const navigate = useNavigate();
    const styling = useStyling(styles);
    const { dropdownPositions, getAvailableLevelsForGroup } = usePositions();
    const { hideNewShiftModal } = useUI();
    const { isLocationSetup } = useShiftlyLocation();

    const { internalStaff, ...internalStaffTotals } = useInternalStaff({
      startTime,
      endTime,
      shiftDate,
      selectedStaffMember,
    });

    const availableLevels = useMemo(
      () => getAvailableLevelsForGroup(selectedGroup),
      [getAvailableLevelsForGroup, selectedGroup]
    );

    return (
      <div className={styling("container")}>
        {/* Positions */}
        <div className={styling("flex", "flex-column", "flex-between", "md-flex-row")}>
          <Dropdown
            label={"What position are you filling?"}
            options={dropdownPositions}
            placeholder={"Select a position"}
            className={styling("w-100", "md-w-60", "md-mr-3")}
            value={selectedGroup}
            setValue={(v) => dispatch({ type: "SET_FIELD", field: "selectedGroup", value: v })}
            onChange={(e) => dispatch({ type: "SET_FIELD", field: "selectedGroup", value: "" })}
          />
          {tab === 0 ? (
            <Dropdown
              label={"At which level?"}
              options={availableLevels}
              placeholder={"Select a level"}
              className={styling("w-100", "md-w-40")}
              value={selectedLevel}
              setValue={(v) => dispatch({ type: "SET_FIELD", field: "selectedLevel", value: v })}
              disabled={!selectedGroup}
              link={
                selectedGroup
                  ? {
                      label: "What's this?",
                      onClick: () => {
                        hideNewShiftModal();
                        navigate("/positions/position-levels/" + selectedGroup);
                      },
                    }
                  : {}
              }
            />
          ) : (
            <Dropdown
              label={"For which staff member?"}
              options={internalStaff.map((staff) => ({
                label: `${staff.first_name} ${staff.last_name}`,
                value: staff._id,
              }))}
              disabled={!selectedGroup}
              placeholder={"Select a staff member"}
              className={styling("w-100", "md-w-40")}
              value={selectedStaffMember}
              setValue={(v) => dispatch({ type: "SET_FIELD", field: "selectedStaffMember", value: v })}
              link={{
                label: "Manage Internal Staff",
                onClick: () => {
                  navigate("/people?tab=internal");
                  hideNewShiftModal();
                },
              }}
            />
          )}
        </div>

        {/* Date and Time */}
        <div className={styling("flex", "flex-column-reverse", "md-flex-row", "flex-align-start")}>
          <div className={styling("w-100", "md-w-60", "md-mr-3")}>
            <div className={styling("flex", "flex-column-reverse", "md-flex-row", "flex-between")}>
              <div className={styling("time-select")}>
                <TimeSelector
                  label={"Start Time"}
                  value={startTime}
                  setValue={(v) => setShiftFields({ startTime: v }, shiftDispatch)}
                  className={styling("mr-1", "w-100")}
                />
                <TimeSelector
                  label={"End Time"}
                  value={endTime}
                  setValue={(v) => setShiftFields({ endTime: v }, shiftDispatch)}
                  className={styling("w-100")}
                />
              </div>
              {tab === 0 && (
                <Input
                  className={styling("w-100", "md-w-40")}
                  value={increasedHourlyRateModifier}
                  label={"Increase hourly rate by"}
                  setValue={(v) => {
                    setShiftFields({ increasedHourlyRateModifier: v }, shiftDispatch);
                  }}
                  icon={{ side: "right", icon: faCircleDollar }}
                />
              )}
            </div>

            <NewShiftModalCostCalculator
              tab={tab}
              duration={duration}
              baseWageFromAward={baseWageFromAward}
              totalEmployerContribution={totalEmployerContribution}
              lateNightPenalty={lateNightPenalty}
              superContribution={superContribution}
              shiftlyFees={shiftlyFees}
              internalStaffTotals={internalStaffTotals}
            />
          </div>
          <div className={styling("w-100", "md-w-40", "mb-2", "md-mb-0")}>
            <Calendar
              minDate={new Date()}
              range={false}
              label={"Select the shift's date"}
              value={shiftDate}
              setValue={(v) => setShiftFields({ shiftDate: v }, shiftDispatch)}
              ref={calendarRef}
            />
          </div>
        </div>
        {/* Notes */}
        <TextArea
          className={styling("mt-2")}
          placeholder={"Add any notes for the shift"}
          rows={2}
          value={notes}
          setValue={(v) => dispatch({ type: "SET_FIELD", field: "notes", value: v })}
        />

        {/* Footer */}
        <div className={styling("flex", "flex-column", "md-flex-row", "flex-end", "mt-auto", "w-100")}>
          {mode === "Edit" && (
            <div className={styling("mr-auto")}>
              <Button theme={"secondary-outline"} onClick={handleDeleteShift}>
                Delete Shift
              </Button>
            </div>
          )}
          <NewShiftModalTotal totalRange={totalRange} tab={tab} internalStaffTotals={internalStaffTotals} />
          <div className={styling("flex", "flex-column", "md-flex-row", "w-100", "md-w-auto")}>
            {tab === 0 && (
              <Button
                theme={"secondary"}
                onClick={() => handleSubmit("unpublished")}
                disabled={isLoading}
                className={styling("m-1", "md-mr-2", "w-100", "md-w-auto")}
              >
                Save as draft
              </Button>
            )}
            <Button
              theme={"primary"}
              onClick={() => handleSubmit("published")}
              className={styling("m-1", "w-100", "md-w-auto", "md-m-0")}
              loading={isLoading}
              disabled={tab === 0 && !isLocationSetup}
              disabledTooltip={{
                text: "Please complete seting up your location before publishing shifts.",
              }}
            >
              {tab === 0 ? "Publish Shift" : "Save Shift"}
            </Button>
          </div>
        </div>
      </div>
    );
  }
);

export default NewShiftModalContent;
