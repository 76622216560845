import { Link } from "../buttons/Link";
import { useStyling } from "../../hooks/useStyling.js";
import { useRef, forwardRef } from "react";
import { useInlineStyles } from "../../hooks/useInlineStyles.js";
import clsx from "clsx";
import styles from "./InputWrapper.module.scss";
export const withInputWrapper = (WrappedComponent) => {
  const WithRefWrapper = forwardRef(
    ({ disabled, name, required, label, link, className, error, specialButton, disabledTooltip, ...props }, ref) => {
      const styling = useStyling(styles);
      const style = useInlineStyles({ ...props });

      const inputRef = useRef();
      const combinedRef = ref || inputRef;

      const shouldShowTooltip = disabled && disabledTooltip;

      const renderedComponent = (
        <WrappedComponent
          ref={combinedRef}
          name={name}
          required={required}
          disabled={disabled}
          specialButton={specialButton}
          {...props}
        />
      );

      return (
        <div
          className={clsx(styling("input-wrapper"), className)}
          style={style}
          onClick={(e) => {
            combinedRef?.current?.onClick?.(e);
            combinedRef?.current?.focus?.();
          }}
        >
          <div className={styling("input-header")}>
            {label && (
              <label className={styling("input-label")}>
                {label} {required && <span>*</span>}
              </label>
            )}
            {link && (
              <Link to={link.destination} onClick={link.onClick} className={styling("input-link")}>
                {link.label}
              </Link>
            )}
          </div>

          <div
            className={styling("input-container", disabled && "disabled")}
            style={{
              borderRadius: specialButton ? "0 7px 7px 0" : "7px",
              backgroundColor: specialButton ? "white" : "var(--input-background)",
              position: "relative",
            }}
          >
            {shouldShowTooltip ? (
              <div className={styling("tooltip-wrapper")}>
                {renderedComponent}
                <div className={styling("tooltip-content")}>
                  <p className={styling("mb-1", "label")}>
                    <strong>{disabledTooltip.title}</strong>
                  </p>
                  <p className={styling("label")}>{disabledTooltip.text}</p>
                </div>
              </div>
            ) : (
              renderedComponent
            )}
          </div>

          {error && (
            <div className={styling("input-error-container")}>
              <p className={styling("input-message")}>{error}</p>
            </div>
          )}
        </div>
      );
    }
  );

  return WithRefWrapper;
};
