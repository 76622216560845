import React, { createRef, forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import styles from "./BankDetails.module.scss";
import { useStyling, useFetch, Input, FormFlex, FormV2, Button, ButtonGroup, useCachedQuery } from "src/shiftly-ui";
import AnimatedContainer from "../components/AnimatedContainer";
import Title from "../components/Title";
import FormContainer from "../components/FormContainer";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";

const newAccount = (remainder = true) => {
  return {
    ref: createRef(),
    account: {
      account_name: "",
      account_number: "",
      bsb: "",
      remainder,
      statement_text: "Wages",
      fixed_amount: 0,
    },
  };
};

const BankDetails = forwardRef(
  (
    {
      internalStaff,
      tax_declaration,
      submitOnboardingRequest = () => {},
      setActiveTab = () => {},
      bank_accounts,
      authToken,
      onboardRequest,
      setFormLoading,
      minified,
    },
    ref
  ) => {
    /*************************************** State *************************************** */
    const [bankAccounts, setBankAccounts] = useState(bank_accounts);
    /*************************************** Hooks *************************************** */
    const styling = useStyling(styles);
    const {
      EmployeeOnboard: { LoadOnboardingDetails },
    } = useCachedQuery();

    /********************************** Refs & Constants ********************************* */
    const [parent] = useAutoAnimate();
    /************************************** Queries ************************************** */
    const { post: onSubmit, refresh } = useFetch({
      options: {
        onSuccess: () => {
          refresh(LoadOnboardingDetails);

          setFormLoading(false);
          if (tax_declaration?.income_type === "LABOURHIRE") {
            submitOnboardingRequest();
          } else {
            setActiveTab((prev) => prev + 1);
          }
        },
        onError: ({ error }) => {
          const { message, field, value, prettyError } = error;
          if (message && field) {
            const accountIndex = bankAccounts.findIndex((acc) => acc.account[field] === value);
            if (accountIndex !== -1) {
              bankAccounts[accountIndex].ref.current.setError(field, {
                type: "manual",
                message: prettyError || message,
              });
            }
          }
          setFormLoading(false);
        },
      },
    });
    /******************************** Functions & Memos ********************************** */
    const handleAdditionalAccount = useCallback(() => {
      if (bankAccounts?.length >= 2) return;
      const newAcc = newAccount(false);

      setBankAccounts((prev) => [...prev, newAcc]);
    }, [bankAccounts]);

    /******************************** Effects & Handles ********************************** */
    useEffect(() => {
      if (bank_accounts?.length > 0)
        setBankAccounts(
          bank_accounts
            ?.map((account) => ({ account, ref: createRef() }))
            .sort((a, b) => (a.account.remainder ? -1 : 1))
        );
      else setBankAccounts([newAccount()]);
    }, [bank_accounts]);

    useImperativeHandle(ref, () => ({
      submit: async () => {
        if (bankAccounts.some((acc) => !acc.ref.current.validateFields())) return;

        return onSubmit(
          bankAccounts?.map(({ account = {} }) => ({
            entity: "BankAccount",
            method: "update",
            criteria: { _id: account?._id },
            data: { ...account, employee_onboard: onboardRequest?._id, internalStaff: internalStaff?._id },
            headers: { token: authToken },
            options: { upsert: true },
          }))
        );
      },
    }));

    return (
      <AnimatedContainer className={styling.container}>
        {!minified && (
          <Title
            title="Bank Details"
            subtitle="Please provide your bank details below so we know how to deposit your wages."
          />
        )}
        <FormContainer ref={parent}>
          {bankAccounts?.map((acc, index) => (
            <BankAccount
              key={index}
              ref={acc.ref}
              account={acc.account}
              setBankAccounts={setBankAccounts}
              index={index}
              authToken={authToken}
            />
          ))}

          {bankAccounts?.length === 1 && (
            <ButtonGroup className={styling("mt-3")}>
              <Button icon={faPlus} theme={"secondary-outline"} onClick={handleAdditionalAccount}>
                Add another bank account
              </Button>
            </ButtonGroup>
          )}
        </FormContainer>
      </AnimatedContainer>
    );
  }
);

const BankAccount = forwardRef(({ account = {}, setBankAccounts, index, authToken }, ref) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  /************************************** Queries ************************************** */
  const { post: deleteAccount } = useFetch({});
  /******************************** Functions & Memos ********************************** */
  const updateAccount = useCallback(
    (fieldName, value) => {
      setBankAccounts((prev) =>
        prev?.map((acc, i) => {
          if (i === index)
            return {
              ...acc,
              account: {
                ...acc.account,
                [fieldName]: value,
              },
            };
          return acc;
        })
      );
    },
    [setBankAccounts, index]
  );

  return (
    <>
      <div className={styling("account")}>
        <FormV2 initial={account} ref={ref}>
          <FormFlex>
            <Input name="account_name" onChange={(v) => updateAccount("account_name", v)} />

            <Input name="bsb" onChange={(v) => updateAccount("bsb", v)} />
            <Input
              name="account_number"
              onChange={(v) => updateAccount("account_number", v)}
              link={
                index === 1 && {
                  label: "Remove this account",
                  onClick: () => {
                    deleteAccount({
                      entity: "BankAccount",
                      method: "delete",
                      criteria: { _id: account._id },
                      headers: { token: authToken },
                    });
                    setBankAccounts((prev) => prev.filter((_, i) => i !== index));
                  },
                }
              }
            />
          </FormFlex>
          <FormFlex>
            <Input
              name="statement_text"
              label={"Reference"}
              onChange={(v) => updateAccount("statement_text", v)}
              placholder={"Statement Text"}
            />

            {index === 1 && (
              <Input
                name="fixed_amount"
                type={"number"}
                label={"Fixed amount to deposit"}
                placeholder={"0.00"}
                onChange={(v) => updateAccount("fixed_amount", v)}
                required
              />
            )}
          </FormFlex>
        </FormV2>
      </div>
    </>
  );
});

export default BankDetails;
