import { normaliseID, useCachedQuery, useFetch } from "src/shiftly-ui";
import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

const useShiftlyApplicants = () => {
  /*************************************** State *************************************** */
  const [activeShift, setActiveShift] = useState(null);

  /*************************************** Hooks *************************************** */
  const navigate = useNavigate();

  const {
    Shift: { ShiftsBetweenDates },
    ShiftApplications: { GetApplicationsForShifts, GetApplicationsForUserAndLocation, GetApplicationsForSpecificShift },
  } = useCachedQuery();

  /********************************** Refs & Constants ********************************* */

  /************************************** Queries ************************************** */
  const {
    post: actionApplicantPost,
    refresh,
    isLoading,
    updateCache,
  } = useFetch({
    options: {
      onMutate: ({ data }) => {
        updateCache(GetApplicationsForSpecificShift, (oldData = []) => {
          const index = oldData.findIndex((app) => app._id === data?.application_id);
          if (index !== -1) oldData[index].status = data?.action;
          return oldData;
        });
      },
      onError: () => {
        refresh([GetApplicationsForSpecificShift, GetApplicationsForShifts, ShiftsBetweenDates]);
      },
      onSuccess: ({ shift }) => {
        navigate("/people?shift=" + shift);
        refresh([
          GetApplicationsForSpecificShift,
          GetApplicationsForShifts,
          ShiftsBetweenDates,
          GetApplicationsForUserAndLocation,
        ]);
      },
    },
  });

  /******************************** Functions & Memos ********************************** */

  const actionApplicant = useCallback(
    async ({ application_id, action, shift, user }) => {
      await actionApplicantPost({
        entity: "ShiftApplication",
        method: "actionApplicant",
        data: {
          application_id,
          shift_id: normaliseID(shift),
          user: normaliseID(user),
          action,
        },
      });
    },
    [actionApplicantPost]
  );

  /******************************** Effects & Handles ********************************** */

  return {
    activeShift,
    setActiveShift,

    loading: isLoading,
    actionApplicant,
  };
};

export default useShiftlyApplicants;
