import { faCommentDots, faCreditCard, faGlobe } from "@fortawesome/pro-regular-svg-icons";
import styles from "./ApplicantProfileHeader.module.scss";
import {
  Button,
  calculateAgeInYears,
  normaliseID,
  RatingDisplay,
  useStyling,
  useFetch,
  useCachedQuery,
} from "src/shiftly-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { faFacebook, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import usePaymentMethods from "src/hooks/usePaymentMethods";
import { useCallback } from "react";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import useMessages from "src/hooks/useMessages";

const Header = ({ profile = {}, user = {}, shift = {} }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const { defaultPaymentMethod, loading } = usePaymentMethods();
  const navigate = useNavigate();

  return (
    <>
      <div className={styling("profile-picture")}>
        <img src={profile.profile_picture} alt="Profile" className={styling("cover")} />
      </div>
      <h2 className={styling("my-4")}>
        {user.first_name} {user.last_name}, {calculateAgeInYears(user.date_of_birth)}
      </h2>
      <div className={styling("flex", "flex-start", "flex-row", "my-4")}>
        <div className={styling("flex", "flex-row", "mr-4")}>
          <p className={styling("mr-2")}>
            <strong>Rating</strong>
          </p>
          <RatingDisplay rating={profile.rating} />
        </div>
        <SendMessageButton user={user} shift={shift} />
        {profile.facebook && (
          <div className={styling("social-icon")} onClick={() => navigate(profile.facebook)}>
            <FontAwesomeIcon icon={faFacebook} />
          </div>
        )}
        {profile.instagram && (
          <div className={styling("social-icon")} onClick={() => navigate(profile.instagram)}>
            <FontAwesomeIcon icon={faInstagram} />
          </div>
        )}
        {profile.linked_in && (
          <div className={styling("social-icon")} onClick={() => navigate(profile.linked_in)}>
            <FontAwesomeIcon icon={faLinkedin} />
          </div>
        )}
        {profile.website && (
          <div className={styling("social-icon")} onClick={() => navigate(profile.website)}>
            <FontAwesomeIcon icon={faGlobe} />
          </div>
        )}
      </div>
      <p>{profile.bio}</p>
      {!defaultPaymentMethod && !loading && (
        <Button
          theme={"secondary"}
          className={styling("mt-4")}
          link={"/business-account/billing-and-payment"}
          iconSide="right"
          icon={faCreditCard}
        >
          Add a payment method to accept {user?.first_name}
        </Button>
      )}
    </>
  );
};

export default Header;

const SendMessageButton = ({ user, shift }) => {
  /*************************************** State *************************************** */

  /*************************************** Hooks *************************************** */
  const navigate = useNavigate();
  const { conversations } = useMessages();
  const { activeLocation } = useShiftlyLocation();
  const {
    Conversation: { GetConversationsForLocation },
  } = useCachedQuery();
  /********************************** Refs & Constants ********************************* */

  /************************************** Queries ************************************** */
  const { post: createNewConversation, refresh } = useFetch({
    options: {
      onSuccess: () => {
        refresh(GetConversationsForLocation);
        goToMessages();
      },
    },
  });
  /******************************** Functions & Memos ********************************** */
  const goToMessages = useCallback(() => {
    navigate(`/messaging?user=${normaliseID(user)}&shift=${normaliseID(shift) || ""}`);
  }, [navigate, user, shift]);

  const handleClick = useCallback(() => {
    const existingConversation = conversations.find((conversation) => {
      return conversation.user?._id === user?._id;
    });

    if (existingConversation) {
      goToMessages();
      return;
    }
    createNewConversation({
      entity: "Conversation",
      method: "create",
      data: {
        user: user,
        location: activeLocation?._id,
        start_date: new Date(),
        last_sent: new Date(),
      },
    });
  }, [user, conversations, activeLocation, createNewConversation, goToMessages]);
  /******************************** Effects & Handles ********************************** */

  const styling = useStyling(styles);

  return (
    <Button
      theme={"secondary-outline"}
      className={styling("py-1")}
      onClick={handleClick}
      to={`/messaging?user=${normaliseID(user)}&shift=${normaliseID(shift) || ""}`}
      icon={faCommentDots}
    >
      Message {user.first_name}
    </Button>
  );
};
