import { Stepper, useFetch, useToast, ModalLabel, FormV2, normaliseID, useStyling } from "src/shiftly-ui";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import NewPositionDetails from "./NewPositionDetails";
import NewPositionQualifications from "./NewPositionQualifications";
import NewPositionWage from "./NewPositionWage";
import useBusiness from "src/hooks/useBusiness";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import usePositions from "src/hooks/usePositions";
import styles from "./NewPosition.module.scss";
const NewPositionModal = ({ newPositionModal, setNewPositionModal, initial }) => {
  /*************************************** State *************************************** */
  const [qualifications, setQualifications] = useState([]);
  const [formData, setFormData] = useState({});

  /*************************************** Hooks *************************************** */
  const { activeBusiness } = useBusiness();
  const { activeLocation, refreshAvailableLocations } = useShiftlyLocation();
  const { refreshAvailablePositions, groupMap } = usePositions();
  const toast = useToast();
  const styling = useStyling(styles);

  /********************************** Refs & Constants ********************************* */
  const formRef = useRef();

  /************************************** Queries ************************************** */
  const {
    post: postPosition,
    isLoading,
    refresh,
  } = useFetch({
    options: {
      onSuccess: () => {
        refreshAvailableLocations();
        setNewPositionModal(false);
        refreshAvailablePositions();
        refresh("Position.GetPositionsByBusiness");
        setTimeout(() => {
          formRef.current.reset();
          setQualifications([]);
        }, 500);
      },
    },
  });
  /******************************** Functions & Memos ********************************** */
  const steps = useMemo(
    () => [
      {
        label: "Position Details",
        content: <NewPositionDetails data={formData} setData={setFormData} />,
        validation: async () => await formRef.current.validateFields(["description", "group"]),
      },
      {
        label: "Wage",
        content: <NewPositionWage />,
        validation: async () => await formRef.current.validateFields(["position_rate_modifier"]),
      },
      {
        label: "Qualifications",
        content: <NewPositionQualifications qualifications={qualifications} setQualifications={setQualifications} />,
      },
    ],
    [qualifications, formData]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const { group } = formData;

      const populatedGroup = groupMap[group];

      if (!populatedGroup) throw new Error('Please select a "Position Name" before proceeding');

      const position = {
        ...formData,
        qualifications: qualifications.flatMap((q) => (q.description ? q : [])),
        location: normaliseID(activeLocation),
        award_code: populatedGroup?.industry?.award_code,
        industry: normaliseID(populatedGroup?.industry),
        business: normaliseID(activeBusiness),
      };

      postPosition({
        entity: "Position",
        method: position?._id ? "update" : "create",
        data: position,
        criteria: { _id: position?._id },
      });
    } catch ({ message }) {
      toast.error(message, "Error Creating Position");
    }
  }, [qualifications, postPosition, groupMap, activeLocation, toast, formData, activeBusiness]);

  /******************************** Effects & Handles ********************************** */
  useEffect(() => {
    setFormData(initial);
  }, [initial]);

  useEffect(() => {
    if (!newPositionModal) return;
    setQualifications(initial.qualifications || []);
  }, [newPositionModal, initial]);

  return (
    <FormV2 data={formData} setData={setFormData} ref={formRef} onSubmit={handleSubmit}>
      <Stepper
        label={<ModalLabel text="Positions" />}
        steps={steps}
        className={styling("modal")}
        showStepper={newPositionModal}
        setShowStepper={setNewPositionModal}
        onComplete={handleSubmit}
        loading={isLoading}
        keyDown={false}
      />
    </FormV2>
  );
};
export default NewPositionModal;
