import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import styles from "./SuperMembership.module.scss";
import {
  useStyling,
  useFetch,
  FormV2,
  Dropdown,
  Input,
  Toggle,
  FormFlex,
  Modal,
  Button,
  useAlerts,
  ModalLabel,
  useToast,
  ButtonGroup,
} from "src/shiftly-ui";
import AnimatedContainer from "../components/AnimatedContainer";
import Title from "../components/Title";
import FormContainer from "../components/FormContainer";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { ObjectID, useCachedQuery } from "src/shiftly-ui";

const SuperMembership = forwardRef(
  (
    {
      internalStaff,
      submitOnboardingRequest = () => {},
      authToken,
      onboardRequest,
      super_membership,
      setFormLoading,
      minified,
    },
    ref
  ) => {
    /*************************************** State *************************************** */
    const [tab, setTab] = useState(0);
    const [superMembershipData, setSuperMembershipData] = useState(super_membership);
    const [showAddSuperModal, setShowAddSuperModal] = useState(false);
    const [newSuperFundData, setNewSuperFundData] = useState({});
    const [smsfData, setSmsfData] = useState({});

    /*************************************** Hooks *************************************** */
    const styling = useStyling(styles);
    const { confirm } = useAlerts();
    const toast = useToast();
    const {
      EmployeeOnboard: { LoadOnboardingDetails },
    } = useCachedQuery();

    /********************************** Refs & Constants ********************************* */
    const regulatedFormRef = useRef(null);
    const smsfFormRef = useRef(null);
    const [tabs] = useAutoAnimate();

    /************************************** Queries ************************************** */
    const { post: onSubmit, refresh } = useFetch({
      options: {
        onSuccess: () => {
          submitOnboardingRequest();
          refresh(LoadOnboardingDetails);
          setFormLoading(false);
        },
        onMutate: () => {
          setFormLoading(true);
        },
        onError: () => {
          setFormLoading(false);
        },
      },
    });

    useFetch({
      request: {
        entity: "SuperFund",
        method: "get",
        criteria: { type: "SMSF", internalStaff: internalStaff?._id },
      },
      dependency: internalStaff?._id,
      options: {
        staleTime: 0,
        onSuccess: ([data = {}]) => {
          setSmsfData((prev) => ({ ...prev, ...data }));
        },
      },
    });

    const {
      post: onSubmitNewSuper,
      isError,
      isLoading,
    } = useFetch({
      options: {
        onSuccess: ([newFund]) => {
          setSuperMembershipData((prev) => ({ ...prev, super_fund: newFund._id }));
          refetch();
          toast.success(`${newFund?.name} has been added successfully`);
          setShowAddSuperModal(false);
          setNewSuperFundData({});
        },
      },
    });

    const { data: superFundsList, refetch } = useFetch({
      request: { entity: "SuperFund", method: "get", criteria: { type: "REGULATED" } },
      options: {
        select: (data) => {
          return data.map((fund) => ({
            ...fund,
            label: fund["name"] + " (" + fund.usi + ")",
            value: fund["_id"],
          }));
        },
      },
    });

    /******************************** Functions & Memos ********************************** */
    const handleSkip = useCallback(async () => {
      if (
        !(await confirm({
          label: (
            <>
              Are you sure you want to <span>skip adding super details?</span>
            </>
          ),
          text: `We'll use our default super fund.`,
          mode: "info",
          confirmText: "Confirm",
          cancelText: "Cancel",
        }))
      )
        return;

      submitOnboardingRequest();
    }, [confirm, submitOnboardingRequest]);

    const handleSubmitNewSuper = useCallback(
      async (data) => {
        return onSubmitNewSuper({
          entity: "SuperFund",
          method: "create",
          data: { ...data, type: "REGULATED" },
          headers: { token: authToken },
        });
      },
      [authToken, onSubmitNewSuper]
    );
    /******************************** Effects & Handles ********************************** */
    useImperativeHandle(ref, () => ({
      submit: async () => {
        if (tab === 0) return await regulatedFormRef.current.submit();
        else return await smsfFormRef.current.submit();
      },
    }));

    useEffect(() => {
      setSmsfData((prev) => ({ ...prev, member_number: super_membership?.member_number }));
      setSuperMembershipData(super_membership ?? {});
      setTab(super_membership?.type === "SMSF" ? 1 : 0);
    }, [super_membership]);

    useEffect(() => {
      if (super_membership?.type === "SMSF") setTab(1);
    }, [super_membership]);

    return (
      <>
        <Modal
          label={<ModalLabel text={"Add your super fund"} />}
          showModal={showAddSuperModal}
          setShowModal={setShowAddSuperModal}
          className={styling("modal")}
        >
          <div className={styling("modal-content")}>
            <FormV2 onSubmit={handleSubmitNewSuper} data={newSuperFundData} setData={setNewSuperFundData}>
              <Input
                name={"usi"}
                label={"Unique Super Identifier (USI)"}
                required
                placeholder={"Unique Super Identifier"}
              />

              <ButtonGroup className={styling("flex-between")}>
                <Button theme={"secondary-transparent"} onClick={() => setShowAddSuperModal(false)}>
                  Cancel
                </Button>
                <Button theme={"primary"} type={"submit"} loading={isLoading} error={isError}>
                  Submit
                </Button>
              </ButtonGroup>
            </FormV2>
          </div>
        </Modal>
        <AnimatedContainer className={styling("")}>
          {!minified && (
            <Title
              title="Super Membership"
              subtitle="Please add your superannuation details below or skip this step if you're happy to use our default super fund."
            />
          )}
          <FormContainer className={styling("w-100", "sm-w-75", "mx-auto", "mt-3", "p-3")}>
            <Toggle
              tabs={[{ text: "Regulated" }, { text: "Self Managed Super Fund (SMSF)" }]}
              tab={tab}
              setTab={setTab}
              className={styling("mb-3")}
            />

            <div ref={tabs}>
              {tab === 0 && (
                <FormV2
                  ref={regulatedFormRef}
                  data={superMembershipData}
                  setData={setSuperMembershipData}
                  onSubmit={async (data) => {
                    return await onSubmit({
                      entity: "SuperMembership",
                      method: "update",
                      criteria: {
                        _id: superMembershipData?._id,
                        internalStaff: internalStaff?._id,
                        employee_onboard: onboardRequest?._id,
                      },
                      data: {
                        ...data,
                        internalStaff: internalStaff?._id,
                        employee_onboard: onboardRequest?._id,
                        type: "REGULATED",
                      },
                      headers: { token: authToken },
                      options: { upsert: true },
                    });
                  }}
                >
                  <Dropdown
                    options={superFundsList}
                    name={"super_fund"}
                    link={{ label: "Don't see your super fund?", onClick: () => setShowAddSuperModal(true) }}
                  />
                  <Input name={"member_number"} />
                </FormV2>
              )}
              {tab === 1 && (
                <FormV2
                  ref={smsfFormRef}
                  data={smsfData}
                  setData={setSmsfData}
                  onSubmit={async () => {
                    const smsfid = smsfData?._id || ObjectID();
                    return await onSubmit([
                      {
                        entity: "SuperFund",
                        method: "update",
                        criteria: { _id: smsfid },
                        data: { ...smsfData, type: "SMSF", internalStaff: internalStaff?._id, _id: smsfid },
                        headers: { token: authToken },
                        options: { upsert: true },
                      },
                      {
                        entity: "SuperMembership",
                        method: "update",
                        criteria: {
                          _id: superMembershipData?._id,
                          internalStaff: internalStaff?._id,
                          employee_onboard: onboardRequest._id,
                        },
                        data: {
                          member_number: smsfData?.member_number,
                          employer_number: smsfData?.employer_number,
                          super_fund: smsfid,
                          internalStaff: internalStaff._id,
                          employee_onboard: onboardRequest._id,
                          type: "SMSF",
                          xero_super_fund_id: smsfData?._id ? superMembershipData?.xero_super_fund_id : null,
                          xero_super_membership_id: superMembershipData?.xero_super_membership_id,
                        },
                        headers: { token: authToken },
                        options: { upsert: true },
                      },
                    ]);
                  }}
                >
                  <FormFlex>
                    <Input name={"name"} label={"Fund Name"} required placeholder={"Fund Name"} />
                    <Input name={"abn"} />
                  </FormFlex>
                  <Input
                    name={"electronic_service_address"}
                    label={"Electronic Service Address"}
                    required
                    placeholder={"Electronic Service Address"}
                  />
                  <FormFlex>
                    <Input name={"member_number"} />
                    <Input
                      name={"employer_number"}
                      label={"Employer Number"}
                      placeholder={"Employer Number"}
                      maxLength={9}
                    />
                  </FormFlex>
                  <FormFlex>
                    <Input name={"account_name"} />
                    <Input name={"bsb"} />
                    <Input name={"account_number"} />
                  </FormFlex>
                </FormV2>
              )}
            </div>
            {!minified && (
              <Button theme={"secondary-transparent"} onClick={handleSkip}>
                Skip adding super details
              </Button>
            )}
          </FormContainer>
        </AnimatedContainer>
      </>
    );
  }
);

export default SuperMembership;
