import io from "socket.io-client";

export const socket = io(process.env.REACT_APP_API_URL.replace(/\/$/, ""), {
  transports: ["websocket"],
  reconnection: true,
  reconnectionAttempts: Infinity,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
});

socket.on("connect", () => console.log("✅ Socket connected"));
socket.on("disconnect", (reason) => console.log("❌ Disconnected:", reason));
