import { useCallback } from "react";
import { useFetch, useAlerts, useToast, useCachedQuery } from "src/shiftly-ui";
import mongoose from "mongoose";
import moment from "moment-timezone";
import usePositions from "src/hooks/usePositions";
import useBusiness from "./useBusiness";
import useShiftlyLocation from "./useShiftlyLocation";
import useInternalStaff from "./useInternalStaff";

const handleTimeUpdate = (shift, newDay) => {
  const originalStartTime = moment.tz(shift.start_time, shift.timezone);
  const originalEndTime = moment.tz(shift.end_time, shift.timezone);
  const newStartTime = newDay
    .clone()
    .hour(originalStartTime.hour())
    .minute(originalStartTime.minute())
    .second(originalStartTime.second())
    .toISOString();
  const newEndTime = newDay
    .clone()
    .hour(originalEndTime.hour())
    .minute(originalEndTime.minute())
    .second(originalEndTime.second())
    .toISOString();
  return { newStartTime, newEndTime };
};

const unactionableShiftStatuses = ["confirmed", "completed", "active", "cancelled", "expired"];

const findElementById = (array, id) => array.find((element) => element._id === id);

const mergeArrays = (array1, array2, idField) => {
  const uniqueMap = new Map();
  array1.forEach((obj) => {
    uniqueMap.set(obj[idField], obj);
  });
  array2.forEach((obj) => {
    uniqueMap.set(obj[idField], obj);
  });
  return Array.from(uniqueMap.values());
};

const useShiftManager = () => {
  const { confirm, prompt } = useAlerts();
  const { info, error } = useToast();
  const { groupedPositions, allPositions } = usePositions();
  const { availableBusinesses } = useBusiness();
  const { allLocations } = useShiftlyLocation();
  const { internalStaff } = useInternalStaff();

  const {
    InternalShift: { GetInternalShifts },
    Shift: { ShiftsBetweenDates },
  } = useCachedQuery();

  const cleanShifts = useCallback(
    (shifts, allowedStatus = []) => {
      const invalidShifts = shifts.filter(
        (shift) => unactionableShiftStatuses.includes(shift.status) && !allowedStatus.includes(shift.status)
      );
      const validShifts = shifts.filter(
        (shift) => !unactionableShiftStatuses.includes(shift.status) || allowedStatus.includes(shift.status)
      );

      const addS = invalidShifts.length > 1 ? "s" : "";
      if (invalidShifts.length > 0) {
        error(
          `${invalidShifts.length} shift${addS} ${!addS ? "was" : "were"} not actioned due to having ${
            addS ? "invalid statuses" : "an invalid status"
          }.`
        );
      }

      return validShifts;
    },
    [error]
  );

  const normaliseShift = useCallback((object) => {
    const propertiesToNormalize = ["user", "position", "location", "position_group", "business"];
    return Object.fromEntries(propertiesToNormalize.map((key) => [key, object[key]?._id || object[key]]));
  }, []);

  const denormaliseShift = useCallback(
    (object) => {
      const propertiesToDenormalize = ["position", "location", "business"];

      const arrayMap = {
        position: allPositions,
        location: allLocations,
        business: availableBusinesses,
      };
      return Object.fromEntries(
        propertiesToDenormalize.map((key) => [key, findElementById(arrayMap[key], object[key])])
      );
    },
    [allPositions, allLocations, availableBusinesses]
  );

  const { post, refresh, updateCache } = useFetch({
    options: {
      onMutate: ({ data: shifts = [], method, entity, criteria }) => {
        const requestName = entity === "Shift" ? ShiftsBetweenDates : GetInternalShifts;
        const populatedShifts = shifts.map((shift) => ({ ...shift, ...denormaliseShift(shift) }));

        switch (method) {
          case "create": {
            updateCache(requestName, (oldData = []) => {
              return mergeArrays(oldData, populatedShifts, "_id");
            });
            break;
          }

          case "update": {
            updateCache(requestName, (oldData = []) => {
              const updatedShifts = oldData.map((oldShift) => {
                const newShift = populatedShifts.find((shift) => shift._id === oldShift._id);
                return newShift || oldShift;
              });
              return updatedShifts;
            });
            break;
          }

          case "delete": {
            updateCache(requestName, (oldData = []) => {
              return oldData.filter((oldShift) => !criteria._id?.includes(oldShift._id));
            });
            break;
          }

          default:
            break;
        }
      },
      onError: ({ error: err }) => {
        err.prettyError && error(err.prettyError);
        refresh([ShiftsBetweenDates, GetInternalShifts]);
      },
      onSuccess: () => {
        refresh([ShiftsBetweenDates, GetInternalShifts]);
      },
    },
  });

  //Copy Shifts
  const copyShifts = useCallback(
    (shifts = []) => {
      const newShifts = cleanShifts(shifts).map((shift) => {
        const newShift = {
          ...shift,
          ...normaliseShift(shift),
          _id: new mongoose.Types.ObjectId(),
        };
        delete newShift._id;

        return newShift;
      });

      const internalShifts = newShifts.filter((shift) => shift.type === "internal");
      const shiftlyShifts = newShifts.filter((shift) => shift.type === "shiftly");

      if (internalShifts.length) {
        post({
          entity: "InternalShift",
          method: "create",
          data: internalShifts,
          payload: {
            users: internalShifts.map((shift) => shift.user),
          },
        });
      }

      if (shiftlyShifts.length) {
        post({
          entity: "Shift",
          method: "create",
          data: shiftlyShifts,
        });
      }
    },
    [post, normaliseShift, cleanShifts]
  );

  //Update Shifts
  const updateShifts = useCallback(
    async (shifts) => {
      const validShifts = cleanShifts(shifts);
      const internalPayloads = [];
      const shiftlyPayloads = [];

      validShifts.forEach((shift) => {
        const newShift = {
          ...shift,
          ...normaliseShift(shift),
        };

        const payload = {
          entity: newShift.type === "internal" ? "InternalShift" : "Shift",
          method: "update",
          criteria: { _id: newShift._id },
          data: newShift,
          payload: {
            users: validShifts.map((shift) => shift.user),
          },
          options: {
            clearShiftApplications: true,
          },
        };

        if (newShift.type === "internal") {
          internalPayloads.push(payload);
        } else {
          shiftlyPayloads.push(payload);
        }
      });

      if (shiftlyPayloads.length) {
        if (
          !(await confirm({
            label: "Update Shifts",
            text: `Are you sure you'd like to update ${shiftlyPayloads.length} shift${
              shiftlyPayloads.length > 1 ? "s" : ""
            }? This will remove all applicants from the shift${shiftlyPayloads.length > 1 ? "s" : ""}.`,
            confirmText: "Update",
            cancelText: "Cancel",
          }))
        )
          return;
      }

      internalPayloads.length && post(internalPayloads);
      shiftlyPayloads.length && post(shiftlyPayloads);
    },
    [post, normaliseShift, cleanShifts, confirm]
  );

  //Move Shift
  const moveShift = useCallback(
    async (shift, newDate) => {
      if (unactionableShiftStatuses.includes(shift.status)) {
        info("This shift has cannot be moved.");
        return;
      }

      const { newStartTime, newEndTime } = handleTimeUpdate(shift, newDate);

      if (
        shift.status !== "unpublished" &&
        !(await confirm({
          label: "Move shift",
          text: ` If this shift has applicants, they will be deleted. Are you sure you'd like to move this shift?`,
          confirmText: "Move",
          cancelText: "Cancel",
        }))
      )
        return;

      const newShift = {
        ...shift,
        ...normaliseShift(shift),
        start_time: newStartTime,
        end_time: newEndTime,
      };

      updateShifts([newShift]);
    },
    [updateShifts, normaliseShift, confirm, info]
  );

  //Delete/Cancel Shifts
  const deleteShifts = useCallback(
    async (shifts) => {
      const validShifts = cleanShifts(shifts, ["expired"]);

      if (
        !(await confirm({
          label: "Delete shifts",
          text: `Are you sure you'd like to delete ${validShifts.length} shift${validShifts.length > 1 ? "s" : ""}?`,
          confirmText: "Delete",
          cancelText: "Cancel",
          inverse: true,
        }))
      )
        return;

      const internalShifts = validShifts.filter((shift) => shift.type === "internal");
      const shiftlyShifts = validShifts.filter((shift) => shift.type === "shiftly");

      if (internalShifts.length) {
        post({
          entity: "InternalShift",
          method: "delete",
          criteria: { _id: internalShifts.map((shift) => shift._id) },
          payload: {
            users: internalShifts.map((shift) => shift.user),
          },
        });
      }

      if (shiftlyShifts.length) {
        post({
          entity: "Shift",
          method: "delete",
          criteria: { _id: shiftlyShifts.map((shift) => shift._id) },
        });
      }
    },
    [confirm, post, cleanShifts]
  );

  //Upgrade Shifts
  const upgradeShift = useCallback(
    async (internalShift) => {
      const positions = groupedPositions[internalShift?.position_group].sort(
        (a, b) => a.classification_level - b.classification_level
      );

      let level;
      do {
        level = await prompt({
          label: "Change to Public",
          text: "Please select the classification level for the new public shift",
          confirmText: "Change",
          cancelText: "Cancel",
          type: "dropdown",
          inputProps: {
            options: positions.map((position) => ({
              value: position.classification_level,
              label: "Level " + position.classification_level,
            })),
          },
        });

        if (level === false) return;

        if (!level) error("Please select a classification level");
      } while (!level);

      const newShift = {
        ...internalShift,
        type: "shiftly",
        classification_level: level,
        position: positions.find((position) => position.classification_level === level),
      };

      delete newShift._id;

      await post([
        {
          entity: "Shift",
          method: "create",
          data: {
            ...newShift,
            ...normaliseShift(newShift),
          },
        },
        {
          entity: "InternalShift",
          method: "delete",
          criteria: { _id: internalShift._id },
        },
      ]);
    },
    [groupedPositions, prompt, post, normaliseShift, error]
  );

  //Downgrade Shifts - TODO
  const downgradeShift = useCallback(
    async (publicShift) => {
      let staffMember;
      do {
        staffMember = await prompt({
          label: "Change to Internal Shift",
          text: "Who you like to assign this shift to?",
          confirmText: "Assign",
          cancelText: "Cancel",
          type: "dropdown",
          inputProps: {
            options: [
              { value: "open", label: "Open to all staff" },
              ...internalStaff.map((staff) => ({
                value: staff._id,
                label: `${staff.first_name} ${staff.last_name}`,
              })),
            ],
          },
        });

        if (staffMember === false) return;

        if (!staffMember) error("Please select an option to continue.");
      } while (!staffMember);

      if (
        !(await confirm({
          label: "Downgrade Shift",
          text: "Are you sure you want to downgrade this shift? All applicants will be rejected.",
          confirmText: "Downgrade",
          cancelText: "Cancel",
          inverse: true,
        }))
      )
        return;

      const newShift = {
        ...publicShift,
        ...normaliseShift(publicShift),
        type: "internal",
        status: staffMember === "open" ? "unpublished" : "published",
        user: staffMember === "open" ? null : staffMember,
      };

      await post([
        {
          entity: "InternalShift",
          method: "create",
          data: newShift,
        },
        {
          entity: "Shift",
          method: "delete",
          criteria: { _id: publicShift._id },
        },
      ]);
    },
    [confirm, prompt, internalStaff, normaliseShift, post, error]
  );

  return {
    refresh,
    updateCache,
    normaliseShift,
    denormaliseShift,
    copyShifts,
    moveShift,
    updateShifts,
    handleTimeUpdate,
    deleteShifts,
    upgradeShift,
    downgradeShift,
  };
};
export default useShiftManager;
