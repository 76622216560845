import { useAuth, Link, useFetch, useAlerts, useCachedQuery, useStyling, truncate } from "src/shiftly-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard, faInstitution } from "@fortawesome/pro-duotone-svg-icons";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { useCallback } from "react";
import useBusiness from "src/hooks/useBusiness";

import styles from "./BusinessAccountPaymentMethodDisplay.module.scss";

const BusinessAccountPaymentMethodDisplay = ({ stripe_payment_method, type, au_becs_debit = {}, card = {}, _id }) => {
  /*************************************** Hooks *************************************** */
  const { user, account } = useAuth();
  const { activeBusiness, refreshActiveBusiness } = useBusiness();
  const { confirm } = useAlerts();
  const {
    PaymentMethod: { GetPaymentMethods },
  } = useCachedQuery();
  const styling = useStyling(styles);

  /********************************** Refs & Constants ********************************* */
  const is_default = account?.default_payment_method === _id;
  const isMethodBeingUsed = activeBusiness?.preferred_payment_method === _id;

  /************************************** Queries ************************************** */
  const { post, refresh, isLoading } = useFetch({
    options: {
      onSuccess: () => {
        refreshActiveBusiness();
        refresh(GetPaymentMethods);
      },
    },
  });

  /******************************** Functions & Memos ********************************** */
  const handleDelete = useCallback(async () => {
    if (
      !(await confirm({
        label: (
          <>
            Are you sure you want to <span>delete this payment method?</span>
          </>
        ),
        text: "All payments will use your account's default payment method from this point forward.",
        inverse: true,
        confirmText: "Delete",
      }))
    )
      return;

    post({
      entity: "PaymentMethod",
      method: "delete",
      criteria: {
        stripe_payment_method,
      },
    });
  }, [post, stripe_payment_method, confirm]);

  const handleMakeDefault = useCallback(() => {
    post({
      entity: "PaymentMethod",
      method: "setDefaultPaymentMethod",
      data: {
        paymentMethod: stripe_payment_method,
        business: activeBusiness?._id,
      },
    });
  }, [post, stripe_payment_method, activeBusiness]);

  return (
    <>
      <div className={styling("mt-3")}>
        {!isMethodBeingUsed || is_default ? (
          <>
            <p>
              <strong>Default Method for {truncate(account?.organisation_name, 35)}</strong>
            </p>
          </>
        ) : (
          <>
            <p>
              <strong>Preferred Method for {truncate(activeBusiness?.name, 25)}</strong>
            </p>
          </>
        )}
      </div>
      <div className={styling("container", isMethodBeingUsed && "border", !isMethodBeingUsed && "grey-out")}>
        <div className={styling("flex", "flex-start")}>
          <div className={styling("image")}>
            <FontAwesomeIcon icon={type === "card" ? faCreditCard : faInstitution} />
          </div>
          {type === "card" ? (
            <div className={styling("flex", "h-100")}>
              <p className={styling("mr-3", "label")}>
                <strong className={styling("mr -1")}>{card.brand?.toUpperCase()} </strong>ending in{" "}
                <strong className={styling("ml-1")}>{card?.last4}</strong>
              </p>
              <p className={styling("mr-3", "label")}>
                <strong>Expiry: </strong> {card.exp_month}/{card.exp_year}
              </p>
            </div>
          ) : (
            <div className={styling("flex", "h-100")}>
              <p className={styling("mr-3", "label")}>
                <strong>Account Name: </strong>
                <br />
                {user?.first_name} {user?.last_name}
              </p>
              <p className={styling("mr-3", "label")}>
                <strong>BSB: </strong>
                <br />
                {au_becs_debit?.bsb_number}
              </p>
              <p className={styling("mr-3", "label")}>
                <strong>Account Number: </strong> <br />
                xxxx-{au_becs_debit?.last4}
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default BusinessAccountPaymentMethodDisplay;
