import { useStyling, Each, normaliseID, EmptyState } from "src/shiftly-ui";
import ApplicantCard from "./ApplicantCard";

import useShiftlyApplicants from "../../hooks/useShiftlyApplicants";
import usePaymentMethods from "src/hooks/usePaymentMethods";
import { useNavigate } from "react-router-dom";

const ApplicantsMobile = ({ setConfirmingApplicant, applicants, profiles }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling();
  const navigate = useNavigate();
  const { actionApplicant } = useShiftlyApplicants();
  const { defaultPaymentMethod } = usePaymentMethods();

  return (
    <EmptyState items={applicants} image="empty_applicants.webp">
      <div className={styling("p-3")}>
        <Each
          of={applicants}
          render={(applicant) => {
            const profile = profiles[normaliseID(applicant?.user)];

            return (
              <ApplicantCard
                key={applicant._id}
                applicant={applicant}
                profile={profile}
                actionApplication={actionApplicant}
                showButtons={!!defaultPaymentMethod}
                onClick={() =>
                  navigate(`/people/${normaliseID(applicant?.user)}`, {
                    state: { shift: normaliseID(applicant?.shift) },
                  })
                }
                setShowConfirmModal={setConfirmingApplicant}
              />
            );
          }}
        />
      </div>
    </EmptyState>
  );
};

export default ApplicantsMobile;
