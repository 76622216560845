import styles from "./ShiftlySuspense.module.scss";
import { useStyling } from "../../hooks/useStyling.js";
import { LoadingSpinner } from "./LoadingSpinner";
import { useDelayUnmount } from "../../hooks/useDelayUnmount";
import clsx from "clsx";

export const ShiftlySuspense = ({ loading = false, children, loader, className, ...props }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const delayedLoader = useDelayUnmount(loading);

  return delayedLoader
    ? loader ?? (
        <div className={clsx(styling("container"), className)} {...props}>
          <LoadingSpinner loader={loading} />
        </div>
      )
    : children;
};
