import { Input, useStyling } from "src/shiftly-ui";
import { useCallback, useRef } from "react";
import styles from "./NewPosition.module.scss";

const NewPositionQualification = ({ id, description, setQualifications, index, qualifications }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  /********************************** Refs & Constants ********************************* */
  const inputRef = useRef();

  /******************************** Functions & Memos ********************************** */
  const removeQualification = useCallback(() => {
    setQualifications((prev) => prev.filter((qualification, i) => qualification.id !== id));
  }, [id, setQualifications]);

  const updateQualification = useCallback(
    (field, value) =>
      setQualifications((prev) =>
        prev.map((qualification) => {
          if (qualification.id === id) {
            return { ...qualification, [field]: value };
          }
          return qualification;
        })
      ),
    [setQualifications, id]
  );

  return (
    <div className={styling("qualification")}>
      <Input
        placeholder={"RSA / Customer Service / Barista Trained"}
        onChange={(v) => updateQualification("description", v)}
        value={description}
        ref={inputRef}
        label={"Qualification"}
        link={{
          label: "Remove Qualification",
          onClick: removeQualification,
        }}
      />
    </div>
  );
};
export default NewPositionQualification;
