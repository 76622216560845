import { useState, useLayoutEffect, useCallback, useEffect } from "react";
import styles from "./XeroIntegrationBase.module.scss";
import {
  useStyling,
  useFetch,
  useAlerts,
  useToast,
  useCachedQuery,
  ShiftlySuspense,
  normaliseID,
  OrangeSpan,
} from "src/shiftly-ui";
import StandardLayout from "src/components/standard_layout/StandardLayout";
import IntegrationStep from "../IntegrationStep";
import { faGear, faPlug, faSpinner, faStore } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IntegrationMap from "../IntegrationMap";
import { useNavigate } from "react-router-dom";
import useBusiness from "src/hooks/useBusiness";
import XeroButton from "../../../assets/xero-connect-white.svg";
import XeroBusinessConfiguration from "./XeroBusinessConfiguration";

const stateTimezones = {
  NSW: "Australia/Sydney", // New South Wales
  VIC: "Australia/Melbourne", // Victoria
  QLD: "Australia/Brisbane", // Queensland
  SA: "Australia/Adelaide", // South Australia
  WA: "Australia/Perth", // Western Australia
  TAS: "Australia/Hobart", // Tasmania
  NT: "Australia/Darwin", // Northern Territory
  ACT: "Australia/Sydney", // Australian Capital Territory (same as NSW)
};

const XeroIntegrationBase = ({
  activeIntegration,
  isLoading,
  connectionLink,
  xeroOrganisations = [],
  activeStep,
  setActiveStep,
}) => {
  /*************************************** State *************************************** */
  const [selectedFrequencies, setSelectedFrequencies] = useState({});
  const [mappedBusinesses, setMappedBusinesses] = useState(null);

  /*************************************** Hooks *************************************** */
  const { availableBusinesses, refreshAvailableBusinesses, refreshActiveBusiness } = useBusiness();
  const toast = useToast();

  const { confirm, prompt } = useAlerts();
  const navigate = useNavigate();
  const {
    Integration: { GetActiveIntegrationByType, GetAllIntegrations, GetXeroElements },
  } = useCachedQuery();

  /********************************** Refs & Constants ********************************* */

  /************************************** Queries ************************************** */
  const { data: industries } = useFetch({ request: { entity: "Industry" } });

  const { post: disconnectOrganisation, isLoading: disconnectIsLoading } = useFetch({
    options: {
      onMutate: ({ data }) => {
        setMappedBusinesses((prev) => {
          const { [data.id]: _, ...rest } = prev;
          return rest;
        });
      },
      onSuccess: () => {
        refresh([GetXeroElements, GetAllIntegrations, GetActiveIntegrationByType]);
        toast.success("Organisation disconnected successfully");
      },
    },
  });

  const {
    post: updateIntegration,
    refresh,
    isLoading: updateIsLoading,
  } = useFetch({
    options: {
      onSuccess: () => {
        refresh([GetXeroElements, GetAllIntegrations, GetActiveIntegrationByType]);
        refreshAvailableBusinesses();
        refreshActiveBusiness();
        setActiveStep(3);
      },
    },
  });

  const { post: saveBusinessConfig, saveConfigIsLoading } = useFetch({
    options: {
      onSuccess: () => {
        toast.success("Integration updated successfully");
        refresh([GetXeroElements, GetAllIntegrations, GetActiveIntegrationByType]);
        navigate("/integrations");
        refreshActiveBusiness();
        refreshAvailableBusinesses();
      },
    },
  });

  const { post: createBusinessFromXero } = useFetch({
    options: {
      onSuccess: () => {
        refreshAvailableBusinesses();
        refresh([GetXeroElements]);
      },
      onError: () => toast.error("Failed to create business. Please continue manually."),
    },
  });

  /******************************** Functions & Memos ********************************** */
  const handleDisconnectOrg = useCallback(
    async (org) => {
      if (
        !(await confirm({
          label: (
            <>
              Are you sure you want to <span>disconnect from Shiftly?</span>
            </>
          ),
          text: <>You can reconnect to {org.name} at any time.</>,
          mode: "info",
          confirmText: "Disconnect",
          cancelText: "Cancel",
          inverse: true,
        }))
      )
        return;

      disconnectOrganisation({
        entity: "Integration",
        method: "disconnect",
        data: {
          id: org.id,
          type: "xero",
        },
      });
    },
    [confirm, disconnectOrganisation]
  );

  const handleCreateBusiness = useCallback(
    async (organisation = {}) => {
      const { address } = organisation;
      const timezone = stateTimezones[address?.region];

      if (timezone) {
        let awardsList = [];

        while (awardsList.length === 0) {
          awardsList = await prompt({
            label: (
              <>
                Would you like to <span>generate a Shiftly business?</span>
              </>
            ),
            text: `You can import your organisation's details directly from Xero. This will automatically create a corresponding business in Shiftly. Alternatively, you can set up the business manually. Please select the relevant industry awards that apply to your workforce.`,
            confirmText: "Import organisation details from Xero",
            cancelText: "Create Manually",
            cancelAction: () => {
              navigate("/?open=true");
            },
            mode: "info",
            type: "dropdown",
            inputProps: {
              label: "Which industry awards cover your clients workforce?",
              multiSelect: true,
              placeholder: "Select awards",
              options: industries.map((industry) => ({
                label: `${industry.award_code} - ${industry?.name}`,
                value: industry?._id,
              })),
            },
          });
          if (awardsList === "") toast.error("Please select at least one industry award.");
        }

        if (!awardsList || !awardsList.length) return;

        createBusinessFromXero({
          entity: "Business",
          method: "create",
          data: {
            name: organisation.name,
            location: {
              name: organisation.name,
              timezone,
            },
            address: {
              street: address?.addressLine1,
              city: address?.city,
              state: address?.region,
              postalCode: address?.postalCode,
              country: address?.country ?? "Australia",
            },
            industries: awardsList,
            xero_integration: activeIntegration?._id,
            xero_organisation: organisation._id,
          },
        });
        return;
      }
    },
    [industries, prompt, navigate, activeIntegration, createBusinessFromXero, toast]
  );

  const handleMapBusinesses = useCallback(async () => {
    if (Object.keys(mappedBusinesses).length !== xeroOrganisations.length) {
      if (
        !(await confirm({
          label: (
            <>
              Are you sure you want to <span>continue?</span>
            </>
          ),
          text: `You haven't mapped all Xero organisations to Shiftly businesses. Any unmapped organisations will be disconnected from Xero.`,
          confirmText: "Continue",
          cancelText: "Cancel",
        }))
      )
        return;
    }

    const organisationsToDisconnect = xeroOrganisations.filter((org) => !mappedBusinesses[org.id]);

    const payloads = [
      {
        entity: "Business",
        method: "update",
        criteria: {},
        data: {
          xero_integration: null,
          xero_organisation: null,
        },
      },
      ...xeroOrganisations.flatMap((org) => [
        {
          entity: "Business",
          method: "update",
          criteria: {
            _id: mappedBusinesses[org.id],
          },
          data: {
            xero_organisation: xeroOrganisations.find((xeroOrg) => xeroOrg.id === org.id)?._id,
            xero_integration: activeIntegration?._id,
          },
        },
        {
          entity: "XeroOrganisation",
          method: "update",
          criteria: {
            id: org.id,
          },
          data: {
            business: mappedBusinesses[org.id],
          },
        },
      ]),
      ...organisationsToDisconnect.map((org) => ({
        entity: "Integration",
        method: "disconnect",
        data: {
          id: org.id,
          type: "xero",
        },
      })),
    ];

    updateIntegration(payloads);
  }, [mappedBusinesses, xeroOrganisations, confirm, updateIntegration, activeIntegration]);

  const handleSaveConfiguration = useCallback(() => {
    if (Object.keys(mappedBusinesses).length !== Object.keys(selectedFrequencies).length) {
      toast.error("Please selecte a pay frequency for all integrated businesses.");
      return;
    }

    const payloads = Object.entries(selectedFrequencies).map(([business, payroll_calendar_id]) => ({
      entity: "Business",
      method: "update",
      criteria: { _id: business },
      data: {
        payroll_calendar_id,
      },
    }));

    saveBusinessConfig(payloads);
  }, [selectedFrequencies, mappedBusinesses, toast, saveBusinessConfig]);

  /******************************** Effects & Handles ********************************** */
  useLayoutEffect(() => {
    if (!activeIntegration) return;
    const newMapped = xeroOrganisations.reduce((acc, org) => {
      if (org.business) acc[org.id] = org.business;
      return acc;
    }, {});

    setMappedBusinesses((prev) => ({ ...prev, ...newMapped }));
  }, [activeIntegration, xeroOrganisations]);

  useEffect(() => {
    if (!activeIntegration) return;
    const numberOfMappedBusinesses = Object.keys(mappedBusinesses ?? {}).length;
    if (numberOfMappedBusinesses && numberOfMappedBusinesses === xeroOrganisations?.length) {
      setActiveStep(3);
      return;
    }
    setActiveStep(2);
    //eslint-disable-next-line
  }, [activeIntegration, xeroOrganisations.length]);

  useEffect(() => {
    const filteredBusiness = availableBusinesses.filter((a) => {
      return Object.values(mappedBusinesses ?? {}).some((b) => b === normaliseID(a));
    });

    const selectedFrequencies = Object.entries(mappedBusinesses ?? {}).reduce((acc, [key, value]) => {
      const business = filteredBusiness.find((b) => b._id === value);
      if (business) {
        acc[value] = business.payroll_calendar_id;
      }
      return acc;
    }, {});

    setSelectedFrequencies(selectedFrequencies);
  }, [availableBusinesses, mappedBusinesses]);

  const xeroButton = <ConnectToXeroButton activeIntegration={activeIntegration} connectionLink={connectionLink} />;

  return (
    <StandardLayout
      heading={
        <>
          Xero <OrangeSpan>Integration</OrangeSpan>
        </>
      }
      breadcrumb={[
        { label: "Business Settings", link: "/business-account" },
        { label: "Integrations", link: "/integrations" },
      ]}
    >
      <ShiftlySuspense loading={isLoading}>
        <IntegrationStep
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          status={activeIntegration ? "complete" : "open"}
          number={1}
          name={activeIntegration ? "Connected" : "Connect to Xero"}
          icon={faPlug}
          description={
            activeIntegration ? (
              <>
                If you would like to connect additional organisations, click the <strong>'Connect to Xero'</strong>{" "}
                button above.
              </>
            ) : (
              `To connect your Xero account with Shiftly, you'll need to authenticate via OAuth. When you click on the Connect button, you'll be redirected to the Xero login page. Here, you'll log in with your Xero credentials and authorise Shiftly to access your Xero organisation. Once authorised, you'll be redirected back to Shiftly, where your Xero account will be securely connected. `
            )
          }
          customButton={xeroButton}
          completeButton={xeroButton}
        ></IntegrationStep>

        <IntegrationStep
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          status={activeStep === 3 ? "complete" : activeStep === 1 ? "incomplete" : "open"}
          number={2}
          name={"Map Businesses"}
          icon={faStore}
          buttonText={updateIsLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Next"}
          buttonAction={handleMapBusinesses}
          disabled={!xeroOrganisations.length || updateIsLoading || disconnectIsLoading}
          description={`In this section, you'll need to map Xero organisations to their corresponding Shiftly businesses. If a Shiftly business doesn't exist yet, please create it before completing the mapping. Any unmapped organisations will be disconnected.`}
          onClick={() => {
            if (activeStep === 3) setActiveStep(2);
          }}
        >
          <IntegrationMap
            toLink={xeroOrganisations}
            options={availableBusinesses}
            mode={"businesses"}
            data={mappedBusinesses}
            setData={setMappedBusinesses}
            setActiveStep={setActiveStep}
            onDelete={handleDisconnectOrg}
            onCreate={handleCreateBusiness}
          />
        </IntegrationStep>
        <IntegrationStep
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          status={activeStep !== 3 ? "incomplete" : "open"}
          number={3}
          name={"Configure Integration"}
          icon={faGear}
          buttonText={saveConfigIsLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Save"}
          buttonAction={handleSaveConfiguration}
          disabled={!xeroOrganisations.length || saveConfigIsLoading}
          description="We need to confirm a few settings with you to ensure your integration between Shiftly and Xero runs smoothly."
          onClick={() => {
            if (xeroOrganisations.length === Object.keys(mappedBusinesses ?? {}).length) setActiveStep(3);
          }}
        >
          <XeroBusinessConfiguration
            mappedBusinesses={mappedBusinesses}
            selectedFrequencies={selectedFrequencies}
            setSelectedFrequencies={setSelectedFrequencies}
          />
        </IntegrationStep>
      </ShiftlySuspense>
    </StandardLayout>
  );
};

const ConnectToXeroButton = ({ connectionLink }) => {
  /*************************************** State *************************************** */
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const { activeBusiness } = useBusiness();
  const navigate = useNavigate();

  /******************************** Functions & Memos ********************************** */
  const handleButtonClick = useCallback(() => {
    if (!activeBusiness?.preferred_payment_method) {
      navigate("/business-account/billing-and-payment");
      return;
    }
    window.open(connectionLink, "_self");
  }, [connectionLink, activeBusiness, navigate]);

  return (
    <div className={styling("flex", "flex-start", "mb-2")}>
      <img
        src={XeroButton}
        alt="Xero Connect Button"
        className={styling("cursor-pointer", "xero-btn")}
        onClick={handleButtonClick}
      ></img>
    </div>
  );
};

export default XeroIntegrationBase;
