import { useMemo } from "react";
import useSubscriptions from "src/hooks/useSubscriptions";
import PricingLayout from "./components/PricingLayout";

const PricingScheduler = () => {
  const { prices, current } = useSubscriptions();

  const cards = useMemo(
    () => [
      {
        title: "Free",
        description:
          "Get started with our powerful scheduling tool at no cost. Ideal for businesses of all sizes to manage shifts and staff effortlessly with unlimited users.",
        prices: [{ amount: 0, unit: "month", id: prices?.scheduler?.free, subscription_item: current?.scheduler?.id }],
        highlight: "Unlimited users",
        active: current.scheduler?.price === prices?.scheduler?.free,
      },
    ],
    [prices, current]
  );

  return <PricingLayout name={"Scheduler"} cards={cards} />;
};

export default PricingScheduler;
