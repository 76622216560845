import { useEffect, useState } from "react";
const useContextMenu = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  let longPressTimer;

  const menuWidth = 400;
  const menuHeight = 520;

  const handleRightClick = (event) => {
    event.preventDefault();
    let posX = event.pageX;
    let posY = event.pageY;
    // Adjust if going out of bounds on the X-axis
    if (posX + menuWidth > window.innerWidth) {
      posX = window.innerWidth - menuWidth;
    } else if (posX < 0) {
      posX = 0;
    }
    // Adjust if going out of bounds on the Y-axis
    if (posY + menuHeight > window.innerHeight) {
      posY = window.innerHeight - menuHeight;
    } else if (posY < 0) {
      posY = 0;
    }
    setPosition({ x: posX, y: posY });
    setDropdownVisible(true);
  };

  const handleTouchStart = (event) => {
    const touch = event.touches[0];
    longPressTimer = setTimeout(() => {
      let posX = touch.pageX;
      let posY = touch.pageY;
      // Adjust if going out of bounds on the X-axis
      if (posX + menuWidth > window.innerWidth) {
        posX = window.innerWidth - menuWidth;
      } else if (posX < 0) {
        posX = 0;
      }
      // Adjust if going out of bounds on the Y-axis
      if (posY + menuHeight > window.innerHeight) {
        posY = window.innerHeight - menuHeight;
      } else if (posY < 0) {
        posY = 0;
      }
      setPosition({ x: posX, y: posY });
      setDropdownVisible(true);
    }, 500);
  };

  const handleTouchEnd = () => {
    clearTimeout(longPressTimer);
  };

  const handleClickOutside = () => {
    setDropdownVisible(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") setDropdownVisible(false);
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("click", handleClickOutside);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return {
    dropdownVisible,
    position,
    handleRightClick,
    handleTouchStart,
    handleTouchEnd,
  };
};
export default useContextMenu;
