import { useInlineStyles, useStyling } from "src/shiftly-ui";
import { useNavigate } from "react-router-dom";
import styles from "./Button.module.scss";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { useLayoutEffect, useMemo, useRef, useState } from "react";
import { motion } from "framer-motion";

const fadeVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};
export const Button = ({
  className,
  children,
  theme,
  size,
  onClick,
  icon,
  link,
  type = "button",
  loading = false,
  error,
  iconSide = "left",
  disabled,
  disabledTooltip,
  ...props
}) => {
  const [renderedContent, setRenderedContent] = useState(<></>);

  const style = useInlineStyles(props);
  const styling = useStyling(styles);
  const navigate = useNavigate();
  const initialRender = useRef(true);

  const shouldShowTooltip = disabled && disabledTooltip;

  const staticContent = useMemo(
    () => (
      <>
        {icon && (
          <FontAwesomeIcon icon={icon} className={clsx(styling("icon"), iconSide === "left" && styling("icon-left"))} />
        )}
        {children}
      </>
    ),
    [icon, iconSide, children, styling]
  );

  useLayoutEffect(() => {
    if (initialRender.current) {
      setRenderedContent(staticContent);
      initialRender.current = false;
      return;
    }

    if (loading) {
      return setRenderedContent(
        <div className={styling("loading-spinner")}>
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      );
    }

    const timeoutid = setTimeout(() => {
      if (!error) return setRenderedContent(staticContent);
      setRenderedContent(<FontAwesomeIcon icon={faTimes} />);
      setTimeout(() => setRenderedContent(staticContent), 2000);
    }, 300);

    return () => clearTimeout(timeoutid);
  }, [loading, error, children, icon, iconSide, staticContent, styling]);

  const buttonElement = (
    <button
      type={type}
      onClick={link ? () => navigate(link) : onClick}
      className={clsx(
        styling("button"),
        styling(theme),
        styling(size),
        loading && styling("loading"),
        className,
        disabled && styling("disabled")
      )}
      style={style}
      disabled={loading || disabled}
      {...props}
    >
      <motion.div
        key={renderedContent}
        initial="visible"
        animate="visible"
        exit="hidden"
        variants={fadeVariants}
        transition={{ duration: 0.3 }}
        className={clsx(styling("content"), icon && styling(iconSide))}
      >
        {renderedContent}
      </motion.div>
    </button>
  );

  return shouldShowTooltip ? (
    <div className={styling("tooltip-wrapper")} style={{ display: "inline-block", position: "relative" }}>
      {buttonElement}
      <div className={styling("tooltip-content")}>
        {disabledTooltip.title && (
          <p className={styling("mb-1", "label")}>
            <strong>{disabledTooltip.title}</strong>
          </p>
        )}
        {disabledTooltip.text && <p className={styling("label")}>{disabledTooltip.text}</p>}
      </div>
    </div>
  ) : (
    buttonElement
  );
};
