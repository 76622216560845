import { useCallback, useMemo } from "react";
import { useFetch } from "./useFetch";
import { useAuth } from "./useAuth";

const publicVapidKey = process.env.REACT_APP_VAPID_KEY;

const urlBase64ToUint8Array = (base64String = "") => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export const usePushNotifications = (receiver) => {
  /*************************************** Hooks *************************************** */
  const { user } = useAuth();

  /************************************** Queries ************************************** */
  const { post: subscribeUser } = useFetch();

  const {
    data: { readNotifications = [], unreadNotifications = [] },
    isLoading,
  } = useFetch({
    request: {
      entity: "Notifications",
      method: "get",
      options: {
        limit: 40,
        sort: { createdAt: -1 },
      },
      criteria: {
        receiver,
      },
      id: "Notifications.GetAllNotifications",
    },
    dependency: receiver,
    options: {
      enabled: Boolean(user?._id),
      select: (data) => {
        return {
          readNotifications: data.filter((notification) => notification.read),
          unreadNotifications: data.filter((notification) => !notification.read),
        };
      },
    },
  });

  const { post: markNotificationsAsRead, updateCache } = useFetch({
    options: {
      onSuccess: () => {
        updateCache("Notifications.GetAllNotifications", (oldData = []) => {
          return oldData.map((notification) => ({ ...notification, read: true }));
        });
      },
    },
  });

  /******************************** Functions & Memos ********************************** */
  const saveSubscription = useCallback(
    (subscription) => {
      if (!subscription) return;

      subscribeUser({
        entity: "PushSubscription",
        method: "createSubscription",
        data: { subscription, user: user?._id },
      });
    },
    [subscribeUser, user]
  );

  const updateSubscription = useCallback(async () => {
    const registration = await navigator.serviceWorker?.ready;
    if (!registration) return;

    const subcription = await registration.pushManager.getSubscription();

    saveSubscription(subcription);
    return subcription;
  }, [saveSubscription]);

  const requestPermission = useCallback(async () => {
    const registration = await navigator.serviceWorker?.ready;
    if (!registration) return;

    const subscription = await registration.pushManager.getSubscription();

    if (!subscription) {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        saveSubscription(
          await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
          })
        );
      }
    }
  }, [saveSubscription]);

  const markAsRead = useCallback(() => {
    if (unreadNotifications.length > 0) {
      const criteria = {
        _id: { $in: unreadNotifications.map((notification) => notification._id) },
      };

      markNotificationsAsRead({
        entity: "Notifications",
        method: "update",
        criteria,
        data: {
          read: true,
        },
      });
    }
  }, [markNotificationsAsRead, unreadNotifications]);

  return useMemo(
    () => ({
      updateSubscription,
      requestPermission,
      readNotifications,
      unreadNotifications,
      markAsRead,
      isLoading,
    }),
    [updateSubscription, requestPermission, readNotifications, unreadNotifications, markAsRead, isLoading]
  );
};
