export const queryCache = {
  Auth: {
    InitAuth: "7cc91b86-715d-47c3-a2cc-c770be667c4b",
    GetAccountDetails: "deac70b7-44e1-47cf-8098-2c7e7c137fc0",
  },
  Award: {
    GetFullListOfAwards: "a7823e79-cc27-4462-9d27-15a3a016c01e",
  },
  Business: {
    AvailableBusinesses: "0ef6c60c-725b-475d-b2a3-c85811011993",
  },
  Conversation: {
    GetConversationsForLocation: "17a7d174-6b8d-4342-ae1d-b6dac7a8ef74",
  },
  EmployeeOnboard: {
    LoadOnboardingDetails: "13371a4a-b1a8-4e71-8ecf-b0f0e887acb8",
  },
  Industry: {
    AllIndustries: "c88e656a-4e87-4602-98fd-8e08880a4541",
  },
  Integration: {
    GetActiveIntegrationByType: "474fdb38-8e17-4c5e-9b09-8116cff75de3",
    GetAllIntegrations: "e4671762-a70b-44f0-b05f-cd78fff42db5",
    GetXeroElements: "bbce4f3d-c889-48eb-9246-53e8714f9eb0",
    GetThirdPartyShifts: "b1cc3f39-17a8-49ed-9abf-217994077538",
  },
  InternalShift: {
    GetInternalShifts: "4095a007-1034-489d-9c1a-6f1431acb1b1",
  },
  InternalStaff: {
    GetStaffForLocation: "7a726dfc-8169-4d5d-b301-f3851dbbf2f8",
    GetAllStaffIncludingIncomplete: "ff946ed0-a475-4e40-8ca6-1001db2df090",
  },
  Location: {
    AvailableLocations: "7910f3ee-721c-4c89-83ca-40a0772af0dd",
  },
  Message: {
    GetMessagesWithAccess: "f5b8c5d6-2b1b-4b5f-8b7b-0a6b6b6c7c5b",
  },
  PaymentMethod: {
    CreateSetupIntent: "b03ca6e4-320e-4a60-b251-6f928479bb1f",
    GetDefaultPaymentMethod: "c8674201-57bf-4c82-a450-a35288580d24",
    GetPaymentMethods: "024d60f0-23e0-4687-8948-99549f36700e",
  },
  Profile: {
    GetUserProfile: '81a4aabb-c696-4eae-ad81-47b100ba9aaf'
  },
  Roster: {
    GetRosterForCurrentPeriod: "42ebf573-505c-42e8-bbf4-c19da285f533",
  },
  Shift: {
    ShiftsBetweenDates: "1e10437e-b11d-4760-ab71-c6ae320ec037",
    GetShiftsByLocation: "617e1c9b-b21d-49f9-a7a3-a39eaad7aa4c",
  },
  ShiftApplications: {
    GetApplicationsForShifts: "46df4db8-667f-45c7-b18d-c2aa57c73fc3",
    GetApplicationsForSpecificShift: "b23aeed9-b97d-41ac-86ff-00fdd9b7b345",
    GetApplicationsForUserAndLocation: "cd72610d-0951-4a5d-b312-67fd2cfff9c7",
  },
  Timesheet: {
    GetFilteredTimesheets: "17e713cc-02c3-418f-bab1-133d77727dd2"
  }
};

export const useCachedQuery = () => {
  return queryCache;
};
