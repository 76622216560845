import useBusiness from "src/hooks/useBusiness";
import styles from "./XeroBusinessConfiguration.module.scss";
import { Dropdown, Each, normaliseID, useFetch, useStyling } from "src/shiftly-ui";

import usePayCalendars from "src/hooks/usePayCalendars";

const XeroBusinessConfiguration = ({ mappedBusinesses, selectedFrequencies, setSelectedFrequencies }) => {
  /*************************************** State *************************************** */

  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const { availableBusinesses } = useBusiness();

  /********************************** Refs & Constants ********************************* */

  /******************************** Functions & Memos ********************************** */
  const filteredBusiness = availableBusinesses.filter((a) => {
    return Object.values(mappedBusinesses).some((b) => b === normaliseID(a));
  });

  /************************************** Queries ************************************** */

  /******************************** Effects & Handles ********************************** */

  return (
    <div className={styling("px-4")}>
      <Each
        of={filteredBusiness}
        render={({ key, ...business }) => (
          <BusinessRow
            key={business?._id}
            {...business}
            selectedFrequencies={selectedFrequencies}
            setSelectedFrequencies={setSelectedFrequencies}
          />
        )}
      />
    </div>
  );
};

export default XeroBusinessConfiguration;

const BusinessRow = ({ _id, name, xero_organisation, selectedFrequencies, setSelectedFrequencies } = {}) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const { frequencySelect, isLoading } = usePayCalendars(xero_organisation);

  return (
    !isLoading && (
      <div className={styling("flex", "flex-between", "mt-1", "mb-3")}>
        <p className={styling("w-50")}>
          <strong>{name}</strong>
        </p>
        <Dropdown
          options={frequencySelect}
          label={"Pay Frequency"}
          className={styling("w-50")}
          required
          value={selectedFrequencies[_id]}
          setValue={(payroll_calendar_id) => {
            setSelectedFrequencies((prev) => ({
              ...prev,
              [_id]: payroll_calendar_id,
            }));
          }}
        />
      </div>
    )
  );
};
