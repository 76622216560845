import StandardLayout from "src/components/standard_layout/StandardLayout";
import styles from "./Managers.module.scss";
import { OrangeSpan, useStyling } from "src/shiftly-ui";
import useBusiness from "src/hooks/useBusiness";

const Managers = () => {
  /*************************************** State *************************************** */

  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  const { activeBusiness } = useBusiness();

  /********************************** Refs & Constants ********************************* */

  /******************************** Functions & Memos ********************************** */

  /************************************** Queries ************************************** */

  /******************************** Effects & Handles ********************************** */

  return (
    <StandardLayout
      heading={
        <>
          <OrangeSpan>Managers</OrangeSpan> of {activeBusiness?.name}
        </>
      }
      description={
        <>
          Grant access to this business, or transfer ownership of business management to another user by adding a
          business manager
        </>
      }
      buttonText={"Share Access to " + activeBusiness?.name}
      buttonEvent={() => {}}
    ></StandardLayout>
  );
};

export default Managers;

//1. Get the owner of the business's account and organisation name, mark it has 'owner'
//2. Get all accounts iwht a 'Shared Access' record for this business, and highligh the organisation name
