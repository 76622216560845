import {
  useAlerts,
  useFetch,
  buildAwardSummaryLink,
  useSearchParams,
  OrangeSpan,
  normaliseID,
  EmptyState,
  Table,
} from "src/shiftly-ui";
import NewPositionModal from "./components/NewPositionModal";
import { useCallback, useMemo, useState } from "react";
import useBusiness from "src/hooks/useBusiness";
import { faTrash, faPencil, faIdBadge } from "@fortawesome/pro-regular-svg-icons";
import StandardLayout from "src/components/standard_layout/StandardLayout";
import usePositions from "src/hooks/usePositions";
import { useNavigate } from "react-router-dom";

const PositionsHome = () => {
  /*************************************** State *************************************** */
  const [newPositionModal, setNewPositionModal] = useState(false);
  const [initial, setInitial] = useState({
    group: "",
    name: "",
    description: "",
    qualifications: [],
    position_rate_modifier: 0,
    is_active: true,
  });

  /*************************************** Hooks *************************************** */
  const { isLoading } = useBusiness();
  const { groupedPositions, refreshAvailablePositions } = usePositions();
  const { confirm } = useAlerts();
  const navigate = useNavigate();
  /************************************** Queries ************************************** */
  const { post: deletePosition } = useFetch({
    options: {
      onSuccess: () => {
        refreshAvailablePositions();
      },
    },
  });

  /******************************** Functions & Memos ********************************** */
  const handleDelete = useCallback(
    async (position) => {
      if (
        !(await confirm({
          label: (
            <>
              Are you sure you want to
              <span>delete this position?</span>
            </>
          ),
          text: (
            <>
              Shifts currently associated with this position will continue as normal, however you will no longer be able
              to create shifts for this position.
            </>
          ),
          inverse: true,
          confirmText: "Delete",
        }))
      )
        return;
      deletePosition({
        entity: "Position",
        method: "delete",
        criteria: { _id: position._id },
      });
    },
    [confirm, deletePosition]
  );

  const handleEdit = useCallback((row) => {
    setInitial({ ...row, group: normaliseID(row.group) });
    setNewPositionModal(true);
  }, []);

  const positions = useMemo(() => Object.values(groupedPositions)?.map((group) => group[0]), [groupedPositions]);

  const columns = useMemo(
    () => [
      { field: "name", headerName: "Name" },
      { field: "award", headerName: "Award Code", render: ({ industry }) => industry?.award_code },
      { field: "description", headerName: "Description", flex: 2 },
      {
        field: "position_rate_modifier",
        headerName: "Position Rate Modifier",
        render: ({ position_rate_modifier }) => `$${position_rate_modifier.toFixed(2)}`,
      },
    ],
    []
  );
  /******************************** Effects & Handles ********************************** */
  useSearchParams(["mode"], ({ mode }) => {
    mode === "new" && setNewPositionModal(true);
  });

  return (
    <>
      <NewPositionModal
        newPositionModal={newPositionModal}
        setNewPositionModal={setNewPositionModal}
        initial={initial}
        setInitial={setInitial}
      />
      <StandardLayout
        heading={
          <>
            Your <OrangeSpan>Positions</OrangeSpan>
          </>
        }
        buttonText={"Create New Position"}
        buttonEvent={() => {
          setInitial({
            group: "",
            name: "",
            description: "",
            qualifications: [],
            position_rate_modifier: 0,
            is_active: true,
          });
          setNewPositionModal(true);
        }}
      >
        <EmptyState items={positions} image={"empty_positions.webp"} isLoading={isLoading}>
          <Table
            columns={columns}
            rows={positions}
            onRowDoubleClick={({ row }) => handleEdit(row)}
            actions={[
              {
                icon: faPencil,
                label: "Edit",
                onClick: handleEdit,
              },
              {
                icon: faIdBadge,
                label: "View Award",
                onClick: ({ group }) => {
                  navigate(`/positions/position-levels/${normaliseID(group)}`);
                },
              },
              {
                icon: faTrash,
                label: "Delete",
                onClick: handleDelete,
              },
            ]}
            link={{
              label: "View awards",
              destination: buildAwardSummaryLink(),
            }}
          />
        </EmptyState>
      </StandardLayout>
    </>
  );
};

export default PositionsHome;
