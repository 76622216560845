import { Input, useFetch, FormV2, Button, RadioButtons, useAlerts, ButtonGroup, FormFlex } from "src/shiftly-ui";

import useBusiness from "src/hooks/useBusiness";
import StandardLayout from "../../../components/standard_layout/StandardLayout";
import NewBusinessRadioButtonCard from "../new_business_setup/NewBusinessRadioButtonCard";
import { faBurger, faForkKnife, faHotel, faMartiniGlass, faSave } from "@fortawesome/pro-regular-svg-icons";
import { useEffect, useMemo, useState } from "react";
import { useStyling, OrangeSpan, useUI, Dropdown } from "src/shiftly-ui";
import useIndustries from "src/hooks/useIndustries";
import { useNavigate } from "react-router-dom";

const iconMap = {
  MA000009: faForkKnife,
  MA000003: faBurger,
  MA000119: faHotel,
};

const supportedAwards = ["MA000009", "MA000003", "MA000119"];

const BusinessAccountBusinessDetails = () => {
  /*************************************** State *************************************** */
  const [data, setData] = useState({});

  /*************************************** Hooks *************************************** */
  const styling = useStyling();
  const { activeBusiness, setActiveBusiness, refreshAvailableBusinesses, clearActiveBusiness } = useBusiness();
  const { industries } = useIndustries();
  const navigate = useNavigate();
  const { confirm } = useAlerts();
  const {
    settings: { isAThirdPartyProvider },
  } = useUI();

  /************************************** Queries ************************************** */
  const {
    post: saveBusiness,
    isLoading,
    isError,
  } = useFetch({
    options: {
      onSuccess: ([newBusiness]) => {
        setActiveBusiness({
          ...newBusiness,
          industries:
            newBusiness?.industries?.map((i) => {
              const industryIndex = industries.findIndex((ind) => ind._id === i);
              return industries[industryIndex];
            }) || [],
        });
      },
    },
  });

  const { post: deleteBusiness, isLoading: deleteIsLoading } = useFetch({
    options: {
      onSuccess: () => {
        clearActiveBusiness();
        refreshAvailableBusinesses();
        navigate("/");
      },
    },
  });

  /******************************** Functions & Memos ********************************** */
  const supportedIndustries = useMemo(
    () => industries?.filter((award) => supportedAwards.includes(award?.award_code)),
    [industries]
  );

  /******************************** Effects & Handles ********************************** */
  useEffect(() => {
    setData({ ...activeBusiness, industries: activeBusiness?.industries?.map((i) => i?._id) || [] });
  }, [activeBusiness]);

  return (
    <StandardLayout
      heading={
        <>
          <OrangeSpan>Business</OrangeSpan> Details
        </>
      }
      breadcrumb={[{ label: "Business Settings", link: "/business-account" }]}
    >
      <FormV2
        onSubmit={async (data) => {
          return await saveBusiness({
            entity: "Business",
            criteria: { _id: activeBusiness?._id },
            data,
            method: "update",
          });
        }}
        initial={activeBusiness}
        data={data}
        setData={setData}
      >
        <Input label={"Business Name"} name={"name"} placeholder={"Business Name"} />
        <Input name={"abn"} required={false} />

        {isAThirdPartyProvider ? (
          <div className={styling("award-dropdown")}>
            <Dropdown
              name={"industries"}
              label={"Which industry awards cover your clients workforce?"}
              multiSelect
              required
              options={industries.map((industry) => ({
                label: `${industry.award_code} - ${industry?.name}`,
                value: industry?._id,
                chip: industry?.award_code,
              }))}
            ></Dropdown>
          </div>
        ) : (
          <RadioButtons
            mt="30px"
            required
            align="center"
            multiselect
            label={"Which industry awards cover your workforce?"}
            options={supportedIndustries.map((industry) => ({
              label: industry?.name,
              value: industry?._id,
              content: (
                <NewBusinessRadioButtonCard
                  label={industry?.name}
                  award={industry?.award_code}
                  icon={iconMap[industry?.award_code]}
                  active={data?.category === industry?._id}
                />
              ),
            }))}
            type="cards"
            name="industries"
          />
        )}
        <ButtonGroup>
          <Button
            error={isError}
            loading={isLoading}
            theme={"primary"}
            type="submit"
            icon={faSave}
            className={styling("mt-3")}
          >
            Save
          </Button>
        </ButtonGroup>
      </FormV2>
      <ButtonGroup>
        <Button
          loading={deleteIsLoading}
          theme={"danger-transparent"}
          onClick={async () => {
            if (
              !(await confirm({
                label: (
                  <>
                    Are you sure you want to <span>delete this business?</span>
                  </>
                ),
                text: "Penalties may apply for confirmed shifts. This cannot be undone.",
                mode: "danger",
                confirmText: "Delete",
                cancelText: "Cancel",
                inverse: true,
              }))
            )
              return;

            deleteBusiness({
              entity: "Business",
              criteria: { _id: activeBusiness?._id },
              method: "delete",
            });
          }}
        >
          Delete this business
        </Button>
      </ButtonGroup>
    </StandardLayout>
  );
};

export default BusinessAccountBusinessDetails;
