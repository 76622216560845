import {
  Button,
  Each,
  OrangeSpan,
  ButtonGroup,
  useStyling,
  ShiftlySuspense,
  useFetch,
  normaliseID,
  roundNumber,
} from "src/shiftly-ui";
import StandardLayout from "../../../../components/standard_layout/StandardLayout";
import styles from "./BusinessAccountBillingAndPayment.module.scss";
import { faFileInvoice, faRefresh } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { faChevronRight, faLoader, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import BusinessAccountAddNewPaymentMethod from "./BusinessAccountAddNewPaymentMethod";
import BusinessAccountPaymentMethodDisplay from "./components/BusinessAccountPaymentMethodDisplay";
import usePaymentMethods from "src/hooks/usePaymentMethods";
import useBusiness from "src/hooks/useBusiness";

const links = [
  { text: "Payment History", icon: faFileInvoice, link: "/business-account/billing-and-payment/payment-history" },
];

const AccountBillingAndPayment = () => {
  /*************************************** State *************************************** */
  const [tab, setTab] = useState(0);
  const [showModal, setShowModal] = useState(false);

  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const { loading, defaultPaymentMethod, preferredMethod } = usePaymentMethods();
  const { activeBusiness } = useBusiness();

  /************************************** Queries ************************************** */
  const {
    data: [upcomingInvoice],
    isLoading,
  } = useFetch({
    request: {
      entity: "Payment",
      method: "getUpcomingInvoice",
      data: {
        business: normaliseID(activeBusiness),
      },
    },
    dependency: normaliseID(activeBusiness),
  });

  return (
    <StandardLayout
      heading={
        <>
          Billing & <OrangeSpan>Payment</OrangeSpan>
        </>
      }
      breadcrumb={[{ label: "Business Settings", link: "/business-account" }]}
      size="large"
    >
      <BusinessAccountAddNewPaymentMethod showModal={showModal} setShowModal={setShowModal} tab={tab} setTab={setTab} />
      <div className={styling("flex", "flex-between", "flex-align-start")}>
        <div className={styling("w-100", "sm-w-50", "p-3")}>
          <h5 className={styling("mb-3")}>Payment Method</h5>

          <ShiftlySuspense loading={loading} style={{ minHeight: "200px" }}>
            {defaultPaymentMethod && <BusinessAccountPaymentMethodDisplay {...defaultPaymentMethod} />}
            {preferredMethod && defaultPaymentMethod?._id !== preferredMethod?._id && (
              <BusinessAccountPaymentMethodDisplay {...preferredMethod} />
            )}
          </ShiftlySuspense>

          {!loading && (
            <ButtonGroup>
              <Button
                theme={"primary-transparent"}
                iconSide="left"
                icon={defaultPaymentMethod && preferredMethod?._id !== defaultPaymentMethod?._id ? faRefresh : faPlus}
                onClick={() => setShowModal(true)}
              >
                {!defaultPaymentMethod
                  ? "Add Payment Method"
                  : preferredMethod?._id !== defaultPaymentMethod?._id
                  ? "Change Payment Method"
                  : "Add a preferred payment method"}
              </Button>
            </ButtonGroup>
          )}
        </div>
        <div className={styling("links-container", "w-100", "sm-w-50", "mt-5")}>
          <Each of={links} render={({ key, ...link }) => <BillingLink key={key} {...link} />} />
          {isLoading ? (
            <div className={styling("flex", "mt-3")}>
              <FontAwesomeIcon icon={faLoader} spin />
            </div>
          ) : (
            <div className={styling("upcoming", "mt-3")}>
              <h6 className={styling("ml-1")}>Upcoming Payment</h6>
              <div className={styling("invoice-container")}>
                <p>
                  <strong>{new Date(upcomingInvoice?.next_payment_attempt * 1000)?.toLocaleDateString()}</strong>
                </p>
                <p>
                  <strong>${roundNumber(upcomingInvoice?.amount_remaining / 100, 2)?.toFixed(2)}</strong>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </StandardLayout>
  );
};

const BillingLink = ({ icon, text, link }) => {
  const navigate = useNavigate();
  const styling = useStyling(styles);

  return (
    <div className={styling("link")} onClick={() => navigate(link)}>
      <div className={styling("icon-container")}>
        <FontAwesomeIcon icon={icon} />
      </div>
      <p>
        <strong>{text}</strong>
      </p>
      <FontAwesomeIcon icon={faChevronRight} className={styling("ml-auto")} />
    </div>
  );
};

export default AccountBillingAndPayment;
