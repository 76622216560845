import { useStyling } from "src/shiftly-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./ActionsDropdown.module.scss";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { useState } from "react";
import { motion } from "framer-motion";

const variants = {
  visible: {
    height: "auto",
  },
  hidden: {
    height: 0,
  },
};
const ActionsDropdown = ({ actions = [] }) => {
  /*************************************** State *************************************** */
  const [visible, setVisible] = useState(false);
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  return (
    <>
      {/* For click handling only */}
      {visible && <div className={styling("overlay")} onClick={() => setVisible(false)}></div>}
      {actions.length ? (
        <div className={styling("position-relative")}>
          <div className={styling("button", visible && "clicked")} onClick={() => setVisible((prev) => !prev)}>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
          <motion.div
            variants={variants}
            initial={"hidden"}
            animate={visible ? "visible" : "hidden"}
            transition={{ duration: 0.2 }}
            className={styling("dropdown")}
          >
            {actions.map((action, index) =>
              action?.spacer ? (
                <div key={index} className={styling("spacer")}></div>
              ) : (
                <div
                  key={index}
                  className={styling("action", action?.disabled && "disabled")}
                  onClick={() => {
                    action.action?.();
                    setVisible(false);
                  }}
                >
                  <p className={styling("text-left", "label")}>
                    <strong>{action.label}</strong>
                  </p>
                </div>
              )
            )}
          </motion.div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
export default ActionsDropdown;
