import clsx from "clsx";
import { Overlay } from "../modals/Overlay";
import styles from "./SlideFrom.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { useInhibitScroll } from "../../hooks/useInhibitScroll";
import { useStyling } from "src/shiftly-ui";

export const SlideFrom = ({ children, open = false, setOpen = () => {}, direction = "bottom", className }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  /*************************************** Refs *************************************** */
  const scrollingDivRef = useInhibitScroll();

  return (
    <>
      <Overlay open={open} setOpen={setOpen} />
      <div
        className={clsx(styling("container"), styling(direction), open && styling("visible"), className)}
        ref={scrollingDivRef}
      >
        <div className={styling("wrapper")}>
          <FontAwesomeIcon icon={faTimes} onClick={() => setOpen(false)} className={styling("close-icon")} />
          {children}
        </div>
      </div>
    </>
  );
};
