import styles from "./ApplicantsTable.module.scss";
import {
  useStyling,
  RatingDisplay,
  calculateAge,
  Button,
  formatDateToPrettyTime,
  Each,
  Table,
  normaliseID,
} from "src/shiftly-ui";
import { useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faArrowLeftToLine } from "@fortawesome/pro-solid-svg-icons";
import logo from "src/shared/assets/logos/icon.svg";
import ActionsDropdown from "../ActionsDropdown";
import useShiftlyApplicants from "../../hooks/useShiftlyApplicants";
import usePaymentMethods from "src/hooks/usePaymentMethods";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const sidePanelVariants = {
  open: {
    width: "360px",
  },
  closed: {
    width: "0px",
  },
};

const ApplicantsTable = ({ setConfirmingApplicant, activeShift, setActiveShift, applicants, profiles, shifts }) => {
  /*************************************** State *************************************** */
  const [sidePanelOpen, setSidePanelOpen] = useState(false);
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const navigate = useNavigate();
  const { actionApplicant, loading } = useShiftlyApplicants();
  const { defaultPaymentMethod, loading: paymentMethodIsLoading } = usePaymentMethods();

  /******************************** Functions & Memos ********************************** */
  const columns = useMemo(
    () => [
      {
        field: "status",
        headerName: "Status",
        sortable: true,
        align: "center",
        width: 100,
        render: (row) => {
          return <div className={styling("status-icon", row.status)}></div>;
        },
      },
      {
        field: "shifter",
        headerName: "Shifter",
        sortable: true,
        flex: 1,
        minWidth: 200,
        render: (row) => {
          return (
            <div className={styling("shifter-cell")}>
              <div className={styling("shifter-profile", row.status)}>
                <img src={row.profile?.profile_picture || logo} alt="profile" className={styling("cover")} />
              </div>
              <p>{row.shifter}</p>
            </div>
          );
        },
      },
      {
        field: "rating",
        headerName: "Rating",
        sortable: true,
        width: 150,
        render: (row) => <RatingDisplay rating={row.rating} />,
        align: "left",
      },
      {
        field: "start_time",
        headerName: "Shift",
        sortable: true,
        width: 300,
        render: ({ shift }) => {
          return (
            <>
              <span className={styling("shift-date")}>
                {shift?.day_of_week} {shift?.prettyDate}
              </span>
              {shift?.shiftPeriod}
            </>
          );
        },
      },
      { field: "age", headerName: "Age", sortable: true, width: 100, render: (row) => calculateAge(row.age) },
      {
        field: "applied",
        headerName: "Applied",
        sortable: true,
        width: 150,
        render: (row) => formatDateToPrettyTime(row.applied),
      },
      {
        field: "actions",
        headerName: "",
        align: "right",
        width: defaultPaymentMethod ? 320 : 0,
        minWidth: defaultPaymentMethod ? 100 : 0,
        render: ({ status, _id, shift, user, ...applicant }) => {
          if (!defaultPaymentMethod) return "";
          if (status === "rejected") return <FontAwesomeIcon icon={faTimes} className={styling("declined-mark")} />;
          if (status === "accepted") return <FontAwesomeIcon icon={faCheck} className={styling("check-mark")} />;
          return (
            status === "pending" && (
              <div className={styling("actions")}>
                <ActionsDropdown
                  actions={[
                    {
                      label: "Decline",
                      action: () => {
                        actionApplicant({ application_id: _id, user, shift, action: "rejected" });
                      },
                    },
                  ]}
                />
                <Button
                  className={styling("accept")}
                  onClick={() => {
                    setConfirmingApplicant({ _id, user, shift, ...applicant });
                  }}
                >
                  Accept
                </Button>
              </div>
            )
          );
        },
      },
    ],
    [styling, actionApplicant, defaultPaymentMethod, setConfirmingApplicant]
  );

  const rows = useMemo(
    () =>
      applicants.flatMap((applicant) => {
        const profile = profiles[applicant?.user?._id];

        return {
          ...applicant,
          profile,
          id: applicant._id,
          shifter: `${applicant?.user?.first_name} ${applicant?.user?.last_name}`,
          rating: profile?.rating,
          shift: applicant?.shift,
          start_time: applicant?.shift?.start_time,
          age: applicant.user?.date_of_birth,
          applied: applicant.createdAt,
          actions: "actions",
        };
      }),
    [applicants, profiles]
  );

  return (
    <div className={styling("container")}>
      {/* Side Panel */}
      <motion.div
        variants={sidePanelVariants}
        initial="closed"
        animate={sidePanelOpen ? "open" : "closed"}
        className={styling("side-panel")}
      >
        <ShiftRow text={"All Shifters"} setActiveShift={setActiveShift} shift={null} />
        <div className={styling("shifts-wrapper")}>
          <Each
            of={shifts}
            render={({ key, ...shift }) => (
              <ShiftRow
                key={shift?._id}
                text={`${shift?.day_of_week} ${shift?.prettyDate} | ${shift?.shiftPeriod}`}
                subtext={`${shift?.position?.name} - Level ${shift?.position?.classification_level}`}
                setActiveShift={setActiveShift}
                shift={shift}
                activeShift={activeShift}
              />
            )}
          />
        </div>
      </motion.div>

      {/* Table */}
      <div
        className={styling("table-container")}
        style={{
          width: sidePanelOpen ? "calc(100% - 360px)" : "100%",
        }}
      >
        <div className={styling("table-header")}>
          <div className={styling("header-icon")} onClick={() => setSidePanelOpen((prev) => !prev)}>
            <FontAwesomeIcon icon={faArrowLeftToLine} className={styling("sidepanel-icon", sidePanelOpen && "open")} />
          </div>
          {activeShift ? (
            <h3>{`${activeShift?.day_of_week} ${activeShift?.prettyDate} | ${activeShift.shiftPeriod}`}</h3>
          ) : (
            <h3>All Shifters</h3>
          )}
          {!defaultPaymentMethod && !paymentMethodIsLoading && (
            <div className={styling("payment-method")}>
              <p>Please set up a payment method to accept your shifters.</p>
              <Button link={"/business-account/billing-and-payment"} theme="secondary">
                Set up payment method
              </Button>
            </div>
          )}
        </div>
        <div className={styling('table-wrapper')}>
          <Table
            columns={columns}
            onRowDoubleClick={({ row }) => {
              navigate(`/people/${normaliseID(row.user)}`, { state: { shift: normaliseID(row.shift) } });
            }}
            loading={loading}
            rows={rows}
          />
        </div>
      </div>
    </div>
  );
};

export default ApplicantsTable;

const ShiftRow = ({ activeShift, text, subtext, shift, setActiveShift }) => {
  const styling = useStyling(styles);

  return (
    <div
      className={styling("shift-row", shift && activeShift?._id === shift?._id && "active-row")}
      onClick={() => setActiveShift(shift)}
    >
      <div className={styling("shift-row-wrapper")}>
        <p className={styling("shift-date")}>{text}</p>
        {subtext && <p className={styling("shift-position")}>{subtext}</p>}
      </div>
    </div>
  );
};
