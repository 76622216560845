import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import styles from "./Calendar.module.scss";
import ReactCalendar from "react-calendar";
import { stringToDate } from "../../utility/date.js";
import clsx from "clsx";
import { withInputBase } from "../HOC/withInputBase";
import { useStyling } from "../../hooks/useStyling";

const days = ["S", "M", "T", "W", "T", "F", "S"];

export const Calendar = withInputBase(
  forwardRef(
    (
      {
        label = "",
        minView = "year",
        minDate,
        maxDate,
        mode = "tap",
        range = false,
        value,
        setValue = () => {},
        required,
        error,
      },
      ref
    ) => {
      /*************************************** State *************************************** */
      const [selectedDate, setSelectedDate] = useState(value || [new Date(), new Date()]);

      /*************************************** Hooks *************************************** */
      const styling = useStyling(styles);

      /********************************** Refs & Constants ********************************* */
      const startDateRef = useRef(new Date());

      /******************************** Functions & Memos ********************************** */
      const handleTouchMove = useCallback(
        (event) => {
          const touch = event.touches[0];
          const targetElement = document.elementFromPoint(touch.clientX, touch.clientY);

          if (targetElement && targetElement.className.includes(styling("tile-controller"))) {
            const dateString = targetElement.getAttribute("data-date");
            const date = stringToDate(dateString);
            if (!minDate || date >= minDate) {
              setSelectedDate([startDateRef.current, date]);
            }
          }
        },
        [startDateRef, minDate, styling]
      );

      /******************************** Effects & Handles ********************************** */
      useImperativeHandle(
        ref,
        () => ({
          refresh: () => {
            setSelectedDate(mode !== "drag" && range ? [] : null);
          },
        }),
        [mode, range]
      );

      useEffect(() => {
        setSelectedDate(value ?? [new Date(), new Date()]);
      }, [value]);

      useEffect(() => {
        if (mode !== "drag") return;
        window.addEventListener("touchmove", handleTouchMove);
        return () => {
          window.removeEventListener("touchmove", handleTouchMove);
        };
      }, [handleTouchMove, mode]);

      return (
        <div className={styling("flex", "flex-column", "flex-align-start", "w-100")}>
          {label && (
            <p className={clsx(styling("input-label"))}>
              {label}
              {required && <span>*</span>}
            </p>
          )}
          <ReactCalendar
            value={selectedDate}
            className={styling("calendar")}
            onChange={(v) => {
              setValue(range ? (v[1] ? v : [v[0], v[0]]) : v);
              setSelectedDate(v);
            }}
            formatShortWeekday={(locale, date) => days[date.getDay()]}
            minDate={minDate}
            allowPartialRange
            selectRange={mode !== "drag" && range}
            maxDate={maxDate}
            minDetail={minView}
            tileContent={({ date }) => (
              <div
                key={date.toLocaleDateString()}
                className={styling("tile-controller")}
                onTouchStart={() => (startDateRef.current = date)}
                data-date={date.toLocaleDateString()}
              >
                {date.toLocaleDateString()}
              </div>
            )}
          />
          {error && (
            <div className={styling("input-error-container")}>
              <p className={styling("input-message")}>{error}</p>
            </div>
          )}
        </div>
      );
    }
  )
);
