import styles from "./ApplicantProfileDigitalCV.module.scss";
import { Each, formatDateDD_MM_YYYY, useStyling } from "src/shiftly-ui";

const ApplicantProfileDigitalCV = ({ profile = {} }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  /********************************** Refs & Constants ********************************* */
  const { work_experience, qualifications } = profile;

  return (
    <div className={styling("mt-4")}>
      <DisplayGrid
        title={"Work Experience"}
        elements={work_experience.map((experience) => ({
          heading: experience.venue,
          subheading: experience.position,
          description: experience.description,
        }))}
      />
      <DisplayGrid
        title={"Qualifications"}
        elements={qualifications.map((qual) => ({
          heading: qual.name,
          subheading: `Issued by ${qual.issuing_body} on ${formatDateDD_MM_YYYY(qual.issue_date)}`,
          description: qual.description,
        }))}
      />
    </div>
  );
};

const DisplayGrid = ({ title, elements = [] }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  return (
    <div className={styling("grid")}>
      <h6 className={styling("mb-1", "heading")}>{title}</h6>
      <Each
        of={elements}
        render={({ key, ...element }) => {
          return (
            <div className={styling("element")} key={key}>
              <div className={styling("title")}>
                <h6>{element.heading}</h6>
              </div>
              <div className={styling("body")}>
                <p className={styling("mb-3")}>
                  <strong>{element.subheading}</strong>
                </p>
                <p>{element.description}</p>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};

export default ApplicantProfileDigitalCV;
