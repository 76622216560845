import clsx from "clsx";
import styles from "./EmptyState.module.scss";
import { ShiftlySuspense, useStyling } from "src/shiftly-ui";
import Illustration from "../decorative/Illustration";

export const EmptyState = ({
  items = [],
  image = "default.svg",
  text = "Nothing to see here...yet!",
  className,
  children,
  isLoading = false,
  component,
  ...props
}) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  return (
    <ShiftlySuspense loading={isLoading}>
      {items.length > 0 ? (
        children
      ) : (
        <div className={clsx(styling("container"), className)} {...props}>
          <Illustration image={image} />

          {component ?? (
            <p className={styling("my-3")}>
              <strong>{text}</strong>
            </p>
          )}
        </div>
      )}
    </ShiftlySuspense>
  );
};

export default EmptyState;
