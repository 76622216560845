import { useUI } from "src/shiftly-ui";
import { useCallback } from "react";
import moment from "moment-timezone";
import useShiftManager from "src/hooks/useShiftManager";

const useContextActions = ({ referencingShift, shiftTray, clipBoard, setClipBoard, multiSelect }) => {
  const { setNewShiftModalDefaults, showNewShiftModal } = useUI();

  const { copyShifts, normaliseShift, handleTimeUpdate, updateShifts, upgradeShift, downgradeShift, deleteShifts } =
    useShiftManager();

  const copyAction = useCallback(() => {
    if (multiSelect) {
      setClipBoard(shiftTray.map(({ shift }) => shift));
      return;
    }
    setClipBoard([referencingShift]);
  }, [multiSelect, referencingShift, shiftTray, setClipBoard]);

  const pasteAction = useCallback(() => {
    copyShifts(clipBoard);
  }, [clipBoard, copyShifts]);

  const repeatAction = useCallback(
    (frequency) => {
      let shiftsToRepeat = multiSelect ? shiftTray.map(({ shift }) => shift) : [referencingShift];

      shiftsToRepeat = shiftsToRepeat.map((shift) => ({
        ...shift,
        ...normaliseShift(shift),
        location: shift?.location?._id || shift?.location,
      }));

      switch (frequency) {
        case "tomorrow": {
          const newShifts = [];
          shiftsToRepeat.forEach((shift) => {
            const newDay = moment.tz(shift.start_time, shift.timezone).add(1, "day");
            const { newStartTime, newEndTime } = handleTimeUpdate(shift, newDay);
            const newShift = {
              ...shift,
              start_time: newStartTime,
              end_time: newEndTime,
            };
            delete newShift._id;
            newShifts.push(newShift);
          });
          copyShifts(newShifts);
          break;
        }
        case "week": {
          const newShifts = [];
          shiftsToRepeat.forEach((shift) => {
            const dayOfWeek = moment.tz(shift.start_time, shift.timezone).day();
            for (let i = dayOfWeek + 1; i < 8; i++) {
              const newDay = moment.tz(shift.start_time, shift.timezone).add(i - dayOfWeek, "day");
              const { newStartTime, newEndTime } = handleTimeUpdate(shift, newDay);
              if (moment(newStartTime).isBefore(moment()) && !shift.type) continue;
              const newShift = {
                ...shift,
                start_time: newStartTime,
                end_time: newEndTime,
              };
              delete newShift._id;
              newShifts.push(newShift);
            }
          });
          copyShifts(newShifts);
          break;
        }
        default: {
        }
      }
    },
    [multiSelect, referencingShift, shiftTray, handleTimeUpdate, copyShifts, normaliseShift]
  );

  const publishAction = useCallback(() => {
    let shiftsToPublish = multiSelect ? shiftTray.flatMap(({ shift }) => shift) : [referencingShift];

    updateShifts(
      shiftsToPublish
        .filter((s) => s.status === "unpublished")
        .map((shift) => ({
          ...shift,
          status: "published",
        }))
    );
  }, [multiSelect, referencingShift, shiftTray, updateShifts]);

  const unpublishAction = useCallback(() => {
    let shiftsToPublish = multiSelect ? shiftTray.flatMap(({ shift }) => shift) : [referencingShift];
    updateShifts(
      shiftsToPublish
        .filter((s) => s.status === "published")
        .map((shift) => ({
          ...shift,
          status: "unpublished",
        }))
    );
  }, [multiSelect, referencingShift, shiftTray, updateShifts]);

  const editAction = useCallback(() => {
    setNewShiftModalDefaults({
      mode: "edit",
      shift: JSON.stringify(referencingShift),
      type: referencingShift?.type ?? "shiftly",
    });
    showNewShiftModal(true);
  }, [referencingShift, setNewShiftModalDefaults, showNewShiftModal]);

  const deleteAction = useCallback(async () => {
    const shiftsToDelete = multiSelect ? shiftTray.map(({ shift }) => shift) : [referencingShift];
    deleteShifts(shiftsToDelete);
  }, [multiSelect, referencingShift, shiftTray, deleteShifts]);

  const changeToPublicAction = useCallback(async () => {
    upgradeShift(referencingShift);
  }, [upgradeShift, referencingShift]);

  const changeToInternalAction = useCallback(() => {
    downgradeShift(referencingShift);
  }, [downgradeShift, referencingShift]);

  return {
    copyAction,
    pasteAction,
    repeatAction,
    publishAction,
    unpublishAction,
    editAction,
    deleteAction,
    changeToPublicAction,
    changeToInternalAction,
  };
};
export default useContextActions;
