import { useStyling } from "src/shiftly-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

import styles from "./BusinessNavItem.module.scss";

const BusinessNavItem = ({ label, icon, path, activeIndex, setActiveNav, index, badge = 0 }) => {
  /*************************************** Hooks *************************************** */
  const navigate = useNavigate();
  const styling = useStyling(styles);

  return (
    <div
      className={styling("container", activeIndex === index && "active")}
      onClick={() => {
        setActiveNav(index);
        navigate(path);
      }}
    >
      <div className={styling("icon-container")}>
        <FontAwesomeIcon icon={icon} />
      </div>
      {badge > 0 && <div className={styling("badge")}>{badge}</div>}
      <p className={styling("link-text")}>{label}</p>
    </div>
  );
};

export default BusinessNavItem;
