import React, { useCallback, useEffect } from "react";
import { Modal } from "../modals/Modal";
import styles from "./Stepper.module.scss";
import { Each } from "./Each";
import clsx from "clsx";
import { Button } from "../buttons/Button";
import { useToast } from "../../hooks/useToast";
import { useDelayUnmount } from "../../hooks/useDelayUnmount";
import { ModalLabel } from "../modals/ModalLabel";
import { useUncontrolled } from "uncontrollable";
import { useStyling } from "src/shiftly-ui";

export const Stepper = (props) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const toast = useToast();
  const {
    activeStep = [],
    setActiveStep,
    steps = [],
    showStepper = false,
    setShowStepper = () => {},
    onNext,
    onPrev,
    onComplete = () => new Promise((resolve) => resolve()),
    label = <ModalLabel />,
    className,
    nextButtonText = "Continue",
    prevButtonText = "Previous",
    loading = false,
    keyDown = true,
  } = useUncontrolled(props, {
    activeStep: "setActiveStep",
  });

  /******************************** Functions & Memos ********************************** */
  const handleStepClick = useCallback((i) => setActiveStep(i), [setActiveStep]);

  const isValid = useCallback(async () => {
    if (!steps[activeStep]?.validation) return true;
    return await steps[activeStep]?.validation();
  }, [activeStep, steps]);

  const handleNext = useCallback(async () => {
    if ((await isValid()) && activeStep < steps?.length - 1) {
      await onNext?.();
      handleStepClick(activeStep + 1);
    } else {
      steps[activeStep]?.message && toast.error(steps[activeStep]?.message);
    }
  }, [activeStep, handleStepClick, steps, isValid, toast, onNext]);

  const handlePrev = useCallback(async () => {
    if (activeStep > 0) {
      await onPrev?.();
      handleStepClick(activeStep - 1);
    }
  }, [activeStep, handleStepClick, onPrev]);

  /******************************** Effects & Handles ********************************** */
  useEffect(() => {
    setTimeout(() => setActiveStep(0), 500);
  }, [showStepper, setActiveStep]);

  useEffect(() => {
    if (!keyDown) return;
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        handleNext();
      }
    };

    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleNext, keyDown]);

  return (
    <Modal label={label} className={clsx(className)} showModal={showStepper} setShowModal={setShowStepper}>
      <div className={styling("stepper-container")}>
        <div className={clsx(styling("progress-bar"), styling("hidden-nav"))}>
          <div className={styling("bar")} style={{ width: ((activeStep + 1) / steps?.length) * 100 + "%" }}></div>
        </div>

        <div className={styling("steps-content-container")}>
          {showStepper && (
            <Each
              of={steps}
              render={({ key, content, ...step }, index) => {
                return (
                  <div
                    className={styling("step-content")}
                    style={{
                      transform: `translateX(${100 * -activeStep}%)`,
                      transition: "transform 0.5s ease-in-out",
                    }}
                    key={key ?? step.label ?? index}
                  >
                    <StepContent activeStep={activeStep} index={index}>
                      {typeof content === "function" ? content(step) : content}
                    </StepContent>
                  </div>
                );
              }}
            />
          )}
        </div>
        <div
          className={styling("step-footer", "flex")}
          style={{
            justifyContent: steps.length > 1 ? "space-between" : "center",
          }}
        >
          {steps.length > 1 && (
            <Button
              theme={"secondary"}
              className={styling("w-100", "sm-w-auto", "my-2")}
              disabled={activeStep === 0}
              onClick={handlePrev}
              style={{ opacity: activeStep === 0 ? "0%" : "100%" }}
            >
              {prevButtonText}
            </Button>
          )}
          <Button
            theme={"primary"}
            disabled={loading}
            className={styling("w-100", "sm-w-auto", "my-2")}
            onClick={async () => {
              if (activeStep === steps?.length - 1) {
                if (isValid()) {
                  try {
                    await onComplete();
                  } catch (error) {
                    toast.error(error.message);
                  }
                }
              } else {
                handleNext();
              }
            }}
          >
            {activeStep !== steps?.length - 1 ? nextButtonText : loading ? "Loading..." : "Complete"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const StepContent = ({ index, activeStep, children }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const showContent = useDelayUnmount(activeStep === index, 500);

  /********************************** Refs & Constants ********************************* */
  const enhancedChildren = React.Children.map(children, (child) => {
    return React.cloneElement(child, { showContent });
  });

  return activeStep === index && <div className={styling("step-content")}>{enhancedChildren}</div>;
};
