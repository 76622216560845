import { forwardRef } from "react";

import { useStyling } from "src/shiftly-ui";

const FormContainer = forwardRef(({ children, ...props }, ref) => {
  const styling = useStyling();
  return (
    <div className={styling("p-4")} ref={ref} {...props}>
      {children}
    </div>
  );
});

export default FormContainer;
