import {
  FormV2,
  Input,
  OrangeSpan,
  useUI,
  Button,
  ButtonGroup,
  useFetch,
  useAuth,
  RadioButtons,
  useCachedQuery,
} from "src/shiftly-ui";
import StandardLayout from "../../components/standard_layout/StandardLayout";
import { useCallback, useState, useEffect, useMemo } from "react";
import { faSave, faBuildingUser, faStore } from "@fortawesome/pro-regular-svg-icons";
import NewBusinessRadioButtonCard from "../business/new_business_setup/NewBusinessRadioButtonCard";

const AccountSettingsOrganisationSettings = () => {
  /*************************************** State *************************************** */
  const [data, setData] = useState({});
  /*************************************** Hooks *************************************** */
  const { account, refreshAccount } = useAuth();

  /********************************** Refs & Constants ********************************* */

  /************************************** Queries ************************************** */
  const {
    post: updateAccountDetails,
    isLoading,
    isError,
  } = useFetch({
    options: {
      onSuccess: () => {
        refreshAccount();
      },
    },
  });

  /******************************** Functions & Memos ********************************** */

  const handleSave = useCallback(
    (data) =>
      updateAccountDetails({
        entity: "Account",
        method: "update",
        criteria: { account_id: account.account_id },
        data,
      }),
    [updateAccountDetails, account]
  );

  /******************************** Effects & Handles ********************************** */
  useEffect(() => {
    setData(account);
  }, [account]);

  return (
    <StandardLayout
      heading={
        <>
          <OrangeSpan>Organisation</OrangeSpan> Settings
        </>
      }
      breadcrumb={[{ label: "Account Management", link: "/account-management" }]}
    >
      <FormV2 initial={account} data={data} setData={setData} onSubmit={handleSave}>
        <Input name={"organisation_name"} label={"Organisation Name"} required placeholder={"Organisation Name"} />
        <RadioButtons
          my={"30px"}
          required
          label="What best describes your organisation?"
          align="center"
          options={[
            {
              label: "Venue",
              value: "venue",
              icon: faStore,
              ie: "i.e Restaurant, Cafe, Bar, etc.",
            },
            {
              label: "Third Party Service Provider",
              value: "third_party_service_provider",
              icon: faBuildingUser,
              ie: "i.e. Bookkeeper, Accountant, Manager, etc.",
            },
          ].map(({ label, value, icon, ie }) => ({
            label,
            value,
            content: (
              <NewBusinessRadioButtonCard
                label={label}
                award={ie}
                icon={icon}
                active={data?.organisation_type === value}
              />
            ),
          }))}
          type="cards"
          name="organisation_type"
        />
        <ButtonGroup>
          <Button type="submit" icon={faSave} theme={"secondary"} loading={isLoading} error={isError}>
            Save
          </Button>
        </ButtonGroup>
      </FormV2>
    </StandardLayout>
  );
};

export default AccountSettingsOrganisationSettings;
