import { useEffect, useMemo, useState } from "react";
import { withDisplay, useFetch } from "src/shiftly-ui";
import MobileRating from "./MobileRating";
import DesktopRating from "./DesktopRating";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";

const RateShifter = ({ isMobile }) => {
  /*************************************** State *************************************** */
  const [open, setOpen] = useState(false);
  /*************************************** Hooks *************************************** */
  const { activeLocation } = useShiftlyLocation();
  /********************************** Refs & Constants ********************************* */

  /************************************** Queries ************************************** */
  const { data: shifts } = useFetch({
    request: {
      entity: "ShiftHistory",
      method: "get",
      criteria: {
        status: "completed",
        rated: false,
        location: activeLocation?._id,
      },
      populate: ["user"],
    },
    dependency: activeLocation?._id,
  });

  /******************************** Functions & Memos ********************************** */
  const props = useMemo(
    () => ({
      shifts,
      open,
      setOpen,
    }),
    [shifts, open, setOpen]
  );
  /******************************** Effects & Handles ********************************** */
  useEffect(() => {
    if (shifts?.length) {
      setTimeout(() => {
        setOpen(true);
      }, 3000);
    }
  }, [shifts]);

  return isMobile ? <MobileRating {...props} /> : <DesktopRating {...props} />;
};

export default withDisplay(RateShifter);
