import styles from "./NewShiftModalLabel.module.scss";
import { useStyling } from "src/shiftly-ui";

const NewShiftModalLabel = ({ tab = 0, setTab = () => {}, mode }) => {
  /*************************************** State *************************************** */

  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  /********************************** Refs & Constants ********************************* */

  /******************************** Functions & Memos ********************************** */

  /************************************** Queries ************************************** */

  /******************************** Effects & Handles ********************************** */

  return (
    <div className={styling("container")}>
      <div className={styling("tab", tab === 0 && "active")} onClick={() => setTab(0)}>
        <h3>{mode} a shift</h3>
      </div>
      <div className={styling("tab", tab === 1 && "active")} onClick={() => setTab(1)}>
        <h3>Internal scheduling</h3>
      </div>
      <div className={styling("slider-container")}>
        <div className={styling("slider-wrapper")}>
          <div className={styling("slider")} style={{ left: `${tab * 50}%` }}></div>
        </div>
      </div>
    </div>
  );
};

export default NewShiftModalLabel;
