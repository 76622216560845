import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./AlreadyConnected.module.scss";
import { Link, Overlay, useStyling } from "src/shiftly-ui";
import { faCircleExclamation } from "@fortawesome/pro-regular-svg-icons";

const AlreadyConnected = ({ show, setShow, organisationName = "a selected organisation" }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  return (
    <>
      <Overlay open={show} setOpen={setShow} />
      <div className={styling("container", show && "visible")}>
        <h3 className={styling("text-center", "mb-3")}>
          An organisation <span>has already been connected</span>
        </h3>
        <p className={styling("my-3")}>
          It looks like Shiftly has already been connected to {organisationName} in Xero.
        </p>
        <p>
          <strong>What you can do next:</strong>
        </p>
        <ul className={styling("my-2")}>
          <li>Check if this connection was set up by someone on your team or your accountant</li>
          <li>Confirm who should have ownership of this integration</li>
          <li>
            <strong>Need to transfer ownership? </strong>{" "}
            <Link to={process.env.REACT_APP_SHIFTLY_WEBSITE + "contact"}>Contact Shiftly Support</Link>
          </li>
        </ul>
        <div className={styling("warning")}>
          <div className={styling("flex", "flex-row", "flex-start", "mb-2")}>
            <FontAwesomeIcon icon={faCircleExclamation} />
            <p className={styling("ml-3")}>
              <strong>Warning</strong>
            </p>
          </div>
          <p className={styling("label")}>
            If you are unable to identify who connected your Xero organisation, please{" "}
            <Link>contact our support team</Link> as this may cause a security risk.
          </p>
        </div>
      </div>
    </>
  );
};

export default AlreadyConnected;
