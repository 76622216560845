import { useStyling, Button, OrangeSpan, ButtonGroup } from "src/shiftly-ui";
import styles from "./IntegrationStep.module.scss";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const iconVariants = {
  complete: {
    left: "60px",
    transform: "translateX(0%)",
    background: "var(--success-50)",
    color: "var(--success-500)",
    width: "60px",
    height: "60px",
  },
  open: {
    left: "60px",
    transform: "translateX(0%)",
    background: "var(--orange-gradient)",
    color: "white",
    width: "60px",
    height: "60px",
  },
  incomplete: {
    left: "60px",
    transform: "translateX(0%)",
    background: "var(--secondary-300)",
    color: "var(--secondary-800)",
    width: "60px",
    height: "60px",
  },
};

const IntegrationStep = ({
  number = 1,
  name = "",
  description = "",
  icon,
  children,
  buttonText = "",
  buttonAction = () => {},
  disabled,
  status = "open",
  completeButton,
  customButton,
  onClick = () => {},
}) => {
  const styling = useStyling(styles);

  return (
    <div className={styling("container", status)} onClick={onClick}>
      <motion.div
        variants={iconVariants}
        animate={status}
        initial={status}
        className={styling("icon-container", status, "no-color-transition")}
      >
        <FontAwesomeIcon icon={icon} />
      </motion.div>
      <div className={styling("heading", status)}>
        <h3 className={styling("mb-3")}>
          <OrangeSpan>Step {number}:</OrangeSpan> {name}
        </h3>
        {status === "complete" && completeButton}
        <p className={styling()}>{description}</p>
      </div>
      {status === "open" && (
        <>
          {children}
          <ButtonGroup className={styling("flex-start", "px-4")}>
            {customButton ?? (
              <Button
                disabled={disabled}
                theme="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  buttonAction();
                }}
                className={styling("button")}
              >
                {buttonText}
              </Button>
            )}
          </ButtonGroup>
        </>
      )}
    </div>
  );
};
export default IntegrationStep;
