import styles from "./ModalLabel.module.scss";
import { useStyling } from "../../hooks/useStyling";
import logo from "../../assets/logos/icon.svg";

export const ModalLabel = ({ text = "" }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  return (
    <div className={styling("container")}>
      <img src={logo} alt="Shiftly Logo" className={styling("shiftly-logo")}></img>
      {typeof text === "string" ? <h4>{text}</h4> : text}
    </div>
  );
};
