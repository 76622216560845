import { Input, TextArea, FormFlex, useStyling, Dropdown, OrangeSpan, Checkbox, normaliseID } from "src/shiftly-ui";
import styles from "./NewPosition.module.scss";
import usePositions from "src/hooks/usePositions";
import { useEffect, useMemo } from "react";

const textAreaPlaceholder = `Include a friendly description, and highlight some of the duties of the position.`;

const NewPositionDetails = ({ setData = () => {}, data = {} }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const { allGroups, dropdownPositions } = usePositions();

  /******************************** Functions & Memos ********************************** */
  const newGroups = useMemo(
    () =>
      allGroups
        .filter(
          (g) =>
            !dropdownPositions.map((g) => g.value).includes(normaliseID(g)) ||
            normaliseID(g) === normaliseID(data.group)
        )
        .map((group) => ({
          value: group._id,
          label: `${group.name} - ${group?.stream}`,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [allGroups, dropdownPositions, data.group]
  );

  /******************************** Effects & Handles ********************************** */
  useEffect(() => {
    if (!data.group) return;

    const group = allGroups.find((g) => g._id === data.group);

    setData((prev) => ({
      ...prev,
      description: group?.description ?? textAreaPlaceholder,
      name: data?.name || group?.name || "",
    }));
  }, [data?.group, data?.name, allGroups, setData]);

  return (
    <div className={styling("container")}>
      <div className={styling("heading")}>
        <h3>
          We'll need some <OrangeSpan>details</OrangeSpan> about the position.
        </h3>
      </div>
      <div className={styling("content")}>
        <FormFlex className={styling("flex-column", "sm-flex-row")}>
          <Dropdown enableSearch name={"group"} label="Name" placeholder={"Barista"} options={newGroups} required />
          <Input label={"Nickname"} placeholder={"Coffee Wizard"} name={"name"} />
        </FormFlex>
        <TextArea label={"Description"} rows={4} placeholder={textAreaPlaceholder} name={"description"} required />
        <Checkbox name={"is_active"} label={"Would you like this position to be visible on the scheduler?"} />
      </div>
    </div>
  );
};
export default NewPositionDetails;
