import { withDisplay, useGestures, useUI } from "src/shiftly-ui";
import styles from "./BusinessWrapper.module.scss";
import { useEffect } from "react";
import useBusiness from "src/hooks/useBusiness";
import MobileLocationSelector from "./MobileLocationSelector";
import TaskList from "./task_list/TaskList";
import { useLocation } from "react-router-dom";
import RateShifter from "src/components/ratings/RateShifter";

const BusinessWrapper = ({ children, isMobile }) => {
  /*************************************** Hooks *************************************** */
  const { initBusiness } = useBusiness();
  const { pathname } = useLocation();
  const { bind } = useGestures();
  const {
    settings: { isAThirdPartyProvider },
  } = useUI();

  useEffect(() => {
    initBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    pathname !== "/portal" && (
      <div className={styles["container"]} {...bind()}>
        <RateShifter />
        {pathname !== "/" && isMobile && <MobileLocationSelector />}
        {!isMobile && !isAThirdPartyProvider && <TaskList />}
        <div className={styles["content"]} id="wrapper">
          {children}
        </div>
      </div>
    )
  );
};
export default withDisplay(BusinessWrapper);
