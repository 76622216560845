import moment from "moment";

export const truncate = (str = "", length = Infinity) => {
  if (str.length > length) {
    return str.substring(0, length) + "...";
  }
  return str;
};

export const formatDateYYYY_MM_DD = (dt = new Date()) => {
  const date = new Date(dt);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const formatDateDD_MM_YYYY = (dt = new Date()) => {
  const date = new Date(dt);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${day}-${month}-${year}`;
};

export const titleCase = (str = "") => {
  return str?.toLowerCase().replace(/(^|\s)\S/g, (L) => L.toUpperCase());
};

export const roundNumber = (number = 0, digits = 2) => {
  const multiplier = Math.pow(10, digits);
  return Math.round(number * multiplier) / multiplier;
};

export const numberToLetter = (number) => {
  // Assuming input number starts from 1 for 'A'
  const asciiValueOfA = "A".charCodeAt(0);
  const letterIndex = number - 1; // Adjust to zero-based index
  const asciiValueOfLetter = asciiValueOfA + letterIndex;
  return String.fromCharCode(asciiValueOfLetter);
};

export const formatPayPeriod = (start_date, end_date) => {
  const start = moment(start_date);
  const end = moment(end_date);
  return `${start.format("Do")} ${start.format("MMMM")} - ${end.format("Do")} ${end.format("MMMM YY")}`;
};

export const arrayToMap = (array, keyProperty) => {
  return array.reduce((map, obj) => {
    const key = obj[keyProperty.toString()];
    if (!map[key]) {
      map[key] = obj;
    }
    return map;
  }, {});
};


export const normaliseID = (object) => object?._id?.toString() ?? object?.toString();