import { useProfile, useStyling, Link, useFetch, normaliseID } from "src/shiftly-ui";
import { faArrowUpRight } from "@fortawesome/pro-solid-svg-icons";
import logo from "src/shared/assets/logos/icon.svg";
import { useCallback, useMemo } from "react";
import styles from "./SchedulerShiftSlot.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useSlotStatus = ({ shift = {} }) => {
  const { user, status: shiftStatus } = shift;
  const styling = useStyling(styles);

  const { profile } = useProfile({
    criteria: { user: shift?.user?._id },
    dependency: shift?.user,
  });

  const {
    data: [signedOn],
  } = useFetch({
    request: {
      entity: "Timesheet",
      method: "get",
      criteria: { shift: normaliseID(shift), status: "incomplete" },
    },
    dependency: normaliseID(shift?._id),
  });

  const status = useMemo(() => {
    if (signedOn) return "active";

    const hasApplicants = shift.applicants?.length > 0;

    if (hasApplicants && !["confirmed", "active", "completed"].includes(shiftStatus)) {
      return shiftStatus === "published" ? "applicants/published" : "applicants/expired";
    }

    if (["external"].includes(shiftStatus)) return "confirmed";

    if (shift.status === "unpublished" && shift.type === "internal") return "unpublished/internal";

    if (shift.type === "internal") return "internal";

    return shiftStatus;
  }, [shiftStatus, shift, signedOn]);

  const createTextElement = useCallback((text) => <p className={styling("status-text")}>{text}</p>, [styling]);

  const applicantsText = useMemo(
    () => (
      <div className={styling("applicants-link")}>
        <Link to={"/people?shift=" + shift?._id} className={styling("status-text")}>
          {shift?.applicants?.length} applicant{shift?.applicants?.length > 1 ? "s" : ""}
          <FontAwesomeIcon icon={faArrowUpRight} className={styling("linkArrow")} />
        </Link>
      </div>
    ),
    [shift?.applicants?.length, styling, shift?._id]
  );

  const defaultStatusMap = useMemo(
    () => ({
      borderColour: "var(--secondary-900)",
      backgroundColour: "white",
      icon: profile?.profile_picture || logo,
      imageFit: "cover",
      iconBorder: "var(--secondary-900)",
      text: (
        <div className={styling("confirmed-shifter")}>
          <p>{user ? `${user?.first_name} ${user?.last_name}` : "Open"}</p>
        </div>
      ),
    }),
    [profile?.profile_picture, styling, user]
  );

  const statusMap = useMemo(
    () => ({
      free: {
        ...defaultStatusMap,
        borderColour: "transparent",
        backgroundColour: "transparent",
        icon: null,
      },
      unpublished: {
        ...defaultStatusMap,
        borderColour: "var(--secondary-600)",
        backgroundColour: "var(--secondary-50)",
        iconBorder: "var(--secondary-600)",
        icon: logo,
        imageFit: "contain",
        text: createTextElement("Unpublished"),
      },
      "unpublished/internal": {
        ...defaultStatusMap,
        borderColour: "var(--secondary-600)",
        backgroundColour: "var(--secondary-50)",
        iconBorder: "var(--secondary-600)",
        icon: shift?.location?.logo || logo,
      },
      published: {
        ...defaultStatusMap,
        borderColour: "var(--orange)",
        backgroundColour: "var(--primary-50)",
        iconBorder: "var(--orange)",
        icon: logo,
        imageFit: "contain",
        text: createTextElement("Published"),
      },
      "applicants/published": {
        ...defaultStatusMap,
        borderColour: "var(--warning-500)",
        backgroundColour: "var(--warning-50)",
        iconBorder: "var(--warning-500)",
        icon: logo,
        imageFit: "contain",
        text: applicantsText,
      },
      "applicants/expired": {
        ...defaultStatusMap,
        borderColour: "var(--greyscale-600)",
        backgroundColour: "var(--greyscale-50)",
        iconBorder: "var(--greyscale-600)",
        icon: logo,
        imageFit: "contain",
        text: applicantsText,
      },
      confirmed: defaultStatusMap,
      active: {
        ...defaultStatusMap,
        borderColour: "var(--success-900)",
        backgroundColour: "var(--success-10)",
        iconBorder: "var(--success-900)",
      },
      completed: {
        ...defaultStatusMap,
        borderColour: "var(--greyscale-400)",
        backgroundColour: "var(--greyscale-50)",
        iconBorder: "var(--greyscale-400)",
      },
      noshow: {
        ...defaultStatusMap,
        borderColour: "var(--error-900)",
        backgroundColour: "var(--error-50)",
        iconBorder: "var(--error-900)",
      },
      expired: {
        ...defaultStatusMap,
        borderColour: "var(--greyscale-400)",
        backgroundColour: "var(--greyscale-50)",
        iconBorder: "var(--greyscale-400)",
        text: createTextElement("Expired"),
        icon: logo,
        imageFit: "contain",
      },
      internal: { ...defaultStatusMap, icon: shift?.location?.logo || logo },
    }),
    [applicantsText, defaultStatusMap, createTextElement, shift]
  );

  return { ...(statusMap[status] || {}), profile, status };
};
export default useSlotStatus;
