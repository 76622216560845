import { useEffect } from "react";
import { Each } from "../layout/Each";
import { NotificationWrapper } from "./NotificationWrapper";
import { useStyling } from "../../hooks/useStyling";
import styles from "./Notifications.module.scss";
import { EmptyState } from "src/shiftly-ui";

export const Notifications = ({ readNotifications = [], unreadNotifications = [], markAsRead = () => {} }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  /******************************** Effects & Handles ********************************** */
  useEffect(() => {
    return () => {
      markAsRead();
    };
  }, [markAsRead]);

  return (
    <div className={styling("container")}>
      {/* No Notifications */}
      <EmptyState
        items={unreadNotifications.concat(readNotifications)}
        text={"You're all caught up!"}
        className={styling("flex-center")}
        image="empty_notifications.webp"
      >
        {/* Unread Notifications */}
        {unreadNotifications?.length > 0 && (
          <div className={styling("notifications")}>
            <h6 className={styling("m-3")}>Unread</h6>
            <Each
              of={unreadNotifications}
              render={({ key, ...notification }) => <NotificationWrapper {...notification} key={key} />}
            />
          </div>
        )}
        {/* Read Notifications */}
        {readNotifications.length > 0 && (
          <div className={styling("notifications")}>
            <h6 className={styling("m-3")}>Read</h6>
            <Each
              of={readNotifications}
              render={({ key, ...notification }) => <NotificationWrapper {...notification} key={key} />}
            />
          </div>
        )}
      </EmptyState>
    </div>
  );
};
