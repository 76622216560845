import { useNavigate, useParams } from "react-router-dom";
import styles from "./Onboarding.module.scss";
import {
  useStyling,
  useFetch,
  ModalLabel,
  Button,
  useCachedQuery,
  ShiftlySuspense,
  ButtonGroup,
  Divider,
} from "src/shiftly-ui";
import { useCallback, useMemo, useRef, useState } from "react";
import Welcome from "./tabs/Welcome";
import YourDetails from "./tabs/YourDetails";
import AddressDetails from "./tabs/AddressDetails";
import BankDetails from "./tabs/BankDetails";
import TaxDeclaration from "./tabs/tax_declaration/TaxDeclaration";
import SuperMembership from "./tabs/SuperMembership";
import Employment from "./tabs/Employment";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { AnimatePresence, motion } from "framer-motion";

const variants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const Onboarding = () => {
  /*************************************** State *************************************** */
  const [activeTab, setActiveTab] = useState(0);

  const [formLoading, setFormLoading] = useState(false);
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const navigate = useNavigate();
  const { token } = useParams();

  const {
    EmployeeOnboard: { LoadOnboardingDetails },
  } = useCachedQuery();

  /********************************** Refs & Constants ********************************* */
  const yourDetailsRef = useRef(null);
  const addressDetailsRef = useRef(null);
  const employmentRef = useRef(null);
  const bankDetailsRef = useRef(null);
  const taxDeclarationRef = useRef(null);
  const superMembershipsRef = useRef(null);

  /************************************** Queries ************************************** */
  const {
    data: {
      bank_accounts,
      employment,
      internalStaff,
      address,
      onboardRequest,
      super_membership,
      tax_declaration,
      location,
      authToken,
    },
    isLoading,
  } = useFetch({
    request: {
      id: LoadOnboardingDetails,
      entity: "EmployeeOnboard",
      method: "loadOnboardingRequest",
      data: {
        token,
      },
    },
    dependency: token,
    options: {
      onSuccess: (data) => {
        const { onboardRequest } = data;

        if (onboardRequest.status === "approved") {
          navigate("/onboarding/status?mode=success");
        }
      },
      onError: () => {
        navigate("/onboarding/status?mode=expired");
      },
    },
  });

  const { post: submit, isLoading: submitIsLoading } = useFetch({
    options: {
      onSuccess: () => {
        navigate("/onboarding/status?mode=success");
      },
    },
  });

  /******************************** Functions & Memos ********************************** */

  const submitOnboardingRequest = useCallback(() => {
    submit({
      entity: "EmployeeOnboard",
      method: "submitOnboardRequest",
      data: { onboardID: onboardRequest?._id },
      headers: { token: authToken },
    });
  }, [authToken, onboardRequest, submit]);

  const sharedProps = useMemo(
    () => ({
      onboardRequest,
      setActiveTab,
      authToken,
      location,
      internalStaff,
      tax_declaration,
      employment,
      submitOnboardingRequest,
      setFormLoading,
    }),
    [
      onboardRequest,
      setActiveTab,
      authToken,
      location,
      internalStaff,
      tax_declaration,
      employment,
      submitOnboardingRequest,
    ]
  );

  const tabs = useMemo(() => {
    const tabs = [
      {
        title: "Welcome",
        Component: <Welcome {...sharedProps} />,
      },
      {
        title: "Your Details",
        Component: <YourDetails ref={yourDetailsRef} {...sharedProps} />,
        ref: yourDetailsRef,
      },
      {
        title: "Address Details",
        Component: <AddressDetails ref={addressDetailsRef} address={address} {...sharedProps} />,
        ref: addressDetailsRef,
      },
      {
        title: "Employment",
        Component: <Employment ref={employmentRef} {...sharedProps} />,
        ref: employmentRef,
      },
      {
        title: "Tax Declaration",
        Component: <TaxDeclaration ref={taxDeclarationRef} {...sharedProps} />,
        ref: taxDeclarationRef,
      },
      {
        title: "Bank Details",
        Component: <BankDetails ref={bankDetailsRef} bank_accounts={bank_accounts} {...sharedProps} />,
        ref: bankDetailsRef,
      },
    ];

    if (tax_declaration?.income_type !== "LABOURHIRE") {
      tabs.push({
        title: "Super Memberships",
        Component: <SuperMembership ref={superMembershipsRef} super_membership={super_membership} {...sharedProps} />,
        ref: superMembershipsRef,
      });
    }

    return tabs;
  }, [bank_accounts, super_membership, sharedProps, address, tax_declaration]);

  const onNextClick = useCallback(async () => {
    const activeTabRef = tabs[activeTab].ref;
    await activeTabRef?.current?.submit();
  }, [activeTab, tabs]);

  return (
    <ShiftlySuspense loading={isLoading || submitIsLoading} className={styling("suspense")}>
      <div className={styling("container")}>
        <div className={styling("content-wrapper")}>
          {activeTab !== 0 && (
            <motion.div
              variants={variants}
              initial={"hidden"}
              animate={activeTab !== 0 ? "visible" : "hidden"}
              transition={{ duration: 0.2 }}
              className={styling("px-3", "pt-3", activeTab !== 0 && "visible")}
            >
              <ModalLabel text={<Numbers tabs={Array.from({ length: tabs.length - 1 })} activeTab={activeTab - 1} />} />
            </motion.div>
          )}
          <Divider />
          <div className={styling("content")}>
            <AnimatePresence>{tabs[activeTab]?.Component}</AnimatePresence>
          </div>
          <Divider />
          {activeTab !== 0 && (
            <ButtonGroup className={styling("p-3", "flex-between")}>
              <Button
                className={styling("w-100", "sm-w-auto")}
                theme={"secondary-outline"}
                icon={faArrowLeft}
                disabled={activeTab <= 1 || formLoading}
                onClick={() => setActiveTab(activeTab > 1 && activeTab - 1)}
              >
                Previous
              </Button>
              <Button
                onClick={onNextClick}
                className={styling("w-100", "sm-w-auto")}
                theme={"primary"}
                icon={faArrowRight}
                iconSide={"right"}
                loading={formLoading}
              >
                {activeTab === tabs.length - 1 ? "Finish" : "Next"}
              </Button>
            </ButtonGroup>
          )}
        </div>
      </div>
    </ShiftlySuspense>
  );
};

const Numbers = ({ activeTab = 0, tabs = [] }) => {
  const styling = useStyling(styles);

  return (
    <div className={styling("numbers")}>
      {tabs.map((_, index) => (
        <div
          key={index}
          className={styling("number", activeTab === index && "active", activeTab > index && "completed")}
        >
          <p>{index + 1}</p>
        </div>
      ))}
    </div>
  );
};

export default Onboarding;
