import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import styles from "./ApplicantProfileShiftApplications.module.scss";
import {
  normaliseID,
  useFetch,
  useStyling,
  Each,
  roundNumber,
  useAlerts,
  useCachedQuery,
  withDisplay,
  ButtonGroup,
  Button,
} from "src/shiftly-ui";
import { useCallback, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/pro-regular-svg-icons";
import { faCheck, faHeart as faHeartSolid, faTimes } from "@fortawesome/pro-solid-svg-icons";
import useShiftlyApplicants from "../../hooks/useShiftlyApplicants";
import ConfirmApplicant from "../ConfirmApplicant";
import usePaymentMethods from "src/hooks/usePaymentMethods";

const ShiftApplications = ({ user = {}, profile = {}, isLaptop }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const { activeLocation } = useShiftlyLocation();
  const {
    ShiftApplications: { GetApplicationsForUserAndLocation },
  } = useCachedQuery();

  /************************************** Queries ************************************** */
  const { data: shiftApplications } = useFetch({
    request: {
      id: GetApplicationsForUserAndLocation,
      entity: "ShiftApplication",
      method: "get",
      criteria: {
        user: normaliseID(user),
        status: "pending",
        location: normaliseID(activeLocation),
      },
      populate: ["user"],
    },
    dependency: user?._id,
  });

  if (!shiftApplications?.length) return null;
  return (
    <>
      <h6 className={styling("mt-4", "mb-2")}>Shifts applied ({shiftApplications?.length})</h6>
      {isLaptop ? (
        <table className={styling("table")}>
          <tbody>
            <Each
              of={shiftApplications}
              render={({ key, ...application }) => <ShiftApplicationRow key={key} {...application} profile={profile} />}
            />
          </tbody>
        </table>
      ) : (
        <Each
          of={shiftApplications}
          render={({ key, ...application }) => <ShiftApplicationCard key={key} {...application} profile={profile} />}
        />
      )}
    </>
  );
};

export default withDisplay(ShiftApplications);

const useActionApplicant = (props) => {
  /*************************************** State *************************************** */
  const [showModal, setShowModal] = useState(false);
  /*************************************** Hooks *************************************** */
  const { confirm } = useAlerts();
  const { actionApplicant } = useShiftlyApplicants();
  const {
    ShiftApplications: { GetApplicationsForUserAndLocation },
  } = useCachedQuery();

  /********************************** Refs & Constants ********************************* */
  const { favourite, shift, _id, user } = props;
  /************************************** Queries ************************************** */

  const { post: favouriteApplicant, updateCache } = useFetch({
    options: {
      onMutate: () => {
        updateCache(GetApplicationsForUserAndLocation, (oldData = []) => {
          return oldData.map((application) => {
            if (application._id === _id) {
              return {
                ...application,
                favourite: !favourite,
              };
            }
            return application;
          });
        });
      },
    },
  });

  /******************************** Functions & Memos ********************************** */
  const handleFavouriteApplicant = useCallback(() => {
    favouriteApplicant({
      entity: "ShiftApplication",
      method: "update",
      criteria: {
        _id,
      },
      data: {
        favourite: !favourite,
      },
    });
  }, [_id, favourite, favouriteApplicant]);

  const handleActionShift = useCallback(
    async (type) => {
      if (type === "accepted") {
        setShowModal(true);
        return;
      }

      if (
        !(await confirm({
          label: (
            <>
              Are you sure you want to <span>decline this shift application?</span>
            </>
          ),
          text: "This applicant will be removed from the shift application list.",
          confirmText: "Decline",
          inverse: true,
        }))
      )
        return;

      actionApplicant({ application_id: _id, action: "rejected", shift, user });
    },

    [confirm, actionApplicant, _id, shift, user]
  );

  return {
    handleFavouriteApplicant,
    handleActionShift,
    showModal,
    setShowModal,
  };
};

const ShiftApplicationRow = (props) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const { defaultPaymentMethod } = usePaymentMethods();
  const { handleFavouriteApplicant, handleActionShift, showModal, setShowModal } = useActionApplicant(props);
  /********************************** Refs & Constants ********************************* */
  const { favourite, shift, total, user, profile } = props;
  return (
    <>
      <tr>
        <td className={styling(favourite && "favourite", "cursor-pointer")} onClick={handleFavouriteApplicant}>
          <FontAwesomeIcon icon={favourite ? faHeartSolid : faHeart} />
        </td>
        <td>
          {shift?.dayOfWeek} {shift?.prettyDate}
        </td>
        <td>
          {shift?.prettyStartTime} - {shift?.prettyEndTime}
        </td>
        <td>{shift?.position?.name}</td>
        <td>Level {shift?.position?.classification_level}</td>
        <td>${roundNumber(total)}</td>
        <td>
          {defaultPaymentMethod && (
            <>
              <ConfirmApplicant
                profile={profile}
                shift={shift}
                user={user}
                showModal={showModal}
                setShowModal={setShowModal}
                application={props}
              />
              <div className={styling("flex", "flex-row")}>
                <div className={styling("action", "rejected")} onClick={() => handleActionShift("declined")}>
                  <FontAwesomeIcon icon={faTimes} />
                </div>
                <div className={styling("action", "accepted")} onClick={() => handleActionShift("accepted")}>
                  <FontAwesomeIcon icon={faCheck} />
                </div>
              </div>
            </>
          )}
        </td>
      </tr>
    </>
  );
};

const ShiftApplicationCard = (props) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const { handleFavouriteApplicant, handleActionShift, showModal, setShowModal } = useActionApplicant(props);
  const { defaultPaymentMethod } = usePaymentMethods();
  /********************************** Refs & Constants ********************************* */
  const { favourite, shift, total, user, profile } = props;
  return (
    <div className={styling("card")}>
      <div className={styling(favourite && "favourite", "cursor-pointer", "my-2")} onClick={handleFavouriteApplicant}>
        <FontAwesomeIcon icon={favourite ? faHeartSolid : faHeart} />
      </div>
      <p className={styling("my-1")}>
        {shift?.dayOfWeek} {shift?.prettyDate}
      </p>
      <p className={styling("my-1")}>
        {shift?.prettyStartTime} - {shift?.prettyEndTime}
      </p>
      <p className={styling("my-1")}>{shift?.position?.name}</p>

      <p className={styling("my-1")}>Level {shift?.position?.classification_level}</p>

      <p className={styling("my-1")}>${roundNumber(total)}</p>

      {defaultPaymentMethod && (
        <>
          <ConfirmApplicant
            profile={profile}
            shift={shift}
            user={user}
            showModal={showModal}
            setShowModal={setShowModal}
            application={props}
          />
          <ButtonGroup className={styling("flex-start")}>
            <Button
              icon={faTimes}
              theme={"secondary-outline"}
              onClick={() => handleActionShift("declined")}
              size={styling("w-100", "sm-w-auto")}
            >
              Decline
            </Button>
            <Button
              icon={faCheck}
              onClick={() => handleActionShift("accepted")}
              theme={"secondary"}
              size={styling("w-100", "sm-w-auto")}
            >
              Accept
            </Button>
          </ButtonGroup>
        </>
      )}
    </div>
  );
};
