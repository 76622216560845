import { useCallback, useMemo } from "react";
import { useAuth } from "./useAuth";
import { useFetch } from "./useFetch";
import { useCachedQuery } from "./useCacheQuery";

export const useProfile = ({ criteria, dependency } = {}) => {
  /*************************************** Hooks *************************************** */
  const { user } = useAuth();
  const {
    Profile: { GetUserProfile },
  } = useCachedQuery();

  /************************************** Queries ************************************** */
  const {
    post,
    updateCache,
    isLoading: updatingProfile,
    isError,
    refresh,
  } = useFetch({
    options: {
      onSuccess: (data) => {
        refresh([GetUserProfile])
      },
    },
  });

  const {
    data: [profile],
    isLoading,
  } = useFetch({
    request: {
      entity: "Profile",
      method: "get",
      criteria: { user: user?._id, ...criteria },
      id: GetUserProfile,
      populate: "user",
    },
    dependency: dependency ?? criteria ?? user?._id,
  });

  /******************************** Functions & Memos ********************************** */
  const updateProfile = useCallback(
    async ({ criteria = {}, data = {} } = {}) => {
      return await post({
        entity: "Profile",
        method: "update",
        criteria: { user: user?._id, ...criteria },
        data,
      });
    },
    [user, post]
  );

  const updateProfilePic = useCallback(
    (url) => {
      url[0] &&
        updateProfile({
          data: { profile_picture: url[0] },
        });
    },
    [updateProfile]
  );

  return useMemo(
    () => ({ profile, isLoading, updateProfile, updateProfilePic, updatingProfile, isError }),
    [profile, updateProfilePic, isLoading, updateProfile, updatingProfile, isError]
  );
};
