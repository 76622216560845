import styles from "./NumberingIcons.module.scss";
import { useStyling } from "src/shiftly-ui";
import clsx from "clsx";

export const NumberingIcons = ({ activeNumber, numbers = [] }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  return (
    <div className={styling("icons")}>
      {numbers.map((text, index) => (
        <div key={index} className={clsx(styling("icon"), activeNumber === index && styling("active"))}>
          <div className={styling("circle")}>
            <p>{index + 1}</p>
          </div>
          <p className={styling('label')}>{text}</p>
        </div>
      ))}
    </div>
  );
};

export default NumberingIcons;
