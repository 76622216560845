import { Input, useFetch, Each, truncate, Button, useStyling, useCachedQuery } from "src/shiftly-ui";
import styles from "./MessagesSidePanel.module.css";
import { useEffect, useMemo, useState } from "react";
import { faMagnifyingGlass, faPenToSquare } from "@fortawesome/pro-regular-svg-icons";
import useMessagesCriteria from "src/pages/app/messages/hooks/useMessagesCriteria";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import { faArrowTurnLeft, faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const MessagesSidePanel = ({ activeConversation, setActiveConversation, readMessages }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredConversations, setFilteredConversations] = useState([]);
  const criteria = useMessagesCriteria(activeConversation);
  const styling = useStyling(styles);
  const { data: conversations } = useFetch({
    request: {
      entity: "Conversation",
      method: "getExistingConversations",
      criteria,
    },
    dependency: criteria,
    options: {
      onSuccess: (data) => {
        setFilteredConversations(data);
      },
      staleTime: 0,
    },
  });
  useEffect(() => {
    setFilteredConversations(
      conversations.filter(
        (conversation) =>
          conversation.user?.first_name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
          conversation.user?.last_name?.toLowerCase().includes(searchTerm?.toLowerCase())
      )
    );
  }, [searchTerm, conversations]);
  return (
    <div className={styling("container")}>
      <Button
        theme={"secondary"}
        icon={faPenToSquare}
        className={styling("new-message-btn")}
        onClick={() => setActiveConversation("temp")}
      >
        Create new message
      </Button>
      <div className={styling("search-container")}>
        <Input
          label={"Search Messages"}
          placeholder={"Search Messages"}
          icon={{ icon: faMagnifyingGlass, side: "left" }}
          value={searchTerm}
          setValue={setSearchTerm}
        />
      </div>
      <div className={styling("messages")}>
        {filteredConversations.length > 0 || searchTerm ? (
          <Each
            of={filteredConversations}
            render={({ key, ...conversation }) => (
              <ConversationCard
                key={key}
                {...conversation}
                activeConversation={activeConversation}
                setActiveConversation={setActiveConversation}
                readMessages={readMessages}
              />
            )}
          />
        ) : (
          <div className={styling("no-messages")}>
            <p>
              You have no messages! <br />
              Create a new message to get started.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
const ConversationCard = (props) => {
  const { activeConversation, setActiveConversation, _id, profile, user, readMessages } = props;
  const { activeLocation } = useShiftlyLocation();
  const criteria = useMessagesCriteria(activeConversation);
  const styling = useStyling(styles);
  const {
    Message: { GetMessagesWithAccess },
  } = useCachedQuery();
  const { data: messages, isLoading: messageIsLoading } = useFetch({
    request: {
      entity: "Message",
      method: "getMessagesWithAccess",
      data: {
        conversation_id: _id,
        ...criteria,
      },
      enabled: Boolean(_id),
      id: GetMessagesWithAccess,
    },
    dependency: { conversation_id: _id, criteria },
  });
  useEffect(() => {
    if (!activeConversation?._id || !messages.length || activeConversation?._id !== _id) return;
  }, [messages, activeConversation, readMessages, _id]);
  const hasUnread = useMemo(() => {
    return messages.some((message) => message?.unread && message?.for_id === activeLocation?._id);
  }, [activeLocation, messages]);
  const lastReceiver = useMemo(() => messages[messages.length - 1]?.for_id, [messages]);
  const showReturnArrow = useMemo(() => {
    return lastReceiver !== activeLocation?._id && messages.length > 0;
  }, [lastReceiver, activeLocation, messages]);
  const lastMessage = messages[messages.length - 1];
  return (
    <div
      className={styling(
        "convo-container",
        activeConversation?._id === _id && "active-convo",
        hasUnread && "hasUnread"
      )}
      onClick={() => setActiveConversation(props)}
      key={_id}
    >
      <div className={styling("convo-image")}>
        <div className={styling("convo-profile-image")}>
          {profile?.profile_picture ? (
            <img src={profile?.profile_picture} alt="profile" />
          ) : (
            <div className={styling("convo-initials-icon")}>
              <p>{user?.first_name?.charAt(0)}</p>
            </div>
          )}
        </div>
      </div>
      <div className={styling("convo-details")}>
        <h4>
          {!messageIsLoading && !messages[0] && "New:"}
          {user?.first_name} {user?.last_name}
        </h4>
        <p className={styling("preview")}>
          {showReturnArrow && <FontAwesomeIcon icon={faArrowTurnLeft} />}{" "}
          {lastMessage && truncate(lastMessage?.type === "text" ? lastMessage?.content : "Attachment", 30)}
        </p>
      </div>
      {hasUnread && <FontAwesomeIcon icon={faCircle} className={styling("chevron")} />}
    </div>
  );
};
export default MessagesSidePanel;
