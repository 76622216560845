import useBusiness from "src/hooks/useBusiness";
import { faCreditCard, faFileInvoice, faList, faLocationDot, faPlug } from "@fortawesome/pro-regular-svg-icons";
import StandardLayout from "../../../components/standard_layout/StandardLayout";
import StandardLayoutLink from "../../../components/standard_layout/StandardLayoutLink";
import { OrangeSpan } from "src/shiftly-ui";
import { useStyling } from "src/shiftly-ui";

const BusinessAccountHome = () => {
  /*************************************** Hooks *************************************** */
  const { activeBusiness } = useBusiness();
  const styling = useStyling();

  return (
    <StandardLayout
      heading={
        <>
          <OrangeSpan>{activeBusiness?.name}</OrangeSpan> Settings
        </>
      }
    >
      <h6 className={styling("mb-4")}>Business</h6>
      <div className={styling("mb-4")}>
        <StandardLayoutLink icon={faList} text={"Business Details"} link={"/business-account/business-details"} />
        <StandardLayoutLink icon={faLocationDot} text={"Locations"} link={"/business-account/locations"} />
        <StandardLayoutLink icon={faPlug} text={"Integrations"} link={"/integrations"} />
      </div>

      <h6 className={styling("mb-4")}>Payment</h6>
      <div className={styling("mb-4")}>
        <StandardLayoutLink
          icon={faCreditCard}
          text={"Billing & Payment"}
          link={"/business-account/billing-and-payment"}
        />
      </div>
    </StandardLayout>
  );
};

export default BusinessAccountHome;
