import { Each, Button, useUI, useAuth, useStyling } from "src/shiftly-ui";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import { faSignalStream } from "@fortawesome/pro-regular-svg-icons";

import styles from "./BusinessNavLaptopMode.module.scss";
import logo from "src/shared/assets/logos/icon.svg";

import BusinessNavItem from "./BusinessNavItem";
import BusinessNavDropDown from "./BusinessNavDropDown";
import BusinessNotificationCentre from "./BusinessNotificationCentre";
import BusinessLocationSelector from "./BusinessLocationSelector";
import usePositions from "src/hooks/usePositions";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import useBusiness from "src/hooks/useBusiness";

const BusinessNavLaptopMode = ({ links, locationFilter, setlocationFilter, showLocationSelector }) => {
  /*************************************** State *************************************** */
  const [activeNav, setActiveNav] = useState(0);
  const [openSubMenu, setOpenSubMenu] = useState(false);

  /*************************************** Hooks *************************************** */
  const {
    showNewShiftModal,
    setNewShiftModalDefaults,
    settings: { isAThirdPartyProvider },
  } = useUI();
  const { user } = useAuth();
  const { availablePositions } = usePositions();
  const location = useLocation();
  const navigate = useNavigate();
  const styling = useStyling(styles);
  const { activeLocation } = useShiftlyLocation();
  const { activeBusiness } = useBusiness();

  /******************************** Effects & Handles ********************************** */
  useEffect(() => {
    setActiveNav(links.findIndex((link) => link.path === location.pathname));
  }, [links, location.pathname]);

  return (
    <>
      <div className={styling("container")}>
        {/* Logo */}
        <div
          className={styling("logo-container")}
          onClick={() => {
            if (!activeLocation || !activeBusiness) return;
            navigate(isAThirdPartyProvider ? "/people" : "/shifts");
          }}
        >
          <img src={logo} alt="Shiftly Logo" className={styling("logo")} />
        </div>

        {/* Location Selector */}
        {showLocationSelector && (
          <div className={styling("location-selector")}>
            <BusinessLocationSelector
              locationFilter={locationFilter}
              setlocationFilter={setlocationFilter}
              showLocationSelector={showLocationSelector}
            />
          </div>
        )}

        {/* Navbar */}
        <div className={styling("navbar-container")}>
          <Each
            of={links}
            render={(link, index) => (
              <BusinessNavItem
                {...link}
                key={link.path}
                index={index}
                activeIndex={activeNav}
                setActiveNav={setActiveNav}
              />
            )}
          />
        </div>

        {/* Right Side - Notifications & Emergency Shift */}
        {showLocationSelector && (
          <div className={styling("account-container")}>
            <BusinessNotificationCentre />
            {!isAThirdPartyProvider && (
              <Button
                className={styling("emergency-shift")}
                onClick={() => {
                  setNewShiftModalDefaults({ shiftDate: new Date().toLocaleDateString() });
                  showNewShiftModal(true);
                }}
                icon={faSignalStream}
                disabled={availablePositions.length === 0}
              >
                Create New Shift
              </Button>
            )}
          </div>
        )}

        {/* User Dropdown */}
        {user && <BusinessNavDropDown open={openSubMenu} setOpen={setOpenSubMenu} />}
      </div>
    </>
  );
};

export default BusinessNavLaptopMode;
