import { withInputBase } from "../HOC/withInputBase";
import { useState, useCallback, useEffect } from "react";
import clsx from "clsx";
import { useStyling } from "../../hooks/useStyling";
import { useInlineStyles } from "../../hooks/useInlineStyles";
import styles from "./Switch.module.css";

export const Switch = withInputBase(
  ({ value, setValue, title, label, error, required, disabled, className, formProps, name, onClick, onChange,  ...props }) => {
    /*************************************** State *************************************** */
    const [toggled, setToggled] = useState(value || false);

    /*************************************** Hooks *************************************** */
    const styling = useStyling(styles);
    const style = useInlineStyles(props);

    /******************************** Functions & Memos ********************************** */
    const handleClick = useCallback(
      (e) => {
        if (disabled) return;
        onClick?.(e);
        onChange?.(!toggled);
        setToggled((prev) => {
          const newValue = !prev;
          setValue(newValue);
          return newValue;
        });
      },
      [disabled, setValue, onClick, onChange, toggled]
    );

    /******************************** Effects & Handles ********************************** */
    useEffect(() => {
      setToggled(value);
    }, [value]);

    return (
      <div className={styling("wrapper")}>
        <div
          className={clsx(styling("container", disabled && "disabled"), className)}
          style={style}
          onClick={handleClick}
        >
          <input type="checkbox" {...formProps} checked={toggled ?? true} style={{ display: "none" }} />
          <div className={clsx(styling("switch"), toggled && styling("checked"))}>
            <div className={styling("switch-button")}></div>
          </div>
          <div className={styling("switch-details")}>
            {title && <h6>{title}:</h6>}
            <p className={styling("label")}>
              {label}
              {required && <span>*</span>}
            </p>
          </div>
        </div>
        {error && (
          <div className={styling("error-container")}>
            <p className={styling("message")}>{error}</p>
          </div>
        )}
      </div>
    );
  }
);
