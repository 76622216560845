import { Calendar, normaliseID, useCachedQuery, useFetch, useStyling, useToday } from "src/shiftly-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faEllipsisVertical, faFileCsv } from "@fortawesome/pro-solid-svg-icons";
import { useEffect, useState, useCallback, useMemo } from "react";
import { motion } from "framer-motion";
import { throttle } from "lodash";
import { useScheduler } from "src/contexts/Scheduler";
import { faCalendar, faDownload, faEnvelope, faPrint, faRefresh, faSparkles } from "@fortawesome/pro-regular-svg-icons";
import SchedulerTemplates from "../calendar/templates/SchedulerTemplates";
import useShiftManager from "src/hooks/useShiftManager";
import styles from "./SchedulerHeader.module.scss";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import SchedulerPublishRoster from "./SchedulerPublishRoster";
import SchedulerHeaderDropdown from "./components/SchedulerHeaderDropdown";

const getPeriodText = (period = []) => {
  const start = period[0]?.toLocaleDateString("en-AU", {
    month: "short",
    day: "numeric",
  });
  const end = period[period.length - 1]?.toLocaleDateString("en-AU", {
    month: "short",
    day: "numeric",
  });
  return `${start} - ${end}`;
};

const SchedulerHeader = () => {
  /*************************************** State *************************************** */
  const [showCalendar, setShowCalendar] = useState(false);
  const [showSchedulerTemplates, setShowSchedulerTemplates] = useState(false);

  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const { deleteShifts } = useShiftManager();
  const { activeLocation } = useShiftlyLocation();
  const {
    Roster: { GetRosterForCurrentPeriod },
  } = useCachedQuery();

  const { today } = useToday();

  const {
    periods = {},
    fromDate,
    setFromDate,
    direction,
    setDirection,
    duration,
    shifts,
    internalShifts,
    setIsLoading,
  } = useScheduler();

  /************************************** Queries ************************************** */

  const {
    data: [currentRoster],
  } = useFetch({
    request: {
      entity: "Roster",
      method: "get",
      criteria: {
        date: periods?.current?.[3] ?? today,
        location: normaliseID(activeLocation),
      },
      id: GetRosterForCurrentPeriod,
    },
    dependency: normaliseID(activeLocation) && periods,
    options: {
      enabled: Boolean(!!normaliseID(activeLocation) && periods?.current?.length),
    },
  });

  /******************************** Functions & Memos ********************************** */
  const handleSetDirection = throttle((newDirection) => {
    setIsLoading(true);
    setDirection(newDirection);
  }, 500);

  const handleClearSchedule = useCallback(() => {
    deleteShifts([...shifts, ...internalShifts]);
  }, [shifts, internalShifts, deleteShifts]);

  const exportActions = useMemo(() => {
    return [
      {
        label: "Download shift calendar",
        icon: faFileCsv,
        onClick: () => {},
      },
      {
        label: "Print to PDF",
        icon: faPrint,
        onClick: () => {},
      },
      {
        label: "Generate Calendar Event",
        icon: faCalendar,
        onClick: () => {},
      },
    ];
  }, []);

  const optionsActions = useMemo(() => {
    return [
      {
        label: (
          <>
            Send <strong>Open</strong> shifts to all staff
          </>
        ),
        icon: faEnvelope,
        onClick: () => {},
      },
      {
        label: (
          <>
            Upgrade all open shifts to <strong>on-demand</strong>
          </>
        ),
        icon: faSparkles,
        onClick: () => {},
      },
      { divider: true },
      {
        label: (
          <>
            Clear all <strong>internal</strong> shifts
          </>
        ),
        icon: faRefresh,
        onClick: handleClearSchedule,
      },
    ];
  }, [handleClearSchedule]);

  /******************************** Effects & Handles ********************************** */
  useEffect(() => {
    setShowCalendar(false);
  }, [fromDate]);

  return (
    <>
      <div className={styling("container")}>
        <div className={styling("flex", "position-relative")}>
          <div className={styling("control", "control-left")} onClick={() => handleSetDirection("100%")}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
          <div className={styling("control", "control-date")}>
            <div className={styling("periods")}>
              <motion.p
                animate={{ x: direction === "100%" ? direction : 0 }}
                transition={{ duration }}
                className={styling("prev-period")}
              >
                {getPeriodText(periods.prev)}
              </motion.p>
              <motion.p
                animate={{ x: direction }}
                transition={{ duration }}
                onClick={() => setShowCalendar((prev) => !prev)}
              >
                {getPeriodText(periods.current)}
              </motion.p>
              <motion.p
                animate={{ x: direction === "-100%" ? direction : 0 }}
                transition={{ duration }}
                className={styling("next-period")}
              >
                {getPeriodText(periods.next)}
              </motion.p>
            </div>
            <div className={styling("calendar", showCalendar && "visible")}>
              {showCalendar && <Calendar value={fromDate} setValue={setFromDate} range={false} />}
            </div>
          </div>
          <div className={styling("control", "control-right")} onClick={() => handleSetDirection("-100%")}>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>
        <div className={styling("flex", "ml-auto")}>
          <div className={styling("action-btn", showSchedulerTemplates && "open")}>
            <div className={styling("click-wrapper")} onClick={() => setShowSchedulerTemplates((prev) => !prev)}></div>
            <FontAwesomeIcon icon={faCalendar} />
            <p>Schedule Templates</p>
            <SchedulerTemplates open={showSchedulerTemplates} setOpen={setShowSchedulerTemplates} />
          </div>
          <SchedulerHeaderDropdown options={exportActions} buttonText={"Export"} buttonIcon={faDownload} />
          <SchedulerHeaderDropdown options={optionsActions} buttonText={"Options"} buttonIcon={faEllipsisVertical} />
          <SchedulerPublishRoster roster={currentRoster} />
        </div>
      </div>
    </>
  );
};
export default SchedulerHeader;
