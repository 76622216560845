import styles from "./NewShiftModalTotal.module.scss";
import { useStyling, Tooltip } from "src/shiftly-ui";
import { faExclamationCircle, faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NewShiftModalTotal = ({ totalRange, tab, internalStaffTotals }) => {
  /*************************************** State *************************************** */

  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);

  /********************************** Refs & Constants ********************************* */

  /******************************** Functions & Memos ********************************** */

  /************************************** Queries ************************************** */

  /******************************** Effects & Handles ********************************** */

  return tab === 0 ? (
    <div className={styling("calc-container, m-1")}>
      <div className={styling("calc-total")}>
        <Tooltip
          position={"top"}
          title={"Why is this a range?"}
          text={
            "The exact amount depends on the age of the shifter who fills the shift. According to the relevant award, adult workers are entitled to higher pay rates."
          }
        >
          <FontAwesomeIcon icon={faQuestionCircle} className={styling("mr-3")} />
        </Tooltip>
        <h6>
          Total Charge:
          <span>
            ${totalRange.min} - ${totalRange.max}
          </span>
        </h6>
      </div>
    </div>
  ) : (
    <div className={styling("calc-container, m-1")}>
      <div className={styling("calc-total")}>
        <Tooltip
          position={"top"}
          title={"Calculations are an estimate"}
          text={
            "The total shift cost is an estimate based on the award rate, super contributions and penalties. The actual shift cost may vary."
          }
        >
          <FontAwesomeIcon icon={faExclamationCircle} className={styling("mr-3")} />
        </Tooltip>
        <h6>
          Total Shift Cost*:
          <span>${internalStaffTotals?.shiftTotal}</span>
        </h6>
      </div>
    </div>
  );
};

export default NewShiftModalTotal;
