import { withDisplay, Link, useStyling, useUI } from "src/shiftly-ui";
import React, { useLayoutEffect } from "react";
import SchedulerHeader from "./header/SchedulerHeader";
import SchedulerCalendarGrid from "./calendar/SchedulerCalendarGrid";
import SchedulerCalendarNav from "./calendar/SchedulerCalendarNav";
import { useNavigate } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import { HTML5Backend } from "react-dnd-html5-backend";
import SchedulerContextMenu from "./SchedulerContextMenu";
import useContextMenu from "./hooks/useContextMenu";
import styles from "./Scheduler.module.scss";

const Scheduler = React.memo(({ isMobile, isLaptop }) => {
  /*************************************** Hooks *************************************** */
  const navigate = useNavigate();
  const { handleRightClick, handleTouchEnd, handleTouchStart, ...contextMenu } = useContextMenu();
  const styling = useStyling(styles);
  const {
    settings: { isAThirdPartyProvider },
  } = useUI();

  /******************************** Effects & Handles ********************************** */
  useLayoutEffect(() => {
    if (isAThirdPartyProvider) navigate("/people");
  }, [isAThirdPartyProvider, navigate]);

  // Navigate to mobile shifts if isMobile is true
  useLayoutEffect(() => {
    if (isMobile) navigate("/mobile-shifts");
  }, [isMobile, navigate]);

  return (
    <DndProvider backend={isLaptop ? HTML5Backend : TouchBackend}>
      <SchedulerContextMenu {...contextMenu} />
      <div
        className={styling("container")}
        onContextMenu={handleRightClick}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <SchedulerHeader />
        <div className={styling("calendar")}>
          <SchedulerCalendarNav />
          <SchedulerCalendarGrid />
          <div className={styling("new-position")}>
            <div className={styling("new-pos")}>
              <Link to={"/positions?mode=new"}> + Add New Position</Link>
            </div>
          </div>
        </div>
      </div>
    </DndProvider>
  );
});

export default withDisplay(Scheduler);
