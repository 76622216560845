import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./ConfirmApplicant.module.scss";
import {
  Modal,
  ModalLabel,
  useStyling,
  OrangeSpan,
  Button,
  ButtonGroup,
  roundNumber,
  Link,
  normaliseID,
} from "src/shiftly-ui";
import { faCalendar, faClock, faCoffee } from "@fortawesome/pro-regular-svg-icons";
import usePaymentMethods from "src/hooks/usePaymentMethods";
import { useCallback } from "react";
import useShiftlyApplicants from "../hooks/useShiftlyApplicants";

const ConfirmApplicant = ({ user, shift, profile, showModal, setShowModal, application }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const { actionApplicant, loading } = useShiftlyApplicants();

  /******************************** Functions & Memos ********************************** */
  const handleConfirm = useCallback(async () => {
    await actionApplicant({ application_id: normaliseID(application), user, shift, action: "accepted" });
    setShowModal(false);
  }, [actionApplicant, application, shift, user, setShowModal]);

  return (
    <Modal showModal={showModal} setShowModal={setShowModal} label={<ModalLabel />}>
      <h2 className={styling("text-center", "my-4")}>
        <OrangeSpan>Confirm</OrangeSpan> {user?.first_name} for this shift?
      </h2>
      <div className={styling("flex", "boxes")}>
        <ShifterBlock user={user} shift={shift} profile={profile} />
        <CostsBlock shift={shift} application={application} />
      </div>
      <ButtonGroup className={styling("footer", "flex-between")} noBottomPadding>
        <Button
          theme={"secondary-outline"}
          onClick={() => setShowModal(false)}
          className={styling("w-100", "sm-w-auto")}
        >
          Cancel
        </Button>
        <Button theme={"primary"} className={styling("w-100", "sm-w-auto")} onClick={handleConfirm} loading={loading}>
          Confirm
        </Button>
      </ButtonGroup>
    </Modal>
  );
};

export default ConfirmApplicant;

const ShifterBlock = ({ shift, user, profile }) => {
  const styling = useStyling(styles);

  return (
    <div className={styling("w-100", "sm-w-50", "block", "shifter")}>
      <div className={styling("profile-picture")}>
        <img src={profile?.profile_picture} alt={"Shifter Profile"} className={styling("cover")} />
      </div>

      <h4 className={styling("my-2")}>
        {user?.first_name} {user?.last_name}
      </h4>
      <p className={styling("my-2")}>
        <strong>{shift?.position?.name}</strong> - Level {shift?.position?.classification_level}
      </p>

      <IconRow icon={faCalendar} text={`${shift?.day_of_week} ${shift?.prettyDate}`} />
      <IconRow icon={faClock} text={`${shift?.shiftPeriod}`} />
      {shift?.breakTime?.split(",")?.map((breakTime, index) => (
        <IconRow key={index} icon={faCoffee} text={breakTime} />
      ))}
    </div>
  );
};

const IconRow = ({ icon, text }) => {
  const styling = useStyling(styles);
  return (
    <div className={styling("flex", "flex-row", "flex-start", "my-2")}>
      <div className={styling("icon")}>
        <FontAwesomeIcon icon={icon} />
      </div>
      <p className={styling("mx-1")}>{text}</p>
    </div>
  );
};

const CostsBlock = ({ shift, application }) => {
  const styling = useStyling(styles);
  const { defaultPaymentMethod } = usePaymentMethods();

  return (
    <div className={styling("w-100", "sm-w-50", "block", "costs")}>
      <div className={styling("cost")}>
        <p>Award Rate ({shift?.duration} hours)</p>
        <p>${roundNumber(application?.base_amount)}</p>
      </div>
      <div className={styling("cost")}>
        <p>Employer Contribution</p>
        <p>${roundNumber(application?.employer_contribution)}</p>
      </div>
      <div className={styling("cost")}>
        <p>Late night penalties</p>
        <p>${roundNumber(application?.late_night_bonus)}</p>
      </div>
      <div className={styling("cost")}>
        <p>Super Contribution</p>
        <p>${roundNumber(application?.super_amount)}</p>
      </div>
      <div className={styling("cost")}>
        <p>Shiftly Fee ({process.env.REACT_APP_SHIFTLY_PERCENTAGE * 100}%)</p>
        <p>${roundNumber(application?.shiftly_fee)}</p>
      </div>
      <div className={styling("totals")}>
        <div className={styling("cost")}>
          <h6 className={styling("mt-3")}>TOTAL FEE</h6>
          <p>
            <strong>${roundNumber(application?.total)}</strong>
          </p>
        </div>
        <p className={styling("my-2")}>
          {defaultPaymentMethod?.type === "card" ? "Card" : "Bank account"} ending in{" "}
          <strong> xxxx - {defaultPaymentMethod?.card?.last4 ?? defaultPaymentMethod?.au_becs_debit?.last4}</strong>{" "}
          will be charged.
        </p>
        <p className={styling("caption")}>
          To change this, go to your{" "}
          <Link to={"/business-account/billing-and-payment"} className={styling("mx-1")}>
            Billing & Payment
          </Link>{" "}
          settings
        </p>
      </div>
    </div>
  );
};
