import { Each, useStyling, useDelayUnmount, Dropdown, EmptyState } from "src/shiftly-ui";
import styles from "./IntegrationMap.module.scss";
import { useMemo } from "react";
import useIntegration from "src/hooks/useIntegration";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { faTrash } from "@fortawesome/pro-duotone-svg-icons";

const IntegrationMap = ({ toLink, options = [], mode, data, setData, onCreate = () => {}, onDelete }) => {
  /*************************************** Hooks *************************************** */
  const styling = useStyling(styles);
  const { locationMap, isFetching } = useIntegration("deputy");
  const loading = useDelayUnmount(isFetching, 500);

  /******************************** Functions & Memos ********************************** */
  const groupedThirdPartyPositions = useMemo(() => {
    return toLink.reduce((acc, curr) => {
      if (!acc[curr?.location]) {
        acc[curr?.location] = [];
      }
      acc[curr?.location].push(curr);
      return acc;
    }, {});
  }, [toLink]);

  const groupedShiftlyPositions = useMemo(() => {
    const grouped = options.reduce((acc, curr) => {
      if (!acc[curr?.location]) {
        acc[curr?.location] = [];
      }
      acc[curr?.location].push(curr);
      return acc;
    }, {});

    Object.entries(grouped).forEach(([locationID, positions]) => {
      //Group Positions by their group
      const groupedByGroup = positions.reduce((acc, curr) => {
        if (!acc[curr?.group?._id]) {
          acc[curr?.group?._id] = curr?.name;
        }
        return acc;
      }, {});
      grouped[locationID] = Object.entries(groupedByGroup).map(([groupID, groupName]) => ({
        label: groupName,
        value: groupID,
      }));
    });
    return grouped;
  }, [options]);

  return (
    <div className={styling("")}>
      {mode === "businesses" ? (
        <Each
          of={toLink}
          render={(item = {}) => (
            <Row
              key={item.key + item.id + item.name}
              text={item.name}
              data={data}
              setData={setData}
              id={item.id}
              options={options}
              mode={mode}
              onCreate={() => onCreate(item)}
              onDelete={onDelete && (() => onDelete(item))}
            />
          )}
        />
      ) : (
        <EmptyState loading={loading}>
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: loading ? 0 : 1 }}>
            <Each
              of={Object.values(groupedThirdPartyPositions)}
              render={(group) => {
                const location = locationMap[group[0]?.location];
                return (
                  <div className={styling("mb-4")} key={location?._id}>
                    <h3 className={styling("text-left", "mb-3", "ml-4")}>{location?.name}</h3>
                    <Each
                      of={Object.values(group)}
                      render={(item = {}) =>
                        item.id && (
                          <Row
                            key={item.key + item.id + item.name + location?._id}
                            text={item.name}
                            data={data}
                            setData={setData}
                            id={item.id}
                            options={groupedShiftlyPositions[location?._id]}
                            mode={mode}
                            onCreate={() => onCreate(location)}
                          />
                        )
                      }
                    />
                  </div>
                );
              }}
            />
          </motion.div>
        </EmptyState>
      )}
    </div>
  );
};
const Row = ({ text, options = [], setData, id, data, mode, onCreate, onDelete }) => {
  const styling = useStyling(styles);
  return (
    <div className={styling("flex", "flex-between", "p-3")}>
      <div className={styling("third-party")}>
        <p>{text}</p>
      </div>
      <div className={styling("mx-3")}>
        <svg width="100" height="20" xmlns="http://www.w3.org/2000/svg">
          <line x1="0" y1="10" x2="90" y2="10" stroke="gray" strokeWidth="2" strokeDasharray="5, 5" />
          <polyline points="75,5 85,10 75,15" fill="none" stroke="gray" strokeWidth="2" />
        </svg>
      </div>
      <div className={styling("shiftly")}>
        <Dropdown
          options={mode === "businesses" ? options.map((opt) => ({ label: opt?.name, value: opt?._id })) : options}
          value={data[id] || ""}
          setValue={(v) => setData((prev) => ({ ...prev, [id]: v || null }))}
          placeholder={"Select Shiftly Mapping"}
        />
      </div>
      <div className={styling("create")} onClick={onCreate}>
        <FontAwesomeIcon icon={faPlus} />
      </div>
      {onDelete && (
        <div className={styling("trash")} onClick={onDelete}>
          <FontAwesomeIcon icon={faTrash} />
        </div>
      )}
    </div>
  );
};
export default IntegrationMap;
